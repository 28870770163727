import React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import Colors from "../../../assets/Colors.json";
import LoginEngine from "./Pages/Login/LoginEngine";
import SessionsEngine from "./Pages/Sessions/SessionsEngine";

const AssistantRoutes = ({ strings, config, lang, setLang, selectLang }) => {
  const colors = Colors.lightTheme;
  let { path } = useRouteMatch();

  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
  };

  return (
    <div style={Wrapper}>
      <Switch>
        <Route path={`${path}/login`} children={<LoginEngine strings={strings} config={config} lang={lang} setLang={setLang} selectLang={selectLang} />} />
        <Route path={`${path}/sessions`} children={<SessionsEngine strings={strings} config={config} lang={lang} selectLang={selectLang} />} />
        <Route path={`/`} children={<Redirect to={{ pathname: `${path}/login` }} />} />
      </Switch>
    </div>
  );
};

export default AssistantRoutes;
