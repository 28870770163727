import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../../shared/TextInput";
import Button from "../../../../../shared/Button";
import Switch from "../../../../../shared/Switch";
import Select from "../../../../../shared/Select";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import IconUpload from "../../../../../../assets/images/ic_upload.png";
import Colors from "../../../../../../assets/Colors.json";
import Config from "../../../../../../assets/Config";

const DEFAULT_LOGOS = {
  DEV: "gs://smartwinetast-91aca.appspot.com/CommonFiles/upwine-logo.svg",
  PROD: "gs://uptasting-prod.appspot.com/CommonFiles/upwine-logo.svg"
}


const FormComponent = ({ celler, setCeller, strings, onImageUpload, confirmAction, cancelAction, goToPlans, cancelSubscrption, updateImage, changePw, enableTooltips, config, }) => {
  new Config();
  let defaultLogo = DEFAULT_LOGOS[Config.ENV || "DEV"];

  const Currencys = {
    "€": "Euros (€)",
    USD: "Dolares Americanos (USD)",
    CLP: "Pesos chilenos (CLP)",
    MXN: "Pesos mejicanos (MXN)",
    ARS: "Pesos argentinos (ARS)",
    PEN: "Soles peruanos (PEN)",
    COP: "Pesos colombianos (COP)",
    CHF: "Franco suizo (CHF)",
  };

  const Socials = {
    instagram: "Instagram",
    facebook: "Facebook",
    twitter: "Twitter",
    linkedIn: "LinkedIn",
    instagram: "Instagram"
  }

  const Container = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };

  const Wrapper = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    padding: "0.5rem 2rem",
  };

  const ButtonWrapper = {
    display: "flex",
    placeItems: "center",
    margin: "3vh",
  };

  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
`;

  const UpperSection = {
    display: "flex",
  };

  const Input = {
    width: "100%",
    boxSizing: "border-box",
  };

  const Subheader = styled.div`
    margin: 1.5rem 0.5rem 1rem 0rem;
    font-weight: bold;
    font-size: 2.5vh;
    color: ${Colors.lightTheme.accentColor1};
    padding-bottom: 0.5rem;
    border-bottom: 1px solid gray;
  `;

  const Tag = styled.div`
    margin: 1rem 1rem 0.5rem 0rem;
    flex: 1;
  `;

  const LogoImg = {
    maxHeight: "70%",
    maxWidth: "70%",
    cursor: "pointer",
    margin: "5%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
  };

  const ImageInput = styled.input`
    display: none;
  `;

  const LogoBackground = {
    position: "relative",
    height: "10vw",
    width: "20vw",
    borderRadius: "0.3vh",
    border: "2px solid rgb(216, 221, 225)",
    textAlign: "center",
    padding: "auto",
    margin: "1.5vh auto",
    backgroundColor: "#443f3f"
  };

  const Hint = styled.div`
    border-radius: 0.3vh;
    border: 2px solid rgb(216, 221, 225);
    color: black;
    background-color: rgb(216, 221, 225);
    text-align: center;
    padding: 1vh;
    font-size: 1.6vh;
  `;

  const SubscriptionWrapper = {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "baseline",
  };

  const SubscriptionTag = {
    fontWeight: "bold",
    margin: "0.5rem 0rem",
    width: "30%",
  };

  const update = (param, value) => {
    setCeller((prevState) => ({
      ...prevState,
      [param]: value,
    }));
    config.update("modificated", true);
    console.log(param, value);
  };

  const updateSocialMedia = (param, value) => {
    setCeller((prevState) => ({
      ...prevState,
      social: {
        ...prevState?.social,
        [param]: value,
      },
    }));
    config.update("modificated", true);
  };

  const getImageSource = () => {
    if (celler.logo == null || celler.logoStorageRef === defaultLogo) {
      return IconUpload;
    } else {
      return celler.logo;
    }
  };

  const checkCurrency = () => {
    if (celler.storeActive && !celler.externalStore) {
      update("currency", "€");
    }
  };

  return (
    <div style={Container}>
      <Title>{strings.updateCeller}</Title>

      <div style={Wrapper}>
        <div style={UpperSection}>
          <div id="leftColumn" style={{ width: "60%" }}>
            <Subheader>{strings.mydata}</Subheader>

            {/* Name */}
            <div>
              <Tag>{strings.nameCeller}</Tag>
              <TextInput
                id="name"
                style={Input}
                placeholder={strings.nameCeller}
                value={celler.name ? celler.name : null}
                onChange={(e) => update("name", e.target.value)}
              />
            </div>

            {/* Web */}
            <div>
              <Tag>{strings.webPage}</Tag>
              <TextInput
                id="webPage"
                style={Input}
                placeholder={strings.webPagePlaceholder}
                value={celler.webPage ? celler.webPage : null}
                onChange={(e) => update("webPage", e.target.value)}
              />
            </div>

            {/*Subscription plan*/}
            <div>
              <Tag>{strings.yourplan}</Tag>
              <div style={SubscriptionWrapper}>
                <div style={SubscriptionTag}>{config.plan ? config.plan.name : ""}</div>
                <Button value={strings.changePlan} action={goToPlans} style={{ margin: "0 1vw" }} />
                <SecondaryButton value={strings.cancelSubscription} action={cancelSubscrption} />
              </div>
            </div>

            <Subheader>{strings.settingsstore}</Subheader>

            <div style={{ display: "flex" }}>
              {/* Store Active */}
              <div style={{ width: "50%", margin: "auto" }}>
                <Switch
                  strings={strings}
                  id="activeStore"
                  value={celler.storeActive}
                  action={() => {
                    update("storeActive", !celler.storeActive);
                    update("externalStore", true);
                    checkCurrency();
                  }}
                />
              </div>

              <div style={{ display: "flex", width: "50%" }}>
                <div style={{ display: "flex", margin: "auto" }}>
                  <Tag style={{ margin: "auto", marginRight: "1vw" }}>Divisa</Tag>
                  <Select defaultOption={celler.currency} options={Currencys} id={"currency"} onChange={update} />
                </div>
              </div>
            </div>

            <div>
              <td colSpan="2" style={{ paddingTop: "1vw" }}>
                <Hint>{strings.externalStoreHint}</Hint>
              </td>
            </div>

            <Subheader>{strings.socialmedia}</Subheader>

            {Object.entries(Socials).map(([socialId, socialName]) => (
              <div style={{ display: "flex", paddingBottom: "1vh" }}>
                <Tag>{socialName}</Tag>
                <TextInput
                  id={socialId}
                  value={celler?.social?.[socialId] || null}
                  rootStyles={{ flex: "4" }}
                  onChange={(e) => {
                    updateSocialMedia(socialId, e.target.value);
                  }}
                />
              </div>
            ))}

            <Subheader>{strings.otherSettings}</Subheader>

            {/* Satisfaction active*/}
            <Switch
              strings={strings}
              id="satisfactionActive"
              value={celler.satisfactionActive}
              action={() => {
                update("satisfactionActive", !celler.satisfactionActive);
              }}
            />
          </div>

          {/*Logo component*/}
          <div id="rightColum" style={{ width: "40%", marginLeft: "1.5rem", paddingLeft: "1.5rem", borderLeft: "1px solid gray" }}>
            <Subheader>{strings.companylogo}</Subheader>

            <div style={LogoBackground}>
              <img id="cellerimage" style={LogoImg} onClick={updateImage} src={getImageSource()} />
              <ImageInput
                type="file"
                id="imageInput"
                onChange={(e) => {
                  onImageUpload(e.target);
                }}
              />
            </div>

            <Hint>{strings.hintlogo}</Hint>

            <Subheader>{strings.otherActions}</Subheader>
            <Switch strings={strings} id="tooltips" value={config.tooltipsEnabled} action={enableTooltips} />
            <Button value={strings.changePw} action={changePw} style="margin: 1vh 0vh; width: 90%;" />
          </div>
        </div>

        <div style={ButtonWrapper}>
          <SecondaryButton value={strings.cancel} action={cancelAction} style="margin: 0 1rem 0 auto" />
          <Button value={strings.confirm} action={confirmAction} style="margin: 0 auto 0 0" />
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
