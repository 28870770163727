import React, { useState, useEffect } from "react";
import Firebase from "../../../../../assets/Firebase";
import LoadingScreen from "../../../../shared/LoadingScreen";
import InstructorRoutes from "./InstructorRoutes";

const InstructorEngine = ({ strings, config }) => {
  let f = new Firebase();
  const [instructors, setInstructors] = useState({});
  const [totalInstructors, setTotalInstructors] = useState();
  const [ready, setReady] = useState(false);

  const success = (snap) => {
    setTotalInstructors(snap.size);

    snap.forEach((doc) => {
      setInstructors((prevState) => ({
        ...prevState,
        [doc.id]: {
          name: doc.data().name,
        },
      }));
    });
  };

  const load = () => {
    f.loadInstructors(success);
  };

  useEffect(() => {
    if (Object.keys(instructors).length === totalInstructors) {
      setReady(true);
    }
  }, [totalInstructors, instructors]);

  useEffect(() => {
    load();
  }, [strings]);

  return (
    <>
      {ready ? (
        <InstructorRoutes strings={strings} config={config} ready={ready} instructors={instructors} setInstructors={setInstructors} refresh={load} />
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};
export default InstructorEngine;
