import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import Table from "../../../../../shared/Table";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import CloneIcon from "../../../../../../assets/images/copy-clipboard.svg";
import CloneFormIcon from "../../../../../../assets/images/copy-form.svg";


const ListComponent = ({ strings, config, products, createProduct, editProduct, cloneProduct, cloneProductStructure, deleteProduct, ready }) => {
  const Container = styled.div`
    width: 100%;
  `;
  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;
  const Wrapper = styled.div`
    flex-grow: 1;
    padding: 2vh 5vh;
    > * {
      margin: 2vh 0;
    }
  `;

  const Icon = styled.img``;
  let actions = [
    {
      title: (
        <Tooltip title={strings.cloneProductStructure} aria-label="add">
          <Icon src={CloneFormIcon} />
        </Tooltip>
      ),
      action: cloneProductStructure,
    },
    {
      title: (
        <Tooltip title={strings.cloneProduct} aria-label="add">
          <Icon src={CloneIcon} />
        </Tooltip>
      ),
      action: cloneProduct,
    },
    {
      title: (
        <Tooltip title={strings.modifyProduct} aria-label="add">
          <Icon src={EditIcon} />
        </Tooltip>
      ),
      action: editProduct,
    },
    {
      title: (
        <Tooltip title={strings.deleteProduct} aria-label="add">
          <Icon src={DeleteIcon} />
        </Tooltip>
      ),
      action: deleteProduct,
    },
  ];
  return (
    <Container>
      <Title>{strings.productsList}</Title>
      <Wrapper>
        <Table header={strings.ProductsTable}
          data={products}
          actions={actions}
          ready={ready}
          noElementsText={strings.noneList}
          sortByField={"lastUpdate"}
          sortOrder={"descending"} />
        <Button value={strings.addProduct} action={createProduct} />
      </Wrapper>
    </Container>
  );
};

export default ListComponent;
