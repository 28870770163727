import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, useRouteMatch } from "react-router-dom";
import ListEngine from "./List/ListEngine";
import FormEngine from "./Form/FormEngine";
import CreateEngine from "./Create/CreateEngine";

const ReportsRoutes = ({ strings, config }) => {
    const Wrapper = {
        width: "100%",
        height: "100%",
        display: "flex",
    };

    let { path } = useRouteMatch();
    return (
        <div style={Wrapper}>
            <Switch>
                <Route path={`${path}/list`}>
                    <ListEngine strings={strings} config={config} />
                </Route>
                <Route path={`${path}/create`}>
                    <CreateEngine strings={strings} config={config} />
                </Route>
                <Route path={`${path}/:type/:id`}>
                    <FormEngine strings={strings} config={config} />
                </Route>
            </Switch>
        </div>
    );
};

export default ReportsRoutes;