import React, { useState, useEffect } from "react";
import BackendAccessor from "../../../../../../../assets/BackendAccessor";
import { BrowserRouter as Router, useHistory, useParams, useLocation } from "react-router-dom";
import ProductsDetailsWrapperForm from "./ProductsDetailsForm";

const FormEngine = ({ strings, config, actions, product, assistantsRanking, sesionAssistant, lang }) => {
    let history = useHistory();
    let ba = new BackendAccessor();

    const [assistantData, setAssistantData] = useState(null);

    useEffect(() => {
        if (assistantsRanking) {
            loadAssistantData();
        }
    }, [assistantsRanking]);

    const loadAssistantData = () => {

        Object.entries(assistantsRanking).forEach(([key1, assistant]) => {
            if (assistant?.reference === sesionAssistant) {
                console.log(assistant)
                setAssistantData(assistant);
            }
        })
    }

    let getParameterValue = (productReference, parameterId) => {
        let categoryId = null;

        if (Array.isArray(parameterId)) {
            categoryId = parameterId[0];
            parameterId = parameterId[1];
        }

        if (categoryId) {
            return assistantData?.assessments?.[productReference]?.categories?.[categoryId]?.[parameterId];
        } else {
            return assistantData?.assessments?.[productReference]?.parameters?.[parameterId];
        }
    }

    /* Se usa - 1*/

    const getRate = (productReference) => {
        return getParameterValue(productReference, "rateBox");
    }

    const getPrice = (productReference) => {
        return getParameterValue(productReference, "blindPrice");
    }

    const getCualityPrice = (productReference) => {
        let sums = getParameterValue(productReference, "ratePrice");
        let selected = null;

        if (sums !== "not_rated") {
            selected = sums;
        }

        return selected ? selected : "-";
    }

    /*---------------------------------------------------*/

    const getProduct = (productReference) => {
        let references = assistantData?.session?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let product = reference?.product;
        return product;
    }

    const getProductName = (productReference) => {
        let references = assistantData?.session?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let productName = reference?.product?.name;
        return productName ? productName : "-";
    }

    const getParameterName = (productReference, parameterId) => {
        const defaultParameters = {
            rateBox: strings.generalAssessment,
            ratePrice: strings.priceQualityAssessment,
            blindPrice: strings.blindPrice,
            notes: "Anotaciones"
        }

        let references = assistantData?.session?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let parameter = reference?.assessmentSheet?.parameters.find(par => par.id === parameterId);
        let parameterName = parameter?.name?.[lang];

        if (parameterName === null || parameterName === undefined) {
            parameterName = defaultParameters?.[parameterId];
        }
        return parameterName;
    }

    const getCategoryName = (productReference, categoryId) => {
        let references = assistantData?.session?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let category = reference?.assessmentSheet?.categories.find(cat => cat?.id === categoryId);
        let categoryName = category?.name?.[lang];
        return categoryName ? categoryName : "-";
    }

    const getCategoryParameterName = (productReference, categoryId, parameterId) => {
        let references = assistantData?.session?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference == productReference);
        let category = reference?.assessmentSheet?.categories?.find(cat => cat?.id === categoryId);
        let component = category?.components?.find(comp => comp?.id === parameterId);;
        let paramaterName = component?.name?.[lang];
        return paramaterName ? paramaterName : "-";
    }

    const getOptionsName = (productReference, categoryId, parameterId, optionsIds) => {
        let references = assistantData?.session?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let category = reference?.assessmentSheet?.categories?.find(cat => cat?.id === categoryId);
        let component = category?.components?.find(comp => comp?.id === parameterId);
        let optionsName = "";

        if (typeof optionsIds === 'string' || optionsIds instanceof String) {
            let option = findOption((component?.options || []), optionsIds, false);
            optionsName = option?.name?.[lang];

        } else if (typeof optionsIds === 'object' && optionsIds !== null) {
            Object.entries(optionsIds).forEach(([optionId, responseId]) => {
                let option = findOption((component?.options || []), optionId, true);
                if (optionsName == "") {
                    optionsName = option?.name?.[lang]
                } else {
                    optionsName += ", " + option?.name?.[lang];
                }
            });
        }
        return optionsName;
    }

    const findOption = (optionsArray, optionId, recursive) => {
        for (let index = 0; index < optionsArray.length; index++) {
            let option = optionsArray[index];
            if (option.id === optionId) {
                return option;
            }
            if (recursive && option.options && option.options.length > 0) {
                let suboption = findOption(option.options, optionId, recursive);
                if (suboption != null) {
                    return suboption;
                }
            }
        }
        return null;
    }

    const parsedData = () => {
        let data = {}
        data.type = "saved";
        data.getRate = getRate;
        data.getCualityPrice = getCualityPrice;
        data.getPrice = getPrice;

        data.getProduct = getProduct;
        data.getProductName = getProductName;
        data.getParameterName = getParameterName;
        data.getCategoryName = getCategoryName;
        data.getCategoryParameterName = getCategoryParameterName;
        data.getOptionsName = getOptionsName;

        data.instructor = assistantData?.session?.instructor?.name;

        return data;
    }

    return (
        <ProductsDetailsWrapperForm
            strings={strings}
            config={config}
            product={parsedData().getProduct(product)}
            productPath={product}
            assessment={assistantData?.assessments?.[product]}
            dataParser={parsedData}
            goBack={actions.goBackList}
            lang={lang}
            goToLink={actions.goToLink} />
    );
};
export default FormEngine;