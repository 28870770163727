import React from "react";
import styled from "@emotion/styled";

import TextInput from "../../../../../shared/TextInput";
import Button from "../../../../../shared/Button";
import SecondaryButton from "../../../../../shared/SecondaryButton";

const PasswordForm = ({ strings, passwordData, setData, changePw, cancel }) => {
  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const Container = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  };

  const Center = {
    maxHeight: "50%",
    maxWidth: "50%",
    cursor: "pointer",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
  };

  const Input = {
    width: "100%",
    boxSizing: "border-box",
  };

  const Tag = styled.div`
    margin: 0.5rem 1rem 0.5rem 0rem;
  `;

  const ButtonContainer = {
    margin: "2vh 0vh",
    textAlign: "center",
    width: "95%",
  };

  const ButtonStyle = {
    margin: "0vw 1vw",
  };

  return (
    <div style={Container}>
      <Title>{strings.changePw}</Title>
      <div style={Center}>
        <div>
          <Tag>{strings.currentPw}:</Tag>
          <TextInput
            id="actualPw"
            style={Input}
            type="password"
            value={passwordData.actualPw}
            onChange={(e) => {
              setData("actualPw", e.target.value);
            }}
          />
        </div>
        <div>
          <Tag>{strings.newPw}:</Tag>
          <TextInput
            id="newPw"
            style={Input}
            type="password"
            value={passwordData.newPw}
            onChange={(e) => {
              setData("newPw", e.target.value);
            }}
          />
        </div>
        <div>
          <Tag>{strings.confirmationPw}:</Tag>
          <TextInput
            id="confirmationPw"
            style={Input}
            type="password"
            value={passwordData.confirmationPw}
            onChange={(e) => {
              setData("confirmationPw", e.target.value);
            }}
          />
        </div>
        <div style={ButtonContainer}>
          <SecondaryButton value={strings.cancel} action={cancel} style={ButtonStyle} />
          <Button value={strings.changePw} action={changePw} style={ButtonStyle} />
        </div>
      </div>
    </div>
  );
};
export default PasswordForm;
