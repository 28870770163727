import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import TextInput from "../../../../../../shared/TextInput";
import Button from "../../../../../../shared/Button";
import DeleteIcon from "../../../../../../../assets/images/delete-icon.svg";

const LANG = "ES";

const SurveyOptionsForm = ({ strings, tasting, activeQuestion, updateOptions, deleteOption, addOption }) => {
  const Wrapper = {
    borderTop: "0.1vh solid #6d7278",
    marginTop: "0vh",
    paddingTop: "0vh",
  };

  const DeleteButton = styled.img`
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    margin-right: 0.3rem;
    margin-left: 0rem;
    &:hover {
      background-color: LightGrey;
    }
`;

  const loadOptions = () => {
    let views = [];
    let num = 1;
    for (let key in tasting?.surveyQuestions?.[activeQuestion]?.options) {
      views.push(
        <table style={{ width: "33%", float: "left" }}>
          <tr>
            <td>
              <h4>{strings.option + " " + num}</h4>
            </td>
            <td>
              <TextInput
                width="100%"
                styles={{ float: "left", width: "100%" }}
                defaultValue={tasting?.surveyQuestions?.[activeQuestion]?.options?.[key]?.[LANG]}
                onChange={(e) => {
                  updateOptions(key, {
                    CA: e.target.value,
                    ES: e.target.value,
                    EN: e.target.value,
                  });
                }}
              />
            </td>
            <td>
              <DeleteButton
                onClick={() => {
                  deleteOption(key);
                }}
                src={DeleteIcon}
              />
            </td>
          </tr>
        </table>
      );
      num++;
    }
    return views;
  };

  return (
    <div style={Wrapper}>
      <div>
        <Button
          action={() => {
            addOption();
          }}
          value={strings.addOption}
          style={{ margin: "0.5vw 0" }}
        />
      </div>

      <div style={{ display: "block" }}>{loadOptions()}</div>
    </div>
  );
};

export default SurveyOptionsForm;
