import React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import HomeEngine from "./Pages/Home/HomeEngine";
import ProductsEngine from "./Pages/Products/ProductsEngine";
import AssessmentSheetsEngine from "./Pages/AssessmentSheets/AssessmentSheetsEngine";
import TastingsEngine from "./Pages/Tastings/TastingsEngine";
import CellerEngine from "./Pages/Cellar/CellarEngine";
import InstructorEngine from "./Pages/Instructor/InstructorEngine";
import EventsEngine from "./Pages/Events/EventsEngine";
import ReportsEngine from "./Pages/Reports/ReportsEngine";
import AboutEngine from "./Pages/About/AboutEngine";
import AvatarEngine from "./Pages/Avatar/AvatarEngine";
import ThemeEngine from "./Pages/Theme/ThemeEngine";
import Colors from "../../../assets/Colors.json";
import IntegrationEngine from "./Pages/Integration/IntegrationEngine";

const DashboardRoutes = ({ strings, config }) => {
  const colors = Colors.lightTheme;
  let { path } = useRouteMatch();

  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: colors.grey,
  };

  return (
    <div style={Wrapper}>
      <Switch>
        <Route path={`${path}/home`}>
          <HomeEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/theme`}>
          <ThemeEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/wines`}>
          <Redirect to="/dashboard/products/list" />
        </Route>
        <Route path={`${path}/products`}>
          <ProductsEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/assessmentSheets`}>
          <AssessmentSheetsEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/tastings`}>
          <TastingsEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/cellar`}>
          <CellerEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/instructors`}>
          <InstructorEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/events`}>
          <EventsEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/campaigns`}>
          <Redirect to={{ pathname: "/dashboard/events/podcast" }} />
        </Route>
        <Route path={`${path}/reports`}>
          <ReportsEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/integration`}>
          <IntegrationEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/about`}>
          <AboutEngine strings={strings} config={config} />
        </Route>
        {/*<Route
          path={`${path}`}
          children={
            <Redirect
              to={{
                pathname: "/dashboard/home",
              }}
            />
          }
        />*/}
      </Switch>
    </div>
  );
};

export default DashboardRoutes;
