import React, { useState, useEffect, useRef } from "react";

import ProductFormComponent from "./ProductFormComponent.js";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";
import Colors from "../../../../../../../assets/Colors.json";
import { max } from "moment";

const ProductFormEngine = ({ strings, reportType, config, utils, reportData, sessionData }) => {
    let history = useHistory();
    let { productId } = useParams();

    const [productData, setProductData] = useState(null);

    const loadProductData = (id) => {
        let wineRef = sessionData?.tasting?.reference + "/wineReferences/" + id
        let productRef = sessionData?.tasting?.reference + "/productReferences/" + id
        let productReferences = sessionData?.tasting?.productReferences || [];
        let productReference = productReferences?.find(ref => ref.reference === productRef || ref.reference === wineRef) || {};
        productReference.id = id;

        setProductData(productReference);
    };

    useEffect(() => {
        if (productData == null && productId != null) {
            loadProductData(productId);
        }
    }, [strings, productId]);

    const goBack = () => {
        history.replace("/dashboard/reports/" + reportType + "/" + reportData.id);
    }

    const getTotalOfCategoryParameter = (categoryRef, paramaterRef, optionId) => {
        return utils.getParameterTotal(productData.reference, [geIdFromPath(categoryRef), geIdFromPath(paramaterRef)], optionId);
    }

    const getPercentOfCategoryParameter = (categoryRef, paramaterRef, optionId) => {
        return utils.getParameterPercent(productData.reference, [geIdFromPath(categoryRef), geIdFromPath(paramaterRef)], optionId);
    }

    const geIdFromPath = (path) => {
        let pathParts = path?.split("/");
        let id = pathParts[pathParts.length - 1];
        return id;
    }

    const getAgregatedParameter = (parameterId) => {
        return utils.getParameterSums(productData?.reference, parameterId);
    }

    const containsAgregatedParameter = (parameterId) => {
        let agregatedParameter = getAgregatedParameter(parameterId);
        return agregatedParameter !== null && agregatedParameter !== undefined;
    }

    const getParameterGraphData = (parameterId, header) => {
        let agregatedParameter = getAgregatedParameter(parameterId);
        let labels = [];
        let data = [];

        Object.entries(agregatedParameter || {}).map(([value, option]) => {
            if (!["total", "avg", "sum"].includes(value)) {
                labels.push(value);
                data.push(option);
            }
        });

        return utils.generateGraphData(labels, data, header);
    }

    const getNumericalParameterGraphData = (parameterId, from, to, step) => {
        let agregatedParameter = getAgregatedParameter(parameterId);
        let labels = [];
        let data = [];

        for (let i = from; i <= to; i += step) {
            let totalOfNumber = agregatedParameter?.[i];
            labels.push(i);
            data.push(totalOfNumber);
        }

        return utils.generateGraphData(labels, data, null);
    }

    const getNumericalParameterLineGraphData = (parameterId, numIntervals) => {
        let agregatedParameter = getAgregatedParameter(parameterId);

        let entries = Object.entries(agregatedParameter || {}).map(([value, count]) => [parseFloat(value.replace(",", ".")), count]);

        let maxVal = Number.MIN_VALUE;
        let minVal = Number.MAX_VALUE;

        entries.map(([value, count]) => {
            if (!isNaN(value)) {
                if (value > maxVal) maxVal = value;
                if (value < minVal) minVal = value;
            }
        });

        let interval = (maxVal - minVal) / numIntervals;

        let labels = [];
        let data = [];

        for (let i = minVal; i <= maxVal; i += interval) {
            labels.push(utils.roundDecimal(i) + " - " + utils.roundDecimal(i + interval));
            data.push(0);
        }

        entries.map(([value, option]) => {
            data[Math.floor((value - minVal) / interval)] += option;
        });

        let graphData = utils.generateGraphData(labels, data, null);
        graphData.datasets[0].backgroundColor = graphData.datasets[0].backgroundColor[0];
        graphData.datasets[0].borderColor = graphData.datasets[0].backgroundColor[0];
        return graphData;
    }

    const getParameterAvg = (parameterId) => {
        let agregatedParameter = getAgregatedParameter(parameterId);
        let sum = 0;
        let total = 0;

        Object.entries(agregatedParameter || {}).filter(([value, count]) => {
            if (value && (typeof value === 'string' || value instanceof String)) {
                value = parseFloat(value.replace(",", "."));
            }
            if (value && typeof value === "number" && !isNaN(value)) {
                sum += (value * count);
                total += count;
            }
        });

        if (total > 0) {
            let avg = sum / total;
            return utils.roundDecimal(avg) || 0;
        } else {
            return 0;
        }
    }

    const getParameterTotal = (parameterId) => {
        let agregatedParameter = getAgregatedParameter(parameterId);
        let total = 0;
        Object.entries(agregatedParameter || {}).map(([option, count]) => {
            if (!["total", "avg", "sum"].includes(option)) {
                total += count;
            }
        });
        return utils.roundDecimal(total);
    }

    const getComponentGraphData = (categoryRef, paramaterRef, component) => {
        let componentsSums = utils.getParameterSums(productData.reference, [geIdFromPath(categoryRef), geIdFromPath(paramaterRef)]);
        let labels = [];
        let data = [];

        for (let option of (component?.options || [])) {
            let componentSum = componentsSums?.[option?.id];
            labels.push(option?.name?.[config.lang] || option?.id || "-");
            data.push(componentSum);
        }

        return utils.generateGraphData(labels, data, null);
    }

    const isCategoryRadarGraphDataPossible = (category) => {
        let allowedComponents = category?.components?.filter(component => component?.type === "OPTIONS_SLIDER");
        return allowedComponents?.length >= 3;
    }

    const getCategoryRadarGraphData = (category) => {
        let agregatedCategoryData = utils.getCategoriesSums(productData.reference, geIdFromPath(category.reference));
        let allowedComponents = category?.components?.filter(component => component?.type === "OPTIONS_SLIDER");
        let labels = [];
        let data = [];

        (allowedComponents || []).forEach(comp => {
            let catSum = 0;
            let total = 0;

            (comp?.options || []).forEach((option, index, options) => {
                let puntuation = index / (options.length - 1);
                let optionSum = agregatedCategoryData?.[geIdFromPath(comp?.reference)]?.[option?.id] || 0;
                catSum += puntuation * optionSum;
                total += optionSum;
            });

            let points = 0;

            if (total > 0) {
                points = catSum / total;
            }

            labels.push(comp?.name?.[config.lang]);
            data.push(points * 10);
        });

        return utils.generateGraphData(labels, data, null);
    }

    const getQuizWinners = () => {
        let winners = sessionData?.quizWinners?.filter(winner => winner?.productPath === productData?.reference);
        if (winners !== null && winners?.length > 0) {
            for (let winner of winners) {
                winner.assistant = sessionData?.assistants?.[geIdFromPath(winner?.assistantPath)];
                winner.date = new Date(winner.date);
            }
        }
        return winners;
    }

    const getStoreClicksCount = () => {
        let assistants = Object.values(sessionData?.assistants || {});

        let count = assistants.reduce((count, assistant) => {
            let actions = assistant?.actions || [];

            if (actions?.size <= 0) return count;

            let clicked = actions.some(action => {
                return action?.type === "STORE_LINK_CLICKED" && action?.metadata?.productRefPath === productData?.reference;
            });

            if (clicked) return count + 1;
            else return count;
        }, 0);

        return count;
    }

    const getDataParser = () => {
        let dataParser = {};
        dataParser.getTotalOfParameter = getTotalOfCategoryParameter;
        dataParser.getPercentOfParameter = getPercentOfCategoryParameter;
        dataParser.getParameterGraphData = getParameterGraphData;
        dataParser.getNumericalParameterLineGraphData = getNumericalParameterLineGraphData;
        dataParser.containsAgregatedParameter = containsAgregatedParameter;
        dataParser.getParameterAvg = getParameterAvg;
        dataParser.getParameterTotal = getParameterTotal;
        dataParser.getComponentGraphData = getComponentGraphData;
        dataParser.isCategoryRadarGraphDataPossible = isCategoryRadarGraphDataPossible;
        dataParser.getCategoryRadarGraphData = getCategoryRadarGraphData;
        dataParser.getNumericalParameterGraphData = getNumericalParameterGraphData;
        dataParser.getQuizWinners = getQuizWinners;
        dataParser.getStoreClicksCount = getStoreClicksCount;
        return dataParser;
    }

    return (
        <ProductFormComponent
            strings={strings}
            config={config}
            sessionData={sessionData}
            productData={productData}
            dataParser={getDataParser()}
            goBack={goBack}
        />
    );
};

export default ProductFormEngine;