import React from "react";
import styled from "@emotion/styled";
import ImagePicker from "../../../../../../shared/ImagePicker";
import TextInput from "../../../../../../shared/TextInput";

const StoreParamsForm = ({ strings, config, product, updateProduct }) => {

  const BoldTag = styled.div`
    margin: 1vh;
    font-size: 2.3vh;
    font-weight: bold;
  `;

  const TableStyle = {
    borderSpacing: 0,
    padding: "0px",
    width: "100%",
  };

  const TdStyle = {
    width: "100%",
  };

  return (
    <div>
      <BoldTag>{strings.image}*</BoldTag>
      <ImagePicker id={"image"} src={product?.image} upload={true} cloudPath={"products"} onSelect={updateProduct} />

      <table style={TableStyle}>
        <tr>
          <td style={TdStyle}>
            <BoldTag>{strings.price}</BoldTag>
            <TextInput
              id={"price"}
              defaultValue={product?.price}
              unit={config?.cellar?.currency || "€"}
              width={"100%"}
              onChange={(e) => {
                updateProduct("price", e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td style={TdStyle}>
            <BoldTag>{strings.discountedPrice}</BoldTag>
            <TextInput
              id={"discountedPrice"}
              defaultValue={product?.discountedPrice}
              unit={config?.cellar?.currency || "€"}
              width={"100%"}
              disabled={!config?.cellar?.storeActive}
              onChange={(e) => {
                updateProduct("discountedPrice", e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td style={TdStyle}>
            <BoldTag>{strings.externalStoreURL}</BoldTag>
            <TextInput
              id={"externalStoreURL"}
              defaultValue={product?.externalStoreURL}
              width={"100%"}
              disabled={!config?.cellar?.storeActive}
              onChange={(e) => {
                updateProduct("externalStoreURL", e.target.value);
              }}
            />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default StoreParamsForm;
