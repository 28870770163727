import React, { useState, useEffect } from "react";
import ProductsDetailsForm from "./ProductsDetailsForm";

const ProductsDetailsWrapperForm = ({ strings, config, sessionData, assessments, dataParser }) => {
    const [activeAssessment, setActiveAssessment] = useState(null);

    useEffect(() => {
        if (activeAssessment === null && assessments !== null) {
            let productReferences = sessionData?.tasting?.productReferences || [];

            if (productReferences.length > 0) {
                setActiveAssessment(productReferences[0]?.reference);
            }
        }
    }, [assessments, activeAssessment]);

    const Wrapper = {
        backgroundColor: "white",
        borderRadius: "0px 6px 6px 6px",
        padding: "2vh",
    };

    const IndexesWrapper = {
        display: "flex",
    };

    const IndexItem = {
        padding: "1vh 2vh",
        backgroundColor: "#d8d8d8",
        cursor: "pointer",
        color: "#6d7278",
        borderRadius: "6px 6px 0px 0px",
        display: "flex",
    };

    const SelectedIndexItem = {
        padding: "1vh 2vh",
        cursor: "pointer",
        backgroundColor: "white",
        color: "black",
        borderRadius: "6px 6px 0px 0px",
        display: "flex",
    };

    const loadAssessmentsTabs = () => {
        let views = [];
        let productReferences = sessionData?.tasting?.productReferences || [];

        productReferences.forEach(prodRef => {
            let key = prodRef.reference;

            views.push(
                <div
                    style={key == activeAssessment ? SelectedIndexItem : IndexItem}
                    onClick={() => {
                        setActiveAssessment(key);
                    }}
                >
                    <div>{dataParser.getProductName(key) || "-"}</div>
                </div>
            );
        });
        return views;
    };

    return (
        <div style={{ paddingBottom: "2vh" }}>

            {(sessionData?.tasting?.productReferences || []).length > 0 ? (
                <>
                    <div style={IndexesWrapper}>{loadAssessmentsTabs()}</div>
                    <div style={Wrapper}>
                        {activeAssessment === null ? (
                            <p>{strings.noCategorySelected}</p>
                        ) : (
                            <ProductsDetailsForm
                                strings={strings}
                                config={config}
                                product={dataParser.getProduct(activeAssessment)}
                                productPath={activeAssessment}
                                assessment={assessments?.[activeAssessment]}
                                dataParser={dataParser} />
                        )}
                    </div>
                </>
            ) : (
                <div>No products found</div>
            )}
        </div >
    );
};

export default ProductsDetailsWrapperForm;
