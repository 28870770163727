import React from "react";
import Colors from "../../assets/Colors.json"

const SelectableIcon = ({ id, selectedValue, iconValue, image, selectedImage, text, action = () => { }, style }) => {
  const SelectableIconStyle = {
    textAlign: "center",
    margin: "0px 3%",
    cursor: "pointer",
  };

  const ImageIcon = {
    width: "4vh",
    height: "auto",
    ...style
  };

  const SelectedImageIcon = {
    ...ImageIcon
  };

  const TextStyle = {
    width: style?.textWidth,
    color: "#585858",
  };

  const SelectedTextStyle = {
    ...TextStyle,
    color: Colors.lightTheme.accentColor1,
    fontWeight: "bold",
  };

  const selected = (selectedValue == iconValue);

  return (
    <div
      id={id}
      style={SelectableIconStyle}
      onClick={() => {
        action(id, iconValue);
      }}
    >
      <img src={selected ? selectedImage : image} style={selected ? SelectedImageIcon : ImageIcon} color="" />
      <div style={selected ? SelectedTextStyle : TextStyle}>{text}</div>
    </div>
  );
};

export default SelectableIcon;
