import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PlanBanner from "./PlanBanner";

const PlanForm = ({ strings, config, plans, celler, selectedPlan, setSelectedPlan, setInterval, goNext }) => {
  const Wrapper = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    padding: "5vh 4vw",
  };

  const selectPlan = (plan, interval) => {
    setSelectedPlan(plans[plan]);
    setInterval(interval);
    goNext();
  };

  return (
    <div style={Wrapper}>
      {Object.entries(plans).map((entry) => {
        if (!entry[1].default) {
          return (
            <PlanBanner
              strings={strings}
              config={config}
              celler={celler}
              plan={entry[1]}
              selectedPlan={selectedPlan}
              selectMensual={() => {
                selectPlan(entry[0], "monthly");
              }}
              selectAnnual={() => {
                selectPlan(entry[0], "annual");
              }}
            />
          );
        }
      })}
    </div>
  );
};
export default PlanForm;
