import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import Colors from "../../../../assets/Colors.json";
import flagCA from "../../../../assets/images/flagCA.png";
import flagES from "../../../../assets/images/flagES.png";
import flagEN from "../../../../assets/images/flagEN.png";
import flagDE from "../../../../assets/images/flagDE.png";
import flagFR from "../../../../assets/images/flagFR.png";
import flagIT from "../../../../assets/images/flagIT.png";
import flagPL from "../../../../assets/images/flagPL.png";
import flagPT from "../../../../assets/images/flagPT.png";
import powerIcon from "../../../../assets/images/power-icon.svg";
import Config from "../../../../assets/Config";
import Tooltip from "@material-ui/core/Tooltip";

const ToolbarComponent = ({ strings, config, selectLang, logOut, goToUpTasting, goToPlans }) => {
  const colors = Colors.lightTheme;

  const Icons = {
    ES: flagES,
    EN: flagEN,
    CA: flagCA,
    DE: flagDE,
    FR: flagFR,
    IT: flagIT,
    PL: flagPL,
    PT: flagPT,
  }

  const getPlanText = (user) => {
    if (user && user.plan) {
      let text = user.plan.name;
      if (user.blocked) text += " (" + strings.finished + ")";
      return text;
    }
    return "";
  };

  const getPlanTooltip = () => {
    if (config && config.plan) {
      let text = strings.yourCurrentPlanIs + config.plan.name;
      if (config.plan.default) text += " (" + config.remainingFreeDays + " " + strings.remainingDays + ")";
      return text;
    }
    return "";
  };

  const Wrapper = {
    padding: "0 1vh",
    display: "flex",
    height: "6vh",
    justifyContent: "space-between",
  };
  const SubscriptionWrapper = {
    textTransform: "uppercase",
    display: "flex",
    marginLeft: "5vh",
    alignItems: "baseline",
  };
  const SubscriptionText = {
    margin: "auto 0",
  };
  const SubscriptionTag = {
    fontWeight: "bold",
    color: `${colors.accentColor1}`,
    margin: "auto 0.5vh",
  };
  const UpdateButton = styled.div`
    font-size: 2vh !important;
    border: 0.1vh solid ${colors.accentColor1};
    padding: 0.5vh 2vh;
    border-radius: 0.5vh;
    color: white;
    background-color: ${colors.accentColor1};
    cursor: pointer;
    margin: auto 1vh;
    align-items: center;
    transition: 0.1s;
    &:hover {
      background-color: white;
      color: ${colors.accentColor1};
    }
  `;
  const ToolsWrapper = styled.div`
    margin-right: 5vh;
    display: flex;
  `;

  const IconsWrapper = styled.div`
    display: flex;
    align-items: center;
    > * {
      margin: 0 0.1vh;
    }
  `;

  const Icon = styled.img`
    height: 2vh;
    width: 2vh;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.1s;
    padding: 1vh;
    &:hover {
      background-color: ${colors.lightGrey};
    }
  `;

  const ProfileName = styled.div`
    margin: 0 1vh;
  `;

  const EnvironmentAnim = keyframes`
    0%   {background-color: #309c40;}
    50%  {background-color: #075c14;}
    100%   {background-color: #309c40;}
  `;

  const EnvironmentTag = styled.div`
    animation: ${EnvironmentAnim} 3s linear infinite;
    font-size: 2vh !important;
    padding: 0.5vh 2vh;
    border-radius: 0.5vh;
    color: white;
    margin: auto 2vh;
    align-items: center;
  `;

  const Tag = styled.div`
    padding: 0.5vh;
    font-size: 1.7vh;
  `;

  return (
    <div style={Wrapper}>
      <div style={SubscriptionWrapper}>
        <div style={SubscriptionText}>{strings.modality}</div>
        <Tooltip title={config.tooltipsEnabled ? <Tag>{getPlanTooltip(config)}</Tag> : ""} aria-label="add" arrow>
          <div style={SubscriptionTag}>{getPlanText(config)} </div>
        </Tooltip>
        <UpdateButton onClick={goToPlans}>{config.blocked ? strings.reactivatePlan : strings.changePlan}</UpdateButton>
      </div>
      <ToolsWrapper>
        <IconsWrapper>
          {Config.ENV != "PROD" ? <EnvironmentTag>{Config.ENV + " MODE "}</EnvironmentTag> : <></>}

          <Tooltip title={config.tooltipsEnabled ? <Tag>{strings.tootltipUpTastingWeb}</Tag> : ""} aria-label="add" arrow>
            <UpdateButton onClick={goToUpTasting}>{strings.goToUpTasting}</UpdateButton>
          </Tooltip>

          <ProfileName>{config.user.email}</ProfileName>

          <Tooltip title={config.tooltipsEnabled ? <Tag>{strings.tooltipLang}</Tag> : ""} aria-label="add" arrow>
            <Icon src={Icons[config.lang]} onClick={selectLang} />
          </Tooltip>

          <Tooltip title={config.tooltipsEnabled ? <Tag>{strings.tooltipLogout}</Tag> : ""} aria-label="add" arrow>
            <Icon src={powerIcon} onClick={logOut} />
          </Tooltip>
        </IconsWrapper>
      </ToolsWrapper>
    </div>
  );
};

export default ToolbarComponent;
