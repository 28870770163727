import React from "react";
import RecoveryPassComponent from "./RecoveryPassComponent";
import Firebase from "../../../assets/Firebase";
import { useHistory } from "react-router-dom";

const RecoveryPassEngine = ({ strings, theme }) => {
  let f = new Firebase();
  let history = useHistory();

  const recoveryEvent = () => {
    let username = document.getElementById("username").value;
    let usernameError = document.getElementById("usernameError");
    usernameError.style.visibility = "hidden";
    f.checkEmailResetPassword(username, success, error);
  };

  const backLogin = () => {
    history.replace("/login");
  };

  const success = () => {
    document.getElementById("formPass").style.display = "none";
    document.getElementById("formSuccess").style.display = "inherit";
  };
  const error = (err) => {
    let usernameError = document.getElementById("usernameError");
    switch (err) {
      case "auth/user-not-found":
        usernameError.style.visibility = "visible";
        break;
      case "auth/invalid-email":
        usernameError.style.visibility = "visible";
        break;
    }
  };
  return <RecoveryPassComponent strings={strings} recoveryEvent={recoveryEvent} backLogin={backLogin} />;
};

export default RecoveryPassEngine;
