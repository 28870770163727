import React, { useState, useEffect, useRef } from "react";
import FormComponent from "./FormComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import BackendAccessor from "../../../../../../assets/BackendAccessor";

import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";

const FormEngine = ({ strings, config }) => {
  let ba = new BackendAccessor();
  let history = useHistory();

  const [theme, setTheme] = useState(null);

  const loadTheme = () => {
    ba.getWebTheme((theme) => {
      console.log("Theme", theme);
      setTheme(theme);
    }, (error) => {
      console.error("Error loading theme: ", error);
      config.popupAlert(strings.error, strings.loadingError, () => { history.replace("/dashboard/home"); });
    })
  };

  useEffect(() => {
    if (theme == null) {
      loadTheme();
    }
  }, [strings]);

  const saveTheme = (onSuccess) => {
    console.log("Theme: ", theme);
    config.showDownloadingPopup(strings.savingTheme);
    ba.uploadWebTheme(theme, onSuccess || success, error);
  }

  const success = () => {
    config.hideDownloadingPopup();
    config.update("modificated", false);
    config.popupAlert(strings.success, strings.succesMsg, () => {
      history.replace("/dashboard/home");
    });
  };

  const error = (error) => {
    config.hideDownloadingPopup();
    console.error("Error saving assessment sheet: ", error);

    if (typeof error === 'string' || error instanceof String) {
      config.popupAlert(strings.error, error);
    } else {
      config.popupAlert(strings.error, strings.otherError);
    }
  };

  const cancelAction = () => {
    config.isModificated(() => {
      history.replace("/dashboard/home");
    });
  };

  const updateTheme = (key, value) => {
    if (!config.modificated) {
      config.update("modificated", true);
    }

    if (value == null) {
      setTheme((prevValue) => {
        delete prevValue[key];
        console.log(prevValue);
        return prevValue;
      });

    } else {
      setTheme((prevValue) => ({
        ...prevValue,
        [key]: value
      }));
    }
  };

  const updateConfig = (configId, param, value) => {
    console.log("updateTheme", param, value);

    setTheme((prevValue) => ({
      ...prevValue,
      otherConfig: {
        ...prevValue?.otherConfig,
        [configId]: {
          ...prevValue?.otherConfig?.[configId],
          [param]: value
        }
      }
    }));
  };

  const updateAudio = (audioId, prop, value) => {
    setTheme((prevValue) => ({
      ...prevValue,
      audios: {
        ...prevValue?.audios,
        [audioId]: {
          ...prevValue?.audios?.[audioId],
          [prop]: value
        }
      }
    }));
  }

  return (
    <>
      {theme != null ? (
        <FormComponent
          strings={strings}
          config={config}
          theme={theme}
          updateTheme={updateTheme}
          updateConfig={updateConfig}
          updateAudio={updateAudio}
          confirmAction={saveTheme}
          cancelAction={cancelAction}
        />
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default FormEngine;