import Config from "../Config";
import Firebase from "../Firebase";

const apiServersUrls = {
  prod: "https://uptasting-api-server.herokuapp.com/api/v1/payments",
  dev: "https://uptasting-api-server-dev.herokuapp.com/api/v1/payments",
  local: "http://localhost:8080/api/v1/payments"
}

const env = process?.env?.REACT_APP_ENVIRONMENT?.toLowerCase();
let StipeServerURL = apiServersUrls[env] || apiServersUrls.prod;

export const processSubscriptionPayment = (stripe, cardElement, celler, planId, interval, succes, fail) => {
  let f = new Firebase();
  const userId = f.firebase.auth().currentUser.uid;

  createPayment(
    stripe,
    cardElement,
    celler,
    (paymentMethodId) => {
      createSubscription(userId, planId, paymentMethodId, interval, succes, fail);
    },
    fail
  );
};

export const createPayment = (stripe, cardElement, celler, succes, fail) => {
  console.log("[Stripe]: Creating payment method...");

  stripe
    .createPaymentMethod({
      billing_details: {
        email: celler.email,
        name: celler.personalName,
        phone: celler.phone,
      },
      type: "card",
      card: cardElement,
    })
    .then((result) => {
      if (result.error) {
        fail(result.error.message);
      } else {
        console.log("[Stripe]: Payment method created with ID " + result.paymentMethod.id);
        succes(result.paymentMethod.id);
      }
    });
};

export const createSubscription = (userId, planId, paymentMethodId, interval, success, fail) => {
  fetch(StipeServerURL + "/createSubscription", {
    method: "post",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
      planId: planId,
      paymentMethodId: paymentMethodId,
      interval: interval,
    }),
  }).then(async (response) => {
    let result = await response.json();
    if (result.error) {
      fail(result);
    } else {
      console.log("[Stripe]: Subscription created with ID " + result.subscriptionId);
      success(result);
    }
  });
};

export const reportStripeUsage = async (success, fail) => {
  let f = new Firebase();
  const userId = f.firebase.auth().currentUser.uid;
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ userId: userId });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(StipeServerURL + "/reportUsage", requestOptions)
    .then(async (response) => {
      let result = await response.json();
      if (result.error) {
        console.log("[Stripe]: Error reporting usage successfully", result);
        fail(result);
      } else {
        console.log("[Stripe]: Usage reported successfully", result);
        success(result);
      }
    })
    .catch((error) => fail(error));
};
