import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import ListEngine from "./List/ListEngine";
import FormEngine from "./Form/FormEngine";

const EventsRoutes = ({ strings, config }) => {
  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
  };

  let { path } = useRouteMatch();

  return (
    <div style={Wrapper}>
      <Switch>
        <Route
          exact
          path={[`${path}/:type`, `${path}/:type/`]}
          render={props => {
            return <Redirect to={`${path}/${props.match.params.type}/list`} />
          }}
        />

        <Route path={`${path}/:type/list`}>
          <ListEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/:type/new`}>
          <FormEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/:type/edit/:id`}>
          <FormEngine strings={strings} config={config} />
        </Route>
      </Switch>
    </div>
  );
};
export default EventsRoutes;
