import React, { useState } from "react";
import ActionComponent from "./ActionComponent";
import Firebase from "../../../../assets/Firebase";
import { useHistory } from "react-router-dom";

const getParameterByName = (name) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results == null) return "";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
};

const EmailVerificationEngine = ({ strings, popupAlert }) => {
  const mode = getParameterByName("mode");
  const actionCode = getParameterByName("oobCode");

  let f = new Firebase();
  let history = useHistory();
  let auth = f.firebase.auth();

  if (mode == null || actionCode == null) {
    history.replace("/login");
    return;
  }

  const verifyEmail = () => {
    auth
      .applyActionCode(actionCode)
      .then(function (resp) {
        popupAlert("¡Verificado!", "Su email ha verificado con exito, ya puede entrar a la aplicacion con su cuenta.", () => {
          history.replace("/login");
        });
      })
      .catch(function (error) {
        popupAlert("Error", "El enlace de verificacion de email ha caducado o ya ha sido utilizado.");
        console.error(error);
      });
  };

  const resetPassword = (newPassword, passwordConfirmation) => {
    console.log(newPassword, passwordConfirmation);
    if (newPassword != passwordConfirmation) {
      popupAlert("Error", "Las contraseñas no coinciden.");
      return;
    }

    if (newPassword !== passwordConfirmation) {
      popupAlert("Error", "Las contraseñas no coinciden.");
      return;
    }

    auth
      .verifyPasswordResetCode(actionCode)
      .then(function (email) {
        auth
          .confirmPasswordReset(actionCode, newPassword)
          .then(function (resp) {
            popupAlert("¡Contraseña recuperada!", "Su contraseña ha sido modificada con exito.", () => {
              history.replace("/login");
            });
          })
          .catch(function (error) {
            popupAlert("Error", "La contraseña no es segura. Introduzca una de al menos 6 caracteres.");
            console.error(error);
          });
      })
      .catch(function (error) {
        popupAlert("Error", "El enlace de recuperacion ha caducado o ya ha sido utilizado.");
        console.error(error);
      });
  };

  return <ActionComponent mode={mode} verifyEmail={verifyEmail} resetPassword={resetPassword} />;
};

export default EmailVerificationEngine;
