import React from "react";
import styled from "@emotion/styled";
import Checkbox from "../../../../../shared/Checkbox";
import TextInput from "../../../../../shared/TextInput";
import SelectableIcon from "../../../../../shared/SelectableIcon";
import icConventional from "../../../../../../assets/images/icon_template_conventional.png";
import icConventionalSelected from "../../../../../../assets/images/icon_template_conventional_selected.png";
import icBlind from "../../../../../../assets/images/icon_template_blind.png";
import icBlindSelected from "../../../../../../assets/images/icon_template_blind_selected.png";
import icSimple from "../../../../../../assets/images/icon_template_simple.png";
import icSimpleSelected from "../../../../../../assets/images/icon_template_simple_selected.png";
import HelpIcon from "../../../../../../assets/images/help-icon.svg";
import CopyImg from "../../../../../../assets/images/copy-clipboard.svg";
import { copyTextToClipBoard } from "../../../../../../assets/utils/DeviceUtils";

const TastingForm = ({ strings, config, tasting, setTasting, type, getPublicLink, offlineAllowed }) => {

  const updateState = (key, value) => {
    console.log(key, value);
    setTasting((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const copyPublicUrl = () => {
    copyTextToClipBoard(getPublicLink(), strings, config);
  }

  const Tag = styled.div`
    margin: 0.5rem;
  `;

  const Hint = styled.div`
    font-size: 1.8vh;
    margin: 1vh;
    color: gray;
    text-align: center;
  `;

  const HintIconStyle = {
    margin: "auto 0",
    height: "3vh",
    width: "auto",
    cursor: "pointer"
  }

  const LinkContainer = {
    borderRadius: "0.5vh",
    border: "solid 0.2vh #d8dde1",
    backgroundColor: "#d8dde1",
    margin: "auto",
    padding: "1vh",
    display: "flex",
    maxWidth: "70%"
  }

  const Link = styled.div`
    max-width: 70%;
    overflow-y: auto;
    margin: 0 2vh;
    
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  `;

  const Input = {
    borderRadius: "4px",
    border: "solid 0.2vh #d8dde1",
    color: "black",
    backgroundColor: "white",
    padding: "0.5rem",
    resize: "vertical",
  };

  const Table = {
    borderBottom: "0.1vh solid grey",
    width: "100%",
    paddingBottom: "1rem",
    tableLayout: "fixed",
    ["td"]: {
      width: "25%",
    },
  };

  const TemplateIcon = {
    width: "15vh",
    heigth: "auto",
    textWidth: "25vh"
  }


  const Icon = {
    width: "1vw",
    height: "auto",
    margin: "auto",
    cursor: "pointer",
    margin: "auto 1vh"
  };

  return (
    <div>
      <table style={Table}>
        <tbody>
          <tr>
            <td colSpan={type === "podcast" ? "1" : "2"}>
              <Tag>
                <b>{strings.name}*</b>
              </Tag>
              <TextInput
                defaultValue={tasting?.name || ""}
                id={"name"}
                onChange={(e) => {
                  updateState("name", e.target.value);
                }}
              />
            </td>
            <td>
              <Tag>
                <b>{strings.duration}*</b>
              </Tag>
              <TextInput
                defaultValue={tasting?.duration || ""}
                id={"duration"}
                onChange={(e) => {
                  updateState("duration", e.target.value);
                }}
              />
            </td>
            {type === "podcast" ? (
              <td style={{ verticalAlign: "top" }}>
                <Tag><b>{strings.offlineMode}</b></Tag>
                {offlineAllowed ? (
                  <Checkbox
                    id={"offlineEnabled"}
                    checked={tasting.offlineEnabled}
                    enabled={offlineAllowed}
                    text={strings.enableOfflineMode}
                    onChange={(e) => {
                      updateState("offlineEnabled", e.target.checked);
                    }} />
                ) : (
                  <Hint>{strings.offlineDisabledMsg}</Hint>
                )}
                </td>
            ) : (
                <></>
            )}
          </tr>
          <tr>
            <td colSpan="3">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Tag>
                  <b>{strings.description}*</b>
                </Tag>
                <textarea
                  id="description"
                  style={Input}
                  maxLength={250}
                  defaultValue={tasting?.description || ""}
                  onInput={(e) => {
                    updateState("description", e.target.value);
                  }}
                ></textarea>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <div style={{ display: "flex", flexDirection: "column", }}>
                <Tag>
                  <b>{strings.template}*</b>
                </Tag>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", textAlign: "center", justifyContent: "center" }}>
                  <SelectableIcon id="template" selectedValue={tasting?.template || ""} iconValue={"CONVENTIONAL_TEMPLATE"}
                    image={icConventional} selectedImage={icConventionalSelected} text={strings.conventionalTemplate} action={updateState} style={TemplateIcon} />
                  <SelectableIcon id="template" selectedValue={tasting?.template || ""} iconValue={"BLIND_TEMPLATE"}
                    image={icBlind} selectedImage={icBlindSelected} text={strings.blindTemplate} action={updateState} style={TemplateIcon} />
                  <SelectableIcon id="template" selectedValue={tasting?.template || ""} iconValue={"SIMPLE_TEMPLATE"}
                    image={icSimple} selectedImage={icSimpleSelected} text={strings.simpleTemplate} action={updateState} style={TemplateIcon} />
                </div>
              </div>
            </td>
          </tr>
          {type === "live" ? (
            <tr>
              <td colSpan="3">
                <div style={{ display: "flex", flexDirection: "column", }}>
                  <Tag>
                    <b>{strings.shareTasting}</b>
                  </Tag>
                  <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <Checkbox
                      checked={tasting.publicAccessEnabled}
                      text={strings.enablePublicAccess}
                      onChange={(e) => {
                        updateState("publicAccessEnabled", e.target.checked);
                      }}
                    />
                    <img style={HintIconStyle} src={HelpIcon} onClick={() => {
                      config.popupAlert(strings.enablePublicAccess, strings.enablePublicAccessHint);
                    }} />

                    {tasting.publicAccessEnabled ? (
                      <div style={LinkContainer} onClick={copyPublicUrl}>
                        <b>{strings.publicAccessLink}:  </b>
                        <Link>{getPublicLink()}</Link>
                        <img style={Icon} src={CopyImg} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <></>
          )
          }
        </tbody>
      </table>
    </div>
  );
};
export default TastingForm;