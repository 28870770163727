import React, { useState, useEffect } from "react";
import ListComponent from "./ListComponent";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import Config from "../../../../../../assets/Config";
import LoadingScreen from "../../../../../shared/LoadingScreen";

import { BrowserRouter as Router, useRouteMatch, useHistory, useParams } from "react-router-dom";

const ListEngine = ({ strings, config }) => {
  let { type } = useParams();
  let history = useHistory();
  let ba = new BackendAccessor();

  const [previousType, setPreviousType] = useState(null);
  const [tastings, setTastings] = useState(null);

  const types = {
    "live": "CONVENTIONAL_TAST",
    "podcast": "PODCAST_TAST"
  }

  const templates = {
    "CONVENTIONAL_TEMPLATE": strings?.conventionalTemplateShort,
    "BLIND_TEMPLATE": strings?.blindTemplateShort,
    "SIMPLE_TEMPLATE": strings?.simpleTemplateShort,
  }

  const formatTastings = (tastings) => {
    let formatedTastings = {};
    if (tastings.length > 0) {
      console.log("tastings", tastings);

      tastings.forEach(tasting => {
        if (tasting !== null || tasting !== undefined) {

          let id = ba.getIdFromPath(tasting?.reference);
          formatedTastings[id] = {
            name: tasting.name,
            type: strings[types[type]],
            template: templates[tasting?.template] || "-",
            lastUpdate: new Date(tasting.lastUpdate),
          }
        }
      });
    }
    return formatedTastings;
  }

  const loadTastings = () => {
    ba.getTastingsList(
      types[type],
      (tastings) => {
        setTastings(formatTastings(tastings));
      },
      (error) => {
        console.error("Error loading tastings: ", error);
        config.popupAlert("Error", "Error loading tastings")
      }
    );
  }

  useEffect(() => {
    if (previousType != type || tastings == null) {
      setPreviousType(type);
      loadTastings();
    }
  }, [strings, type]);

  const createTasting = () => {
    checkTastingsLeft(
      () => {
        history.replace(`/dashboard/tastings/${type}/new`);
      },
      () => {
        config.popupAlert(strings.error, strings.maxTastingsReached);
      }
    );
  };

  const editTasting = (id) => {
    history.replace(`/dashboard/tastings/${type}/edit/${id}`);
  };

  const deleteTasting = (key) => {
    config.popupAlert(strings.deleteTaste + " - " + tastings[key].name, strings.deleteConfirm, null, true, () => {
      ba.deleteTastingById(
        key,
        (response) => {
          console.info("Tasting with id " + key + " deleted succefully", response);
          setTastings((prevState) => {
            const state = { ...prevState };
            delete state[key];
            return state;
          });
        },
        (error) => {
          console.error("Error deleting tasting: ", error);
        }
      );
    });
  };

  const testTasting = (tastingId) => {
    if (config.isBlocked()) return;
    new Config();

    let isPodcast = types[type] == "PODCAST_TAST";
    let isDev = Config.ENV == "DEV";

    ba.createTastingDemoSession(
      tastingId,
      (response) => {
        let url = isDev ? "https://dev.uptasting.online/" : "https://www.uptasting.online/";
        if (!isPodcast) url += "instructor/";
        url += response.publicCode + "/?lang=" + config.lang.toLowerCase();
        window.open(url, "'_blank'");

      },
      (error) => {
        config.popupAlert(strings.error, strings.errorTest);
        console.error("Error generating test session: ", error);
      }
    );
  };

  const clone = (tastingId) => {
    config.showDownloadingPopup(strings.cloning);
    ba.cloneTasting(
      tastingId,
      (response) => {
        console.log("Clone response", response);
        setTimeout(function () {
          loadTastings();
          config.hideDownloadingPopup();
          config.popupAlert(strings.success, strings.successClone);
        }, 2000);
      },
      (error) => {
        console.error("Error cloning tasting: ", error);
        config.hideDownloadingPopup();
        config.popupAlert(strings.error, strings.errorClone);
      }
    );
  };

  const openCloneDialog = (assessmentSheetsId) => {
    config.popupAlert(
      strings.cloneTasting,
      strings.cloneText,
      null,
      true,
      () => {
        clone(assessmentSheetsId);
      }
    );
  };

  const checkTastingsLeft = (success, error) => {
    let maxTastings = config?.plan?.maxTastings || 1;
    Object.entries(tastings).length < maxTastings ? success() : error();
  };

  return (
    <>
      {
        tastings != null ? (
          <ListComponent
            strings={strings}
            tastings={tastings}
            createTasting={createTasting}
            editTasting={editTasting}
            deleteTasting={deleteTasting}
            testTasting={testTasting}
            clone={openCloneDialog}
            ready={true}
            type={type}
          />
        ) : (
          <LoadingScreen />
        )
      }
    </>
  );
};

export default ListEngine;
