import React from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../../../shared/TextInput";
import Select from "../../../../../../shared/Select";

const FixedParamsForm = ({ strings, config, product, productTypes, updateProduct }) => {

  const getFormatedProductTypes = () => {
    let formated = { notSelected: strings.notSelected };
    if (productTypes) {
      productTypes.forEach((productType) => {
        formated[productType?.reference] = productType?.name?.[config?.lang];
      });
    }
    return formated;
  }

  const getFormatedProductSubtypes = () => {
    let formated = { notSelected: strings.notSelected };
    let type = productTypes.find(t => { return t?.reference === product?.type?.reference });

    if (type?.subtypes) {
      type.subtypes.forEach((producSubtype) => {
        formated[producSubtype?.reference] = producSubtype?.name?.[config?.lang];
      });
    }
    return formated;
  }

  const selectProductType = (id, productTypePath) => {
    updateProduct("typePath", productTypePath);
    updateProduct("subtypePath", "notSelected");
    let typeObj = productTypes.find(t => { return t?.reference === productTypePath });
    updateProduct("type", typeObj);

    let newProperties = product?.properties || {};

    // Remove default empty properties
    Object.entries(newProperties).forEach(([key, property]) => {
      if ((property?.default || !property?.name) && !property?.value) {
        delete newProperties[key];
      }
    });

    // Add default product properties
    if (typeObj?.properties) {
      Object.entries(typeObj?.properties).forEach(([key, property]) => {
        if (!newProperties?.[key]) {
          newProperties[key] = { ...property, default: true };
        }
      });
    }

    updateProduct("properties", newProperties);
  }

  const selectProductSubype = (id, subtypePath) => {
    updateProduct("subtypePath", subtypePath);
  }

  const BoldTag = styled.div`
    margin: 1vh;
    font-size: 2.3vh;
    font-weight: bold;
  `;

  const TableStyle = {
    borderSpacing: 0,
    padding: "0px",
    width: "100%",
  };

  return (
    <table style={TableStyle}>
      <tr>
        <td>
          <BoldTag>{strings.name}*</BoldTag>
          <TextInput
            id={"name"}
            defaultValue={product?.name}
            onChange={(e) => {
              updateProduct("name", e.target.value);
            }}
          />
        </td>
        <td>
          <BoldTag>{strings.type}*</BoldTag>
          <Select defaultOption={product?.typePath} options={getFormatedProductTypes()} id={"typePath"} onChange={selectProductType} />
        </td>
        {
          product?.type ? (
            <td>
              <BoldTag>{strings.subtype}*</BoldTag>
              <Select defaultOption={product?.subtypePath} options={getFormatedProductSubtypes()} id={"subtypePath"} onChange={selectProductSubype} />
            </td>
          ) : (
            <></>
          )
        }
      </tr>
    </table >
  );
};
export default FixedParamsForm;
