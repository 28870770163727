import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../../../shared/TextInput";
import Select from "../../../../../../shared/Select";
import Button from "../../../../../../shared/Button";
import allCountries from "../../../../../../../assets/strings/Countries.json";

export default function PersonalForm({ strings, celler, setCeller, selectedPlan, mentor, goBack, goNext, openTermsConditions, openPrivacyPolicy }) {
  const LENG = "ES";

  const updateState = (key, value) => {
    setCeller((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  let countries = {
    notSelected: strings.notSelected,
    ...allCountries[LENG],
  };

  return [
    <FormComponent
      strings={strings}
      countries={countries}
      celler={celler}
      mentor={mentor}
      setCeller={setCeller}
      selectedPlan={selectedPlan}
      goBack={goBack}
      goNext={goNext}
      updateState={updateState}
      openTermsConditions={openTermsConditions}
      openPrivacyPolicy={openPrivacyPolicy}
    />,
  ];
}

const FormComponent = ({
  strings,
  countries,
  celler,
  setCeller,
  mentor,
  selectedPlan,
  goBack,
  goNext,
  updateState,
  openTermsConditions,
  openPrivacyPolicy,
}) => {
  const Container = {
    padding: "4vh 10vw",
  };

  const Header = {
    width: "100%",
    fontSize: "3vh",
    fontWeight: "bold",
    marginBottom: "3vh",
  };

  const Tag = styled.div`
    margin: 1vh;
    font-size: 2vh;
  `;

  const TableStyle = {
    width: "100%",
  };

  const TdName = {
    padding: "0.5vh",
    width: "20%",
  };

  const TdInput = {
    padding: "0.5vh",
    width: "80%",
  };

  const A = styled.a`
    text-decoration: underline;
    cursor: pointer;
  `;

  return (
    <div style={Container}>
      <div style={Header}>{strings.dataFormHeader + " " + selectedPlan?.name} </div>

      <table style={TableStyle}>
        <tbody>
          <tr>
            <td style={TdName}>
              <Tag>{strings.companyName}*</Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"name"}
                defaultValue={celler.name}
                onChange={(e) => {
                  updateState("name", e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>
                {strings.nif}
                {celler.country == "España" ? "*" : ""}
              </Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"nif"}
                defaultValue={celler.nif}
                onChange={(e) => {
                  updateState("nif", e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>{strings.name}*</Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"personalName"}
                defaultValue={celler.personalName}
                onChange={(e) => {
                  e.preventDefault();
                  updateState("personalName", e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>{strings.personalSurnames}*</Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"personalSurnames"}
                defaultValue={celler.personalSurnames}
                onChange={(e) => {
                  updateState("personalSurnames", e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>{strings.address}*</Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"address"}
                defaultValue={celler.address}
                onChange={(e) => {
                  updateState("address", e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>{strings.cp}*</Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"cp"}
                defaultValue={celler.cp}
                onChange={(e) => {
                  updateState("cp", e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>{strings.population}*</Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"population"}
                defaultValue={celler.population}
                onChange={(e) => {
                  updateState("population", e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>{strings.country}*</Tag>
            </td>
            <td style={TdInput}>
              <Select defaultOption={celler.country} options={countries} id={"country"} onChange={updateState} width={"95%"} />
            </td>
          </tr>
          <tr>
            <td style={TdName}>
              <Tag>{strings.phone}*</Tag>
            </td>
            <td style={TdInput}>
              <TextInput
                id={"phone"}
                defaultValue={celler.phone}
                onChange={(e) => {
                  updateState("phone", e.target.value);
                }}
              />
            </td>
          </tr>
          {mentor ? (
            <tr>
              <td style={TdName}>
                <Tag>Mentor: </Tag>
              </td>
              <td style={TdInput}>
                <Tag>
                  <b>{mentor?.completeName}</b>
                </Tag>
              </td>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <td></td>
            <td style={{ padding: "1vh" }}>
              <input type="checkbox" id="terminosYCondiciones" />
              <label for="terminosYCondiciones">
                {strings.aception}
                <A onClick={openTermsConditions}>{strings.terms}</A> {strings.and} <A onClick={openPrivacyPolicy}>{strings.privacyPolicy}</A>
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <Button value={strings.goBack} action={goBack} />
            </td>
            <td style={{ textAlign: "end" }}>
              <Button value={strings.goPayment} action={goNext} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
