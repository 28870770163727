import React from "react";
import styled from "@emotion/styled";

import TextInput from "../../../../../shared/TextInput";
import Select from "../../../../../shared/Select";

export default function NameForm({ strings, instructor, setInstructor }) {
  const updateState = (key, value) => {
    setInstructor((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  return [<FormComponent strings={strings} updateState={updateState} state={instructor} />];
}
const FormComponent = ({ strings, state, updateState }) => {
  const Wrapper = {
    textAlign: "center",
  };
  const Tag = {
    margin: "1vh",
    fontSize: "2.5vh",
  };
  const Input = {
    borderRadius: "0.3vh",
    border: "solid 0.2vh #d8dde1",
    color: "black",
    backgroundColor: "white",
    padding: "1vh",
    resize: "vertical",
    fontSize: "2.5vh",
  };
  return (
    <div style={Wrapper}>
      <div style={Tag}>
        <b>{strings.name}*</b>
      </div>
      <input
        style={Input}
        id={"name"}
        defaultValue={state.name}
        onChange={(e) => {
          updateState("name", e.target.value);
        }}
      />
    </div>
  );
};
