
import React from "react";
import styled from "@emotion/styled";
import EmailForm from "./EmailForm";
import VerificationCodeForm from "./VerificationCodeForm";

const Wrapper = styled.div`
  display: flex;
  background-color: white;
  text-align: center;
  width: 100vw;
  height: 100vh;
`;

const LoginComponent = ({ config, strings, data, setData, actions, selectLang, lang }) => {

  return (
    <Wrapper>
      <EmailForm
        config={config}
        strings={strings}
        data={data}
        setData={setData}
        actions={actions}
        selectLang={selectLang}
        lang={lang} />
    </Wrapper >
  );

};
export default LoginComponent;
