import React, { useEffect, useRef } from "react";
import Radium from "radium";
import { useMediaQuery } from "@material-ui/core";
import Colors from "../../assets/Colors.json";

const TextInput = ({
  id = "textInput",
  placeholder,
  value,
  styles,
  rootStyles,
  defaultValue,
  maxLength,
  type = "text",
  min,
  max,
  pattern,
  width = "95%",
  disabled = false,
  enterAction,
  focused = false,
  unit,
  onChange = () => { },
}) => {
  const myRef = useRef(null);
  const matches = useMediaQuery('(max-width:426px)');

  const Input = {
    borderRadius: "0.3vh",
    border: "solid 0.2vh #d8dde1",
    borderColor: "#d8dde1",
    color: "black",
    backgroundColor: "white",
    padding: "1.5vh",
    resize: "vertical",
    fontSize: matches ? "1rem" : "1rem",
    width: unit ? "90%" : "100%",
    animationDuration: "1s",
    animationIterationCount: "2",
    animationDirection: "alternate",
    ":focus": {
      borderColor: Colors.lightTheme.accentColor1,
    },
  };

  for (let key in styles) {
    Input[key] = styles[key];
  }

  const DisabledInput = {
    ...Input,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };

  const Root = {
    width: width,
    display: "flex",
  };

  for (let key in rootStyles) {
    Root[key] = rootStyles[key];
  }

  const UnitStyle = {
    backgroundColor: "#d8dde1",
    fontSize: matches ? "1rem !important" : "1rem !important",
    padding: "1.5vh",
    margin: "auto",
    width: "25%",
    borderRadius: "0.3vh",
    border: "solid 0.2vh #d8dde1",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  useEffect(() => {
    if (focused) {
      onFocus();
    }
  }, [focused]);

  const onFocus = () => {
    myRef.current.style.animationName = "onInputFocus";
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setTimeout(() => {
      myRef.current.focus();
    }, 2000);
  }

  return (
    <div style={Root}>
      <input
        ref={myRef}
        style={disabled ? DisabledInput : Input}
        onKeyDown={(e) => {
          if (e.keyCode == 13) {
            //Tecla enter
            if (enterAction != undefined) enterAction();
          }
        }}
        value={defaultValue}
        defaultValue={value}
        id={id}
        maxLength={maxLength}
        onChange={(e) => {
          onChange(e);
        }}
        placeholder={placeholder}
        type={type}
        min={min}
        max={max}
        focused={focused}
        disabled={disabled}
        pattern={pattern}
      />
      {unit ? <div style={UnitStyle}>{unit}</div> : <></>}
    </div>
  );
};

export default Radium(TextInput);
