import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, useRouteMatch, Redirect, useHistory } from "react-router-dom";
import FormEngine from "./Form/FormEngine";
import ListEngine from "./List/ListEngine";
import RankingEngine from "./Ranking/RankingEngine";
import styled from "@emotion/styled";
import Button from "../../../../shared/Button";
import Colors from "../../../../../assets/Colors.json";
import Tooltip from "@material-ui/core/Tooltip";
import flagCA from "../../../../../assets/images/flagCA.png";
import flagES from "../../../../../assets/images/flagES.png";
import flagEN from "../../../../../assets/images/flagEN.png";
import logoUpwine from "../../../../../assets/images/upwine-logo.svg";

const SessionsRoutes = ({ strings, config, lang, selectLang }) => {

  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  };

  const Icons = {
    ES: flagES,
    EN: flagEN,
    CA: flagCA,
  };

  const Wrapper1 = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 3vh
  `;

  const Wrapper2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30vw;
  `;

  const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #d8d8d8;
    height: 15vh;
    align-items: center;
  `;

  const Icon = styled.img`
    height: 4vh;
    width: 4vh;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.1s;
    padding: 1vh;
    &:hover {
    background-color: ${Colors.lightTheme.lightGrey};
    }
  `;

  const Tag = styled.div`
    padding: 0.5vh;
    font-size: 1.7vh;
  `;

  const LogoDiv = styled.div`
    background-color: #161c28;
    height: 30%;
    width: 15vw;
    align-items: center;
    justify-content: center;
    display: flex;
  `;

  const ColumnDiv = styled.div`
    background-color: #161c28;
    height: 100vh;
    width: 15vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  let { path } = useRouteMatch();

  let history = useHistory();

  const goRanking = () => {
    history.replace(`/assistant/sessions/ranking`);
  }

  const goBack = () => {
    history.replace(`/assistant/sessions`);
  }

  return (
    <div style={Wrapper}>
      <ColumnDiv>
        <LogoDiv>
          <img src={logoUpwine} style={{ height: "10vh", cursor: "pointer" }} onClick={() => { goBack() }} />
        </LogoDiv>
        <Button value={strings.rankingList} action={() => { goRanking() }} style={{ width: "100%", height: "fit-content", padding: "2vh 3vh" }} />
        <Button value={strings.productsInfo} action={() => { goBack() }} style={{ width: "100%", height: "fit-content", padding: "2vh 3vh" }} />
      </ColumnDiv>
      <div style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column"
      }}>
        <WrapperButtons>
          {!history.location.pathname.toString().includes("list") ? <Button id={"especialButton"} value={"< " + strings.backList} action={goBack} style={{ marginLeft: "12vh" }} /> : <br></br>}
          <Wrapper2>
            <p>{config?.email}</p>
            <Tooltip title={<Tag>{strings.tooltipLang}</Tag>} aria-label="add" arrow>
              <Icon src={Icons[lang]} onClick={selectLang} />
            </Tooltip>
            <Button value={strings.tooltipLogout} action={() => { config.logout() }} style={{ width: "fit-content", height: "fit-content", padding: "2vh 3vh", marginRight: "5vh" }} />
          </Wrapper2>
        </WrapperButtons>
        <Switch>
          <Route path={`${path}/list`}>
            <ListEngine strings={strings} config={config} lang={lang} selectLang={selectLang} />
          </Route>
          <Route path={`${path}/:userId/:assistantId`}>
            <FormEngine strings={strings} config={config} lang={lang} />
          </Route>
          <Route path={`${path}/ranking`}>
            <RankingEngine strings={strings} config={config} lang={lang} selectLang={selectLang} />
          </Route>
          <Route path={`${path}/`} children={<Redirect to={{ pathname: `${path}/list` }} />} />
        </Switch>
      </div>
    </div>
  );
};

export default SessionsRoutes;
