import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import TableRanking from "../../../../../shared/TableRanking";

const FormComponent = ({ strings, config, assistantData, goBack, parsedData, showProductData, data, goToLink, lang, selectLang }) => {

    const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 100%;
    `;

    const BorderTable = styled.div`
        border: 1px solid #f0f0f0;
        border-radius: 2vh;
        box-shadow: 0 0 7vh 1em #c6c6c6;
        max-height: 70vh;
    `;

    const DivTitle = styled.div`
        text-transform: uppercase;
        font-size: 4vh;
        margin: 3vh;
    `;

    const DivButton = styled.div`
        width: 202vh;
    `;

    const DivTable = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80vh;
    `;

    let action = [
        {
            title: (
                <Button
                    value={strings.buy}
                />
            ),
            action: goToLink,
            id: "goToLink"
        },
        {
            title: (
                <Button
                    value={strings.viewCompleteInfo}
                />
            ),
            action: showProductData,
        },
    ];

    return (
        <Wrapper>
            <DivTable>
                <BorderTable>
                    <DivTitle>{strings.productsInfo}</DivTitle>
                    <TableRanking
                        header={strings.WinesTable}
                        data={data}
                        actions={action}
                        ready={true}
                        sortByField={"rate"}
                        sortOrder={"descending"}
                        noElementsText={strings.noneList} />
                    <br></br>
                </BorderTable>
            </DivTable>
            {/* <MainTable style={{ width: "100%" }}>
                <tr>
                    <th></th>
                    <th>{strings.name}</th>
                    <th>{strings.type}</th>
                    <th>{strings.averageScore}</th>
                    <th>{assistantData?.session?.tasting?.template === "BLIND_TEMPLATE" ? strings.averagePrice : strings.priceQualityAssessment}</th>
                    <th>{strings.actions}</th>
                </tr>
                {assistantData ?
                    (assistantData?.session?.tasting?.productReferences || {}).map((productReference) => {
                        return (
                            <tr>
                                <td style={{ display: "flex" }}><ProductImage src={productReference?.product?.image} /></td>
                                <td>{productReference?.product?.name || "-"}</td>
                                <td>{productReference?.product?.type?.name?.[config.lang] || "-"}</td>
                                <td>{parsedData.getRate(productReference.reference)}</td>
                                <td>{assistantData?.session?.tasting?.template === "BLIND_TEMPLATE" ? (
                                    parsedData.getPrice(productReference.reference) + " " + (config?.cellar?.currency || "€")
                                ) : (
                                    parsedData.getCualityPrice(productReference.reference)
                                )}</td>
                                <td>
                                    <Button
                                        value={strings.viewCompleteInfo}
                                        action={() => { showProductData(productReference.reference) }}
                                    />
                                </td>
                            </tr>
                        );
                    }) : <></>
                }
            </MainTable> */}
        </Wrapper>
    );
};
export default FormComponent;