import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from "react-router-dom";
import ListEngine from "./List/ListEngine";
import CreateFormEngine from "./Form/CreateFormEngine";
import EditFormEngine from "./Form/EditFormEngine";

const InstructorRoutes = ({ strings, config, ready, instructors, setInstructors, refresh }) => {
  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
  };

  let { path } = useRouteMatch();
  return (
    <div style={Wrapper}>
      <Switch>
        <Route path={`${path}/list`}>
          <ListEngine strings={strings} config={config} ready={ready} instructors={instructors} setInstructors={setInstructors} />
        </Route>
        <Route path={`${path}/new`}>
          <CreateFormEngine strings={strings} config={config} instructors={instructors} refresh={refresh} />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <EditFormEngine strings={strings} config={config} refresh={refresh} />
        </Route>
      </Switch>
    </div>
  );
};

export default InstructorRoutes;
