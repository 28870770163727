import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HomeComponent from "./HomeComponent";
import VideoPopup from "./VideoPopup";
import Firebase from "../../../../../assets/Firebase";

const HomeEngine = ({ strings, config }) => {
  let f = new Firebase();
  let history = useHistory();

  const [videoPopupData, setVideoPopupData] = useState({ visible: false });

  const changePlan = () => {
    history.push("/dashboard/cellar/plan");
  };

  const loadVideo = (header, videoID) => {
    setVideoPopupData({ visible: true, header: header, videoID: videoID });
  };

  return (
    <>
      <VideoPopup strings={strings} popupData={videoPopupData} setPopupData={setVideoPopupData} />
      <HomeComponent strings={strings} changePlan={changePlan} loadVideo={loadVideo} config={config} />
    </>
  );
};

export default HomeEngine;
