import React from "react";
import styled from "@emotion/styled";
import Radium from "radium";
import Colors from "../../assets/Colors.json";

const Select = ({ id = "selectId", options, defaultOption, width = "95%", disabled = false, onChange = () => {} }) => {
  const Wrapper = styled.select`
    border-radius: 0.3vh;
    border: solid 0.2vh #d8dde1;
    padding: 1.5vh;
    width: ${width};
    &:focus {
      border-color: ${Colors.lightTheme.accentColor1};
    }
  `;

  const Option = styled.option``;

  const addElementStyle = {
    color: "gray"
  }

  const sortOptions = (options) => {
    let entries = Object.entries(options);
    let sortedEntries = entries.sort(([keyA, valA], [keyB, valB]) => {
      if (keyA === "notSelected") return -1;
      else if (keyB === "notSelected") return 1;
      else if (valA?.toLowerCase() < valB?.toLowerCase()) return -1;
      else if (valA?.toLowerCase() > valB?.toLowerCase()) return 1;
      else return 0;
    });
    const sortedObj = Object.fromEntries(sortedEntries);
    return sortedObj;
  }

  const loadOptions = () => {
    let Views = [];
    let sortedOptions = sortOptions(options);
    for (let key in sortedOptions) {
      Views.push(
        <Option key={key} value={key} style={key === "addElement" ? addElementStyle : null}>
          {options[key]}
        </Option>
      );
    }
    return Views;
  };

  return (
    <Wrapper id={id} disabled={disabled} defaultValue={defaultOption} onChange={onChange ? (e) => onChange(id, e.target.value) : null}>
      {loadOptions()}
    </Wrapper>
  );
};

export default Radium(Select);
