import React from "react";
import TerciaryButton from "../../../../../shared/TerciaryButton";
import Button from "../../../../../shared/Button";
import ImagePicker from "../../../../../shared/ImagePicker";
import TextArea from "../../../../../shared/TextArea";
import TextInput from "../../../../../shared/TextInput";
import Checkbox from "../../../../../shared/Checkbox";
import Colors from "../../../../../../assets/Colors.json";
import styled from "@emotion/styled";
import LinkInputComponent from "./LinkInputComponent";
import SoundInput from "./SoundInput";

const FormComponent = ({ strings, config, theme, updateTheme, updateConfig, updateAudio, confirmAction, cancelAction }) => {
    const sounds = {
        startTasting: strings?.startTasting,
        validate: strings?.validationVideo,
        storeSound: strings?.store,
        prerecomendation: strings?.prerecomendation,
        postrecomendation: strings?.postrecomendation,
    }

    const Title = styled.div`
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        padding: 2vh 5vh;
    `;

    const Subheader = styled.div`
        margin: 1.5rem 0.5rem 1rem 0rem;
        font-weight: bold;
        font-size: 2.5vh;
        color: ${Colors.lightTheme.accentColor1};
        padding-bottom: 0.5rem;
        border-bottom: 1px solid gray;
    `;

    const ButtonWrapper = styled.div`
        text-align: center;
        padding-top: 1rem;
        margin: 2vh 3vh;
        border-top: 0.1vh solid grey;
    `;

    const Tag = styled.div`
        margin: 0.5rem 1rem 0.5rem 0rem;
    `;

    const LobyTextArea = {
        width: "90%",
        height: "27.4vh",
    }

    const Row = {
        display: "flex",
        flexDirection: "row",
    }

    return (
        <div style={{ width: "100%" }}>
            <Title>{strings.editTheme}</Title>
            <div style={{ padding: "1vh 3vh", display: "flex", }}>

                <div id="leftColumn" style={{ width: "50%", marginRight: "0.5rem" }}>
                    <Subheader>{strings.colors}</Subheader>
                    <div style={Row}>
                        <div style={{ marginRight: "3vh" }}>
                            <Tag>{strings.mainColor}</Tag>
                            <input type="color" value={theme?.mainColor ? theme?.mainColor : "#ae1c38"} onChange={(e) => { updateTheme("mainColor", e.target.value) }} />
                        </div>
                        <div>
                            <Tag>{strings.secondaryColor}</Tag>
                            <input type="color" value={theme?.secondaryColor ? theme?.secondaryColor : "#757a80"} onChange={(e) => { updateTheme("secondaryColor", e.target.value) }} />
                        </div>
                    </div>

                    <Subheader>{strings.lobbyScreen}</Subheader>
                    <div style={Row}>
                        <div style={{ marginRight: "1%", width: "49%" }}>
                            <Tag>{strings.lobbyImage}</Tag>
                            <ImagePicker
                                id={"lobbyImage"}
                                src={theme?.lobbyImage}
                                upload={true}
                                cloudPath={"theme/web"}
                                onSelect={updateTheme}
                                width={"100%"}
                                height={"30vh"} />
                        </div>
                        <div style={{ marginLeft: "1%", width: "49%" }}>
                            <Tag>{strings.lobbyText}</Tag>
                            <TextArea
                                id={"lobbyText"}
                                value={theme?.lobbyText}
                                style={LobyTextArea}
                                onChange={updateTheme}
                                translatable={true} />
                        </div>
                    </div>
                    <div style={{ ...Row, marginTop: "2vh", justifyContent: "space-between" }}>
                        <Checkbox
                            text={strings.skipAuto}
                            checked={theme?.lobbySkipActive}
                            onChange={() => {
                                updateTheme("lobbySkipActive", !theme?.lobbySkipActive);
                                if (!theme?.lobbySkipTimeout) {
                                    updateTheme("lobbySkipTimeout", 10);
                                }
                            }} />

                        {theme?.lobbySkipActive ? (
                            <TextInput type="number" unit="segs" width="50%" min="1" max="120" pattern={"[1-9]{2}"}
                                value={theme?.lobbySkipTimeout}
                                onChange={(e) => {
                                    let numVal = parseInt(e?.target?.value);
                                    if (numVal) updateTheme("lobbySkipTimeout", numVal);
                                }} />
                        ) : (
                            <></>
                        )}
                    </div>

                    <Subheader>{strings.otherSettings}</Subheader>
                    <LinkInputComponent strings={strings} config={config} id={"termsOfUse"} text={strings.termsOfUse} theme={theme} updateTheme={updateTheme} updateConfig={updateConfig} confirmAction={confirmAction} />
                    <LinkInputComponent strings={strings} config={config} id={"privacyPolicies"} text={strings.privacyPolicies} theme={theme} updateTheme={updateTheme} updateConfig={updateConfig} confirmAction={confirmAction} />

                </div>
                <div id="rightColumn" style={{ width: "50%", marginLeft: "1.5rem", paddingLeft: "1.5rem", borderLeft: "1px solid gray" }}>
                    <Subheader>{strings.background}</Subheader>
                    <div>
                        <Tag>{strings.mainBackground}</Tag>
                        <ImagePicker
                            id={"mainBackground"}
                            strings={strings}
                            config={config}
                            maxImgSize={20}
                            maxImgHeight={1440}
                            maxImgWidth={2560}
                            src={theme?.mainBackground}
                            upload={true}
                            cloudPath={"theme/web"}
                            onSelect={updateTheme}
                            width={"100%"}
                            height={"30vh"} />
                    </div>

                    <Subheader>{strings.sounds}</Subheader>
                    <div>
                        {Object.entries(sounds).map(([audioId, audioName]) =>
                            <SoundInput
                                id={audioId}
                                header={audioName}
                                strings={strings}
                                config={config}
                                audioData={theme?.audios?.[audioId]}
                                upload={true}
                                cloudPath={"theme/web"}
                                updateAudio={(prop, value) => {
                                    updateAudio(audioId, prop, value);
                                }} />
                        )}
                    </div>
                </div>
            </div>
            <ButtonWrapper>
                <TerciaryButton style={{ marginRight: "2vh" }} action={cancelAction} value={strings.cancel} />
                <Button action={() => { confirmAction() }} value={strings.confirm} />
            </ButtonWrapper>
        </div>
    );
};
export default FormComponent;
