import React from "react";
import TerciaryButton from "../../../../../shared/TerciaryButton";
import Button from "../../../../../shared/Button";
import styled from "@emotion/styled";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomPageComponent = ({ strings, config, getContent, updateContent, confirmAction, cancelAction }) => {

    const Title = styled.div`
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        padding: 2vh 5vh;
    `;

    const ButtonWrapper = styled.div`
        text-align: center;
        padding-top: 1rem;
        margin: 2vh 3vh;
        border-top: 0.1vh solid grey;
    `;

    const InputTextWrapper = {
        margin: "1vh 2.5%",
        display: "flex",
        width: "95%",
        height: "30%",
        maxHeight: "30%",
        backgroundColor: "white",
        border: "3px solid gray"
    }

    const InputTextStyle = {
        height: "90%",
        width: "100%",
    }

    return (
        <div style={{ width: "100%" }}>
            <Title>{strings.editTheme}</Title>
            <div style={InputTextWrapper}>
                <ReactQuill theme="snow" value={getContent()} onChange={updateContent} style={InputTextStyle} />
            </div>
            <ButtonWrapper>
                <TerciaryButton style={{ marginRight: "2vh" }} action={cancelAction} value={strings.cancel} />
                <Button action={confirmAction} value={strings.confirm} />
            </ButtonWrapper>
        </div>
    );
};
export default CustomPageComponent;