import React from "react";
import styled from "@emotion/styled";

import Firebase from "../../../../assets/Firebase";
import Colors from "../../../../assets/Colors.json";
import Tooltip from "@material-ui/core/Tooltip";
import flagCA from "../../../../assets/images/flagCA.png";
import flagES from "../../../../assets/images/flagES.png";
import flagEN from "../../../../assets/images/flagEN.png";
import flagDE from "../../../../assets/images/flagDE.png";
import flagFR from "../../../../assets/images/flagFR.png";
import flagIT from "../../../../assets/images/flagIT.png";
import flagPL from "../../../../assets/images/flagPL.png";
import flagPT from "../../../../assets/images/flagPT.png";


const LanguagePopup = ({ strings, visible, setVisible, setLanguage }) => {
  const PopupBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  `;

  const Popup = styled.div`
    position: absolute;
    z-index: 99;
    max-width: 30%;
    min-width: 20%;
    max-height: 40%;
    min-height: 10%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${Colors.lightTheme.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${Colors.lightTheme.accentColor1};
    margin: 1vh auto;
  `;

  const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `;

  const Icon = styled.img`
    margin: 1vw;
    width: 4vw;
    height: auto;
    cursor: pointer;
    box-shadow: grey 0px 0px 20px;
    border-radius: 2vw;
  `;

  const Tag = styled.div`
    padding: 0.5vh;
    font-size: 1.7vh;
  `;

  const close = () => {
    setVisible(false);
  };

  const confirm = (lang) => {
    setLanguage(lang);
    close();

    let f = new Firebase();
    f.updateCeller({ lang: lang }, () => {});
  };

  const Langs = {
    ES: {
      icon: flagES,
      name: strings.spanish
    },
    EN: {
      icon: flagEN,
      name: strings.english
    },
    CA: {
      icon: flagCA,
      name: strings.catalan
    },
    DE: {
      icon: flagDE,
      name: strings.german
    },
    FR: {
      icon: flagFR,
      name: strings.french
    },
    IT: {
      icon: flagIT,
      name: strings.italian
    },
    PL: {
      icon: flagPL,
      name: strings.polish
    },
    PT: {
      icon: flagPT,
      name: strings.portuguese
    }
  }

  return (
    <React.Fragment>
      {visible ? (
        <PopupBackground>
          <Popup>
            <Header>{strings.changeLang}</Header>
            <Divider />
            <IconWrapper>
              {Object.entries(Langs).map(([code, data]) => {
                return (
                  <Tooltip title={<Tag>{data.name}</Tag>} aria-label="add" arrow>
                    <Icon src={data.icon} onClick={() => confirm(code)} />
                  </Tooltip>
                );
              })}
            </IconWrapper>
          </Popup>
        </PopupBackground>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default LanguagePopup;
