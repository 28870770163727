import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import Table from "../../../../../shared/Table";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import ResumeIconGray from "../../../../../../assets/images/resume-icon-gray.svg";
import Tooltip from "@material-ui/core/Tooltip";

const ListComponent = ({ strings, type, events, paymentData, newEvent, editEvent, deleteEvent, showReport, ready }) => {
  const Container = styled.div`
    width: 100%;
  `;

  const Wrapper = styled.div`
    flex-grow: 1;
    padding: 1rem 2rem;
    > * {
      margin: 0.5rem 0;
    }
  `;

  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const SumaryTable = styled.table`
    border-collapse: collapse;
    margin: 2vh 0vh;
    td {
      padding: 1vh 3vh;
    }
    th {
      padding: 1vh 3vh;
      text-align: left;
      background-color: rgba(0, 0, 0, 0.1);
    }
    tr {
      background-color: white;
    }
  `;

  const Icon = styled.img`
    margin: auto;
    height: 2.5vh;
    width: auto;
  `;

  let actions = [
    {
      title: (
        <Tooltip title={strings.modifySession} aria-label="add">
          <Icon src={EditIcon} />
        </Tooltip>
      ),
      action: editEvent,
    },
    {
      title: (
        <Tooltip title={strings.deleteSession} aria-label="add">
          <Icon src={DeleteIcon} />
        </Tooltip>
      ),
      action: deleteEvent,
    },
    {
      title: (
        <Tooltip title={strings.showReport} aria-label="add">
          <Icon src={ResumeIconGray} />
        </Tooltip>
      ),
      action: showReport,
    }
  ];

  return (
    <Container>
      <Title>{type == "CONVENTIONAL_TAST" ? strings.liveListTittle : strings.podcastListTittle}</Title>
      <Wrapper>
        <Table
          header={type == "CONVENTIONAL_TAST" ? strings.LiveSessionsTable : strings.PodcastSessionsTable}
          noElementsText={strings.noneList}
          data={events}
          actions={actions}
          ready={ready}
          sortByField={"fromDate"}
          sortOrder={"descending"} />

        {paymentData ? (
          <SumaryTable>
            <tbody>
              <tr>
                <th></th>
                <th>{strings.active}</th>
                <th>{strings.available}</th>
              </tr>
              <tr>
                <td>{strings.included}</td>
                <td>{paymentData.includedActive}</td>
                <td>{paymentData.includedLeft}</td>
              </tr>
              <tr>
                <td>{strings.additional}</td>
                <td>{paymentData.additional}</td>
                <td></td>
              </tr>
              <tr>
                <th colSpan="2">{strings.additionalexpense}</th>
                <th>{paymentData.additionalPayment}</th>
              </tr>
            </tbody>
          </SumaryTable>
        ) : (
          <></>
        )}

        <Button value={type == "CONVENTIONAL_TAST" ? strings.addLiveSession : strings.addPodcastSession} action={newEvent} />
      </Wrapper>
    </Container>
  );
};

export default ListComponent;
