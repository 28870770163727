import React, { useEffect, useState } from "react";
import DashboardRoutes from "./DashboardRoutes";

import Firebase from "../../../assets/Firebase";
import { useHistory } from "react-router-dom";
import LoadingScreen from "../../shared/LoadingScreen";
import NavBarEngine from "./Navbar/NavbarEngine";
import ToolbarEngine from "./Toolbar/ToolbarEngine";
import DemoDataPopupEngine from "./DemoDataPopup/DemoDataPopupEngine";
import DownloadingPopup from "../../shared/DownloadingPopup";
import InputPopup from "../../shared/InputPopup";

const DashboardEngine = ({ strings, lang, setLang, theme, popupAlert, closePopupAlert }) => {
  let history = useHistory();
  let f = new Firebase();
  let loadingUser = false;

  const [config, setConfig] = useState({
    user: null,
    plan: null,
    blocked: false,
    tooltipsEnabled: true,
    remainingFreeDays: 0,
    lang: lang,
    modificated: false,
    demoDataLoaded: true,
    downloadingText: null,
    inputPoupData: null,
    strings: strings,

    // Functions
    isBlocked: function isBlocked() {
      if (this.blocked) {
        this.popupAlert(
          this.strings.updatePayment,
          this.strings.blockMsg,
          null,
          true,
          () => {
            history.replace("/dashboard/cellar/plan");
          },
          null,
          this.strings.renewLicense
        );
      }
      return this.blocked;
    },

    isModificated: function (onAccept) {
      if (this.modificated) {
        this.popupAlert(this.strings.warning, this.strings.modificationWaring, null, true, () => {
          this.update("modificated", false);
          onAccept();
        });
      } else {
        onAccept();
      }
      return this.modificated;
    },

    canSave: function () {
      if (!this.modificated) {
        this.popupAlert(this.strings.error, this.strings.noModificarionsError);
      }
      return this.modificated;
    },

    update: function (parameter, value) {
      setConfig((prevState) => ({
        ...prevState,
        [parameter]: value,
      }));
    },

    closeInputPopup: function () {
      this.update("inputPoupData", null);
    },

    showInputPopup: function (header, hint, onAccept) {
      this.update("inputPoupData", {
        header: header,
        hint: hint,
        onAccept: onAccept,
        onCancel: () => this.closeInputPopup(),
      });
    },

    showDownloadingPopup: function (text) {
      this.update("downloadingText", text);
    },

    hideDownloadingPopup: function () {
      this.update("downloadingText", null);
    },

    popupAlert: popupAlert,
    closePopupAlert: closePopupAlert,
  });

  useEffect(() => {
    if (config.user === null && !loadingUser) {
      // Check user type
      loadingUser = true;
      loadUser()
    }
  }, [config.user]);

  const loadUser = () => {
    const query = new URLSearchParams(window.location.search);
    const tokenId = query.get('tokenId');

    if (tokenId) {
      loadUserWithTokenId(tokenId);
    } else {
      f.checkSignedUser(success, error);
    }
  }

  const loadUserWithTokenId = (tokenId) => {
    console.log("TokenId verified. Trying to login...");

    f.tryLoginWithCustomToken(tokenId, (result) => {
      console.log("Logged with custom token");
      success(result.user);
      history.replace("/")
    }, (erro) => {
      console.error("Error TryLogin: ", erro);
      error();
    });
  }

  useEffect(() => {
    config.update("lang", lang);
  }, [lang]);

  const success = (user) => {
    let userData = {
      uid: user.uid,
      email: user.email,
      creationDate: Date.parse(f.getCreationDate()),
    };
    config.update("user", userData);

    f.loadPlan((planData) => {
      let plan = { ...planData };
      config.update("plan", plan);

      f.loadCeller((cellarDoc) => {
        let cellar = cellarDoc.data();
        if (cellar?.storeActive === null || cellar?.storeActive === undefined) {
          cellar.storeActive = true
        }

        config.update("cellar", cellar);
        config.update("demoDataLoaded", cellar?.demoDataLoaded);

        if ("tooltipsEnabled" in cellar) {
          config.update("tooltipsEnabled", cellar?.tooltipsEnabled);
        }

        let freePlanLimit;
        if ("freePlanLimit" in cellarDoc.data()) {
          freePlanLimit = cellar.freePlanLimit.seconds * 1000;//new f.firebase.firestore().Timestamp(cellar.freePlanLimit.seconds, 0).toMillis();
        } else {
          freePlanLimit = userData.creationDate + 1000 * 60 * 60 * 24 * 30;
          f.updateCeller({ freePlanLimit: new Date(freePlanLimit) }, () => { }, user.uid);
        }

        if (plan.default) {
          const now = new Date();
          const diffTime = freePlanLimit - now;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays <= 0) {
            config.update("remainingFreeDays", 0);
            config.update("blocked", true);
          } else {
            config.update("remainingFreeDays", diffDays);
          }

          config.update("freePlanLimit", new Date(freePlanLimit));
        }
        loadingUser = false;
      }, user.uid);
    }, user.uid);
  };

  const error = () => {
    console.log("Error")
    history.replace("/login");
    loadingUser = false;
  };

  const Container = {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
  };

  const Wrapper = {
    flex: "1",
    flexDirection: "column",
    display: "flex",
  };

  useEffect(() => {
    config.update("strings", strings);
  }, [strings]);

  return (
    <div style={Container}>
      <DemoDataPopupEngine strings={strings} config={config} visible={!config.demoDataLoaded} />
      <NavBarEngine strings={strings} config={config} />
      <div style={Wrapper}>
        {config.user ? (
          <>
            <ToolbarEngine strings={strings} config={config} setLang={setLang} />
            <DashboardRoutes strings={strings} config={config} />
          </>
        ) : (
          <LoadingScreen />
        )}
      </div>
      {config?.downloadingText !== null ? (
        <DownloadingPopup headerText={config?.downloadingText} />
      ) : (
        <></>
      )}

      {config?.inputPoupData !== null ? (
        <InputPopup
          strings={strings}
          headerText={config?.inputPoupData?.header}
          hintText={config?.inputPoupData?.hint}
          onAccept={config?.inputPoupData?.onAccept}
          onCancel={config?.inputPoupData?.onCancel}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DashboardEngine;
