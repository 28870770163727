import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import UploadImg from "../../assets/images/ic_upload.png";
import BackendAccessor from "../../assets/BackendAccessor";
import DownloadingPopup from "./DownloadingPopup";

const ImagePicker = ({ config, strings, id, src, upload, cloudPath, onSelect, maxImgSize, maxImgWidth, maxImgHeight, width = "100%", height = "20vh" }) => {
    let ba = new BackendAccessor();
    const [loadingText, setLoadingText] = useState(null);

    const onChange = (input) => {
        if (input.target.files && input.target.files[0]) {

            let file = input.target.files[0];
            let url = URL.createObjectURL(file);
            let size = file.size / (1024 * 1024); //MB
            if (maxImgSize && size > maxImgSize) {
                config.popupAlert(strings?.error, strings?.errorMaxSize?.replace("{size}", maxImgSize));
            } else {
                let image = new Image();
                image.src = url;
                image.onload = () => {
                    let height = image.height;
                    let width = image.width;

                    console.log("Uploaded image: " + size + "kb (" + height + "x" + width + " px)");

                    if (maxImgHeight && height > maxImgHeight) {
                        config.popupAlert(strings?.error, strings?.errorMaxHeight?.replace("{height}", maxImgHeight));
                    } else if (maxImgWidth && width > maxImgWidth) {
                        config.popupAlert(strings?.error, strings?.errorMaxWidth?.replace("{width}", maxImgWidth));
                    } else if (upload) {
                        uploadImage(file);
                    } else {
                        readImage(file);
                    }
                };
            }
        }
    };

    const readImage = (file) => {
        let reader = new FileReader();
        reader.onload = function (event) {
            onSelect(id, event.target.result);
        };
        reader.readAsDataURL(file);
    }

    const uploadImage = (file) => {
        setLoadingText("Uploading image");
        ba.uploadFile(
            cloudPath,
            file,
            (result) => {
                console.log("Result", result);
                onSelect(id, result.url);
                setLoadingText(null);
            },
            (error) => {
                console.error("Error uplading icon: ", error);
                setLoadingText(null);
            }
        );
    };

    const ImageContainer = {
        height: height,
        width: width,
        textAlign: "center",
        backgroundColor: "#cbcbcb",
        position: "relative",
    };

    const ImageStyle = {
        maxHeight: "90%",
        maxWidth: "90%",
        cursor: "pointer",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
    };

    const ImageUpload = {
        ...ImageStyle,
        maxHeight: "40%",
        maxWidth: "40%",
    };

    const ImageInput = styled.input`
        display: none;
    `;

    return (
        <div style={ImageContainer}>
            <img src={src ? src : UploadImg} style={src ? ImageStyle : ImageUpload} onClick={() => document.getElementById("ImageInput-" + id).click()} />
            <ImageInput type="file" id={"ImageInput-" + id} accept="image/png, image/jpeg" onChange={onChange} />
            {loadingText != null ? <DownloadingPopup headerText={loadingText} /> : <></>}
        </div>
    );
};
export default ImagePicker;
