import Firebase from "./Firebase";

const apiServersUrls = {
  prod: "https://uptasting-api-server.herokuapp.com",
  staging: "https://uptasting-api-server.herokuapp.com",
  dev: "https://uptasting-api-server-dev.herokuapp.com",
  local: "http://localhost:8080"
}

class BackendAccessor {
  constructor() {
    // Singleton instance
    if (typeof BackendAccessor.instance === "object") {
      return BackendAccessor.instance;

    } else {
      const f = new Firebase();
      const env = process.env.REACT_APP_ENVIRONMENT;

      this.backendUrl = apiServersUrls[env] || apiServersUrls.prod;

      f.runWhenSignUp(() => {
        BackendAccessor.instance.uid = f.getUid();
        f.getTokenId(
          (tokenId) => {
            BackendAccessor.instance.tokenId = tokenId;
          },
          (error) => {
            console.error("Error loading tokenId: " + error);
          }
        );
      });

      BackendAccessor.instance = this;
      return this;
    }
  }

  fetchBackend(method, path, body, onSuccess, onError) {
    const controller = new AbortController();
    var requestOptions = {
      method: method,
      headers: {
        Authorization: "Bearer " + this.tokenId,
      },
      redirect: "follow",
      signal: controller.signal
    };

    if (body != null) {
      if (body instanceof FormData) {
        requestOptions.body = body;
      } else {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
    }

    const id = setTimeout(() => {
      controller.abort();
    }, 120000);

    fetch(this.backendUrl + path, requestOptions)
      .then((response) => {
        clearTimeout(id);
        return response.text()
      }).then((result) => {
        var resultObj = JSON.parse(result);
        if (resultObj.error) {
          onError(resultObj);
        } else {
          onSuccess(resultObj);
        }
      })
      .catch((error) => onError(error));
  }

  postBackend(path, body, onSuccess, onError) {
    this.fetchBackend("POST", path, body, onSuccess, onError);
  }

  getBackend(path, body, onSuccess, onError) {
    this.fetchBackend("GET", path, body, onSuccess, onError);
  }

  deleteBackend(path, body, onSuccess, onError) {
    this.fetchBackend("DELETE", path, body, onSuccess, onError);
  }

  createUser(data, onSuccess, onError) {
    this.postBackend("/api/v1/user/createUser", data, onSuccess, onError);
  };

  generateDemoData(onSuccess, onError) {
    this.postBackend("/api/v1/user/" + this.uid + "/generateDemoData", null, onSuccess, onError);
  };

  getUserById(uid, onSuccess, onError) {
    this.getBackend("/api/v1/user/" + uid, null, onSuccess, onError);
  };

  getPublicAssessmentSheets(onSuccess, onError) {
    this.getBackend("/api/v1/assessmentSheet/list", null, onSuccess, onError);
  }

  getPublicAssessmentSheetsOfProductType(productTypeId, onSuccess, onError) {
    this.getBackend("/api/v1/assessmentSheet/" + productTypeId, null, onSuccess, onError);
  }

  getPrivateAssessmentSheets(onSuccess, onError) {
    this.getBackend("/api/v1/assessmentSheet/user/" + this.uid + "/list", null, onSuccess, onError);
  }

  getPrivateAssessmentSheet(assessmentSheetId, onSuccess, onError) {
    this.getBackend("/api/v1/assessmentSheet/user/" + this.uid + "/" + assessmentSheetId, null, onSuccess, onError);
  }

  getAssessmentSheetCategories(assessmentSheetPath, onSuccess, onError) {
    this.getBackend("/api/v1/assessmentSheet/categories?path=" + assessmentSheetPath, null, onSuccess, onError);
  }

  deletePrivateAssessmentSheet(assessmentSheetId, onSuccess, onError) {
    this.deleteBackend("/api/v1/assessmentSheet/user/" + this.uid + "/" + assessmentSheetId, null, onSuccess, onError);
  }

  importAssessmentSheets(path, onSuccess, onError) {
    this.postBackend(
      "/api/v1/assessmentSheet/user/" + this.uid + "/import",
      {
        path: path,
      },
      onSuccess,
      onError
    );
  }

  cloneAssessmentSheets(assessmentSheetId, onSuccess, onError) {
    this.postBackend("/api/v1/assessmentSheet/user/" + this.uid + "/clone/" + assessmentSheetId, null, onSuccess, onError);
  }

  createAssessmentSheet(assessmentSheet, onSuccess, onError) {
    this.postBackend("/api/v1/assessmentSheet/user/" + this.uid, assessmentSheet, onSuccess, onError);
  }

  updateAssessmentSheet(id, assessmentSheet, onSuccess, onError) {
    this.postBackend("/api/v1/assessmentSheet/user/" + this.uid + "/" + id, assessmentSheet, onSuccess, onError);
  }

  uploadFile(cloudPath, file, onSuccess, onError) {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("path", cloudPath);
    this.postBackend("/api/v1/files/" + this.uid, formdata, onSuccess, onError);
  }

  getPublicProductsTypes(onSuccess, onError) {
    this.getBackend("/api/v1/assessmentSheet/product", null, onSuccess, onError);
  }

  getAllowedProductsTypes(onSuccess, onError) {
    this.getBackend("/api/v1/assessmentSheet/userProductTypes/" + this.uid, null, onSuccess, onError);
  }

  getWebTheme(onSuccess, onError) {
    this.getBackend("/api/v1/theme/" + this.uid + "/web", null, onSuccess, onError);
  }

  uploadWebTheme(theme, onSuccess, onError) {
    this.postBackend("/api/v1/theme/" + this.uid + "/web", theme, onSuccess, onError);
  }

  getCustomPage(pageId, onSuccess, onError) {
    this.getBackend("/api/v1/customPage/" + this.uid + "/" + pageId, null, onSuccess, onError);
  }

  getCustomPageOfUser(uid, pageId, onSuccess, onError) {
    this.getBackend("/api/v1/customPage/" + uid + "/" + pageId, null, onSuccess, onError);
  }

  uploadCustomPage(pageId, page, onSuccess, onError) {
    this.postBackend("/api/v1/customPage/" + this.uid + "/" + pageId, page, onSuccess, onError);
  }

  getTastingsList(type, onSuccess, onError) {
    this.getBackend("/api/v1/tasting/" + this.uid + "?type=" + type, null, onSuccess, onError);
  }

  getTastingById(id, onSuccess, onError) {
    this.getBackend("/api/v1/tasting/" + this.uid + "/" + id, null, onSuccess, onError);
  }

  createTasting(tasting, onSuccess, onError) {
    this.postBackend("/api/v1/tasting/" + this.uid, tasting, onSuccess, onError);
  }

  cloneTasting(id, onSuccess, onError) {
    this.postBackend("/api/v1/tasting/" + this.uid + "/" + id + "/clone", null, onSuccess, onError);
  }

  updateTastingById(id, tasting, onSuccess, onError) {
    this.postBackend("/api/v1/tasting/" + this.uid + "/" + id, tasting, onSuccess, onError);
  }

  deleteTastingById(id, onSuccess, onError) {
    this.deleteBackend("/api/v1/tasting/" + this.uid + "/" + id, null, onSuccess, onError);
  }

  createTastingDemoSession(tastingId, onSuccess, onError) {
    this.getBackend("/api/v1/tasting/" + this.uid + "/" + tastingId + "/demoSession", null, onSuccess, onError);
  }

  getProductsList(onSuccess, onError) {
    this.getBackend("/api/v1/product/" + this.uid, null, onSuccess, onError);
  }

  getProductById(productId, onSuccess, onError) {
    this.getBackend("/api/v1/product/" + this.uid + "/" + productId, null, onSuccess, onError);
  }

  createProduct(product, onSuccess, onError) {
    this.postBackend("/api/v1/product/" + this.uid, product, onSuccess, onError);
  }

  updateProductById(productId, product, onSuccess, onError) {
    this.postBackend("/api/v1/product/" + this.uid + "/" + productId, product, onSuccess, onError);
  }

  deleteProductById(productId, onSuccess, onError) {
    this.deleteBackend("/api/v1/product/" + this.uid + "/" + productId, null, onSuccess, onError);
  }

  cloneProduct(productId, onSuccess, onError) {
    this.postBackend("/api/v1/product/" + this.uid + "/clone/" + productId, null, onSuccess, onError);
  }

  cloneProductStructure(productId, onSuccess, onError) {
    this.postBackend("/api/v1/product/" + this.uid + "/cloneStructure/" + productId, null, onSuccess, onError);
  }

  getMentorByPublicCode(publicCode, onSuccess, onError) {
    this.getBackend("/api/v1/mentor/publicCode/" + publicCode, null, onSuccess, onError);
  }

  getAssociatedMentor(onSuccess, onError) {
    this.getBackend("/api/v1/user/" + this.uid + "/mentor", null, onSuccess, onError);
  }

  getAllEvents(onSuccess, onError) {
    this.getBackend("/api/v1/session/user/" + this.uid + "?includeOutDated=true", null, onSuccess, onError);
  }

  getAllEventsOfType(type, onSuccess, onError) {
    this.getBackend("/api/v1/session/user/" + this.uid + "?includeOutDated=true&type=" + type, null, onSuccess, onError);
  }

  getEventById(id, onSuccess, onError) {
    this.getBackend("/api/v1/session/user/" + this.uid + "/" + id, null, onSuccess, onError);
  }

  deleteEventById(id, onSuccess, onError) {
    this.deleteBackend("/api/v1/session/user/" + this.uid + "/" + id, null, onSuccess, onError);
  }

  createEvent(event, onSuccess, onError) {
    this.postBackend("/api/v1/session/user/" + this.uid, event, onSuccess, onError);
  }

  updateEvent(id, event, onSuccess, onError) {
    this.postBackend("/api/v1/session/user/" + this.uid + "/" + id, event, onSuccess, onError);
  }

  checkSessioCodeFree(code, onSuccess, onError) {
    this.getBackend("/api/v1/session/checkAll/" + code, null, onSuccess, onError);
  }

  generateSessioCodeFree(onSuccess, onError) {
    this.getBackend("/api/v1/session/generateFreePublicCode", null, onSuccess, onError);
  }

  getInstructorsList(onSuccess, onError) {
    this.getBackend("/api/v1/instructor/" + this.uid, null, onSuccess, onError);
  }

  getApiKeys(onSuccess, onError) {
    this.getBackend("/api/v1/user/" + this.uid + "/apiKey", null, onSuccess, onError);
  }

  createApiKey(apiKey, onSuccess, onError) {
    this.postBackend("/api/v1/user/" + this.uid + "/apiKey", apiKey, onSuccess, onError);
  }

  deleteApiKey(apiKeyId, onSuccess, onError) {
    this.deleteBackend("/api/v1/user/" + this.uid + "/apiKey/" + apiKeyId, null, onSuccess, onError);
  }

  // REPORTS

  // Generates the report without assitants
  generateReportBody(report, onSuccess, onError) {
    this.postBackend("/api/v1/report/" + this.uid + "/generateBody", report, onSuccess, onError);
  }

  // Generates a page of X assistans
  generateReportAssistantsPage(report, page, onSuccess, onError) {
    this.postBackend("/api/v1/report/" + this.uid + "/generateAssistantsPage/" + page, report, onSuccess, onError);
  }

  // List the reports with basic data
  getReportList(onSuccess, onError) {
    this.getBackend("/api/v1/report/" + this.uid, null, onSuccess, onError);
  }

  // Get an existing saved report
  getReportById(reportId, onSuccess, onError) {
    this.getBackend("/api/v1/report/" + this.uid + "/" + reportId, null, onSuccess, onError);
  }

  createReport(report, onSuccess, onError) {
    this.postBackend("/api/v1/report/" + this.uid, report, onSuccess, onError);
  }

  generateReport(report, onSuccess, onError) {
    this.postBackend("/api/v1/report/" + this.uid + "/generate", report, onSuccess, onError);
  }

  getReportStatus(reportId, onSuccess, onError) {
    this.getBackend("/api/v1/report/" + this.uid + "/" + reportId + "/status", null, onSuccess, onError);
  }

  // Update the report atributes (Report name, dates from or to...)
  updateReport(reportId, report, onSuccess, onError) {
    this.postBackend("/api/v1/report/" + this.uid + "/" + reportId, report, onSuccess, onError);
  }

  // Delete the reports
  deleteReport(reportId, onSuccess, onError) {
    this.deleteBackend("/api/v1/report/" + this.uid + "/" + reportId, null, onSuccess, onError);
  }

  sendVerificationCode(data, onSuccess, onError) {
    this.postBackend("/api/v1/assistant/sendVerificationCode", data, onSuccess, onError);
  }

  checkVerificationCode(data, onSuccess, onError) {
    this.postBackend("/api/v1/assistant/checkVerificationCode", data, onSuccess, onError);
  }

  getAssistantSessionsListByEmail(email, page, onSuccess, onError) {
    this.getBackend(`/api/v1/assistant/${email}/sessions/list/${page}`, null, onSuccess, onError);
  }

  getAssistantSessionsListByEmailCreator(email, email2, page, onSuccess, onError) {
    this.getBackend(`/api/v1/assistant/${email}/user/${email2}/sessions/list/${page}`, null, onSuccess, onError);
  }

  getCompleteAssistantSessionsListByEmail(email, onSuccess, onError) {
    this.getBackend(`/api/v1/assistant/list/${email}`, null, onSuccess, onError);
  }

  getCompleteAssistantSessionsListByEmailCreator(email, email2, onSuccess, onError) {
    this.getBackend(`/api/v1/assistant/list/${email}/user/${email2}`, null, onSuccess, onError);
  }

  getCompleteSessionOfAssistant(userId, assistantId, onSuccess, onError) {
    this.getBackend(`/api/v1/assistant/${userId}/${assistantId}`, null, onSuccess, onError);
  }

  getAssistantSessionsList(data, onSuccess, onError) {
    this.postBackend("/api/v1/assistant/list/data", data, onSuccess, onError);
  }

  /* Auxiliary methods */

  getUid() {
    return this.uid;
  }

  getIdFromPath(path) {
    let pathParts = path?.split("/");
    let id = pathParts[pathParts.length - 1];
    return id;
  }

  createUid(length = 20) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
export default BackendAccessor;
