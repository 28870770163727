import React, { useState, useEffect } from "react";
import CustomPageComponent from "./CustomPageComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import BackendAccessor from "../../../../../../assets/BackendAccessor";

import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";

const CustomPageEngine = ({ strings, config }) => {
    let ba = new BackendAccessor();
    let history = useHistory();

    let { id } = useParams();
    const [page, setPage] = useState(null);

    const loadPage = () => {
        ba.getCustomPage(id, (page) => {
            console.info("Page loaded: ", page);
            setPage(page);
        }, (error) => {
            console.error("Error loading page: ", error);
            config.popupAlert(strings.error, strings.loadingError);
            setPage({ id: id });
        })
    };

    useEffect(() => {
        if (page == null) {
            loadPage();
        }
    }, [strings]);

    const savePage = () => {
        console.log("Page: ", page);
        config.showDownloadingPopup(strings.saving);
        ba.uploadCustomPage(id, page, success, error);
    }

    const success = () => {
        config.hideDownloadingPopup();
        config.update("modificated", false);
        config.popupAlert(strings.success, strings.succesMsg, () => {
            history.replace("/dashboard/theme/edit");
        });
    };

    const error = (error) => {
        config.hideDownloadingPopup();
        console.error("Error saving custom page: ", error);

        if (typeof error === 'string' || error instanceof String) {
            config.popupAlert(strings.error, error);
        } else {
            config.popupAlert(strings.error, strings.otherError);
        }
    };

    const cancelAction = () => {
        config.isModificated(() => {
            history.replace("/dashboard/theme/edit");
        });
    };

    const setPageFiltered = (input) => {
        if (!config.modificated) {
            config.update("modificated", true);
        }
        setPage(input);
    };

    const updateContent = (value) => {
        setPageFiltered((prevState) => ({
            ...prevState,
            "content": value,
        }));
    };

    const getContent = () => {
        let content = page?.content;

        if (typeof content === 'string' || content instanceof String) {
            return content;
        } else if (content?.[config.lang]) {
            return content?.[config.lang];
        } else {
            return "";
        }
    }

    return (
        <>
            {page != null ? (
                <CustomPageComponent
                    strings={strings}
                    config={config}
                    getContent={getContent}
                    updateContent={updateContent}
                    confirmAction={savePage}
                    cancelAction={cancelAction}
                />
            ) : (
                <LoadingScreen />
            )}
        </>
    );
};

export default CustomPageEngine;