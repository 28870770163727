import React, { useState, useEffect, useRef } from "react";

import AssistantListFormComponent from "./AssistantListFormComponent.js";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";

const AssistantListFormEngine = ({ strings, reportType, config, reportData, sessionData }) => {
    let history = useHistory();

    const goBack = () => {
        history.replace("/dashboard/reports/" + reportType + "/" + reportData.id);
    }

    const goToDetails = (id) => {
        history.replace("/dashboard/reports/" + reportType + "/" + reportData.id + "/assistant/" + id);
    }

    return (
        <AssistantListFormComponent
            strings={strings}
            config={config}
            reportType={reportType}
            sessionData={sessionData}
            goToDetails={goToDetails}
            goBack={goBack}
        />
    );
};

export default AssistantListFormEngine;