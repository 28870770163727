import React from "react";
import styled from "@emotion/styled";
import { jsx, css, keyframes } from "@emotion/core";
import Colors from "../../assets/Colors.json"

const LoadingAnimation = ({ heigth, width, thickness }) => {
  const colors = Colors.lightTheme;

  const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`;

  const Animation = styled.div`
    display: inline-block;
    position: relative;
    width: ${heigth};
    height: ${width};
    margin: auto;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${heigth};
      height: ${width};
      border: ${thickness || "0.5vh"} solid ${colors.accentColor1};
      border-radius: 50%;
      animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${colors.accentColor1} transparent transparent transparent;
    }
  `;


  return (
    <Animation>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Animation>
  );
};

export default LoadingAnimation;
