import userEvent from "@testing-library/user-event";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Colors from "../../../../../../../assets/Colors.json";

const PlanBanner = ({ strings, config, plan, celler, selectedPlan, selectMensual, selectAnnual }) => {
  let theme = "lightTheme";
  let colors = Colors[theme];

  const Wrapper = {
    backgroundColor: "white",
    borderRadius: "1vh",
    fontSize: "3vh",
    width: "40%",
    marginLeft: "5%",
    boxShadow: "0 5px 16px 0 #6d7278",
    height: "fit-content",
  };

  const Header = {
    borderRadius: "1vh 1vh 0vh 0vh",
    backgroundColor: colors.accentColor1,
    fontSize: "3.5vh",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "white",
    padding: "1.5vh",
    textAlign: "center",
  };

  const FeatsWrapper = {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    minHeight: "40%",
  };

  const PropietyDiv = {
    margin: "2.5vh",
    fontSize: "2vh",
  };

  const PricesWrapper = {
    borderRadius: "0vh 0vh 1vh 1vh",
    backgroundColor: "#d8d8d8",
  };

  const PriceItem = {
    borderTop: "2px solid #cccccc",
    padding: "1.5vh",
    textAlign: "center",
  };

  const SpanCurrency = {
    fontSize: "2vh",
  };

  const PriceHeader = {
    color: "black",
    fontSize: "2vh",
    fontWeight: "bold",
  };

  const Price = {
    fontSize: "3.5vh",
    color: colors.accentColor1,
    fontWeight: "bolder",
  };

  const Iva = {
    fontSize: "1.5vh",
    paddingBottom: "1vh",
  };

  const Button = {
    color: "white",
    bottom: "3vh",
    width: "80%",
    margin: "0 10%",
    borderRadius: "1vh",
    fontWeight: "bold",
    fontSize: "2vh",
    cursor: "pointer",
    padding: "1vh 0",
    backgroundColor: colors.accentColor1,
  };

  const DisabledButton = {
    ...Button,
    color: "white",
    backgroundColor: "gray",
    cursor: "default",
  };

  const formatPrice = (price) => {
    if (price) {
      return price.toFixed(2).replace(".", ",");
    } else {
      return "0,00";
    }
  };

  return (
    <div style={Wrapper}>
      <div style={Header}>{plan.name}</div>

      <div style={FeatsWrapper}>
        {plan.visibleProperties.map((propiety, i) => {
          return (
            <div style={PropietyDiv}>
              <b>·</b> {propiety[config.lang]}
            </div>
          );
        })}
      </div>

      <div style={PricesWrapper}>
        <div style={PriceItem}>
          <div style={PriceHeader}>{strings.monthlySubscription}</div>
          <div style={Price}>
            {formatPrice(plan?.monthlyPayment)}
            <span style={SpanCurrency}>{" " + strings.paymentUnit}</span>
          </div>
          <div style={Iva}>{strings.VATnoInclued}</div>

          {selectedPlan != null && plan.ref == selectedPlan.ref && celler?.billing?.interval == "monthly" ? (
            <div style={DisabledButton}>{strings.currentPlan}</div>
          ) : (
            <div style={Button} onClick={selectMensual}>
              {strings.mensualPayment}
            </div>
          )}
        </div>

        {/*<div style={PriceItem}>
          <div style={PriceHeader}>{strings.annualSubscription}</div>
          <div style={Price}>
            {formatPrice(plan?.annualPayment)}
            <span style={SpanCurrency}>{" " + strings.paymentUnit}</span>
          </div>
          <div style={Iva}>{strings.VATnoInclued}</div>

          {selectedPlan != null && plan.ref == selectedPlan.ref && celler?.billing?.interval == "annual" ? (
            <div style={DisabledButton}>{strings.currentPlan}</div>
          ) : (            
            <div style={Button} onClick={selectAnnual}>
              {strings.annualPayment}
            </div>
          )}
          </div>*/}

        <div style={PriceItem}>{ReactHtmlParser(strings.bankTansferHint)}</div>
      </div>

      {/*<div style={{ textAlign: "center", padding: "1vh", margin: "auto 0px" }}>
        {plan.monthlyDiscountedPayment ? (
          <>
            <h1 style={H1}>
              {formatPrice(plan.monthlyDiscountedPayment)}
              {strings.month}
            </h1>
            <p style={Subprice}>{strings.firstMonths.replace("{months}", plan.discountDuration).replace("{price}", formatPrice(plan.monthlyPayment))}</p>
          </>
        ) : (
          <>
            <h1 style={H1}>{formatPrice(plan.monthlyPayment) + strings.paymentUnit}</h1>
            <p style={Subprice}></p>
          </>
        )}
        <p style={Iva}>{strings.VATnoInclued}</p>
      </div>


      {selectedPlan != null && plan.ref == selectedPlan.ref ? (
        <DisButton>{strings.currentPlan}</DisButton>
      ) : (
        <Button onClick={select}>{strings.subscribe}</Button>
      )}*/}
    </div>
  );
};

export default PlanBanner;
