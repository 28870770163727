import React from "react";
import styled from "@emotion/styled";
import Button from "../../shared/Button";
import Colors from "../../../assets/Colors.json";

const VeridficatedEmailPopup = ({ strings, visible, setVisible, resendEmail }) => {
  const PopupBackground = styled.div`
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
  `;

  const Popup = styled.div`
    position: absolute;
    max-width: 40%;
    min-width: 20%;
    max-height: 40%;
    min-height: 10%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${Colors.lightTheme.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${Colors.lightTheme.accentColor1};
    margin: 1vh auto;
  `;

  const Link = {
    margin: "1vw 0",
    color: "gray",
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: Colors.lightTheme.accentColor1,
    },
  };

  const close = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      {visible ? (
        <PopupBackground>
          <Popup>
            <Header>{strings.attention}</Header>
            <Divider />
            <p>{strings.notVerified}</p>
            <div style={Link} onClick={resendEmail}>
              {strings.notRecived}
            </div>
            <Button value={strings.close} action={close} />
          </Popup>
        </PopupBackground>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default VeridficatedEmailPopup;
