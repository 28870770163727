import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import Colors from "../../../../../../assets/Colors.json";
import Table from "../../../../../shared/Table";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import TestIcon from "../../../../../../assets/images/ic_play.svg";
import CloneIcon from "../../../../../../assets/images/copy-clipboard.svg";
import Tooltip from "@material-ui/core/Tooltip";

const ListComponent = ({ strings, tastings, createTasting, editTasting, deleteTasting, testTasting, clone, ready, type }) => {
  const colors = Colors.lightTheme;

  const Container = styled.div`
    width: 100%;
  `;
  const Wrapper = styled.div`
    flex-grow: 1;
    padding: 1rem 2rem;
    > * {
      margin: 0.5rem 0;
    }
  `;
  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const ButtonTest = styled.div`
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 3px;
    display: flex;
  `;

  const Icon = styled.img``;

  let actions = [
    {
      title: (
        <Tooltip title={strings.cloneTasting} aria-label="add">
          <Icon src={CloneIcon} />
        </Tooltip>
      ),
      action: clone,
    },
    {
      title: (
        <Tooltip title={strings.modifyTaste} aria-label="add">
          <Icon src={EditIcon} />
        </Tooltip>
      ),
      action: editTasting,
    },
    {
      title: (
        <Tooltip title={strings.deleteTaste} aria-label="add">
          <Icon src={DeleteIcon} />
        </Tooltip>
      ),
      action: deleteTasting,
    },
    {
      title: (
        <Tooltip title={strings.testTaste} aria-label="add">
          {/*<ButtonTest>
            {strings.testTaste2}
            <Icon src={TestIcon} style={{ marginLeft: "5px", width: "2vh" }} />
          </ButtonTest>*/}
          <Icon src={TestIcon} />
        </Tooltip>
      ),
      action: testTasting,
    },
  ];

  return (
    <Container>
      <Title>{type == "PODCAST_TAST" ? strings.podcastTastingList : type == "CONVENTIONAL_TAST" ? strings.liveTastingList : strings.tastingList}</Title>
      <Wrapper>
        <Table
          header={strings.TastingsTable}
          data={tastings}
          actions={actions}
          ready={ready}
          sortByField={"lastUpdate"}
          sortOrder={"descending"}
          noElementsText={strings.noneList} />
        <Button
          value={type == "PODCAST_TAST" ? strings.addPodcastTaste : type == "CONVENTIONAL_TAST" ? strings.addLiveTaste : strings.addTaste}
          action={createTasting}
        />
      </Wrapper>
    </Container>
  );
};

export default ListComponent;
