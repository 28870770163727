class Config {
  constructor() {
    //Detects environment
    Config.ENV = process.env.REACT_APP_ENVIRONMENT?.toUpperCase().replaceAll(/\s/g, '');

    //Detects navigator
    var isFirefox = typeof InstallTrigger !== "undefined";
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification));      
    var isIE = false || !!document.documentMode;
    var isEdge = !isIE && !!window.StyleMedia;
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    Config.NAV = {
      firefox: isFirefox,
      safari: isSafari,
      explorer: isIE,
      edge: isEdge,
      chrome: isChrome,
    };
  }
}
export default Config;
