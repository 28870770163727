import React from "react";
import styled from "@emotion/styled";
import Button from "./Button";

const IconButton = ({ id = "actionButton", icon, text, action = () => { }, style, iconStyle, enabled = true }) => {

  let ButtonStyle = {
    margin: "1vh",
    ...style
  }

  const Icon = styled.img`
    height: 5vh;
    width: auto;
    margin: auto;
    filter: brightness(0) invert(1);
    ${iconStyle};
  `;

  return (
    <Button id={id} action={action} style={ButtonStyle} enabled={enabled}
      value={
        <div style={{ display: "flex" }}>
          <Icon src={icon} />
          <div style={{ margin: "auto" }}>{text}</div>
        </div>
      } />
  );
};

export default IconButton;
