import React from "react";
import ExpandImg from "../../../../assets/images/ic_expand.png";
import ContractImg from "../../../../assets/images/ic_contract_left.png";

const OptionWrapper = ({ children, icon, strings, id, selected, setSelected }) => {
  const ImgArrow = {
    width: "2vh",
    height: "2vh",
    marginRight: "1vh",
  };

  const ImgIcon = {
    width: "2.5vh",
    height: "2.5vh",
    marginRight: "1vh",
  };

  const Text = {
    width: "80%",
  };

  const LineDiv = {
    display: "flex",
  };

  return (
    <React.Fragment>
      <div
        style={LineDiv}
        onClick={() => {
          selected == id ? setSelected(null) : setSelected(id);
        }}
      >
        <img style={ImgIcon} src={icon} />
        <div style={Text}>{strings[id]}</div>
        <img src={selected == id ? ContractImg : ExpandImg} style={ImgArrow} />
      </div>

      {selected == id ? children : <></>}
    </React.Fragment>
  );
};

export default OptionWrapper;
