import React, { useState, useEffect } from "react";
import Colors from "../../../../../../../assets/Colors.json";
import TerciaryButton from "../../../../../../shared/TerciaryButton";
import Face1 from "../../../../../../../assets/images/face_1.png";
import Face2 from "../../../../../../../assets/images/face_2.png";
import Face3 from "../../../../../../../assets/images/face_3.png";
import Face4 from "../../../../../../../assets/images/face_4.png";
import SurveyIcon from "../../../../../../../assets/images/assessmentsheet-icon.svg";
import AssistantIcon from "../../../../../../../assets/images/cellar-icon.svg";
import ResumeIcon from "../../../../../../../assets/images/resume-icon.svg";
import Button from "../../../../../../shared/Button";
import IconButton from "../../../../../../shared/IconButton";
import styled from "@emotion/styled";

const FormComponent = ({ strings, config, reportData, sessionData, parsedData, showSurveyData, showAssistantListData, showProductData, cancelAction }) => {
    const ButtonWrapper = styled.div`
        text-align: center;
        padding-top: 1rem;
        margin: 2vh 3vh;
        border-top: 0.1vh solid grey;
    `;

    const Subheader = styled.div`
        margin: 1.5rem 0.5rem 1rem 0rem;
        font-weight: bold;
        font-size: 2.5vh;
        color: ${Colors.lightTheme.accentColor1};
        padding-bottom: 0.5rem;
        border-bottom: 1px solid gray;
    `;

    const TableStyle = {
        width: "50%",
    }

    const ButtonTableStyle = {
        width: "100%",
    }

    const MainTable = styled.table`
        border-collapse: collapse;
        width: 100%;
        td {
            padding: 2vh;
            min-width: 5vw;
        }
        th {
            padding: 2vh;
            text-align: left;
            background-color: rgba(0, 0, 0, 0.1);
        }
        tr:nth-of-type(even) {
            background-color: rgba(255, 255, 255, 0.5);
        }
        tr {
            background-color: white;
        }
    `;

    const ProductImage = styled.img`
        height: 10vh;
        width: auto;
        margin: auto;
        padding: 0.5vh 2vh;
    `;

    const FaceImg = styled.img`
        height: 5vh;
        width: auto;
        margin: auto;
    `;

    return (
        <div style={{ width: "100%" }}>
            <div style={{ padding: "1vh 3vh" }}>
                <Subheader>{strings.dataFromEvent}</Subheader>
                <div style={{ display: "flex" }}>
                    {sessionData?.tasting?.type !== "PODCAST_TAST" ?
                        <table style={TableStyle}>
                            <tr>
                                <th>{strings.tastingDay}</th>
                                <td>{parsedData.date ? parsedData.date : "-"}</td>
                            </tr>
                            <tr>
                                <th>{strings.startHour}</th>
                                <td>{parsedData.hour ? parsedData.hour : "-"}</td>
                            </tr>
                            <tr>
                                <th>{strings.tastingDuration}</th>
                                <td>{parsedData.duration ? parsedData.duration : "-"}</td>
                            </tr>
                            <tr>
                                <th>{strings.dayOfWeek}</th>
                                <td>{parsedData.dayOfWeek ? parsedData.dayOfWeek : "-"}</td>
                            </tr>

                        </table>
                        :
                        <></>
                    }

                    <table style={TableStyle}>
                        <tr>
                            <th>{strings.instructor}</th>
                            <td>{parsedData.instructor ? parsedData.instructor : "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.assistants}</th>
                            <td>{parsedData.assistantsNum ? parsedData.assistantsNum : "0"}</td>
                        </tr>
                        <tr>
                            <th>{strings.emailsObtained}</th>
                            <td>{parsedData.emailsNum ? parsedData.emailsNum : "0"}</td>
                        </tr>
                        <tr>
                            <th>{strings.emailsPercent}</th>
                            <td>{parsedData.emailPercent ? parsedData.emailPercent : "0"}%</td>
                        </tr>
                    </table>
                </div>

                <table style={ButtonTableStyle}>
                    <tr>
                        <td style={{ width: "50%" }}>
                            <IconButton
                                action={showSurveyData}
                                icon={SurveyIcon}
                                text={strings.viewSurveyResponses}
                                style={{ width: "90%", padding: "2%", margin: "3%" }} />
                        </td>
                        <td style={{ width: "50%" }}>
                            <IconButton
                                action={showAssistantListData}
                                icon={AssistantIcon}
                                text={strings.viewAssistantList}
                                style={{ width: "90%", padding: "2%", margin: "3%" }} />
                        </td>
                    </tr>
                </table>

                <Subheader>{strings.productsInfo}</Subheader>
                <MainTable style={{ width: "100%" }}>
                    <tr>
                        <th></th>
                        <th>{strings.name}</th>
                        <th>{strings.type}</th>
                        <th>{strings.averageScore}</th>
                        <th>{sessionData?.tasting?.template === "BLIND_TEMPLATE" ? strings.averagePrice : strings.priceQualityAssessment}</th>
                        <th>{strings.actions}</th>
                    </tr>
                    {
                        (sessionData?.tasting?.productReferences || {}).map((productReference) => {
                            return (<tr>
                                <td style={{ display: "flex" }}><ProductImage src={productReference?.product?.image} /></td>
                                <td>{productReference?.product?.name || "-"}</td>
                                <td>{productReference?.product?.type?.name?.[config.lang] || "-"}</td>
                                <td>{parsedData.getRate(productReference.reference)}</td>
                                <td>{sessionData?.tasting?.template === "BLIND_TEMPLATE" ? (
                                    parsedData.getPrice(productReference.reference) + " " + (config?.cellar?.currency || "€")
                                ) : (
                                    parsedData.getCualityPrice(productReference.reference)
                                )}</td>
                                <td>
                                    <Button
                                        value={strings.viewCompleteInfo}
                                        action={() => { showProductData(parsedData.getProductRefId(productReference.reference)) }}
                                    />
                                </td>
                            </tr>);
                        })
                    }
                </MainTable>

                <Subheader>{strings.eventEvaluation}</Subheader>
                <MainTable style={{ width: "100%" }}>
                    <tr>
                        <th colSpan="3">{strings.concept}</th>
                        <th><FaceImg src={Face1} /></th>
                        <th><FaceImg src={Face2} /></th>
                        <th><FaceImg src={Face3} /></th>
                        <th><FaceImg src={Face4} /></th>
                        <th>{strings.average}</th>
                    </tr>
                    {
                        (sessionData?.tasting?.satisfactionConcepts).map((satisfactionConcept) => {
                            return (
                                <tr>
                                    <td style={{ fontWeight: "bold" }} colSpan="3">{satisfactionConcept?.concept?.[config.lang]}</td>
                                    <td><b>{parsedData.getPercentOfSatisfaction(satisfactionConcept?.reference, 1)}%</b><br />{parsedData.getTotalOfSatisfaction(satisfactionConcept?.reference, 1)} {strings.votes}</td>
                                    <td><b>{parsedData.getPercentOfSatisfaction(satisfactionConcept?.reference, 2)}%</b><br />{parsedData.getTotalOfSatisfaction(satisfactionConcept?.reference, 2)} {strings.votes}</td>
                                    <td><b>{parsedData.getPercentOfSatisfaction(satisfactionConcept?.reference, 3)}%</b><br />{parsedData.getTotalOfSatisfaction(satisfactionConcept?.reference, 3)} {strings.votes}</td>
                                    <td><b>{parsedData.getPercentOfSatisfaction(satisfactionConcept?.reference, 4)}%</b><br />{parsedData.getTotalOfSatisfaction(satisfactionConcept?.reference, 4)} {strings.votes}</td>
                                    <td>{parsedData.getAvgOfSatisfcaction(satisfactionConcept?.reference) + strings.overTen} </td>
                                </tr>
                            )
                        })
                    }
                </MainTable>

                <Subheader>{strings.finalComments}</Subheader>
                <MainTable style={{ width: "100%" }}>
                    <tr>
                        <th>{strings.name}</th>
                        <th>{strings.date}</th>
                        <th width="60%">{strings.comment}</th>
                    </tr>
                    {
                        Object.entries(sessionData?.assistants || {}).map(([assistantId, assistant]) => {
                            if (assistant?.finalComment) {
                                return (
                                    <tr>
                                        <td>{assistant?.name === "Lite assistant" ? "-" : assistant?.name || "-"}</td>
                                        <td>{parsedData.getAssistantDate(assistant)}</td>
                                        <td width="60%">{assistant?.finalComment || "-"}</td>
                                    </tr>
                                );
                            }
                        })
                    }
                </MainTable>
            </div>

            <ButtonWrapper>
                <TerciaryButton style={{ marginRight: "2vh" }} action={cancelAction} value={strings.close} />
            </ButtonWrapper>
        </div >
    );
};
export default FormComponent;
