import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useHistory, useParams, useLocation } from "react-router-dom";
import RankingComponent from "./RankingComponent";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import FormEngine from "./Form/FormEngine";

const SessionsEngine = ({ strings, config, lang, selectLang }) => {

    let history = useHistory();
    let ba = new BackendAccessor();

    const [assistantsRanking, setAssistantsRanking] = useState(null)
    const [data, setData] = useState(null);
    const [sesionAssistant, setSesionAssistant] = useState(null);
    const [productAssessment, setProductAssessment] = useState(false);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        if (assistantsRanking) {
            loadDataTable();
        }
        else if (config?.email && config?.userEmail && assistantsRanking === null) {
            config.showDownloadingPopup(strings.loadWines);
            ba.getCompleteAssistantSessionsListByEmailCreator(config?.email, config?.userEmail, (sessions) => {
                console.log("Complete", sessions)
                config.hideDownloadingPopup();
                setAssistantsRanking(sessions)
            }, (error) => {
                config.hideDownloadingPopup();
                config.popupAlert("Error", "Error getting sessions");
                console.error("Error getting sessions: ", error);
            })

        }
        else if (config?.email && assistantsRanking === null) {
            config.showDownloadingPopup(strings.loadWines);
            ba.getCompleteAssistantSessionsListByEmail(config?.email, (sessions) => {
                console.log("Complete", sessions)
                config.hideDownloadingPopup();
                setAssistantsRanking(sessions)
            }, (error) => {
                config.hideDownloadingPopup();
                config.popupAlert("Error", "Error getting sessions");
                console.error("Error getting sessions: ", error);
            })

        }
    }, [assistantsRanking, config?.email]);

    const loadDataTable = () => {
        let formatedData = {};
        Object.entries(assistantsRanking).forEach(([key1, assistant]) => {
            Object.entries(assistant?.session?.tasting?.productReferences).forEach(([key2, productValue]) => {
                let entry = {
                    wine: productValue?.product?.image,
                    name: productValue?.product.name,
                    date: assistant?.session?.tasting?.lastUpdate ? new Date(assistant?.session?.tasting?.lastUpdate) : new Date(assistant?.session?.tasting?.creationDate),
                    rate: assistant?.assessments?.[productValue.reference]?.parameters?.["rateBox"] === undefined ? 0 : assistant?.assessments?.[productValue.reference]?.parameters?.["rateBox"],
                    price: productValue?.product?.price + assistant?.session?.currency,
                    reference: { "productReference": productValue?.reference, "assistantReference": assistant?.reference, "url": productValue?.product?.externalStoreURL },
                };
                formatedData[key1 + "-" + key2] = entry;
            })
        });
        setData(formatedData)
    }

    /* --------------------- */

    const showProductData = (id, assistantReference) => {
        setProductAssessment(true);
        setProduct(id)
        setSesionAssistant(assistantReference)
    }

    const goBackList = () => {
        setProductAssessment(false);
    }

    const goToLink = (WineStoreURL) => {
        if (WineStoreURL) {
            if (WineStoreURL.includes("http://") || WineStoreURL.includes("https://")) {
                window.open(WineStoreURL, "_blank");
            } else {
                window.open("https://" + WineStoreURL, "_blank")
            }
        }
    }

    const goRanking = () => {
        history.replace(`/assistant/sessions/ranking`);
    }

    const actions = {
        showProductData: showProductData,
        goBackList: goBackList,
        goToLink: goToLink,
        goRanking: goRanking,
    }

    const values = {
        data: data,
    }

    return (
        productAssessment === true ?
            <FormEngine
                strings={strings}
                config={config}
                actions={actions}
                product={product}
                assistantsRanking={assistantsRanking}
                sesionAssistant={sesionAssistant}
                lang={lang}
                data={values.data} />
            :
            <RankingComponent
                strings={strings}
                config={config}
                actions={actions}
                values={values}
                lang={lang}
                selectLang={selectLang} />
    );
};
export default SessionsEngine;