import React, { useState, useEffect } from "react";
import BackendAccessor from "../../../../../assets/BackendAccessor";
import LoginComponent from "./LoginComponent";
import Firebase from "../../../../../assets/Firebase";
import { useHistory } from "react-router-dom";

const LoginEngine = ({ strings, config, lang, setLang, selectLang }) => {
  const ba = new BackendAccessor();
  let history = useHistory();
  let f = new Firebase();

  const [data, setData] = useState({
    email: "",
    userEmail: "",
    password: "",
    verificationCode: "",
    verificationSent: false
  });

  const login = () => {
    f.tryLogin(data?.email, data?.password, success, error);
  };

  const success = () => {
    let verified = f.isEmailVerificated();
    if (verified) {
      config.login(data?.email)
    }
  };

  const loginMail = () => {
    if (data?.email && data?.userEmail) {
      if (validateEmail(data?.email)) {
        if (validateEmail(data?.userEmail)) {
          config.login(data?.email, data?.userEmail)
        }
        else {
          error({ code: "false3" })
        }
      }
      else {
        error({ code: "false2" })
      }
    }
    else {
      error({ code: "false1" })
    }
  }

  const validateEmail = (email) => {
    let isValid = true
    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (email !== '') {
      if (regex.test(email)) {
        isValid = true
      }
      else {
        isValid = false
      }
    }
    else {
      isValid = false
    }
    return isValid
  }

  const actions = {
    login: login,
    loginMail: loginMail
  }

  const error = (err) => {
    console.log(err);

    switch (err?.code) {
      case "auth/wrong-password":
        config.popupAlert(strings.error, strings.errorPassword);
        break;
      case "auth/user-not-found":
        config.popupAlert(strings.error, strings.errorEmail);
        break;
      case "auth/too-many-requests":
        config.popupAlert(strings.error, strings.errorToManyRequests);
        break;
      case "false1":
        config.popupAlert(strings.error, strings.false1);
        break;
      case "false2":
        config.popupAlert(strings.error, strings.false2);
        break;
      case "false3":
        config.popupAlert(strings.error, strings.false3);
        break;
      default:
        config.popupAlert(strings.error, strings.wrongCredentials);
        break;
    }
  };

  return (
    <>
      <LoginComponent
        config={config}
        strings={strings}
        data={data}
        setData={setData}
        actions={actions}
        selectLang={selectLang}
        lang={lang} />
    </>
  );
};
export default LoginEngine;
