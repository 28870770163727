import React, { useState } from "react";
import Button from "../../shared/Button";
import TerciaryButton from "../../shared/TerciaryButton";
import DownloadingPopup from "../../shared/DownloadingPopup";
import UpTastingLogo from "../../../assets/images/logo-full-light.png";
import facebookIcon from "../../../assets/images/facebook-icon.svg";
import twitterIcon from "../../../assets/images/twitter-icon.svg";
import instagramIcon from "../../../assets/images/instagram-icon.svg";
import youtubeIcon from "../../../assets/images/youtube-icon.svg";
import eyeIcon from "../../../assets/images/icon_eye-slash.svg";
import eyeSlashIcon from "../../../assets/images/icon_eye.svg";
import TextInput from "../../shared/TextInput";
import Colors from "../../../assets/Colors.json";
import VeridficatedEmailPopup from "./VeridficatedEmailPopup";
import background from "../../../assets/images/background-login.png";
import WrongPasswordPopup from "./WrongPasswordPopup";
import styled from "@emotion/styled";


const LoginComponent = ({
  strings,
  login,
  clicKPassword,
  clickAccount,
  theme,
  loadingMsg,
  verificationEmailVisible,
  setVerificationEmailVisible,
  resendVerificationEmail,
  recoverPassword,
  wrongPasswordVisible,
  setWrongPasswordVisible
}) => {
  let colors = Colors[theme];

  const Wrapper = {
    height: "100vh",
    display: "flex",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    transition: "background-image 1.5s linear",
  };

  const TextWrapper = styled.div`
  width: 70%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  @media (max-width: 426px) {
    display:none;
  }
  `

  const TextContent = {
    width: "70%",
    margin: "auto",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }

  const TextHeader = {
    fontSize: "4vh",
    color: "white",
    margin: "2vh",
    fontWeight: "bolder",
    textShadow: "3px 3px 5px " + colors.accentColor1,
  }

  const TextDetails = {
    color: "white",
    fontSize: "2.5vh",
    margin: "2vh",
    lineHeight: "8vh",
    fontWeight: "bold",
    textShadow: "2px 2px 3px " + colors.accentColor1,
  }

  const Form = {
    width: "30%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: "0px 5vw",
  };

  const FormContent = {
    margin: "0 auto",
    textAlign: "center",
    position: "relative",
    top: "50%",
    transform: "translate(0, -50%)",
  };

  const LogoStyle = styled.img`
    width: 20vw;
    margin: auto;
    @media (max-width: 426px) {
      width:10rem;
    }
    `

  const ForgotPassword = {
    display: "flex",
    textAlign: "center",
  };

  const H3 = {
    color: colors.secondaryColor,
    margin: "0 0.5rem 0 auto",
  };

  const H3Bold = {
    color: colors.secondaryColor,
    fontWeight: "bold",
    margin: "0 auto 0 0",
    cursor: "pointer",
  };

  const SocialsWrapper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const Icon = {
    margin: "0.5rem",
    cursor: "pointer",
  };

  const Invis = {
    display: "none",
    textAlign: "left",
    margin: "0.75rem 0",
  };

  const Tag = {
    color: colors.secondaryColor,
    textAlign: "left",
    margin: "0.75rem 0",
  };

  const ButtonStyle = {
    textAlign: "center",
    marginTop: "2rem",
    width: "90%",
  };

  const SecondaryButtonStyle = {
    ...ButtonStyle,
    backgroundColor: "lightGray",
    color: "black",
    marginTop: "0.75rem",
    marginBottom: "2rem",
  };

  const [shown, setShown] = useState(false);
  const [password, setPassword] = useState('');
  const switchShown = () => setShown(!shown);

  const PwdDiv = {
    borderRadius: "0.3vh",
    border: "0.2vh solid rgb(216, 221, 225)",
    backgroundColor: "white",
    fontSize: "2vh",
    transition: "all 0.1s ease 0s",
    width: "99%",
    display: "flex",
    height: "5vh",
  };

  const PwdInput = {
    display: "flex",
    width: "87%",
    border: "none !important",
    fontSize: "2.3vh",
    backgroundColor: "white",
    outline: "none",
  }

  const Eye = {
    background: "no-repeat center",
    backgroundSize: "80% auto",
    width: "10%",
    cursor: "pointer",
  }

  return (
    <>
      {loadingMsg ? <DownloadingPopup headerText={loadingMsg} /> : <></>}

      <VeridficatedEmailPopup
        strings={strings}
        visible={verificationEmailVisible}
        setVisible={setVerificationEmailVisible}
        resendEmail={resendVerificationEmail}
      />

      <WrongPasswordPopup
        strings={strings}
        visible={wrongPasswordVisible}
        setVisible={setWrongPasswordVisible}
        recoverPassword={recoverPassword}
      />

      <div id={"background"} style={Wrapper}>
        <TextWrapper>
          <div style={TextContent}>
            <div style={TextHeader}>
              La primera plataforma de gestión de catas interactivas.
            </div>
            <div style={TextDetails}>
              1.- Transforma las catas en experiencias<br />
              2.- Digitaliza las opiniones de  tus consumidores<br />
              3.- Incrementa la venta de tus productos<br />
            </div>
            <Button value={"Darse de alta gratis"} action={clickAccount} />
          </div>
        </TextWrapper>
        <form style={Form} autoComplete="off">
          <div style={FormContent}>
            <LogoStyle src={UpTastingLogo} />
            <div style={Invis} autoComplete="false" />
            <div style={Tag}>Email:</div>
            <TextInput id="nameInput" placeholder={strings.user} width="100%" />
            <div style={Tag}>Contraseña:</div>
            <div style={PwdDiv}>
              <TextInput style={PwdInput} enterAction={login} id="passwordInput" placeholder={strings.password} width="100%" value={password} onChange={(e) => setPassword(e.target.value)} type={shown ? 'text' : 'password'} />
              <img style={Eye} onClick={switchShown} src={shown ? eyeIcon : eyeSlashIcon} />
            </div>
            <Button value={strings.login} action={login} style={ButtonStyle} />
            <TerciaryButton value={strings.newAccount + "\n" + strings.newFree} action={clickAccount} style={SecondaryButtonStyle} />
            <div style={ForgotPassword}>
              <div style={H3}>{strings.forgotPassword}</div>
              <div style={H3Bold} onClick={clicKPassword}>
                {strings.enterHere}
              </div>
            </div>
            <div style={SocialsWrapper}>
              <img style={Icon} src={facebookIcon} />
              <img style={Icon} src={twitterIcon} />
              <img style={Icon} src={instagramIcon} />
              <img style={Icon} src={youtubeIcon} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginComponent;
