import React from "react";
import { BrowserRouter as Router, Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const Option = ({ child = false, text, style, to, strings, icon, id, tooltip, stringId, config }) => {
  let { url } = useRouteMatch();
  let locations = useLocation().pathname.split("/"); //[2];
  let history = useHistory();

  const selectedStyle = {
    ...style,
    backgroundColor: "#d8d8d8",
  };

  const Img = {
    width: "2.5vh",
    height: "2.5vh",
    marginRight: "1.5vh",
  };

  const Tag = {
    padding: "0.5vh",
    fontSize: "1.7vh",
  };

  const isSelected = () => {
    let selected = true;
    let index = 2;
    id.split("/").forEach((idPart) => {
      if (idPart != locations[index]) selected = false;
      index++;
    });
    return selected;
  };

  const redirect = () => {
    config.isModificated(() => {
      history.replace(to);
    });
  };

  return (
    <>
      {tooltip && config && config.tooltipsEnabled ? (
        <Tooltip title={<div style={Tag}>{tooltip}</div>} aria-label="add" arrow>
          <div className={child ? "child" : ""} onClick={redirect} style={isSelected() ? selectedStyle : style}>
            <img style={Img} src={icon} />
            {text && text.length > 0 ? text : (stringId ? strings[stringId] : strings[id])}
          </div>
        </Tooltip>
      ) : (
        <div className={child ? "child" : ""} onClick={redirect} style={isSelected() ? selectedStyle : style}>
          <img style={Img} src={icon} />
          {stringId ? strings[stringId] : strings[id]}
        </div>
      )}
    </>
  );
};
export default Option;
