import React, { useState, useEffect } from "react";
import AssessmentSheetForm from "./AssessmentSheetForm";
import ParametersWrapperForm from "./ParametersWrapperForm";
import CategoriesWrapperForm from "./CategoriesWrapperForm";
import TerciaryButton from "../../../../../shared/TerciaryButton";
import Button from "../../../../../shared/Button";
import styled from "@emotion/styled";

const FormComponent = ({ strings, config, availableProducts, assessmentSheet, setAssessmentSheet, confirmAction, cancelAction }) => {
  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const ButtonWrapper = styled.div`
    text-align: center;
    padding-top: 1rem;
    margin: 2vh 3vh;
    border-top: 0.1vh solid grey;
  `;

  return (
    <div style={{ width: "100%" }}>
      <Title>{assessmentSheet.id ? strings.editAssessmentSheet : strings.createAssessmentSheet}</Title>
      <div style={{ padding: "2vh 3vh" }}>
        <AssessmentSheetForm
          strings={strings}
          config={config}
          availableProducts={availableProducts}
          assessmentSheet={assessmentSheet}
          setAssessmentSheet={setAssessmentSheet}
        />
        <ParametersWrapperForm strings={strings} config={config} assessmentSheet={assessmentSheet} setAssessmentSheet={setAssessmentSheet} />
        <CategoriesWrapperForm strings={strings} config={config} assessmentSheet={assessmentSheet} setAssessmentSheet={setAssessmentSheet} />
      </div>
      <ButtonWrapper>
        <TerciaryButton style={{ marginRight: "2vh" }} action={cancelAction} value={strings.cancel} />
        <Button action={confirmAction} value={strings.confirm} />
      </ButtonWrapper>
    </div>
  );
};
export default FormComponent;
