import React, { useState, useEffect, useRef } from "react";

import MainFormComponent from "./MainFormComponent";
import BackendAccessor from "../../../../../../../assets/BackendAccessor";
import { BrowserRouter as Router, useParams, useHistory, useLocation } from "react-router-dom";
import { isNullOrUndefined } from "../../../../../../../assets/utils/FormsUtils";
import { Assistant } from "@material-ui/icons";

const MainFormEngine = ({ strings, config, reportType, reportData, sessionData, setReportData, utils }) => {
    let history = useHistory();
    let location = useLocation();
    let ba = new BackendAccessor();

    const cancelAction = () => {
        config.isModificated(() => {
            history.replace(`/dashboard/reports/list`);
        });
    };

    const showSurveyData = () => {
        config.isModificated(() => {
            history.replace(location.pathname + "/survey");
        });
    };

    const showAssistantListData = () => {
        config.isModificated(() => {
            history.replace(location.pathname + "/assistant");
        });
    };

    const showProductData = (id) => {
        config.isModificated(() => {
            history.replace(location.pathname + "/product/" + id);
        });
    }

    const twoDigits = (value) => {
        return (value < 10 ? '0' : '') + value;
    }

    const roundDecimal = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getRate = (productReference) => {
        return utils.getParameterAvg(productReference, "rateBox");
    }

    const getPrice = (productReference) => {
        return utils.getParameterAvg(productReference, "blindPrice");
    }

    const getCualityPrice = (productReference) => {
        let sums = utils.getParameterSums(productReference, "ratePrice");
        let maxValue = -1;
        let selected = null;

        Object.entries(sums).forEach(([key, sum]) => {
            if (maxValue < sum && key !== "not_rated") {
                maxValue = sum;
                selected = key;
            }
        });

        return selected ? selected : "-";
    }



    const getTotalOfSatisfaction = (satisfactionRef, value) => {
        let total = 0;

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let satisfaction = assistant?.satisfactions?.find(e => e.satisfactionConceptPath === satisfactionRef && e.value === value);
            if (!isNullOrUndefined(satisfaction)) total++;
        });

        return total;
    }

    const getPercentOfSatisfaction = (satisfactionRef, value) => {
        let totalOfValue = 0;
        let total = 0;

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let valueSelected = assistant?.satisfactions?.some(e => e.satisfactionConceptPath === satisfactionRef);
            if (valueSelected) {
                let sameValueSelected = assistant?.satisfactions?.some(e => e.satisfactionConceptPath === satisfactionRef && e.value === value);
                total++;
                if (sameValueSelected) {
                    totalOfValue++;
                }
            }
        });

        let percent = ((totalOfValue / total) * 100);
        return percent ? roundDecimal(percent) : 0;
    }

    const getAvgOfSatisfcaction = (satisfactionRef) => {
        const maxIndex = 4;
        const maxPuntuation = 10;
        let totalOfConcept = {};

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let value = assistant?.satisfactions?.find(e => e.satisfactionConceptPath === satisfactionRef && e.value)?.value;
            if (value) {
                if (totalOfConcept?.[value]) {
                    totalOfConcept[value]++;
                } else {
                    totalOfConcept[value] = 1;
                }
            }
        });

        let sum = 0;
        let total = 0;
        Object.entries(totalOfConcept || {}).forEach(([value, totalOfvalue]) => {
            if (value !== null && value !== "total" && !isNaN(value)) {
                let realValue = maxIndex - value;
                let points = ((realValue * maxPuntuation) / (maxIndex - 1));
                sum += points * totalOfvalue;
                total += totalOfvalue;
            }
        });

        if (total > 0) {
            return roundDecimal(sum / total);
        } else {
            return 0;
        }
    }

    const getProductRefId = (productRefPath) => {
        let pathParts = productRefPath?.split("/");
        let id = pathParts[pathParts.length - 1];
        return id;
    }

    const formatDate = (date) => {
        let dateObj = new Date(date);
        return dateObj;
    }

    const getAssistantDate = (assistant) => {
        if (!isNullOrUndefined(assistant.lastUpdate)) {
            return formatDate(assistant.lastUpdate).toLocaleString();
        } else if (!isNullOrUndefined(assistant.creationDate)) {
            return formatDate(assistant?.creationDate).toLocaleString();
        }
        return "-";
    }

    const parseData = () => {
        let data = {}
        data.type = reportType;
        data.getRate = getRate;
        data.getCualityPrice = getCualityPrice;
        data.getPrice = getPrice;
        data.getTotalOfSatisfaction = getTotalOfSatisfaction;
        data.getPercentOfSatisfaction = getPercentOfSatisfaction;
        data.getProductRefId = getProductRefId;
        data.getAvgOfSatisfcaction = getAvgOfSatisfcaction;
        data.getAssistantDate = getAssistantDate;

        if (sessionData?.startDate) {
            let startDate = new Date(sessionData?.startDate);

            data.date = twoDigits(startDate.getDate()) + "/" + twoDigits((startDate.getMonth() + 1)) + "/" + startDate.getFullYear();
            data.hour = twoDigits(startDate.getHours()) + ":" + twoDigits(startDate.getMinutes());
            data.dayOfWeek = capitalizeFirstLetter(startDate.toLocaleDateString(undefined, { weekday: 'long' }));

            if (sessionData?.finishDate) {
                let finishDate = new Date(sessionData?.finishDate);

                let mins = roundDecimal((finishDate.getTime() - startDate.getTime()) / (1000 * 60));
                data.duration = mins + " mins"
            }
        }

        data.instructor = sessionData?.instructor?.name;

        if (sessionData?.assistants) {
            let emailsNum = 0;
            Object.entries(sessionData?.assistants).forEach(([key, assistant]) => {
                if (assistant.email != null) {
                    emailsNum++;
                }
            });
            data.emailsNum = emailsNum;
            data.assistantsNum = Object.keys(sessionData?.assistants).length;
            data.emailPercent = roundDecimal((data.emailsNum / data.assistantsNum) * 100);
        }

        return data;
    }


    return (
        <MainFormComponent
            strings={strings}
            config={config}
            reportData={reportData}
            sessionData={sessionData}
            parsedData={parseData()}
            showSurveyData={showSurveyData}
            showAssistantListData={showAssistantListData}
            showProductData={showProductData}
            cancelAction={cancelAction}
        />
    );
};

export default MainFormEngine;