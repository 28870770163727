import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  ${(props) => `
  `}
`;

const StarSpan = styled.span`
  ${(props) => `
    position: relative;
    overflow: hidden;
    display: block;
    float: left;
    color: ${props.active ? "#f52c56" : props?.props?.color1};
    cursor:  default;
    font-size: ${props?.props?.size || 5}vh;
   
    ${props?.animActive ? "animation-name: starsAnim;" : ""}
    animation-fill-mode: alternate;
    animation-duration: 2s;
    animation-iteration-count: 5;

    @keyframes starsAnim {
      0%  { filter: brightness(100%); }
      50% { filter: brightness(130%); }
      100%  { filter: brightness(100%); }
    }
  `}
`;

const HalfStarSpan = styled.span`
  ${(props) => `
    position: absolute;
    overflow: hidden;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    width: 50%;
    color: #f52c56;
    font-size: ${props?.props?.size || 5}vh;
    
    ${props?.animActive ? "animation-name: starsAnim;" : ""}
    animation-fill-mode: alternate;
    animation-duration: 2s;
    animation-iteration-count: 2;

    @keyframes starsAnim {
      0%  { filter: brightness(100%); }
      50% { filter: brightness(130%); }
      100%  { filter: brightness(100%); }
    }
  `}
`;

const ReactStars = (props) => {
  const char = '★';
  const emptyChar = "☆";
  const totalStarts = 5;

  const [value, setValue] = useState(props?.value || null);
  const [animActive, setAnimActive] = useState(false);

  useEffect(() => {
    if (props?.value) {
      if (props?.value !== value) setValue(props?.value);
    } else {
      if (value !== null) setValue(null);
    }
  }, [props?.value]);

  let activeAnim = () => {
    if (!animActive) {
      setAnimActive(true);
      setTimeout(() => {
        setAnimActive(false);
      }, 4200);
    }
  }

  let moreThanHalf = (event) => {
    let { target } = event
    var mouseAt = event.clientX - target.getBoundingClientRect().left
    mouseAt = Math.round(Math.abs(mouseAt));
    return mouseAt > 40 / 2;
  }

  let onClick = (event, index) => {
    let newValue = index;

    if (!moreThanHalf(event)) {
      newValue -= 0.5;
    }

    if (newValue !== value) {
      setValue(newValue);
      if (typeof props?.onChange === "function") {
        props.onChange(newValue);
      }
      activeAnim();
    }
  }

  let renderStars = () => {
    let stars = [];
    let selected = Math.floor(props?.value) || -1;
    let halfIndex = (props?.value % 1 !== 0) ? (Math.floor(props?.value) + 1) : null;

    for (let i = 1; i <= totalStarts; i++) {
      let isFilled = (i <= selected) && (halfIndex !== i);
      stars.push(
        <StarSpan
          props={props}
          edit={true}
          active={isFilled}
          animActive={animActive}
          onClick={(e) => onClick(e, i)}>

          {halfIndex === i ? (
            <HalfStarSpan
              props={props}
              animActive={animActive}>
              {char}
            </HalfStarSpan>
          ) : (
            null
          )}

          {isFilled ? char : emptyChar}
        </StarSpan>
      )
    }
    return stars;
  }

  return (
    <Wrapper>
      {renderStars()}
    </Wrapper>
  );
};

export default ReactStars;
