import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../../shared/Button";
import Colors from "../../../../../../../assets/Colors.json";

const TermsPopup = ({ strings, termsVisibles, setTermsVisibles }) => {
  const PopupBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
  `;

  const Popup = styled.div`
    position: absolute;
    width: 60%;
    height: 80%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${Colors.lightTheme.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${Colors.lightTheme.accentColor1};
    margin: 1vh auto;
  `;

  const Content = {
    width: "100%",
    height: "80%",
    overflowY: "scroll",
    overflowX: "hidden",
    textAlign: "left",
  };

  const close = () => {
    setTermsVisibles(false);
  };

  return (
    <React.Fragment>
      {termsVisibles ? (
        <PopupBackground>
          <Popup>
            <Header>{strings.header}</Header>
            <Divider />
            <div style={Content}>
              <p>
                <strong>POL&Iacute;TICA DE PRIVACIDAD DEL SITIO WEB</strong>
              </p>
              <p>
                <strong>www.iwines.es</strong>
              </p>
              <p>
                <strong>I. POL&Iacute;TICA DE PRIVACIDAD Y PROTECCI&Oacute;N DE DATOS</strong>
              </p>
              <p>
                Respetando lo establecido en la legislaci&oacute;n vigente, iWines (en adelante, tambi&eacute;n Sitio Web) se compromete a adoptar las medidas
                t&eacute;cnicas y organizativas necesarias, seg&uacute;n el nivel de seguridad adecuado al riesgo de los datos recogidos.
              </p>
              <p>
                <strong>Leyes que incorpora esta pol&iacute;tica de privacidad</strong>
              </p>
              <p>
                Esta pol&iacute;tica de privacidad est&aacute; adaptada a la normativa espa&ntilde;ola y europea vigente en materia de protecci&oacute;n de
                datos personales en internet. En concreto, la misma respeta las siguientes normas:
              </p>
              <ul>
                <li>
                  <p>
                    El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas
                    f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos (RGPD).
                  </p>
                </li>
                <li>
                  <p>
                    La Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales
                    (LOPD-GDD).
                  </p>
                </li>
                <li>
                  <p>
                    El Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Org&aacute;nica 15/1999, de 13 de
                    diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter Personal (RDLOPD).
                  </p>
                </li>
                <li>
                  <p>La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y de Comercio Electr&oacute;nico (LSSI-CE).</p>
                </li>
              </ul>
              <p>
                <strong>Identidad del responsable del tratamiento de los datos personales</strong>
              </p>
              <p>
                El responsable del tratamiento de los datos personales recogidos en iWines es: Happy Customer Box, S.L., provista de NIF: B66979113 e inscrita
                en: Registro <span lang="ca-ES">M</span>ercantil de Barcelona cuyo representante es: Albert Grau i Peris&eacute; (en adelante, Responsable del
                tratamiento). Sus datos de contacto son los siguientes:
              </p>
              <p>
                Direcci&oacute;n:
                <br />
                Calle Telers 5B
                <br /> 2&ordf; planta, Local 1<br /> 08221 Terrassa, Barcelona
                <br /> Email de contacto: info@happycustomerbox.com
              </p>
              <p>
                <strong>Registro de Datos de Car&aacute;cter Personal</strong>
              </p>
              <p>
                En cumplimiento de lo establecido en el RGPD y la LOPD-GDD, le informamos que los datos personales recabados por iWines mediante los formularios
                extendidos en sus p&aacute;ginas quedar&aacute;n incorporados y ser&aacute;n tratados en nuestro ficheros con el fin de poder facilitar,
                agilizar y cumplir los compromisos establecidos entre iWines y el Usuario o el mantenimiento de la relaci&oacute;n que se establezca en los
                formularios que este rellene, o para atender una solicitud o consulta del mismo. Asimismo, de conformidad con lo previsto en el RGPD y la
                LOPD-GDD, salvo que sea de aplicaci&oacute;n la excepci&oacute;n prevista en el art&iacute;culo 30.5 del RGPD, se mantiene un registro de
                actividades de tratamiento que especifica, seg&uacute;n sus finalidades, las actividades de tratamiento llevadas a cabo y las dem&aacute;s
                circunstancias establecidas en el RGPD.
              </p>
              <p>
                <strong>Principios aplicables al tratamiento de los datos personales</strong>
              </p>
              <p>
                El tratamiento de los datos personales del Usuario se someter&aacute; a los siguientes principios recogidos en el art&iacute;culo 5 del RGPD y
                en el art&iacute;culo 4 y siguientes de la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y
                garant&iacute;a de los derechos digitales:
              </p>
              <ul>
                <li>
                  <p>
                    Principio de licitud, lealtad y transparencia: se requerir&aacute; en todo momento el consentimiento del Usuario previa informaci&oacute;n
                    completamente transparente de los fines para los cuales se recogen los datos personales.
                  </p>
                </li>
                <li>
                  <p>
                    Principio de limitaci&oacute;n de la finalidad: los datos personales ser&aacute;n recogidos con fines determinados, expl&iacute;citos y
                    leg&iacute;timos.
                  </p>
                </li>
                <li>
                  <p>
                    Principio de minimizaci&oacute;n de datos: los datos personales recogidos ser&aacute;n &uacute;nicamente los estrictamente necesarios en
                    relaci&oacute;n con los fines para los que son tratados.
                  </p>
                </li>
                <li>
                  <p>Principio de exactitud: los datos personales deben ser exactos y estar siempre actualizados.</p>
                </li>
                <li>
                  <p>
                    Principio de limitaci&oacute;n del plazo de conservaci&oacute;n: los datos personales solo ser&aacute;n mantenidos de forma que se permita
                    la identificaci&oacute;n del Usuario durante el tiempo necesario para los fines de su tratamiento.
                  </p>
                </li>
                <li>
                  <p>
                    Principio de integridad y confidencialidad: los datos personales ser&aacute;n tratados de manera que se garantice su seguridad y
                    confidencialidad.
                  </p>
                </li>
                <li>
                  <p>
                    Principio de responsabilidad proactiva: el Responsable del tratamiento ser&aacute; responsable de asegurar que los principios anteriores se
                    cumplen.
                  </p>
                </li>
              </ul>
              <p>
                <strong>Categor&iacute;as de datos personales</strong>
              </p>
              <p>
                Las categor&iacute;as de datos que se tratan en iWines son &uacute;nicamente datos identificativos. En ning&uacute;n caso, se tratan
                categor&iacute;as especiales de datos personales en el sentido del art&iacute;culo 9 del RGPD.
              </p>
              <p>
                <strong>Base legal para el tratamiento de los datos personales</strong>
              </p>
              <p>
                La base legal para el tratamiento de los datos personales es el consentimiento. iWines se compromete a recabar el consentimiento expreso y
                verificable del Usuario para el tratamiento de sus datos personales para uno o varios fines espec&iacute;ficos.
              </p>
              <p>
                El Usuario tendr&aacute; derecho a retirar su consentimiento en cualquier momento. Ser&aacute; tan f&aacute;cil retirar el consentimiento como
                darlo. Como regla general, la retirada del consentimiento no condicionar&aacute; el uso del Sitio Web.
              </p>
              <p>
                En las ocasiones en las que el Usuario deba o pueda facilitar sus datos a trav&eacute;s de formularios para realizar consultas, solicitar
                informaci&oacute;n o por motivos relacionados con el contenido del Sitio Web, se le informar&aacute; en caso de que la cumplimentaci&oacute;n de
                alguno de ellos sea obligatoria debido a que los mismos sean imprescindibles para el correcto desarrollo de la operaci&oacute;n realizada.
              </p>
              <p>
                <strong>Per&iacute;odos de retenci&oacute;n de los datos personales</strong>
              </p>
              <p>
                Los datos personales solo ser&aacute;n retenidos durante el tiempo m&iacute;nimo necesario para los fines de su tratamiento y, en todo caso,
                &uacute;nicamente durante el siguiente plazo: 2 a&ntilde;os, o hasta que el Usuario solicite su supresi&oacute;n.
              </p>
              <p>
                En el momento en que se obtengan los datos personales, se informar&aacute; al Usuario acerca del plazo durante el cual se conservar&aacute;n los
                datos personales o, cuando eso no sea posible, los criterios utilizados para determinar este plazo.
              </p>
              <p>
                <strong>Destinatarios de los datos personales</strong>
              </p>
              <p>Los datos personales del Usuario no ser&aacute;n compartidos con terceros.</p>
              <p>
                En cualquier caso, en el momento en que se obtengan los datos personales, se informar&aacute; al Usuario acerca de los destinatarios o las
                categor&iacute;as de destinatarios de los datos personales.
              </p>
              <p>
                <strong>Datos personales de menores de edad</strong>
              </p>
              <p>
                Respetando lo establecido en los art&iacute;culos 8 del RGPD y 7 de la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de
                Datos Personales y garant&iacute;a de los derechos digitales, solo los mayores de 14 a&ntilde;os podr&aacute;n otorgar su consentimiento para el
                tratamiento de sus datos personales de forma l&iacute;cita por iWines. Si se trata de un menor de 14 a&ntilde;os, ser&aacute; necesario el
                consentimiento de los padres o tutores para el tratamiento, y este solo se considerar&aacute; l&iacute;cito en la medida en la que los mismos lo
                hayan autorizado.
              </p>
              <p>
                <strong>Secreto y seguridad de los datos personales</strong>
              </p>
              <p>
                iWines se compromete a adoptar las medidas t&eacute;cnicas y organizativas necesarias, seg&uacute;n el nivel de seguridad adecuado al riesgo de
                los datos recogidos, de forma que se garantice la seguridad de los datos de car&aacute;cter personal y se evite la destrucci&oacute;n,
                p&eacute;rdida o alteraci&oacute;n accidental o il&iacute;cita de datos personales transmitidos, conservados o tratados de otra forma, o la
                comunicaci&oacute;n o acceso no autorizados a dichos datos.
              </p>
              <p>
                El Sitio Web cuenta con un certificado SSL (Secure Socket Layer), que asegura que los datos personales se transmiten de forma segura y
                confidencial, al ser la transmisi&oacute;n de los datos entre el servidor y el Usuario, y en retroalimentaci&oacute;n, totalmente cifrada o
                encriptada.
              </p>
              <p>
                Sin embargo, debido a que iWines no puede garantizar la inexpugnabilidad de internet ni la ausencia total de hackers u otros que accedan de modo
                fraudulento a los datos personales, el Responsable del tratamiento se compromete a comunicar al Usuario sin dilaci&oacute;n indebida cuando
                ocurra una violaci&oacute;n de la seguridad de los datos personales que sea probable que entra&ntilde;e un alto riesgo para los derechos y
                libertades de las personas f&iacute;sicas. Siguiendo lo establecido en el art&iacute;culo 4 del RGPD, se entiende por violaci&oacute;n de la
                seguridad de los datos personales toda violaci&oacute;n de la seguridad que ocasione la destrucci&oacute;n, p&eacute;rdida o alteraci&oacute;n
                accidental o il&iacute;cita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicaci&oacute;n o acceso no
                autorizados a dichos datos.
              </p>
              <p>
                Los datos personales ser&aacute;n tratados como confidenciales por el Responsable del tratamiento, quien se compromete a informar de y a
                garantizar por medio de una obligaci&oacute;n legal o contractual que dicha confidencialidad sea respetada por sus empleados, asociados, y toda
                persona a la cual le haga accesible la informaci&oacute;n.
              </p>
              <p>
                <strong>Derechos derivados del tratamiento de los datos personales</strong>
              </p>
              <p>
                El Usuario tiene sobre iWines y podr&aacute;, por tanto, ejercer frente al Responsable del tratamiento los siguientes derechos reconocidos en el
                RGPD y en la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos
                digitales:
              </p>
              <ul>
                <li>
                  <p>
                    <em>Derecho de acceso</em>: Es el derecho del Usuario a obtener confirmaci&oacute;n de si iWines est&aacute; tratando o no sus datos
                    personales y, en caso afirmativo, obtener informaci&oacute;n sobre sus datos concretos de car&aacute;cter personal y del tratamiento que
                    iWines haya realizado o realice, as&iacute; como, entre otra, de la informaci&oacute;n disponible sobre el origen de dichos datos y los
                    destinatarios de las comunicaciones realizadas o previstas de los mismos.
                  </p>
                </li>
                <li>
                  <p>
                    <em>Derecho de rectificaci&oacute;n</em>: Es el derecho del Usuario a que se modifiquen sus datos personales que resulten ser inexactos o,
                    teniendo en cuenta los fines del tratamiento, incompletos.
                  </p>
                </li>
                <li>
                  <p>
                    <em>Derecho de supresi&oacute;n ("el derecho al olvido")</em>: Es el derecho del Usuario, siempre que la legislaci&oacute;n vigente no
                    establezca lo contrario, a obtener la supresi&oacute;n de sus datos personales cuando estos ya no sean necesarios para los fines para los
                    cuales fueron recogidos o tratados; el Usuario haya retirado su consentimiento al tratamiento y este no cuente con otra base legal; el
                    Usuario se oponga al tratamiento y no exista otro motivo leg&iacute;timo para continuar con el mismo; los datos personales hayan sido
                    tratados il&iacute;citamente; los datos personales deban suprimirse en cumplimiento de una obligaci&oacute;n legal; o los datos personales
                    hayan sido obtenidos producto de una oferta directa de servicios de la sociedad de la informaci&oacute;n a un menor de 14 a&ntilde;os.
                    Adem&aacute;s de suprimir los datos, el Responsable del tratamiento, teniendo en cuenta la tecnolog&iacute;a disponible y el coste de su
                    aplicaci&oacute;n, deber&aacute; adoptar medidas razonables para informar a los responsables que est&eacute;n tratando los datos personales
                    de la solicitud del interesado de supresi&oacute;n de cualquier enlace a esos datos personales.
                  </p>
                </li>
                <li>
                  <p>
                    <em>Derecho a la limitaci&oacute;n del tratamiento</em>: Es el derecho del Usuario a limitar el tratamiento de sus datos personales. El
                    Usuario tiene derecho a obtener la limitaci&oacute;n del tratamiento cuando impugne la exactitud de sus datos personales; el tratamiento sea
                    il&iacute;cito; el Responsable del tratamiento ya no necesite los datos personales, pero el Usuario lo necesite para hacer reclamaciones; y
                    cuando el Usuario se haya opuesto al tratamiento.
                  </p>
                </li>
                <li>
                  <p>
                    <em>Derecho a la portabilidad de los datos</em>: En caso de que el tratamiento se efect&uacute;e por medios automatizados, el Usuario
                    tendr&aacute; derecho a recibir del Responsable del tratamiento sus datos personales en un formato estructurado, de uso com&uacute;n y
                    lectura mec&aacute;nica, y a transmitirlos a otro responsable del tratamiento. Siempre que sea t&eacute;cnicamente posible, el Responsable
                    del tratamiento transmitir&aacute; directamente los datos a ese otro responsable.
                  </p>
                </li>
                <li>
                  <p>
                    <em>Derecho de oposici&oacute;n</em>: Es el derecho del Usuario a que no se lleve a cabo el tratamiento de sus datos de car&aacute;cter
                    personal o se cese el tratamiento de los mismos por parte de iWines.
                  </p>
                </li>
                <li>
                  <p>
                    <em>
                      Derecho a no ser a no ser objeto de una decisi&oacute;n basada &uacute;nicamente en el tratamiento automatizado, incluida la
                      elaboraci&oacute;n de perfiles
                    </em>
                    : Es el derecho del Usuario a no ser objeto de una decisi&oacute;n individualizada basada &uacute;nicamente en el tratamiento automatizado
                    de sus datos personales, incluida la elaboraci&oacute;n de perfiles, existente salvo que la legislaci&oacute;n vigente establezca lo
                    contrario.
                  </p>
                </li>
              </ul>
              <p>
                As&iacute; pues, el Usuario podr&aacute; ejercitar sus derechos mediante comunicaci&oacute;n escrita dirigida al Responsable del tratamiento con
                la referencia "RGPD-www.iwines.es", especificando:
              </p>
              <ul>
                <li>
                  <p>
                    Nombre, apellidos del Usuario y copia del DNI. En los casos en que se admita la representaci&oacute;n, ser&aacute; tambi&eacute;n necesaria
                    la identificaci&oacute;n por el mismo medio de la persona que representa al Usuario, as&iacute; como el documento acreditativo de la
                    representaci&oacute;n. La fotocopia del DNI podr&aacute; ser sustituida, por cualquier otro medio v&aacute;lido en derecho que acredite la
                    identidad.
                  </p>
                </li>
                <li>
                  <p>Petici&oacute;n con los motivos espec&iacute;ficos de la solicitud o informaci&oacute;n a la que se quiere acceder.</p>
                </li>
                <li>
                  <p>Domicilio a efecto de notificaciones.</p>
                </li>
                <li>
                  <p>Fecha y firma del solicitante.</p>
                </li>
                <li>
                  <p>Todo documento que acredite la petici&oacute;n que formula.</p>
                </li>
              </ul>
              <p>Esta solicitud y todo otro documento adjunto podr&aacute; enviarse a la siguiente direcci&oacute;n y/o correo electr&oacute;nico:</p>
              <p>
                Direcci&oacute;n postal:
                <br />
                <span lang="ca-ES">
                  Calle Telers 5B
                  <br /> 2&ordf; planta, Local 1<br /> 08221 Terrassa, Barcelona
                </span>
                <br /> Correo electr&oacute;nico: <a href="mailto:info@happycustomerbox.com">info@</a>
                <a href="mailto:info@happycustomerbox.com">
                  <span lang="ca-ES">happycustomerbox.com</span>
                </a>
              </p>
              <p>
                <strong>Enlaces a sitios web de terceros</strong>
              </p>
              <p>
                El Sitio Web puede incluir hiperv&iacute;nculos o enlaces que permiten acceder a p&aacute;ginas web de terceros distintos de iWines, y que por
                tanto no son operados por iWines. Los titulares de dichos sitios web dispondr&aacute;n de sus propias pol&iacute;ticas de protecci&oacute;n de
                datos, siendo ellos mismos, en cada caso, responsables de sus propios ficheros y de sus propias pr&aacute;cticas de privacidad.
              </p>
              <p>
                <strong>Reclamaciones ante la autoridad de control</strong>
              </p>
              <p>
                En caso de que el Usuario considere que existe un problema o infracci&oacute;n de la normativa vigente en la forma en la que se est&aacute;n
                tratando sus datos personales, tendr&aacute; derecho a la tutela judicial efectiva y a presentar una reclamaci&oacute;n ante una autoridad de
                control, en particular, en el Estado en el que tenga su residencia habitual, lugar de trabajo o lugar de la supuesta infracci&oacute;n. En el
                caso de Espa&ntilde;a, la autoridad de control es la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos (http://www.agpd.es).
              </p>
              <p>
                <strong>
                  <br />
                </strong>
                <strong>II. POL&Iacute;TICA DE COOKIES</strong>
              </p>
              <p>
                <br /> El acceso a este Sitio Web puede implicar la utilizaci&oacute;n de cookies. Las cookies son peque&ntilde;as cantidades de
                informaci&oacute;n que se almacenan en el navegador utilizado por cada Usuario &mdash;en los distintos dispositivos que pueda utilizar para
                navegar&mdash; para que el servidor recuerde cierta informaci&oacute;n que posteriormente y &uacute;nicamente el servidor que la
                implement&oacute; leer&aacute;. Las cookies facilitan la navegaci&oacute;n, la hacen m&aacute;s amigable, y no da&ntilde;an el dispositivo de
                navegaci&oacute;n.
              </p>
              <p>
                La informaci&oacute;n recabada a trav&eacute;s de las cookies puede incluir la fecha y hora de visitas al Sitio Web, las p&aacute;ginas
                visionadas, el tiempo que ha estado en el Sitio Web y los sitios visitados justo antes y despu&eacute;s del mismo. Sin embargo, ninguna cookie
                permite que esta misma pueda contactarse con el n&uacute;mero de tel&eacute;fono del Usuario o con cualquier otro medio de contacto personal.
                Ninguna cookie puede extraer informaci&oacute;n del disco duro del Usuario o robar informaci&oacute;n personal. La &uacute;nica manera de que la
                informaci&oacute;n privada del Usuario forme parte del archivo Cookie es que el usuario d&eacute; personalmente esa informaci&oacute;n al
                servidor.
              </p>
              <p>
                Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas les ser&aacute; de aplicaci&oacute;n
                la Pol&iacute;tica de Privacidad anteriormente descrita. En este sentido, para la utilizaci&oacute;n de las mismas ser&aacute; necesario el
                consentimiento del Usuario. Este consentimiento ser&aacute; comunicado, en base a una elecci&oacute;n aut&eacute;ntica, ofrecido mediante una
                decisi&oacute;n afirmativa y positiva, antes del tratamiento inicial, removible y documentado.
              </p>
              <p>
                <strong>Cookies propias</strong>
              </p>
              <p>
                Son aquellas cookies que son enviadas al ordenador o dispositivo del Usuario y gestionadas exclusivamente por iWines para el mejor
                funcionamiento del Sitio Web. La informaci&oacute;n que se recaba se emplea para mejorar la calidad del Sitio Web y su Contenido y su
                experiencia como Usuario. Estas cookies permiten reconocer al Usuario como visitante recurrente del Sitio Web y adaptar el contenido para
                ofrecerle contenidos que se ajusten a sus preferencias.
              </p>
              <p>
                La(s) entidad(es) encargada(s) del suministro de cookies podr&aacute;(n) ceder esta informaci&oacute;n a terceros, siempre y cuando lo exija la
                ley o sea un tercero el que procese esta informaci&oacute;n para dichas entidades.
              </p>
              <p>
                <strong>Deshabilitar, rechazar y eliminar cookies</strong>
              </p>
              <p>
                El Usuario puede deshabilitar, rechazar y eliminar las cookies &mdash;total o parcialmente&mdash; instaladas en su dispositivo mediante la
                configuraci&oacute;n de su navegador (entre los que se encuentran, por ejemplo, Chrome, Firefox, Safari, Explorer). En este sentido, los
                procedimientos para rechazar y eliminar las cookies pueden diferir de un navegador de Internet a otro. En consecuencia, el Usuario debe acudir a
                las instrucciones facilitadas por el propio navegador de Internet que est&eacute; utilizando. En el supuesto de que rechace el uso de cookies
                &mdash;total o parcialmente&mdash; podr&aacute; seguir usando el Sitio Web, si bien podr&aacute; tener limitada la utilizaci&oacute;n de algunas
                de las prestaciones del mismo.
              </p>
              <p>
                <br />
                <strong>III. ACEPTACI&Oacute;N Y CAMBIOS EN ESTA POL&Iacute;TICA DE PRIVACIDAD</strong>
              </p>
              <p>
                <br /> Es necesario que el Usuario haya le&iacute;do y est&eacute; conforme con las condiciones sobre la protecci&oacute;n de datos de
                car&aacute;cter personal contenidas en esta Pol&iacute;tica de Privacidad y de Cookies, as&iacute; como que acepte el tratamiento de sus datos
                personales para que el Responsable del tratamiento pueda proceder al mismo en la forma, durante los plazos y para las finalidades indicadas. El
                uso del Sitio Web implicar&aacute; la aceptaci&oacute;n de la Pol&iacute;tica de Privacidad y de Cookies del mismo.
              </p>
              <p>
                iWines se reserva el derecho a modificar su Pol&iacute;tica de Privacidad y de Cookies, de acuerdo a su propio criterio, o motivado por un
                cambio legislativo, jurisprudencial o doctrinal de la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos. Los cambios o actualizaciones de
                esta Pol&iacute;tica de Privacidad y de Cookies no ser&aacute;n notificados de forma expl&iacute;cita al Usuario. Se recomienda al Usuario
                consultar esta p&aacute;gina de forma peri&oacute;dica para estar al tanto de los &uacute;ltimos cambios o actualizaciones.
              </p>
              <p>
                Esta Pol&iacute;tica de Privacidad y de Cookies fue actualizada el d&iacute;a 14 de mayo 2020 para adaptarse al Reglamento (UE) 2016/679 del
                Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al
                tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos (RGPD) y a la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de
                Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales.
              </p>
              <p>
                <br />
                &Uacute;ltima modificaci&oacute;n: 14 de mayo 2020
              </p>
            </div>
            <div style={{ marginTop: "3vh" }}>
              <Button value={strings.close} action={close} />
            </div>
          </Popup>
        </PopupBackground>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default TermsPopup;
