export const isNullOrUndefined = (value) => {
  return (value === null || value === undefined);
};

export const isNullString = (string) => {
  if (string == null || string.length <= 0) return true;
  else return false;
};

export const isNullMap = (map) => {
  let result = false;

  if (map == null) return true;

  Object.entries(map).forEach(([key, value]) => {
    if (isNullString(value)) result = true;
  });

  return result;
};

export const isNullSelecction = (selectionId) => {
  if (isNullString(selectionId)) return true;
  else if (selectionId == "notSelected") return true;
  else return false;
};

export const isInvalidBoolean = (value) => {
  if (value == null || value == undefined) return true;
  else if (typeof value == "boolean") return false;
};

export const isInvalidInteger = (stringInt) => {
  if (isNullString(stringInt)) return true;
  else {
    let parsedInt = parseInt(stringInt);
    if (isNaN(parsedInt)) return true;
    else return false;
  }
};

export const isInvalidFloat = (stringFloat) => {
  if (isNullString(stringFloat)) return true;
  else {
    let parsedFloat = parseFloat(stringFloat);
    if (isNaN(parsedFloat)) return true;
    else return false;
  }
};

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
};

function isObject(object) {
  return object != null && typeof object === "object";
}

export function isValidateDNI(dni) {
  var numero, letr, letra;
  var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
  dni = dni.toUpperCase();
  if (expresion_regular_dni.test(dni) === true) {
    numero = dni.substr(0, dni.length - 1);
    numero = numero.replace("X", 0);
    numero = numero.replace("Y", 1);
    numero = numero.replace("Z", 2);
    letr = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    letra = "TRWAGMYFPDXBNJZSQVHLCKET";
    letra = letra.substring(numero, numero + 1);
    if (letra != letr) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function isValidCif(cif) {
  if (!cif || cif.length !== 9) {
    return false;
  }
  var letters = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
  var digits = cif.substr(1, cif.length - 2);
  var letter = cif.substr(0, 1);
  var control = cif.substr(cif.length - 1);
  var sum = 0;
  var i;
  var digit;
  if (!letter.match(/[A-Z]/)) return false;

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i]);
    if (isNaN(digit)) return false;
    if (i % 2 === 0) {
      digit *= 2;
      if (digit > 9) {
        digit = parseInt(digit / 10) + (digit % 10);
      }
      sum += digit;
    } else {
      sum += digit;
    }
  }
  sum %= 10;
  if (sum !== 0) digit = 10 - sum;
  else digit = sum;

  if (letter.match(/[ABEH]/)) return String(digit) === control;
  if (letter.match(/[NPQRSW]/)) return letters[digit] === control;
  return String(digit) === control || letters[digit] === control;
}

export function isValidPhone(p) {
  var phoneRe = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  var digits = p.replace(/\D/g, "");
  return phoneRe.test(digits);
}
