import React from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../../shared/TextInput";
import Select from "../../../../../shared/Select";
import CrossIconGrey from "../../../../../../assets/images/cross-icon-grey.svg";
import ArrowDownIcon from "../../../../../../assets/images/ic_expand.png";
import ArrowTopIcon from "../../../../../../assets/images/ic_contract.png";
import OptionsWrapperForm from "./OptionsWrapperForm";
import ImagePicker from "../../../../../shared/ImagePicker";
import VideoUploader from "../../../../../shared/VideoUploader";

const ComponentForm = ({ strings, config, componentId, component, groupedByCategories, updateComponent, deleteComponent, moveBack, moveForward, }) => {
  const componentTypes = {
    notSelected: strings.notSelected,
    OPTIONS_SLIDER: strings.optionsSlider,
    IMAGES_SLIDER: strings.imageSlider,
    TREE: strings.tree,
    FACES: strings.faces
  };

  const Wrapper = {
    backgroundColor: "white",
    borderRadius: "6px",
    padding: "1.5vh",
    marginBottom: "1.5vh",
    position: "relative",
  };

  const CategoryWrapper = {
    ...Wrapper,
    backgroundColor: "rgb(237, 237, 237)",
  };

  const Tag = styled.div`
    margin: 0.5rem;
  `;

  const IconsContainer = styled.div`
    position: absolute;
    top: 1vh;
    right: 1vh;
  `;

  const Icon = styled.img`
    padding: 0.5vh;
    height: 1.5vh;
    margin-left: 0.5vw;
    border-radius: 50%;
    &:hover {
      background-color: LightGray;
    }
  `;

  const Table = {
    width: "100%",
    tableLayout: "fixed",
  };

  return (
    <div style={groupedByCategories ? CategoryWrapper : Wrapper}>
      <IconsContainer>
        {
          moveBack ? <Icon src={ArrowTopIcon} onClick={moveBack} /> : <></>
        }
        {
          moveForward ? <Icon src={ArrowDownIcon} onClick={moveForward} /> : <></>
        }
        <Icon src={CrossIconGrey} onClick={deleteComponent} />
      </IconsContainer>

      <table style={Table}>
        <tbody>
          <tr>
            <td>
              <Tag>
                <b>{strings.name}*</b>
              </Tag>
              <TextInput
                defaultValue={component?.name?.[config.lang]}
                id={"name"}
                maxLength="30"
                onChange={(e) => {
                  updateComponent("name", {
                    CA: e.target.value,
                    ES: e.target.value,
                    EN: e.target.value,
                  });
                }}
              />
            </td>
            {component?.type == "TREE" ? (
              <td>
                <Tag>
                  <b>{strings.buttonAddText}</b>
                </Tag>
                <TextInput
                  defaultValue={component?.buttonAddText?.[config.lang]}
                  placeholder={strings.defaultButtonAddText}
                  id={"buttonAddText"}
                  maxLength="30"
                  onChange={(e) => {
                    updateComponent("buttonAddText", {
                      CA: e.target.value,
                      ES: e.target.value,
                      EN: e.target.value,
                    });
                  }}
                />
              </td>
            ) : (
              <></>
            )}
            <td>
              <Tag>
                <b>{strings.type}*</b>
              </Tag>
              <Select
                id="type"
                options={componentTypes}
                defaultOption={component?.type}
                onChange={updateComponent}
              />
            </td>
            {component?.type == "OPTIONS_SLIDER" ? (
              <td style={{ width: "17vh" }}>
                <Tag>
                  <b>{strings.icon}</b>
                </Tag>
                <ImagePicker
                  id={"icon-" + componentId}
                  src={component?.icon}
                  upload={true}
                  cloudPath={"assessmentSheets/icons"}
                  onSelect={(id, image) => {
                    updateComponent("icon", image);
                  }}
                  width="15vh"
                  height="15vh"
                />
              </td>
            ) : (
              <></>
            )}
            <td style={{ width: "32vh" }}>
              <Tag>
                <b>{strings.videoExplanation}</b>
              </Tag>
              <VideoUploader
                strings={strings}
                config={config}
                id={component?.id}
                videoId={component?.videoExplanationId}
                videoUrl={component?.videoExplanationUrl}
                upload={true}
                width="26.7vh"
                height="15vh"
                onUrlChange={(url) => {
                  updateComponent("videoExplanationUrl", url);
                }}
                onIdChange={(videoId) => {
                  updateComponent("videoExplanationId", videoId);
                }}
              />
            </td>
          </tr>
          {component?.type && component?.type !== "notSelected" && component?.type !== "FACES" ? (
            <tr>
              <td colSpan={["OPTIONS_SLIDER", "TREE"].includes(component?.type) ? "4" : "3"}>
                <Tag>
                  <b>{strings.options}*</b>
                </Tag>
                <OptionsWrapperForm
                  strings={strings}
                  config={config}
                  componentId={componentId}
                  component={component}
                  updateComponent={updateComponent}
                />
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default ComponentForm;
