import React from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../../../shared/TextInput";
import DeleteIcon from "../../../../../../../assets/images/delete-icon.svg";

const DynamicParamField = ({ strings, config, product, paramId, productTypes, updateProduct }) => {
  let propiety = product?.properties[paramId];

  let updateParam = (key, value) => {
    let properties = product?.properties || {};
    properties[paramId][key] = value;
    updateProduct("properties", properties);
  }

  let deleteParam = () => {
    let properties = product?.properties || {};
    delete properties[paramId];
    updateProduct("properties", properties);
  }

  let getName = () => {
    if (propiety?.name) {
      return propiety?.name?.[config?.lang];

    } else if (product?.type?.properties?.[paramId]) {
      let defaultPropiety = product?.type?.properties?.[paramId];
      if (defaultPropiety?.name) {
        updateParam("name", defaultPropiety.name);
      }
      if (!propiety?.unit && defaultPropiety?.unit) {
        updateParam("unit", defaultPropiety.unit);
      }
    }
    return null;
  }

  let getValue = () => {
    if (propiety?.value) {
      if (typeof propiety?.value === 'object') {
        return propiety?.value[config.lang];
      } else {
        return propiety?.value;
      }
    } else {
      return null;
    }
  }



  const Wrapper = {
    display: "flex",
  }

  const Separator = {
    margin: "auto",
    textAlign: "center",
    width: "5%"
  }

  const Icon = styled.img`
    height: 2.5vh;
    margin: auto 0.5vw;
    border-radius: 40%;
    padding: 0.3vw;
    &:hover {
      background-color: White;
    }
  `;

  return (
    <div style={Wrapper}>
      <TextInput
        width={"35%"}
        defaultValue={getName()}
        styles={{ fontWeight: "bold" }}
        onChange={(e) => {
          updateParam("name", {
            ES: e.target.value,
            CA: e.target.value,
            EN: e.target.value
          });
        }}
      />
      <div style={Separator}>:</div>
      <TextInput
        width={"60%"}
        defaultValue={getValue()}
        unit={propiety?.unit}
        onChange={(e) => {
          updateParam("value", {
            ES: e.target.value,
            CA: e.target.value,
            EN: e.target.value
          });
        }}
      />
      <Icon src={DeleteIcon} onClick={deleteParam} />
    </div>
  );
};
export default DynamicParamField;
