import React, { useState, useEffect } from "react";
import ListComponent from "./ListComponent";
import ImportSelector from "./ImportSelector";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import { BrowserRouter as Router, useRouteMatch, useHistory } from "react-router-dom";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";

const ListEngine = ({ strings, config }) => {
  let ba = new BackendAccessor();
  let history = useHistory();

  const [assessmentSheets, setAssessmentSheets] = useState(null);
  const [importData, setImportData] = useState(null);
  const [loadingText, setLoadingText] = useState(null);

  const loadAssessmentSheets = () => {
    ba.getPrivateAssessmentSheets(
      (assessmentSheets) => {
        setAssessmentSheets(assessmentSheets);
      },
      (error) => {
        console.error("Error loading public assessment sheet: ", error);
      }
    );
  };

  useEffect(() => {
    if (assessmentSheets == null) {
      loadAssessmentSheets();
    }
  }, [strings]);

  const newAssessmentSheet = () => {
    history.replace(`/dashboard/assessmentSheets/new`);
  };

  const editAssessmentSheet = (id) => {
    history.replace(`/dashboard/assessmentSheets/edit/${id}`);
  };

  const deleteAssessmentSheet = (key) => {
    config.popupAlert(
      strings.deleteAssessmentSheet,
      strings.deleteText,
      () => {},
      true,
      () => {
        ba.deletePrivateAssessmentSheet(
          key,
          (result) => {
            console.error("Assessment sheet " + key + " has been deleted", result);
            loadAssessmentSheets();
          },
          (error) => {
            console.error("Error loading public assessment sheet: ", error);
          }
        );
      },
      strings.cancel,
      strings.accept
    );
  };

  const loadImportAssessmentSheet = () => {
    setLoadingText(strings.loadingPublicSheets);
    ba.getPublicAssessmentSheets(
      (assessmentSheets) => {
        setLoadingText(null);
        setImportData(assessmentSheets);
      },
      (error) => {
        setLoadingText(null);
        config.popupAlert(strings.error, strings.errorPublicSheets);
        console.error("Error loading public assessment sheet: ", error);
      }
    );
  };

  const importAssessmentSheet = (ref) => {
    setLoadingText(strings.importing);
    ba.importAssessmentSheets(
      ref,
      (response) => {
        setTimeout(function () {
          loadAssessmentSheets();
          setLoadingText(null);
        }, 2000);
      },
      (error) => {
        console.error("Error importing assessment sheet: ", error);
        setLoadingText(null);
      }
    );
  };

  const openCloneDialog = (assessmentSheetsId) => {
    config.popupAlert(
      strings.cloneAssessmentSheet,
      strings.cloneText,
      () => {},
      true,
      () => {
        clone(assessmentSheetsId);
      },
      strings.cancel,
      strings.accept
    );
  };

  const clone = (assessmentSheetsId) => {
    setLoadingText(strings.cloning);
    ba.cloneAssessmentSheets(
      assessmentSheetsId,
      (response) => {
        console.log("Clone response", response);
        setTimeout(function () {
          loadAssessmentSheets();
          setLoadingText(null);
        }, 2000);
      },
      (error) => {
        console.error("Error cloning assessment sheet: ", error);
        setLoadingText(null);
      }
    );
  };

  return (
    <>
      {importData != null ? (
        <ImportSelector
          strings={strings}
          config={config}
          importData={importData}
          close={() => {
            setImportData(null);
          }}
          select={importAssessmentSheet}
        />
      ) : (
        <></>
      )}
      {assessmentSheets != null ? (
        <ListComponent
          strings={strings}
          config={config}
          assessmentSheets={assessmentSheets}
          create={newAssessmentSheet}
          edit={editAssessmentSheet}
          clone={openCloneDialog}
          deleteAS={deleteAssessmentSheet}
          importAS={loadImportAssessmentSheet}
        />
      ) : (
        <LoadingScreen />
      )}
      {loadingText != null ? <DownloadingPopup strings={strings} headerText={loadingText} /> : <></>}
    </>
  );
};

export default ListEngine;
