import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";

import DashboardEngine from "./components/views/Dashboard/DashboadEngine";
import AssistantEngine from "./components/views/Assistant/AssistantEngine";
import CustomPageEngine from "./components/views/CustomPage/CustomPageEngine";
import LoginEngine from "./components/views/Login/LoginEngine";
import SignupEngine from "./components/views/Signup/SignupEngine";
import RecoveryEngine from "./components/views/RecoveryPass/RecoveryPassEngine";

import Firebase from "./assets/Firebase";
import Config from "./assets/Config";
import StringsLoader from "./assets/strings/StringsLoader";
import Popup from "./components/shared/Popup";

function App() {
  let history = useHistory();
  let f = new Firebase();
  let strLoader = new StringsLoader();

  const [lang, setLang] = useState(strLoader.DEFAULT_LANG);
  const [strings, setStrings] = useState(strLoader.getDefaultStrings());
  const [theme, setTheme] = useState("lightTheme");
  const [popupData, setPopupData] = useState({
    visible: false,
    header: "",
    text: "",
    confirmation: false,
  });


  useEffect(() => {
    // Comprueva el navegador
    checkBrowser();

    // Carga el idioma del usuario logueado
    // TODO: Save lang in cookies

    f.checkSignedUser((user) => {
      f.loadCeller((doc) => {
        if (doc.exists && doc.data().lang) {
          setLang(doc.data().lang);
        }
      });
    },
      () => { }
    );
  }, []);

  // Carga los strings cuando el lang cambia
  useEffect(() => {
    setStrings(strLoader.getStrings(lang));
  }, [lang]);

  function checkBrowser() {
    new Config();
    console.debug("Browser", Config.NAV);
    console.debug("Envoriment " + Config.ENV);

    if (Config.NAV.safari /*|| Config.NAV.firefox*/) {
      popupAlert(strings.incompatibleBrowserHeader, strings.incompatibleBrowserBody, () => {
        window.location.replace("https://www.google.com/chrome/");
      });
    }
  }

  const popupAlert = (header, text, onClose, confirmation, onConfirm, closeText, acceptText) => {
    setPopupData({
      visible: true,
      header: header,
      text: text,
      onClose: onClose,
      confirmation: confirmation,
      onConfirm: onConfirm,
      closeText: closeText,
      acceptText: acceptText,
    });
  };

  const closePopupAlert = () => {
    setPopupData({ visible: false });
  }

  const getDefaultPath = () => {
    let hostName = window?.location?.hostname || "";

    if (hostName.includes("assistant") || hostName.includes("asistente")) {
      return "/assistant";
    } else {
      return "/dashboard/home";
    }
  }

  return (
    <>
      <Popup strings={strings} popupData={popupData} setPopupData={setPopupData} />
      <Router>
        <Switch>
          <Route path="/assistant" children={<AssistantEngine strings={strings} lang={lang} setLang={setLang} theme={theme} popupAlert={popupAlert} closePopupAlert={closePopupAlert} />} />
          <Route path="/login" children={<LoginEngine strings={strings} theme={theme} popupAlert={popupAlert} />} />
          <Route path="/signup" children={<SignupEngine strings={strings} theme={theme} popupAlert={popupAlert} />} />
          <Route path="/recoveryPass" children={<RecoveryEngine strings={strings} theme={theme} />} />
          <Route path="/dashboard" children={<DashboardEngine strings={strings} lang={lang} setLang={setLang} theme={theme} popupAlert={popupAlert} closePopupAlert={closePopupAlert} />} />
          <Route path="/page/:uid/:pageId" children={<CustomPageEngine strings={strings} lang={lang} />} />
          <Route path="/" children={<Redirect to={{ pathname: getDefaultPath() }} />} />
          <Route path="*" children={<div>Error 404 page not found</div>} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
