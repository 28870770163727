import React, { useState, useEffect } from "react";
import TableRanking from "../../../../../shared/TableRanking";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";

const DivTable = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
`;

const BorderTable = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 2vh;
    box-shadow: 0 0 7vh 1em #c6c6c6;
    max-height: 70vh;
`;

const DivTitle = styled.div`
    text-transform: uppercase;
    font-size: 4vh;
    margin: 3vh;
`;

const DivButton = styled.div`
    width: 202vh;
`;

const RankingComponent = ({ strings, config, actions, values, lang, selectLang }) => {

    const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 100%;
    `;

    let action = [
        {
            title: (
                <Button
                    value={strings.buy}
                />
            ),
            action: actions.goToLink,
            id: "goToLink",
        },
        {
            title: (
                <Button
                    value={strings.viewCompleteInfo}
                />
            ),
            action: actions.showProductData,
        },
    ];

    return (
        <Wrapper>
            <DivTable>
                <BorderTable>
                    <DivTitle>{strings.rankingList}</DivTitle>
                    <TableRanking
                        header={strings.WinesTable}
                        data={values.data}
                        actions={action}
                        ready={true}
                        sortByField={"rate"}
                        sortOrder={"descending"}
                        noElementsText={strings.noneList} />
                    <br></br>
                </BorderTable>
            </DivTable>
        </Wrapper>
    );
};
export default RankingComponent;