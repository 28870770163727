import React, { useState, useEffect, useRef } from "react";

import AssistantDetailsFormComponent from "./AssistantDetailsFormComponent.js";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";
import { data } from "jquery";

const AssistantDetailsFormEngine = ({ strings, config, reportType, reportData, sessionData }) => {
    let history = useHistory();
    let { assistantId } = useParams();

    const [assistantData, setAssistantData] = useState(null);

    const loadAssistantData = (id) => {
        let assistant = sessionData?.assistants?.[id];
        assistant.id = id;
        setAssistantData(assistant);
    };

    useEffect(() => {
        if (assistantData == null && assistantId != null) {
            loadAssistantData(assistantId);
        }
    }, [strings, assistantId]);

    const goBack = () => {
        history.replace("/dashboard/reports/" + reportType + "/" + reportData.id + "/assistant");
    }

    const getQuestionByRef = (questionRef) => {
        let questions = sessionData?.tasting?.surveyQuestions || [];
        let question = questions.find(ques => ques.reference == questionRef);
        let questionText = question?.question?.[config.lang];
        return questionText ? questionText : "-";
    }

    const getAnswersByIds = (questionRef, answersIds) => {
        let questions = sessionData?.tasting?.surveyQuestions || [];
        let question = questions.find(ques => ques.reference == questionRef);
        let optionsText = "";

        answersIds.forEach(answerId => {
            let optionText = question?.options?.[answerId]?.[config.lang];
            if (optionText) {
                if (optionsText == "") {
                    optionsText = optionText
                } else {
                    optionsText += ", " + optionText;
                }
            }
        });

        return optionsText;
    }

    const getProduct = (productReference) => {
        let references = sessionData?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let product = reference?.product;
        return product;
    }

    const getProductName = (productReference) => {
        let references = sessionData?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let productName = reference?.product?.name;
        return productName ? productName : "-";
    }

    const getParameterName = (productReference, parameterId) => {
        const defaultParameters = {
            rateBox: strings.generalAssessment,
            ratePrice: strings.priceQualityAssessment,
            blindPrice: strings.blindPrice,
            notes: "Anotaciones"
        }

        let references = sessionData?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let parameter = reference?.assessmentSheet?.parameters.find(par => par.id === parameterId);
        let parameterName = parameter?.name?.[config.lang];

        if (parameterName === null || parameterName === undefined) {
            parameterName = defaultParameters?.[parameterId];
        }
        return parameterName;
    }

    const getCategoryName = (productReference, categoryId) => {
        let references = sessionData?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let category = reference?.assessmentSheet?.categories.find(cat => cat?.id === categoryId);
        let categoryName = category?.name?.[config.lang];
        return categoryName ? categoryName : "-";
    }

    const getCategoryParameterName = (productReference, categoryId, parameterId) => {
        let references = sessionData?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference == productReference);
        let category = reference?.assessmentSheet?.categories?.find(cat => cat?.id === categoryId);
        let component = category?.components?.find(comp => comp?.id === parameterId);;
        let paramaterName = component?.name?.[config.lang];
        return paramaterName ? paramaterName : "-";
    }

    const getOptionsName = (productReference, categoryId, parameterId, optionsIds) => {
        let references = sessionData?.tasting?.productReferences || [];
        let reference = references.find(ref => ref.reference === productReference);
        let category = reference?.assessmentSheet?.categories?.find(cat => cat?.id === categoryId);
        let component = category?.components?.find(comp => comp?.id === parameterId);
        let optionsName = "";

        if (typeof optionsIds === 'string' || optionsIds instanceof String) {
            let option = findOption((component?.options || []), optionsIds, false);
            optionsName = option?.name?.[config.lang];

        } else if (typeof optionsIds === 'object' && optionsIds !== null) {
            Object.entries(optionsIds).forEach(([optionId, responseId]) => {
                let option = findOption((component?.options || []), optionId, true);
                if (optionsName == "") {
                    optionsName = option?.name?.[config.lang]
                } else {
                    optionsName += ", " + option?.name?.[config.lang];
                }
            });
        }
        return optionsName;
    }

    const findOption = (optionsArray, optionId, recursive) => {
        for (let index = 0; index < optionsArray.length; index++) {
            let option = optionsArray[index];
            if (option.id === optionId) {
                return option;
            }
            if (recursive && option.options && option.options.length > 0) {
                let suboption = findOption(option.options, optionId, recursive);
                if (suboption != null) {
                    return suboption;
                }
            }
        }
        return null;
    }

    const getSatisfactionConcept = (satisfactionRef) => {
        let concepts = sessionData?.tasting?.satisfactionConcepts || [];
        let concept = concepts.find(c => c.reference == satisfactionRef);
        let conceptText = concept?.concept?.[config.lang];
        return conceptText ? conceptText : "-";
    }

    const hasStoreClicked = (productReference) => {
        let actions = assistantData?.actions || [];

        if (actions?.size <= 0) return false;

        let clicked = actions.some(action => {
            return action?.type === "STORE_LINK_CLICKED" && action?.metadata?.productRefPath === productReference;
        });

        return clicked;
    }

    const getDataParser = () => {
        let dataParser = {};
        dataParser.getQuestionByRef = getQuestionByRef;
        dataParser.getAnswersByIds = getAnswersByIds;
        dataParser.getProduct = getProduct;
        dataParser.getProductName = getProductName;
        dataParser.getParameterName = getParameterName;
        dataParser.getCategoryName = getCategoryName;
        dataParser.getCategoryParameterName = getCategoryParameterName;
        dataParser.getOptionsName = getOptionsName;
        dataParser.getSatisfactionConcept = getSatisfactionConcept;
        dataParser.hasStoreClicked = hasStoreClicked;
        return dataParser;
    }


    return (
        <AssistantDetailsFormComponent
            strings={strings}
            config={config}
            sessionData={sessionData}
            assistantData={assistantData}
            dataParser={getDataParser()}
            goBack={goBack}
        />
    );
};

export default AssistantDetailsFormEngine;