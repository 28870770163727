import React from "react";
import styled from "@emotion/styled";
import background from "../../../assets/images/background-login.png";
import TextInput from "../../shared/TextInput";
import Button from "../../shared/Button";
import SecondaryButton from "../../shared/SecondaryButton";

const RecoveryPassComponent = ({ strings, recoveryEvent, backLogin }) => {
  const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    background-image: url(${background});
    background-position: center;
    background-size: cover;
  `;
  const Wrapper = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem 3rem;
    border-radius: 5px;
    width: 30%;
    @media (max-width: 426px) {
      width: 70%;
      padding: 1rem 2rem;
    }
    > div {
      margin: 0.5rem;
    }
  `;
  const WrapperS = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem 3rem;
    border-radius: 5px;
    width: 30%;
    display: none;
    > div {
      margin: 1rem;
      font-size: 2.2vh;
      text-align: center;
    }
  `;

  const ErrorMsg = styled.div`
    color: red;
    visibility: hidden;
    font-size: 0.8rem;
  `;

  const Tittle = styled.div`
    font-size: 3.5vh;
    font-weight: 600;
    margin: 1.5vh 0;
    text-align: center;
    @media (max-width: 426px) {
      font-size: 1.5rem;
    }
  `;

  return (
    <Container>
      <Wrapper id={"formPass"}>
        <Tittle>Recordar usuario</Tittle>
        <div style={{ margin: "2vh 0vh" }}>
          <div>Email</div>
          <TextInput id="username" enterAction={recoveryEvent} width="100%" />
          <ErrorMsg id="usernameError">Este correo no existe</ErrorMsg>
        </div>
        <Button value="Recuperar Contraseña" action={recoveryEvent} />
        <SecondaryButton value="Cancelar" action={backLogin} />
      </Wrapper>

      <WrapperS id={"formSuccess"}>
        <div>Se ha enviado correctamente un correo electronico para cambiar cu contraseña</div>
        <Button value="Aceptar" action={backLogin} />
      </WrapperS>
    </Container>
  );
};

export default RecoveryPassComponent;
