/* Vimeo config */
const TOKEN = "fda050b836519715dea62d128be8e435";

export const getVideoName = (id, sucess, error) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "bearer " + TOKEN);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch("https://api.vimeo.com/videos/" + id, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      let resObj = JSON.parse(result);
      sucess(resObj.name, resObj.status, resObj.duration, resObj.link);
    })
    .catch(error);
};

export const moveVideoToFolder = (videoId, folderId, success, error) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "bearer " + TOKEN);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch("https://api.vimeo.com/me/projects/" + folderId + "/videos/" + videoId, requestOptions)
    .then(() => success(videoId))
    .catch(error);
};

export const createVimeoFolder = (name, success, error) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");
  myHeaders.append("Authorization", "bearer " + TOKEN);

  var raw = JSON.stringify({ name: name });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://api.vimeo.com/me/projects", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      let resObj = JSON.parse(result);
      let id = resObj.uri.split("projects/")[1];
      success(id);
    })
    .catch(error);
};

export const uploadVideoToVimeo = (file, success, error) => {
  let headersPost = new Headers();
  headersPost.append("Authorization", "bearer " + TOKEN);
  headersPost.append("Content-Type", "application/json");
  headersPost.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

  let raw = JSON.stringify({
    upload: {
      approach: "tus",
      size: file.size,
    },
    name: file.name,
  });

  let requestOptions = {
    method: "POST",
    headers: headersPost,
    body: raw,
    redirect: "follow",
  };

  // ---- CREATE THE VIDEO ----
  fetch("https://api.vimeo.com/me/videos", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      let resObj = JSON.parse(result);
      if ("error" in resObj || "error_code" in resObj) {
        error(resObj);
        return;
      }

      let uploadLink = resObj.upload.upload_link;
      let id = resObj.link.replace("https://vimeo.com/", "");

      // ---- READ THE FILE----
      const reader = new FileReader();
      reader.onload = (r) => {
        var fileContent = r.target.result;

        let headersPatch = new Headers();
        headersPatch.append("Tus-Resumable", "1.0.0");
        headersPatch.append("Upload-Offset", "0");
        headersPatch.append("Content-Type", "application/offset+octet-stream");
        headersPatch.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

        var requestOptions = {
          method: "PATCH",
          headers: headersPatch,
          body: fileContent,
          redirect: "follow",
        };

        // ---- UPLOAD THE VIDEO ----
        fetch(uploadLink, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let headersHead = new Headers();
            headersHead.append("Tus-Resumable", "1.0.0");
            headersHead.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

            var requestOptions = {
              method: "HEAD",
              headers: headersHead,
              redirect: "follow",
            };

            // ---- VERIFY THE VIDEO ----
            fetch(uploadLink, requestOptions)
              .then((response) => response.text())
              .then((result) => {
                success(id);
              })
              .catch(error);
          })
          .catch(error);
      };
      reader.onerror = error;
      reader.readAsArrayBuffer(file);
    })
    .catch(error);
};
