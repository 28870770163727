import React, { useState, useEffect } from "react";
import LoginComponent from "./LoginComponent";
import WrongPasswordPopup from "./WrongPasswordPopup";
import Firebase from "../../../assets/Firebase";
import { useHistory } from "react-router-dom";

import background1 from "../../../assets/images/background-login.png";
import background2 from "../../../assets/images/background2.jpg";
import background3 from "../../../assets/images/background3.jpg";
import background4 from "../../../assets/images/background4.jpg";
import background5 from "../../../assets/images/background5.jpg";

const LoginEngine = ({ strings, theme, popupAlert }) => {
  let history = useHistory();
  let f = new Firebase();

  let [verificationEmailVisible, setVerificationEmailVisible] = useState(false);
  let [wrongPasswordVisible, setWrongPasswordVisible] = useState(false);

  let [loadingMsg, setLoadingMsg] = useState(null);

  const startChangeImage = () => {
    var images = [background1, background2, background3, background4, background5];
    var imgElement = document.getElementById("background");

    // Preload images
    images.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });

    var i = 1;
    setInterval(function () {
      imgElement.style.backgroundImage = "url(" + images[i] + ")";
      i = i + 1;
      if (i == images.length) {
        i = 0;
      }
    }, 6000);
  }

  useEffect(() => {
    startChangeImage();
  }, []);

  const login = () => {
    let name = document.getElementById("nameInput").value;
    let password = document.getElementById("passwordInput").value;
    f.tryLogin(name, password, success, error);
  };

  const success = () => {
    let verified = f.isEmailVerificated();
    if (verified) {
      history.replace("/");
    } else {
      setVerificationEmailVisible(true);
      f.tryLogout(
        () => {},
        () => {}
      );
    }
  };

  const error = (err) => {
    console.log(err);

    switch (err?.code) {
      case "auth/wrong-password":
        setWrongPasswordVisible(true);
        break;
      case "auth/user-not-found":
        popupAlert(strings.error, strings.errorEmail);
        break;
      case "auth/too-many-requests":
        popupAlert(strings.error, strings.errorToManyRequests);
        break;
      default:
        popupAlert(strings.error, strings.wrongCredentials);
        break;
    }
  };

  const clicKPassword = () => {
    history.replace("/recoveryPass");
  };

  const clickAccount = () => {
    history.replace("/Signup");
  };

  const resendVerificationEmail = () => {
    let email = document.getElementById("nameInput").value;
    setLoadingMsg(strings.sendingEmail);
    setVerificationEmailVisible(false);

    f.sendEmailVerification(
      email,
      () => {
        setLoadingMsg(null);
        popupAlert(strings.emailSentHeader, strings.emailSentBody + email);
      },
      () => {
        setLoadingMsg(null);
        popupAlert(strings.error, strings.emailSentError);
      }
    );
  };

  return (
    <LoginComponent
      strings={strings}
      login={login}
      clicKPassword={clicKPassword}
      clickAccount={clickAccount}
      theme={theme}
      loadingMsg={loadingMsg}
      resendVerificationEmail={resendVerificationEmail}
      verificationEmailVisible={verificationEmailVisible}
      setVerificationEmailVisible={setVerificationEmailVisible}
      recoverPassword={clicKPassword}
      wrongPasswordVisible={wrongPasswordVisible}
      setWrongPasswordVisible={setWrongPasswordVisible}
    />
  );
};

export default LoginEngine;
