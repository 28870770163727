import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, useRouteMatch } from "react-router-dom";
import AssistantDetailsFormEngine from "./Assistant/AssistantDetailsFormEngine";
import AssistantListFormEngine from "./Assistant/AssistantListFormEngine";
import ProductFormEngine from "./Product/ProductFormEngine";
import MainFormEngine from "./Main/MainFormEngine";
import SurveyFormEngine from "./Survey/SurveyFormEngine";

const FormRoutes = ({ strings, config, reportType, utils, reportData, sessionData, setReportData }) => {
    const Wrapper = {
        width: "100%",
        display: "flex",
    };

    let { path } = useRouteMatch();
    return (
        <div style={Wrapper}>
            <Switch>
                <Route path={`${path}/survey`}>
                    <SurveyFormEngine strings={strings} config={config} reportType={reportType} utils={utils} reportData={reportData} sessionData={sessionData} />
                </Route>
                <Route path={`${path}/assistant/:assistantId`}>
                    <AssistantDetailsFormEngine strings={strings} config={config} reportType={reportType} utils={utils} reportData={reportData} sessionData={sessionData} />
                </Route>
                <Route path={`${path}/assistant`}>
                    <AssistantListFormEngine strings={strings} config={config} reportType={reportType} utils={utils} reportData={reportData} sessionData={sessionData} />
                </Route>
                <Route path={`${path}/product/:productId`}>
                    <ProductFormEngine strings={strings} config={config} reportType={reportType} utils={utils} reportData={reportData} sessionData={sessionData} />
                </Route>
                <Route path={`${path}`}>
                    <MainFormEngine strings={strings} config={config} reportType={reportType} utils={utils} reportData={reportData} sessionData={sessionData} setReportData={setReportData} />
                </Route>
            </Switch>
        </div>
    );
};

export default FormRoutes;