import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import UploadImg from "../../assets/images/ic_upload.png";
import EditIcon from "../../assets/images/edit-icon-white.svg";
import DeleteIcon from "../../assets/images/delete-icon-white.svg";
import BackendAccessor from "../../assets/BackendAccessor";
import DownloadingPopup from "./DownloadingPopup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createVimeoFolder, uploadVideoToVimeo, moveVideoToFolder, getVideoName } from "../../assets/utils/VimeoUtils";
import Firebase from "../../assets/Firebase";
import ReactPlayer from "react-player";

const VideoUploader = ({ strings, config, videoId, videoUrl, upload, onUrlChange, onIdChange, onMetadataChange, onMethodsLoaded,
    maxSizeMB = "600", width = "100%", height = "20vh" }) => {

    let f = new Firebase();
    let availableTimeout;

    const [id, setId] = useState(f.createUid());
    const [cursonOn, setCursonOn] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [loadingText, setLoadingText] = useState(null);
    const [status, setStatus] = useState(null);
    const [url, setUrl] = useState(videoUrl);
    const [retryActive, setRetryActive] = useState(false);

    useEffect(() => {
        if (typeof onMethodsLoaded === 'function') {
            onMethodsLoaded({
                upload: openSelectFileDialog,
                delete: deleteVideo
            });
        }

        return () => {
            if (availableTimeout !== null) {
                setRetryActive(false);
                clearTimeout(availableTimeout);
            }
        }
    }, []);

    useEffect(() => {
        if (availableTimeout !== null) {
            setRetryActive(false);
            clearTimeout(availableTimeout);
        }

        setUrl(null);
        setStatus(null);

        if (typeof onMetadataChange === 'function') {
            onMetadataChange({ name: null, status: null, duration: null, link: null });
        }

        getMetadataFromVideo();
    }, [videoId]);

    useEffect(() => {
        if (url !== videoUrl && typeof onUrlChange === 'function') {
            onUrlChange(url);
        }
    }, [url]);

    const getMetadataFromVideo = () => {
        if (videoId !== null && videoId !== undefined && videoId !== "null" && videoId !== "undefined") {
            getVideoName(videoId,
                (name, status, duration, link) => {
                    setStatus(status);
                    setUrl(link);

                    if (typeof onMetadataChange === 'function') {
                        onMetadataChange({ name: name, status: status, duration: duration, link: link });
                    }

                    if (status !== "available") {
                        setRetryActive(true);
                        availableTimeout = setTimeout(() => {
                            if (retryActive) getMetadataFromVideo();
                        }, 5000);
                    }
                },
                onError
            );
        }
    }

    const uploadVideo = (file, vimeoFolderId) => {
        uploadVideoToVimeo(
            file,
            (videoId) => {
                moveVideoToFolder(videoId, vimeoFolderId, onSuccess, onError);
            },
            onError
        );
    }

    const preUploadVideo = (file) => {
        setLoadingText(strings.uploadingVideo);

        let size = file.size / 1024 / 1024; //Mb
        console.log("Video Size: " + size + "MB (Max allowed " + maxSizeMB + "MB)");

        if (size > maxSizeMB) {
            config.popupAlert(strings.error, strings.errorVideoSize.replace("{size}", maxSizeMB));
            setLoadingText(null);
            return;
        }

        let vimeoFolderId = config?.cellar?.vimeoFolder;

        if (vimeoFolderId === null || vimeoFolderId === undefined) {
            createVimeoFolder(
                config?.user?.uid,
                (newVimeoFolderId) => {
                    vimeoFolderId = newVimeoFolderId;
                    f.updateCeller({ vimeoFolder: newVimeoFolderId }, () => {
                        uploadVideo(file, vimeoFolderId);
                    });
                },
                onError
            );
        } else {
            uploadVideo(file, vimeoFolderId);
        }
    };

    const deleteVideo = () => {
        config.popupAlert(strings.delete, strings.deleteConfirm, null, true, () => {
            setStatus(null);
            setUrl(null);

            if (typeof onUrlChange === 'function') {
                onUrlChange(null);
            }
            if (typeof onIdChange === 'function') {
                onIdChange(null);
            }
        });
    }

    const onSelectFile = (input) => {
        let file = input?.target?.files?.[0];

        if (file !== undefined && file !== null) {
            if (upload) {
                preUploadVideo(file);
            } else {
                // Load preview in local
            }
        }
    }

    const openSelectFileDialog = () => {
        document.getElementById("VideoInput-" + id).click();
    }

    const onSuccess = (id) => {
        setLoadingText(null);
        onIdChange(id);
        config.popupAlert(strings.popupOK, strings.popupvideoOK);
    };

    const onError = (error) => {
        setLoadingText(null);
        console.error("Error uploading video", error);

        let errorMsg = strings.errorUploadingVideo;
        if (typeof error === "object" && error !== null) {
            if (error.error_code && error.error_code === 4101) {
                errorMsg = strings.errorVimeoFee;
            }
        }

        config.popupAlert(strings.error, errorMsg);
    };

    const Wrapper = {
        height: height,
        width: width,
        textAlign: "center",
        backgroundColor: "#cbcbcb",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
    };

    const VideoBackground = {
        ...Wrapper,
        backgroundColor: "black",
        position: "relative",
        width: "100%",
        height: "100%",
    };

    const FloatingIconsWrapper = {
        ...Wrapper,
        backgroundColor: "rgb(0 0 0 / 75%)",
        width: "25%",
        height: "100%",
        position: "absolute",
        right: 0,
    }

    const ImageUpload = {
        maxHeight: "40%",
        maxWidth: "90%",
        cursor: "pointer",
        width: "auto",
    };

    const Icon = {
        width: "60%",
        cursor: "pointer",
        margin: "1%",
    };

    const ImageInput = styled.input`
        display: none;
    `;

    return (
        <div style={Wrapper}
            onMouseEnter={() => setCursonOn(true)}
            onMouseLeave={() => setCursonOn(false)}>

            {url !== null && url !== undefined ? (
                <div style={VideoBackground}>
                    {status === "available" || !upload ? (
                        <>
                            <ReactPlayer height="100%" width="100%" url={url} controls={true}
                                onPlay={() => setVideoPlaying(true)}
                                onPause={() => setVideoPlaying(false)} />

                            {!videoPlaying || (videoPlaying && cursonOn) ? (
                                <div style={FloatingIconsWrapper}>
                                    <img src={EditIcon} style={Icon} onClick={openSelectFileDialog} />
                                    <img src={DeleteIcon} style={Icon} onClick={deleteVideo} />
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <CircularProgress style={{ color: "white" }} />
                    )}
                </div>
            ) : (
                <img src={UploadImg} style={ImageUpload} onClick={openSelectFileDialog} />
            )}
            <ImageInput type="file" id={"VideoInput-" + id} accept="video/*" onChange={onSelectFile} />
            {loadingText != null ? <DownloadingPopup headerText={loadingText} /> : <></>}
        </div>
    );
};
export default VideoUploader;
