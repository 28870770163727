import React, { useState, useEffect, useRef } from "react";
import FormComponent from "./FormComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import Firebase from "../../../../../../assets/Firebase";

import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";
import { isNullMap, isNullSelecction, isNullString } from "../../../../../../assets/utils/FormsUtils";

const FormEngine = ({ strings, config }) => {
  let { id } = useParams();
  let ba = new BackendAccessor();
  let history = useHistory();

  const [assessmentSheet, setAssessmentSheet] = useState(null);
  const [availableProducts, setAvailableProducts] = useState(null);
  const [loadingText, setLoadingText] = useState(null);

  const loadAssessmentSheet = () => {
    ba.getPrivateAssessmentSheet(
      id,
      (assessmentSheet) => {
        setAssessmentSheet({
          ...assessmentSheet,
          id: id,
        });
      },
      (error) => {
        console.error("Error loading assessment sheet: ", error);
        cancelAction();
      }
    );
  };

  const createAssessmentSheet = () => {
    console.log("Local assessment sheet created");
    setAssessmentSheet({});
  };

  const loadAvaliableProducts = () => {
    ba.getPublicProductsTypes(
      (prodcutTypes) => {
        setAvailableProducts(prodcutTypes);
      },
      (error) => {
        console.error("Error loading products types: ", error);
        cancelAction();
      }
    );
  }

  useEffect(() => {
    if (assessmentSheet == null) {
      if (id == null) {
        createAssessmentSheet();
      } else {
        loadAssessmentSheet();
      }
    }
    if (availableProducts == null) {
      loadAvaliableProducts();
    }
  }, [strings, id]);

  const checkData = () => {
    let errorFound = false;
    setLoadingText(strings.saving);

    if (isNullMap(assessmentSheet.name)) {
      error(strings.name + strings.cantBeNull);
      errorFound = true;
    }

    if (isNullSelecction(assessmentSheet.product)) {
      error(strings.product + strings.cantBeNull);
      errorFound = true;
    }

    if (isNullSelecction(assessmentSheet.type)) {
      errorFound = true;
      error(strings.type + strings.cantBeNull);
    }

    // --- Parameters ---
    if (assessmentSheet?.parameters && assessmentSheet?.parameters.length > 0) {
      for (let parIndex = 0; parIndex < assessmentSheet.parameters.length; parIndex++) {
        let parameter = assessmentSheet.parameters[parIndex] || {};
        parameter.order = (parIndex + 1);

        if (isNullMap(parameter.name)) {
          error(strings.containsComponentWithout + strings.name);
          errorFound = true;
        }

        if (isNullSelecction(parameter.type)) {
          error(strings.containsComponentWithout + strings.type);
          errorFound = true;
        }

        // --- Options ---
        let optionErrorFound = checkSuboptions(parameter.type, parameter.options);
        if (optionErrorFound) errorFound = true;
      }
    }

    // --- Categories ---
    if (assessmentSheet?.categories && assessmentSheet?.categories.length > 0) {
      for (let catIndex = 0; catIndex < assessmentSheet.categories.length; catIndex++) {

        let category = assessmentSheet.categories[catIndex];
        if (category.id == null) category.id = ba.createUid();
        category.order = catIndex;

        if (isNullMap(category.name)) {
          error(strings.name + strings.of + strings.category + " " + (catIndex + 1) + strings.cantBeNull);
          errorFound = true;
        }

        if (isNullString(category.image)) {
          error(strings.image + strings.of + strings.category + " " + (catIndex + 1) + strings.cantBeNull);
          errorFound = true;
        }

        // --- Components ---
        if (category?.components && category?.components?.length > 0) {
          for (let compIndex = 0; compIndex < category.components.length; compIndex++) {

            let component = category.components[compIndex] || {};
            if (component?.id == null) component.id = ba.createUid();
            component.order = compIndex;

            if (isNullMap(component.name)) {
              error(strings.category + " " + (catIndex + 1) + strings.containsComponentWithout + strings.name);
              errorFound = true;
            }

            if (isNullSelecction(component.type)) {
              error(strings.category + " " + (catIndex + 1) + strings.containsComponentWithout + strings.type);
              errorFound = true;
            }

            // --- Options ---
            let optionErrorFound = checkSuboptions(component.type, component.options, catIndex);
            if (optionErrorFound) errorFound = true;
          }
        }
      }
    }

    if (!errorFound) {
      updloadAssessmentSheet();
    }
  }

  const checkSuboptions = (compType, options, catIndex) => {
    if (compType === "FACES") {
      return false;
    }
    if (options != null && options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id == null) options[i].id = ba.createUid();
        options[i].order = i;

        if (isNullMap(options[i].name)) {
          if (catIndex != null) {
            error(strings.category + " " + (catIndex + 1) + strings.containsComponentWithout + strings.options);
          } else {
            error(strings.containsComponentWithout + strings.options);
          }
          return true;
        }

        if (compType === "TREE") {
          let suboptionsCheck = checkSuboptions(options[i].options, catIndex);
          if (suboptionsCheck) return true;
        }
      }
    }
    return false;
  }

  const updloadAssessmentSheet = () => {
    if (id == null) {
      ba.createAssessmentSheet(assessmentSheet, success, error);
    } else {
      ba.updateAssessmentSheet(id, assessmentSheet, success, error);
    }
  };

  const success = () => {
    setLoadingText(null);
    config.update("modificated", false);
    config.popupAlert(strings.success, strings.succesMsg, () => {
      history.replace("/dashboard/assessmentSheets/list");
    });
  };

  const error = (error) => {
    setLoadingText(null);
    console.error("Error saving assessment sheet: ", error);

    if (typeof error === 'string' || error instanceof String) {
      config.popupAlert(strings.error, error);
    } else {
      config.popupAlert(strings.error, strings.otherError);
    }
  };

  const cancelAction = () => {
    config.isModificated(() => {
      history.replace("/dashboard/assessmentSheets/list");
    });
  };

  const setAssessmentSheetFiltered = (input) => {
    if (!config.modificated) {
      config.update("modificated", true);
    }
    setAssessmentSheet(input);
  };

  return (
    <>
      {assessmentSheet != null && availableProducts != null ? (
        <FormComponent
          strings={strings}
          config={config}
          availableProducts={availableProducts}
          assessmentSheet={assessmentSheet}
          setAssessmentSheet={setAssessmentSheetFiltered}
          confirmAction={checkData}
          cancelAction={cancelAction}
        />
      ) : (
        <LoadingScreen />
      )}
      {loadingText != null ? <DownloadingPopup strings={strings} headerText={loadingText} /> : <></>}
    </>
  );
};

export default FormEngine;