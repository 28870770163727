import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Colors from "../../../../../../../assets/Colors.json";

const StepTopBar = ({ strings, step }) => {
  const totalSteps = 3;

  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1.6vh 5vh;
  `;

  const StepperStyle = {
    height: "2vh",
    padding: "0",
    backgroundColor: "transparent",
  };

  const muiTheme = createMuiTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          color: "rgb(124,124,124)",
          textColor: "black",
          "&$active": {
            color: Colors.lightTheme.accentColor1,
          },
          "&$completed": {
            color: Colors.lightTheme.accentColor1,
          },
        },
      },
    },
  });

  return (
    <Title>
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ width: "70%" }}>{strings.changetitle}</td>
          <td>
            <MuiThemeProvider theme={muiTheme}>
              <Stepper style={StepperStyle} activeStep={step - 1}>
                {Array(totalSteps)
                  .fill(1)
                  .map((el, i) => (
                    <Step key={i + 1}>
                      <StepLabel />
                    </Step>
                  ))}
              </Stepper>
            </MuiThemeProvider>
          </td>
        </tr>
      </table>
    </Title>
  );
};
export default StepTopBar;
