import React from "react";
import Table from "../../../../../shared/Table";
import Button from "../../../../../shared/Button";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";

const ListComponent = ({ strings, ready, instructors, newInstructor, editInstructor, deleteInstructor }) => {
  const Wrapper = {
    padding: "2vh 5vh",
  };
  const Container = {
    width: "100%",
  };
  const Title = {
    backgroundColor: "black",
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: "2vh 5vh",
  };
  let actions = [
    {
      title: (
        <Tooltip title={strings.modifyInstructor} aria-label="add">
          <img src={EditIcon} />
        </Tooltip>
      ),
      action: editInstructor,
    },
    {
      title: (
        <Tooltip title={strings.deleteInstructor} aria-label="add">
          <img src={DeleteIcon} />
        </Tooltip>
      ),
      action: deleteInstructor,
    },
  ];
  return (
    <div style={Container}>
      <div style={Title}>{strings.instructorsList}</div>

      <div style={Wrapper}>
        <Table header={strings.InstructorsTable} data={instructors} ready={ready} noElementsText={strings.noInstructors} actions={actions} />
        <Button value={strings.addInstructor} action={newInstructor} style={{ margin: "2vh 0" }} />
      </div>
    </div>
  );
};

export default ListComponent;
