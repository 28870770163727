import React, { useState, useEffect } from "react";
import Button from "../../../../../shared/Button";
import CrossIconGrey from "../../../../../../assets/images/cross-icon-grey.svg";
import ComponentsWrapperForm from "./ComponentsWrapperForm";
import styled from "@emotion/styled";
import Firebase from "../../../../../../assets/Firebase";

const ParametersWrapperForm = ({ strings, config, assessmentSheet, setAssessmentSheet }) => {

  const Wrapper = {
    paddingBottom: "1rem",
    borderBottom: "0.1vh solid grey"
  };

  const Header = {
    margin: "2vh 0",
    width: "100%",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
  };

  const updateAssessmentSheet = (key, value) => {
    setAssessmentSheet((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div style={Wrapper}>
      <div>
        <h3 style={Header}>{strings.parametersHeader}</h3>
      </div>
      <ComponentsWrapperForm
        strings={strings}
        config={config}
        object={assessmentSheet}
        parameter={"parameters"}
        updateObject={updateAssessmentSheet}
        groupedByCategories={false}
      />
    </div>
  );

  return <div>Parameters</div>
};
export default ParametersWrapperForm;
