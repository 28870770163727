import React from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../../shared/TextInput";
import ComponentsWrapperForm from "./ComponentsWrapperForm";
import ImagePicker from "../../../../../shared/ImagePicker";

const CategoryForm = ({ strings, config, categoryId, category, updateCategory }) => {
  const Table = {
    borderBottom: "0.1vh solid grey",
    width: "100%",
    paddingBottom: "1rem",
    marginBottom: "2vh",
    tableLayout: "fixed",
    ["td"]: {
      width: "25%",
    },
  };

  const Tag = styled.div`
    margin: 1vh;
    font-size: 2.5vh;
  `;

  return (
    <div>
      <table style={Table}>
        <tbody>
          <tr>
            <td colspan="2">
              <Tag>
                <b>{strings.name}*</b>
              </Tag>
              <TextInput
                defaultValue={category?.name?.[config.lang] || ""}
                id={"name"}
                width="50%"
                maxLength="30"
                onChange={(e) => {
                  updateCategory("name", {
                    CA: e.target.value,
                    ES: e.target.value,
                    EN: e.target.value,
                  });
                }}
              />
            </td>
            <td>
              <Tag>
                <b>{strings.image}*</b>
              </Tag>
              <ImagePicker
                id={"image-" + categoryId}
                src={category?.image}
                upload={true}
                cloudPath={"assessmentSheets/icons"}
                onSelect={(id, image) => {
                  updateCategory("image", image);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <ComponentsWrapperForm
        strings={strings}
        config={config}
        parameter={"components"}
        object={category}
        updateObject={updateCategory}
      />
    </div>
  );
};
export default CategoryForm;
