import { isNullString, isNullSelecction, isNullMap, isInvalidBoolean } from "../../../../../../assets/utils/FormsUtils";

export const validatTasting = (strings, tasting) => {
    if (tasting == null) return strings.tasting + strings.cantBeNull;

    // Tasting atributes
    if (isInvalidBoolean(tasting.offlineEnabled)) tasting.offlineEnabled = false;
    if (isNullSelecction(tasting.type)) return strings.tastingType + strings.cantBeNull;
    if (isNullString(tasting.name)) return '"' + strings.name + '" ' + strings.cantBeNull;
    if (isNullString(tasting.description)) return '"' + strings.description + '" ' + strings.cantBeNull;
    if (isNullString(tasting.duration)) return '"' + strings.duration + '" ' + strings.cantBeNull;
    if (isNullString(tasting.template)) return '"' + strings.template + '" ' + strings.cantBeNull;

    // Tasting videos 
    let videoError;

    videoError = processVideoObject("podcastPresentationVideo", tasting);
    if (videoError != null) return videoError;

    videoError = processVideoObject("podcastGoodbyeVideo", tasting);
    if (videoError != null) return videoError;

    // Product references
    if (tasting?.productReferences == null || Object.keys(tasting?.productReferences).length == 0) {
        return strings.errorEmptyTasting;
    }

    let productOrder = 1;
    for (let productRefKey in tasting?.productReferences) {
        let productReference = tasting?.productReferences[productRefKey];

        if (productReference.product == null || productReference.product.reference == null) {
            return `${strings.ref} ${strings.fromProduct} ${productOrder} ` + strings.cantBeNull;
        }

        if (Object.values(tasting?.productReferences).some((e) => e?.product?.reference === productReference.product.reference && e != productReference)) {
            return strings.errorRepeatedProduct.replace("{num}", productOrder);
        }

        if (isNullSelecction(productReference.assessmentSheetPath)) {
            return `${strings.type} ${strings.fromProduct} ${productOrder} ` + strings.cantBeNull;
        }

        if (productReference.product == null || productReference.product.reference == null) {
            return `${strings.ref} ${strings.fromProduct} ${productOrder} ` + strings.cantBeNull;
        }

        // Product videos
        let productVideoError;

        productVideoError = processVideoObject("podcastMainVideo", productReference);
        if (productVideoError != null) return productVideoError;

        productVideoError = processVideoObject("podcastViewVideo", productReference);
        if (productVideoError != null) return productVideoError;

        productVideoError = processVideoObject("podcastSmellVideo", productReference);
        if (productVideoError != null) return productVideoError;

        productVideoError = processVideoObject("podcastTasteVideo", productReference);
        if (productVideoError != null) return productVideoError;

        productVideoError = processVideoObject("podcastFinalVideo", productReference);
        if (productVideoError != null) return productVideoError;


        // Product quiz
        let quizOrder = 1;

        for (let quizKey in productReference?.quizQuestions) {
            let question = productReference?.quizQuestions[quizKey];
            let options = question?.options;

            if (isNullMap(question?.question)) {
                return `${strings.question} ${strings.fromQuiz} ${quizOrder} ${strings.fromProduct} ${productOrder} ` + strings.cantBeNull;
            }

            for (let i = 1; i <= 4; i++) {
                if (isNullMap(options?.["answer" + i])) {
                    return `${strings.answer} ${i} ${strings.fromQuiz} ${quizOrder} ${strings.fromProduct} ${productOrder} ` + strings.cantBeNull;
                }
            }

            if (question?.correct == null || question?.question.lentgh === 0) {
                return `${strings.correctAnswer} ${strings.fromQuiz} ${quizOrder} ${strings.fromProduct} ${productOrder} ` + strings.cantBeNull;
            }

            question.order = quizOrder;
            quizOrder++;;
        }

        productReference.order = productOrder;
        productOrder++;
    }

    // Survey questions
    let surveyIndex = 0;
    for (let surKey in tasting?.surveyQuestions) {
        let surveyQuestion = tasting?.surveyQuestions?.[surKey];
        let surveyQuestionOptions = surveyQuestion?.options;
        surveyQuestion.deleted = false;
        surveyQuestion.order = surveyIndex;

        if (isNullMap(surveyQuestion?.question)) {
            return '"' + "Pregunta" + '" en "Preguntas iniciales" ' + strings.cantBeNull;
        }

        if (isNullSelecction(surveyQuestion?.type)) {
            return '"' + strings.typeQuetions + '"' + strings.errorInitialQuestions + strings.cantBeNull;
        }

        let surveyOptionIndex = 1;
        for (let optionKey in surveyQuestionOptions) {
            let surveyOption = surveyQuestionOptions?.[optionKey];

            if (isNullMap(surveyOption)) {
                return '"' + strings.option + surveyOptionIndex + strings.errorInitialQuestions;
            }
            surveyOption.order = surveyOptionIndex;
            surveyOptionIndex++;
        }

        if (surveyQuestionOptions == null || Object.entries(surveyQuestionOptions).length == 0) {
            return strings.errorInitialQuestionsIndex1 + surveyIndex + strings.errorInitialQuestionsIndex2;
        }

        surveyIndex++;
    }
    return null;
};

export const processVideoObject = (videoName, object) => {
    let videoData = object?.[videoName];

    if (videoData == null) {
        return null;
    }

    if (videoData.platform == "generated") {
        if (isNullString(videoData.text)) {
            return "El texto de un vídeo generado no puede estar vacío";
        }

        if (isNullSelecction(videoData.avatarPath)) {
            return "El avatar de un vídeo generado no puede estar vacío"
        }

        if (isNullSelecction(videoData.language)) {
            return "El idioma de un vídeo generado no puede estar vacío";
        }
    }
};