import { isNullString, isNullSelecction, isNullMap, isInvalidBoolean, isInvalidFloat } from "../../../../../../assets/utils/FormsUtils";

export const validateProduct = (strings, product) => {
    if (product == null) return strings.product + strings.cantBeNull;

    // Fixed parameters
    if (isNullString(product.name)) return strings.name + ' ' + strings.cantBeNull;
    if (isNullSelecction(product.typePath)) return strings.type + ' ' + strings.cantBeNull;
    if (isNullSelecction(product.subtypePath)) return strings.subtype + ' ' + strings.cantBeNull;
    if (isNullString(product.image)) return strings.image + ' ' + strings.cantBeNull;

    if (!isNullString(product.price) && isInvalidFloat(product.price)) {
        return strings.price + ' ' + strings.mustBeNumeric;
    }

    if (!isNullString(product.discountedPrice) && isInvalidFloat(product.discountedPrice)) {
        return strings.discountedPrice + ' ' + strings.mustBeNumeric;
    }

    // Dinamic parameter
    if (product?.properties) {
        let i = 0;
        for (let propKey in product.properties) {
            let properties = product.properties;
            let property = properties[propKey];
            property.order = i;
            if (isNullMap(property?.name)) {
                delete properties[propKey];
            } else if (isNullMap(property?.value)) {
                property.value = {
                    "ES": "-",
                    "CA": "-",
                    "EN": "-"
                }
                //return strings.propertiesMustBeCompleted;
            }
            i++;
        }
    }

    return null;
};