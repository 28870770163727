import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "../../../../../../shared/Button";
import SecondaryButton from "../../../../../../shared/SecondaryButton";
import ReactPlayer from "react-player";

export default function YoutubeVideoForm({ strings, object, activeVideo, updateState }) {
  const [videoName, setVideoName] = useState(null);

  useEffect(() => {
    if (object != null && object[activeVideo] != null) {
      let id = object[activeVideo].id;
      if (id) {
        getVideoName(id);
      } else {
        setVideoName(null);
      }
    }
  }, [object, activeVideo]);

  const updateVideo = (activeVideo, id) => {
    let obj = {
      platform: "youtube",
      id: id,
    };
    updateState(activeVideo, obj);
  };

  const deleteVideo = () => {
    let obj = {
      platform: "youtube",
      id: null,
    };
    updateState(activeVideo, obj);
  };

  const getVideoName = (id) => {
    setVideoName(id);
    fetch("https://www.googleapis.com/youtube/v3/videos?part=snippet&id=" + id + "&key=" + "AIzaSyBJf9UbJRLxV6snFV9lKBpHrc8et5lNo0U")
      .then((res) => res.json())
      .then(
        (result) => {
          setVideoName(result.items[0].snippet.localized.title);
        },
        (error) => {
          setVideoName(null);
        }
      );
  };

  const getVideoId = (rawurl) => {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = rawurl.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };

  const validateVideo = () => {
    let url = document.getElementById("videoUrl").value;
    let id = getVideoId(url);
    if (!id) {
      alert("Incorrect url");
    } else {
      updateVideo(activeVideo, id);
    }
    document.getElementById("videoUrl").value = "";
  };

  const videoId = object ? (object[activeVideo] ? object[activeVideo].id : null) : null;

  const Tag = styled.div`
    padding: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 45vh;
  `;

  const VideoInput = styled.input`
    border-radius: 0.3vh;
    border: solid 0.2vh #d8dde1;
    color: black;
    background-color: white;
    padding: 1.5vh;
    resize: vertical;
    fontsize: 2vh;
    width: 85%;
    type: text;
  `;

  const VideoContainer = {
    height: "20vh",
    width: "35vh",
    backgroundColor: "black",
    textAlign: "center",
  };

  const GrayHint = {
    borderRadius: "0.3vh",
    border: "2px solid rgb(216, 221, 225)",
    color: "black",
    backgroundColor: "rgb(216, 221, 225)",
    textAlign: "center",
    padding: "1vh",
    width: "95%",
  };

  return (
    <table style={{ width: "100%", textAlign: "left" }}>
      <tbody>
        <tr>
          <td colSpan="2" style={{ paddingBottom: "1vh" }}>
            <div style={GrayHint}>{strings.infoYoutube}</div>
          </td>
        </tr>
        <tr>
          <td style={{ width: "30%" }}>
            <div style={VideoContainer}>
              {videoId ? <ReactPlayer height="20vh" width="35vh" url={"https://www.youtube.com/watch?v=" + videoId} controls={true} /> : <></>}
            </div>
          </td>
          <td style={{ padding: "0vw 1vw" }}>
            <Tag>
              <b>{strings.tittleVideo} </b>
              {videoName ? videoName : "-"}
            </Tag>
            <Tag>
              <b>{strings.idVideo} </b>
              {videoId ? videoId : "-"}
            </Tag>

            {/* <Tag>
              <b>Enlace de Youtube: </b>
            </Tag>
            <table style={{ width: "100%" }}>
              <tr>
                <td>
                  <VideoInput id="videoUrl" defaultValue={videoId ? "https://youtu.be/" + videoId : ""} />
                </td>
                <td>
                  <Button value="Confirmar" action={validateVideo} />
                  <SecondaryButton style={{ marginLeft: "1vw" }} value="Borrar" action={deleteVideo} />
                </td>
              </tr>
            </table>*/}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
