import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import Table from "../../../../../shared/Table";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import AvatarExample from "../../../../../../assets/images/avatar_example.png";
import Tooltip from "@material-ui/core/Tooltip";

const ListComponent = ({ strings, config, avatars, newAvatar, editAvatar, deleteAvatar, ready }) => {
  const Container = styled.div`
    width: 100%;
  `;

  const Row = styled.div`
    display: flex;
  `;

  const Wrapper = styled.div`
    flex-grow: 1;
    padding: 1rem 2rem;
    max-width: 40%;
    > * {
      margin: 0.5rem 0;
    }
  `;

  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const Hint = styled.div`
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    max-width: 40%;
    margin: 1rem auto;
  `;

  const HintHeader = styled.div`
    color: #53728f;
    font-size: 2.5vh;
    font-weight: bold;
  `;

  const HintImage = styled.img`
    max-width: 80%;
    margin: 2vh 10%;
  `;

  const HintBody = styled.div`
    font-size: 2vh;
    text-align: justify;
  `;

  const Icon = styled.img``;

  let actions = [
    {
      title: (
        <Tooltip title={strings.modifyAvatar} aria-label="add">
          <Icon src={EditIcon} />
        </Tooltip>
      ),
      action: editAvatar,
    },
    {
      title: (
        <Tooltip title={strings.deleteAvatar} aria-label="add">
          <Icon src={DeleteIcon} />
        </Tooltip>
      ),
      action: deleteAvatar,
    },
  ];

  return (
    <Container>
      <Title>{strings.listTittle}</Title>
      <Row>
        <Wrapper>
          <Table header={strings.AvatarsTable} noElementsText={strings.noneList} data={avatars} actions={actions} ready={ready} />
          <Button value={strings.addAvatar} action={newAvatar} />
        </Wrapper>
        <Hint>
          <HintHeader>{strings.hintHeader}</HintHeader>
          <HintImage src={AvatarExample} />
          <HintBody>{strings.hintText}</HintBody>
        </Hint>
      </Row>
    </Container>
  );
};

export default ListComponent;
