import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from "react-router-dom";
import ListEngine from "./List/ListEngine";
import FormEngine from "./Form/FormEngine";

const ProductsRoutes = ({ strings, config }) => {
  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
  };

  let { path } = useRouteMatch();
  return (
    <div style={Wrapper}>
      <Switch>
        <Route path={`${path}/list`}>
          <ListEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/new`}>
          <FormEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <FormEngine strings={strings} config={config} />
        </Route>
      </Switch>
    </div>
  );
};

export default ProductsRoutes;
