import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Firebase from "../../../../../../assets/Firebase";
import PassworForm from "./PasswordForm";
import { isNullMap } from "../../../../../../assets/utils/FormsUtils";

const PasswordEngine = ({ strings, config }) => {
  let f = new Firebase();
  let history = useHistory();

  const [passwordData, setPasswordData] = useState({
    actualPw: null,
    newPw: null,
    confirmationPw: null,
  });

  const setData = (id, value) => {
    setPasswordData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const changePw = () => {
    if (isNullMap(passwordData)) {
      config.popupAlert(strings.error, strings.emptyFields);
      return;
    }

    if (passwordData.newPw != passwordData.confirmationPw) {
      config.popupAlert(strings.error, strings.diferentsPw);
      return;
    }

    if (passwordData.newPw.length < 6) {
      config.popupAlert(strings.error, strings.shortPw);
      return;
    }

    f.reauthenticateUser(
      passwordData.actualPw,
      () => {
        f.changeUserPassword(passwordData.newPw, onSuccess, (error) => {
          config.popupAlert(strings.error, strings.otherErrors);
          console.error(error);
        });
      },
      (error) => {
        config.popupAlert(strings.error, strings.wrongCurrentPw);
        console.error(error);
      }
    );
  };

  const onSuccess = () => {
    config.popupAlert(strings.succes, strings.succesMsg, () => {
      history.push("/dashboard/cellar/edit");
    });
  };

  const cancel = () => {
    history.push("/dashboard/cellar/edit");
  };

  return <PassworForm strings={strings} passwordData={passwordData} setData={setData} changePw={changePw} cancel={cancel} />;
};
export default PasswordEngine;
