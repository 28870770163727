import React, { useState, useEffect, useRef } from "react";
import FormRoutes from "./FormRoutes";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import Colors from "../../../../../../assets/Colors.json";
import Firebase from "../../../../../../assets/Firebase";
import GrapDataModel from "./GraphDataModel.json";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { isNullOrUndefined, isNullString } from "../../../../../../assets/utils/FormsUtils";
import { LogarithmicScale } from "chart.js";
import LoadingAnimation from "../../../../../shared/LoadingAnimation";

const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1vh 5vh;
    display: flex;
`;

const TitleWrapper = styled.div` 
    display: flex;
    flex-direction: row;
    margin-left: auto;
`;

const TitleTag = styled.div`
    text-transform: none;
    margin-right: 1vw;
    width: fit-content;
    color: white;
    padding: 1vh 1.5vh;
    font-weight: normal;
`;

const Option = styled.div`
    margin-left: 1vw;
    margin-right: 1vw;
    width: fit-content;
    cursor: pointer;
    color: white;
    padding: 1vh;
    background-color: ${Colors.lightTheme.accentColor1};
    border-radius: 0.5vh;
    font-weight: normal;
   
    &:hover {
        background-color: rgb(174 28 56 / 90%);
        font-weight: bold;
    }
`;


const FormEngine = ({ strings, config }) => {
    let { id, type } = useParams();
    let f = new Firebase();
    let ba = new BackendAccessor();
    let history = useHistory();

    const [generating, setGenerating] = useState(true);
    const [reportData, setReportData] = useState(null);
    const [sessionData, setSessionData] = useState(null);

    const generateNewReportData = (id) => {
        let reportData = {
            publicCode: id,
            id: id,
            name: id
        };

        ba.generateReportBody(reportData, (report) => {
            console.log("Session body data: ", report?.session);
            setSessionData(report?.session);
            setReportData(reportData);
            generateAssistantsPageData(reportData, 0);
        }, (error) => {
            console.error("Error loading session data: ", error);
            config.popupAlert(strings.error, strings.errorLoadingReports, () => {
                history.replace("/dashboard/home");
            });
        });
    };

    const generateAssistantsPageData = (reportData, pageNum) => {
        ba.generateReportAssistantsPage(reportData, pageNum, (assistants) => {
            console.log("Assistants page " + pageNum + " data: ", assistants);
            setSessionData((prevState) => ({
                ...prevState,
                assistants: {
                    ...prevState?.assistants,
                    ...assistants
                }
            }));

            if (Object.keys(assistants).length > 0) {
                generateAssistantsPageData(reportData, pageNum + 1);
            } else {
                setGenerating(false);
            }
        }, (error) => {
            console.error("Error generating assistants page: ", error);
            setGenerating(false);
        });
    }

    const loadExisitngReportData = (id) => {
        ba.getReportById(
            id,
            (report) => {
                console.log("Report data: ", report);
                setSessionData(report?.session);
                setReportData({
                    id: id,
                    name: report?.name,
                    dateFilterActive: report?.dateFilterActive,
                    fromDate: report?.fromDate,
                    toDate: report?.toDate,
                });
                setGenerating(false);
            },
            (error) => {
                console.error("Error loading report data: ", error);
                config.popupAlert(strings.error, strings.errorLoadingReports, () => {
                    history.replace("/dashboard/home");
                });
            }
        );
    };

    const generateGraphData = (labels, data, dataName) => {
        return {
            ...GrapDataModel,
            labels: labels,
            datasets: [
                {
                    label: dataName || "-",
                    data: data,
                    ...GrapDataModel.datasets[0],
                }
            ]
        };
    }

    const roundDecimal = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100
    }

    let getParameterValue = (assistant, productReference, parameterId) => {
        let categoryId = null;

        if (Array.isArray(parameterId)) {
            categoryId = parameterId[0];
            parameterId = parameterId[1];
        }

        if (categoryId) {
            return assistant?.assessments?.[productReference]?.categories?.[categoryId]?.[parameterId];
        } else {
            return assistant?.assessments?.[productReference]?.parameters?.[parameterId];
        }
    }

    const getParameterAvg = (productReference, parameterId) => {
        let sum = 0;
        let total = 0;

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let value = getParameterValue(assistant, productReference, parameterId);

            if (value && (typeof value === 'string' || value instanceof String)) {
                value = parseFloat(value.replace(",", "."));
            }

            if (value && !isNaN(value)) {
                sum += value;
                total++;
            }
        });

        if (total > 0) {
            let avg = sum / total;
            return roundDecimal(avg) || 0;
        } else {
            return 0;
        }
    }

    const getParameterPercent = (productReference, parameterId, optionId) => {
        let totalOfSelected = 0;
        let total = 0;

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let value = getParameterValue(assistant, productReference, parameterId);
            if (!isNullOrUndefined(value)) {
                total++;
                if (value === optionId) {
                    totalOfSelected++;
                }
            }
        });

        if (total > 0) {
            return roundDecimal((totalOfSelected / total) * 100) || 0;
        } else {
            return 0;
        }
    }

    const getParameterSums = (productReference, parameterId) => {
        let sum = {};

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let value = getParameterValue(assistant, productReference, parameterId);
            if (!isNullOrUndefined(value)) {
                if (sum?.[value]) {
                    sum[value]++;
                } else {
                    sum[value] = 1;
                }
            }
        });

        return sum;
    }

    const getCategoriesSums = (productReference, categoryId) => {
        let sum = {};

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let categoryAssessment = assistant?.assessments?.[productReference]?.categories?.[categoryId];

            Object.entries(categoryAssessment || {}).forEach(([paramId, paramValue]) => {
                if (isNullOrUndefined(sum[paramId])) sum[paramId] = {};

                if (isNullOrUndefined(sum[paramId][paramValue])) {
                    sum[paramId][paramValue] = 1;
                } else {
                    sum[paramId][paramValue]++;
                }
            });
        });

        return sum;
    }

    const getParameterTotal = (productReference, parameterId, value) => {
        let total = 0;

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let selectedValue = getParameterValue(assistant, productReference, parameterId);
            if (selectedValue === value) total++;
        });

        return total;
    }

    const getUtils = () => {
        return {
            generateGraphData: generateGraphData,
            roundDecimal: roundDecimal,
            getParameterAvg: getParameterAvg,
            getParameterSums: getParameterSums,
            getCategoriesSums: getCategoriesSums,
            getParameterTotal: getParameterTotal,
            getParameterPercent: getParameterPercent,
        }
    }


    const renameReport = () => {
        config.showInputPopup(strings.saveReport, strings.insertReportName, (text) => {
            if (isNullString(text)) {
                config.popupAlert(strings.error, strings.errorName);
            } else {
                config.closeInputPopup();
                config.showDownloadingPopup(strings.saving);

                ba.updateReport(reportData?.id, { name: text }, (reponse) => {
                    config.hideDownloadingPopup();
                    config.popupAlert(strings.success, strings.successMsg);
                    console.info("Report updated", reponse);

                    setReportData({
                        ...reportData,
                        name: text,
                    });
                }, (error) => {
                    config.hideDownloadingPopup();
                    config.popupAlert(strings.error, strings.errorSavingReport);
                    console.error("Error updating report", error);
                });
            }
        });
    }

    const saveReport = () => {
        config.showInputPopup(strings.saveReport, strings.insertReportName, (text) => {
            if (isNullString(text)) {
                config.popupAlert(strings.error, strings.errorName);
            } else {
                config.closeInputPopup();
                config.showDownloadingPopup(strings.saving);

                let report = {
                    name: text,
                    session: sessionData,
                }

                ba.createReport(report, (reponse) => {
                    config.hideDownloadingPopup();
                    config.popupAlert(strings.success, strings.successMsg);
                    console.info("Report saved", reponse);

                    let reportId = ba.getIdFromPath(reponse?.reference);
                    history.replace("/dashboard/reports/saved/" + reportId);
                }, (error) => {
                    config.hideDownloadingPopup();
                    config.popupAlert(strings.error, strings.errorSavingReport);
                    console.error("Error saving report", error);
                }
                );
            }
        });
    }

    const formatDate = (date) => {
        if (typeof date === "string")
            date = new Date(date);

        if (date instanceof Date) {
            var d = date.getDate();
            var m = date.getMonth() + 1;
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
        }
        return "";
    }

    const getHeader = () => {
        let header = strings.resumes;
        if (reportData?.name) header += " " + reportData?.name;
        if (reportData?.dateFilterActive) {
            if (!isNullOrUndefined(reportData?.fromDate) && !isNullOrUndefined(reportData?.toDate)) {
                header += " (" + formatDate(reportData?.fromDate) + " - " + formatDate(reportData?.toDate) + ")";
            }
        }
        return header;
    }

    useEffect(() => {
        if (sessionData === null && id !== null) {
            if (type === "saved") {
                loadExisitngReportData(id);
            } else if (type === "generated") {
                generateNewReportData(id);
            }
        }
    }, [strings, id]);

    return (
        <>
            {sessionData != null ? (
                <div style={{ width: "100%" }}>
                    <Title>
                        <div style={{ margin: "auto 0" }}>{getHeader()}</div>

                        {generating ? (
                            <TitleWrapper>
                                <LoadingAnimation heigth={"4vh"} width={"4vh"} thickness={"0.75vh"} />
                                <TitleTag>Espere, generándose informe...</TitleTag>
                            </TitleWrapper>
                        ) : (
                            <TitleWrapper>
                                <TitleTag>Informe completo</TitleTag>
                                {type === "generated" ? (
                                    <Option onClick={saveReport}>Save report</Option>
                                ) : (
                                    <Option onClick={renameReport}>Rename report</Option>
                                )}
                            </TitleWrapper>
                        )}
                    </Title>

                    <FormRoutes
                        strings={strings}
                        config={config}
                        reportType={type}
                        utils={getUtils()}
                        reportData={reportData}
                        sessionData={sessionData}
                        setReportData={setReportData}
                    />
                </div>
            ) : (
                <LoadingScreen />
            )}
        </>
    );
};

export default FormEngine;