import React, { useState, useEffect } from "react";
import { Doughnut } from 'react-chartjs-2';
import Colors from "../../../../../../../assets/Colors.json";
import IconButton from "../../../../../../shared/IconButton";
import BackIcon from "../../../../../../../assets/images/back-arrow-icon.svg";
import styled from "@emotion/styled";

const SurveyFormComponent = ({ strings, config, sessionData, getSurveyTotal, getSurveyPercent, getCharData, goBack }) => {
    const Subheader = styled.div`
        margin: 1.5rem 0.5rem 1rem 0rem;
        font-weight: bold;
        font-size: 2.5vh;
        color: ${Colors.lightTheme.accentColor1};
        padding-bottom: 0.5rem;
        border-bottom: 1px solid gray;
    `;

    const ResultsTable = styled.table`
        border-collapse: collapse;
        table-layout: fixed;
        width: 60%;
        height: max-content;
        td {
            padding: 2vh;
        }
        th {
            padding: 2vh;
            text-align: left;
            background-color: rgba(0, 0, 0, 0.1);
        }
        tr:nth-of-type(even) {
            background-color: rgba(255, 255, 255, 0.5);
        }
        tr {
            background-color: white;
        }
    `;

    const GraphContainer = {
        width: "30%",
        minHeight: "25vh",
        padding: "2vh",
        margin: "auto",
    }

    return (
        <div style={{ width: "100%" }}>
            <div style={{ padding: "1vh 3vh" }}>
                <IconButton
                    action={goBack}
                    icon={BackIcon}
                    text={strings.returnBack}
                    style={{ margin: "1vh" }}
                    iconStyle={{ height: "3vh" }} />

                {(sessionData?.tasting?.surveyQuestions).map((surveyQuestion, index) => {
                    return (<>
                        <Subheader>{strings.question} {index + 1}: {surveyQuestion?.question?.[config.lang]}</Subheader>
                        <div style={{ display: "flex" }}>
                            <ResultsTable>
                                {Object.entries(surveyQuestion?.options).map(([optionId, option]) => {
                                    return (
                                        <tr>
                                            <th colSpan="2">{option?.[config.lang]}</th>
                                            <td>{getSurveyTotal(surveyQuestion?.reference, optionId)} respuestas</td>
                                            <td>{getSurveyPercent(surveyQuestion?.reference, optionId)} %</td>
                                        </tr>
                                    )
                                })}
                            </ResultsTable>
                            <div style={GraphContainer}>
                                <Doughnut options={{ maintainAspectRatio: false }} data={getCharData(surveyQuestion)} />
                            </div>
                        </div>
                    </>);
                })}
            </div>
        </div >
    );
};

export default SurveyFormComponent;
