import React from "react";
import ListComponent from "./ListComponent";
import Firebase from "../../../../../../assets/Firebase";

import { BrowserRouter as Router, useRouteMatch, useHistory } from "react-router-dom";

const ListEngine = ({ strings, config, instructors, setInstructors, ready }) => {
  let f = new Firebase();

  let history = useHistory();

  const newInstructor = () => {
    checkInstructorsLeft(
      () => {
        history.replace(`/dashboard/instructors/new`);
      },
      () => {
        config.popupAlert(strings.error, strings.errorMaxInstructors);
      }
    );
  };

  const editInstructor = (id) => {
    history.replace(`/dashboard/instructors/edit/${id}`);
  };

  const deleteInstructor = (id) => {
    config.popupAlert(strings.deleteInstructor + " - " + instructors[id].name, strings.deleteConfirm, null, true, () => {
      f.deleteInstructor(id, () => {
        setInstructors((prevState) => {
          const state = { ...prevState };
          delete state[id];
          return state;
        });
      });
    });
  };

  const checkInstructorsLeft = (success, error) => {
    f.loadPlan((obj) => {
      Object.entries(instructors).length < obj.maxInstructors ? success() : error();
    });
  };

  return (
    <ListComponent
      strings={strings}
      instructors={instructors}
      ready={ready}
      newInstructor={newInstructor}
      editInstructor={editInstructor}
      deleteInstructor={deleteInstructor}
    />
  );
};

export default ListEngine;
