import app, { useState } from "firebase/app";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import Config from "./Config";
import BackendAccessor from "./BackendAccessor";

const configDev = {
  apiKey: "AIzaSyCNG0HV0sEZRh47-aTUon2LcXkl1K4nI8M",
  authDomain: "upwine-dev.firebaseapp.com",
  projectId: "upwine-dev",
  storageBucket: "upwine-dev.appspot.com",
  messagingSenderId: "716041717575",
  appId: "1:716041717575:web:26132bb23f73652c87ee62",
  measurementId: "G-55ERSHVS9T"
};

const configProd = {
  apiKey: "AIzaSyAa0AVlQEmHiCb1WnylmRJUcab5GLbj5Vk",
  authDomain: "uptasting-prod.firebaseapp.com",
  projectId: "uptasting-prod",
  storageBucket: "uptasting-prod.appspot.com",
  messagingSenderId: "977171864415",
  appId: "1:977171864415:web:d863965a11d1597e64f4ea",
  measurementId: "G-TNMVQP3PZZ"
};

class Firebase {
  constructor() {
    try {
      if (typeof Firebase.instance === "object") {
        return Firebase.instance;

      } else {
        new Config();
        Firebase.instance = this;
        Firebase.instance.firebase = app.initializeApp(Config.ENV === "DEV" || Config.ENV === "LOCAL" ? configDev : configProd);

        app.analytics().setAnalyticsCollectionEnabled(true);

        this.runWhenSignUp(() => {
          app.analytics().setUserId(firebase.auth().currentUser.uid);
        });

        return this;
      }
    } catch (r) {
      console.error("Error init firebase: ", r);
    }
  }

  tryCreateUser(email, password, username, phone, country, mentorCode, onSuccess, onError) {
    const ba = new BackendAccessor();

    const createUser = () => {
      this.firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          let data = {
            uid: userCredential.user.uid,
            name: username,
            phone: phone,
            country: country,
            mentorCode: mentorCode,
          };

          ba.createUser(
            data,
            (response) => {
              console.log("Create user response", response);
              onSuccess(email, username, phone, country);
            },
            (error) => {
              onError(error.error_msg);
            }
          );
        })
        .catch(function (err) {
          onError(err.code);
        });
    };

    if (mentorCode !== null && mentorCode !== undefined && mentorCode.length > 0) {
      ba.getMentorByPublicCode(mentorCode, (success) => {
        createUser();
      }, (error) => {
        onError(error.error_msg);
      });
    } else {
      createUser();
    }
  }

  tryLogin(name, password, success, error) {
    this.firebase
      .auth()
      .signInWithEmailAndPassword(name, password)
      .then(() => {
        success();
      })
      .catch(function (err) {
        error(err);
      });
  }

  tryLoginWithCustomToken(token, success, error) {
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(success)
      .catch(error);
  }

  isEmailVerificated() {
    return firebase.auth().currentUser.emailVerified;
  }

  changeUserPassword(newPassword, success, error) {
    this.firebase.auth().currentUser.updatePassword(newPassword).then(success).catch(error);
  }

  reauthenticateUser(password, success, error) {
    let user = this.firebase.auth().currentUser;
    let credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
    user.reauthenticateWithCredential(credential).then(success).catch(error);
  }

  tryLogout(success, error) {
    this.firebase
      .auth()
      .signOut()
      .then(function () {
        success();
      })
      .catch(function (err) {
        //error(err);
      });
  }

  checkSignedUser(success, error) {
    this.firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        Firebase.user = user;
        success(user);
      } else {
        error();
      }
    });
  }

  runWhenSignUp(action) {
    this.firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        action();
      }
    });
  }

  sendEmailVerification(email, success, fail) {
    let sendEmail = this.firebase.functions().httpsCallable("emailVerificationSender");
    sendEmail({ email: email })
      .then((result) => {
        if (success) success();
      })
      .catch((error) => {
        if (fail) fail();
      });
  }

  checkEmailResetPassword(email, success, error) {
    this.firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        success();
      })
      .catch(function (e) {
        error(e.code);
      });
  }

  getCreationDate() {
    return this.firebase.auth().currentUser?.metadata.creationTime;
  }

  getTokenId(onSuccess, onError) {
    let user = this.firebase.auth().currentUser;

    if (user == null || user == undefined) {
      onError("You must be singned up");
    } else {
      user.getIdToken(true)
        .then(onSuccess)
        .catch(onError);
    }
  }

  getRefFromPath(path) {
    try {
      return this.firebase.firestore().doc(path);
    } catch (e) {
      return null;
    }
  }

  getServerTimeStamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  loadImage(ref, success) {
    this.firebase
      .storage()
      .refFromURL(ref)
      .getDownloadURL()
      .then((url) => success(url))
      .catch((error) => console.error(error));
  }

  uploadImage(image, success) {
    let ref = this.firebase.storage().ref().child(`${Firebase.user.uid}/${this.createUid()}`);
    ref.put(image).then(function (snapshot) {
      let imgRef = `gs://${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`;
      success(imgRef);
    });
  }

  loadCeller(success, uid) {
    var db = this.firebase.firestore();
    db.collection("users")
      .doc(uid ? uid : Firebase.user.uid)
      .get()
      .then((doc) => {
        success(doc);
      });
  }

  updateCeller(celler, success, uid) {
    var db = this.firebase.firestore();

    let ref = db.collection("users").doc(uid ? uid : Firebase.user.uid);

    ref.get().then((doc) => {
      if (!doc.exists) {
        db.collection("users")
          .doc(uid ? uid : Firebase.user.uid)
          .set(celler)
          .then(() => {
            success();
          });
      } else {
        ref.update(celler).then(() => {
          success();
        });
      }
    });
  }

  loadInstructors(success) {
    var db = this.firebase.firestore();
    db.collection("instructors")
      .where("group", "==", Firebase.user.uid)
      .where("deleted", "==", false)
      .get()
      .then((snap) => {
        success(snap);
      });
  }

  loadInstructor(id, success) {
    var db = this.firebase.firestore();
    db.collection("instructors")
      .doc(id)
      .get()
      .then((snap) => {
        success(snap);
      });
  }

  loadOneRndInstructor(success) {
    var db = this.firebase.firestore();
    db.collection("instructors")
      .where("group", "==", Firebase.user.uid)
      .where("deleted", "==", false)
      .limit(1)
      .get()
      .then((snap) => {
        success(snap);
      });
  }

  loadInstructorFromRef(ref, success) {
    ref.get().then((doc) => {
      success(doc);
    });
  }

  updateInstructor(id, instructor, success) {
    var db = this.firebase.firestore();

    db.collection("instructors")
      .doc(id)
      .update(instructor)
      .then(() => {
        success();
      });
  }

  createInstructor(instructor, success) {
    var db = this.firebase.firestore();
    db.collection("instructors")
      .add(instructor)
      .then((doc) => {
        success(doc.id);
      });
  }

  deleteInstructor(id, success) {
    var db = this.firebase.firestore();
    let ref = db.collection("instructors").doc(id);
    ref.update({ deleted: true }).then(() => {
      success();
    });
  }

  loadPlan(success, uid) {
    var db = this.firebase.firestore();
    this.loadCeller((cellerDoc) => {
      if (cellerDoc.data() && cellerDoc?.data()?.plan) {
        cellerDoc
          .data()
          .plan.get()
          .then((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            success(obj);
          });
      } else {
        db.collection("plans")
          .doc("free")
          .get()
          .then((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            success(obj);
          });
      }
    }, uid);
  }

  loadAllPlans(success) {
    var db = this.firebase.firestore();
    db.collection("plans")
      .where("deleted", "==", false)
      //.orderBy("order")
      .get()
      .then((querySnapshot) => {
        success(querySnapshot);
      });
  }

  loadAllAvatars(success) {
    var db = this.firebase.firestore();
    db.collection("users")
      .doc(Firebase.user.uid)
      .collection("avatars")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        success(querySnapshot);
      });
  }

  loadDefaultAvatars(success) {
    var db = this.firebase.firestore();
    db.collection("defaultAvatars")
      .get()
      .then((querySnapshot) => {
        success(querySnapshot);
      });
  }

  loadAvatar(id, success) {
    var db = this.firebase.firestore();
    db.collection("users")
      .doc(Firebase.user.uid)
      .collection("avatars")
      .doc(id)
      .get()
      .then((doc) => {
        success(doc);
      });
  }

  createAvatar(avatar, success, fail) {
    var db = this.firebase.firestore();

    db.collection("users")
      .doc(Firebase.user.uid)
      .collection("avatars")
      .add(avatar)
      .then((doc) => {
        success(doc.id);
      })
      .catch(function (error) {
        fail(error);
      });
  }

  updateAvatar(avatar, success, fail) {
    avatar.ref
      .update(avatar)
      .then((doc) => {
        success(doc);
      })
      .catch(function (error) {
        fail(error);
      });
  }

  updateRefParam(ref, param, value, success, fail) {
    ref
      .update({ [param]: value })
      .then((ref) => {
        success(ref);
      })
      .catch(function (error) {
        fail(error);
      });
  }

  getUid() {
    return Firebase?.user?.uid || firebase?.auth()?.currentUser?.uid;
  }

  createUid(length = 20) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

export default Firebase;
