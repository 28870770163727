import React from "react";
import GraphComponentForm from "./GraphComponentForm";
import styled from "@emotion/styled";

const CategoryForm = ({ strings, config, category, dataParser }) => {
    const Root = {
        padding: "1%",
    }

    const Wrapper = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    }

    const MainTable = styled.table`
        border-collapse: collapse;
        table-layout: fixed;
        width: 98%;
        margin: 0.5%;
        background-color: rgb(216 216 216 / 46%);

        td {
            padding: 2vh;
            background-color: white;
            border: 2px solid rgba(100, 100, 100, 0.5);
        }
        th {
            padding: 2vh;
            text-align: left;
            background-color: rgb(225, 225, 225);
            border: 2px solid rgba(100, 100, 100, 0.5);
        }
    `;

    return (
        <div style={Root}>

            <div style={Wrapper}>
                {
                    dataParser.isCategoryRadarGraphDataPossible(category) ?
                        <GraphComponentForm
                            strings={strings}
                            config={config}
                            category={category}
                            dataParser={dataParser}
                            type={"radar"} />
                        :
                        <></>
                }

                {Object.entries(category?.components || []).map(([componentIndex, component]) => {
                    return (
                        <GraphComponentForm
                            strings={strings}
                            config={config}
                            category={category}
                            component={component}
                            dataParser={dataParser}
                            type={component?.type === "IMAGES_SLIDER" ? "bar" : "pie"}
                            header={component?.name?.[config.lang] || "-"} />
                    );
                })}
            </div>

            <MainTable>
                {Object.entries(category?.components || []).map(([componentIndex, component]) => {
                    return (
                        Object.entries(component?.options || []).map(([optionIndex, option]) => {
                            return (
                                <tr>
                                    {optionIndex == 0 ? <th rowSpan={(component?.options || []).length} colSpan="2">{component?.name?.[config.lang] || "-"}</th> : <></>}
                                    <th colSpan="2">{option?.name?.[config.lang]}</th>
                                    <td>{dataParser.getTotalOfParameter(category?.reference, component?.reference, option?.id)} {strings.votes}</td>
                                    <td>{dataParser.getPercentOfParameter(category?.reference, component?.reference, option?.id)} %</td>
                                </tr>
                            );
                        })
                    );
                })}
            </MainTable>
        </div>
    );
};
export default CategoryForm;
