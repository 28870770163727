import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useRouteMatch, useHistory } from "react-router-dom";
import CreateComponent from "./CreateComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import { isNullString, isNullSelecction, isNullOrUndefined } from "../../../../../../assets/utils/FormsUtils";

const CreateEngine = ({ strings, config }) => {
    let history = useHistory();
    let ba = new BackendAccessor();

    const [events, setEvents] = useState(null);
    const [report, setReport] = useState({});

    const generationTypes = {
        notSelected: strings.notSelected,
        "STANDAR": "STANDAR",
        "PROGRAMATED": "PROGRAMATED",
        "PERIODIC": "PERIODIC",
    }

    const formatEvents = (events) => {
        let fomatedEvents = {
            notSelected: strings.notSelected
        };
        if (fomatedEvents) {
            events.forEach(event => {
                fomatedEvents[event?.sessionCode?.publicCode] = event?.sessionCode?.publicCode + " - " + (event?.tasting?.name || "?");
            });
        }
        return fomatedEvents;
    }

    const loadEvents = () => {
        ba.getAllEvents(
            (events) => {
                setEvents(formatEvents(events));
            },
            (error) => {
                console.error("Error loading reports", error);
                config.popupAlert(strings.error, strings.errorLoadingEvents);
            }
        );
    };

    useEffect(() => {
        if (events == null) {
            loadEvents();
        }
    }, [strings]);

    const updateReport = (key, value) => {
        if (!config.modificated) {
            config.update("modificated", true);
        }

        setReport({
            ...report,
            [key]: value
        });
    }

    const createReport = () => {
        if (isNullString(report?.name)) {
            config.popupAlert(strings.error, strings.errorName);
            return;
        }
        if (isNullSelecction(report?.publicCode)) {
            config.popupAlert(strings.error, strings.errorEmptyEvent);
            return;
        }
        if (report?.dateFilterActive) {
            if (isNullOrUndefined(report?.fromDate) || isNullOrUndefined(report?.toDate)) {
                config.popupAlert(strings.error, strings.errorEmptyDate);
                return;
            }
            if (report.fromDate >= report.toDate) {
                config.popupAlert(strings.error, strings.errorDateDiference);
                return;
            }
        } else {
            report.fromDate = null;
            report.toDate = null;
        }

        config.showDownloadingPopup(strings.generating);

        ba.generateReport(report, (response) => {
            let reportId = ba.getIdFromPath(response?.reference);
            checkCreationStatus(reportId);
        }, onError);
    }

    const checkCreationStatus = (reportId) => {
        setTimeout(() => {
            ba.getReportStatus(reportId, (response) => {
                if (response?.state === "DONE") {
                    onSuccess(reportId);
                } else if (response?.state === "GENERATING" || response?.state === "PENDING") {
                    checkCreationStatus(reportId);
                } else {
                    onError(response);
                }
            }, onError);
        }, 5000);
    }

    const onSuccess = (reportId) => {
        config.update("modificated", false);
        config.hideDownloadingPopup();
        history.replace("/dashboard/reports/saved/" + reportId);
    }

    const onError = (error) => {
        config.hideDownloadingPopup();
        console.error("Error generating report", error);
        config.popupAlert(strings.error, strings.otherError);
    }

    const cancelAction = () => {
        config.isModificated(() => {
            history.replace("/dashboard/reports/list");
        });
    };

    return (
        events !== null ? (
            <CreateComponent
                strings={strings}
                events={events}
                report={report}
                generationTypes={generationTypes}
                updateReport={updateReport}
                confirmAction={createReport}
                cancelAction={cancelAction}
            />
        ) : (
            <LoadingScreen />
        )
    );
};

export default CreateEngine;