import React, { useState, useEffect } from "react";

import styled from "@emotion/styled";

import ExpandImg from "../../../../../../../assets/images/ic_expand.png";
import BackendAccessor from "../../../../../../../assets/BackendAccessor";
import ContractImg from "../../../../../../../assets/images/ic_contract.png";
import QuizForm from "./QuizForm";
import Select from "../../../../../../shared/Select";
import Button from "../../../../../../shared/Button";
import PodcastVideoForm from "../PodcastVideosForm/PodcastVideoForm";
import CrossIconGrey from "../../../../../../../assets/images/cross-icon-grey.svg";
import Colors from "../../../../../../../assets/Colors.json";

const ProductsForm = ({ strings, config, type, tasting, setTasting, products, assessmentSheets }) => {
  let ba = new BackendAccessor();

  const [activeProduct, setActiveProduct] = useState(0);
  const [videoCategories, setVideoCategories] = useState(null);
  const [expanded, setExpanded] = useState(true);

  const loadVideoCategories = (asPath) => {
    setVideoCategories(null);
    ba.getAssessmentSheetCategories(asPath,
      (categories) => {
        let parsedCategories = {};

        parsedCategories["MAIN"] = strings.main;
        categories.forEach(category => {
          parsedCategories[category.id] = category?.name?.[config.lang];
        });
        parsedCategories["FINAL"] = strings.final;

        console.debug("Categories", categories, parsedCategories);
        setVideoCategories(parsedCategories);
      },
      (error) => {
        console.error("Error loading categories: ", error);
      }
    );
  }

  const updateProductRef = (key, value) => {
    let productReferences = tasting?.productReferences || [];
    let productReference = productReferences?.[activeProduct] || {};
    productReference[key] = value;

    setTasting((prevState) => ({
      ...prevState,
      ["productReferences"]: productReferences,
    }));
  };

  const updatePodcastVideos = (key, value) => {
    let podcastVideos = tasting?.productReferences?.[activeProduct]?.podcastVideos || {};
    podcastVideos[key] = value;

    updateProductRef("podcastVideos", podcastVideos);
  };

  const addProductRef = () => {
    let productReferences = tasting?.productReferences || [];
    productReferences.push({
      podcastVideos: {}
    });

    setTasting((prevState) => ({
      ...prevState,
      ["productReferences"]: productReferences,
    }));
    setActiveProduct(tasting?.productReferences?.length - 1 || 0);
  };

  const deleteProductRef = (index) => {
    config.popupAlert(strings.deleteProductFromTasting, strings.deleteProductPop, null, true, () => {
      let productReferences = tasting?.productReferences || [];
      productReferences.splice(index, 1);

      setTasting((prevState) => ({
        ...prevState,
        ["productReferences"]: productReferences,
      }));
      setActiveProduct(0);
    });
  };

  const getFormatedProductOptions = () => {
    let formated = { notSelected: strings.notSelected };
    if (products) {
      Object.entries(products).forEach(([ref, product]) => {
        formated[ref] = product.name;
      });
    }
    return formated;
  }

  const getFormatedAssessmentOptions = () => {
    let formated = { notSelected: strings.notSelected };

    let selectedProduct = tasting?.productReferences?.[activeProduct].product;
    let productType = selectedProduct?.type?.id || "WINE";
    let productSubtype = selectedProduct?.subtype?.id || "RED";

    if (typeof assessmentSheets === "object" && assessmentSheets !== null) {
      Object.entries(assessmentSheets?.[productType]?.sheets || {}).forEach(([key, assessmentSheet]) => {
        if (assessmentSheet?.type === productSubtype) {
          formated[assessmentSheet.reference] = assessmentSheet?.name?.[config.lang];
        }
      });
    }

    return formated;
  };

  const getFormatedRecommendationOptions = () => {
    let formated = getFormatedProductOptions();
    formated.notSelected = strings.withoutRecomendation;
    return formated;
  }


  useEffect(() => {
    if (type === "podcast") {
      let asPath = tasting?.productReferences?.[activeProduct]?.assessmentSheetPath;
      if (asPath) {
        loadVideoCategories(asPath);
      } else {
        loadVideoCategories(null);
      }
    }
  }, [tasting?.productReferences?.[activeProduct]?.assessmentSheetPath]);


  useEffect(() => {
    if (type === "podcast") {
      if (activeProduct !== null && activeProduct !== undefined) {
        let podcastVideos = tasting?.productReferences?.[activeProduct]?.podcastVideos;

        if (podcastVideos === null || podcastVideos === undefined) {
          updateProductRef("podcastVideos", {});
        }
      }
    }
  }, [activeProduct, tasting?.productReferences?.[activeProduct]?.podcastVideos]);

  const Wrapper = {
    backgroundColor: "white",
    borderRadius: "0px 6px 6px 6px",
    padding: "2vh",
  };

  const Header = {
    width: "91%",
    margin: "2vh",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
  };

  const IndexItem = {
    padding: "1vh 2vh",
    backgroundColor: "#d8d8d8",
    cursor: "pointer",
    color: "#6d7278",
    borderBottom: "0px",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const AddIndexItem = {
    ...IndexItem,
    backgroundColor: Colors.lightTheme.accentColor1,
    color: "white",
  };

  const SelectedIndexItem = {
    padding: "1vh 2vh",
    cursor: "pointer",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const ExpandButton = styled.div`
    text-align: center;
    width: 9%;
    margin: 1vh;
    padding: 1vh;
    background-color: #d8d8d8;
    cursor: pointer;
    color: #6d7278;
    border-radius: 6px;
  `;

  const DeleteImg = styled.img`
    padding: 0.5vh;
    height: 1.5vh;
    margin-left: 1vw;
    border-radius: 50%;
    &:hover {
      background-color: LightGray;
    }
  `;

  const IndexesWrapper = {
    display: "flex",
  };

  const loadProductsIndexes = () => {
    let views = [];
    let index = 1;

    for (let key in tasting?.productReferences) {
      views.push(
        <div
          style={key == activeProduct ? SelectedIndexItem : IndexItem}
          onClick={() => {
            setActiveProduct(key);
          }}
        >
          <div>{strings.product + " " + index++}</div>
          <DeleteImg
            src={CrossIconGrey}
            onClick={() => deleteProductRef(key)}
          ></DeleteImg>
        </div>
      );
    }

    views.push(
      <div
        style={AddIndexItem}
        onClick={() => {
          addProductRef();
        }}
      >
        {strings.addProductToTasting}
      </div>
    );
    return views;
  };

  return (
    <div style={{ borderBottom: "0.1vh solid grey", paddingBottom: "2vh" }}>
      <div style={{ display: "flex" }}>
        <h3 style={Header}>{strings.tastingProducts}</h3>

        <ExpandButton
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <img
            src={expanded ? ContractImg : ExpandImg}
            style={{ height: "2vh", marginRight: "1vh" }}
          />
          {expanded ? strings.hide : strings.expand}
        </ExpandButton>
      </div>

      {expanded ? (
        Object.entries(tasting?.productReferences || []).length > 0 ? (
          <>
            <div style={IndexesWrapper}>{loadProductsIndexes()}</div>
            <div style={Wrapper}>
              {activeProduct != undefined &&
                tasting.productReferences[activeProduct] != undefined ? (
                <table style={{ width: "100%" }}>
                  <tbody>
                    {/* ------ DATOS DEL VINO ------*/}
                    <tr>
                      <td>
                        <div>
                          <b>{strings.product}*</b>
                        </div>
                        <Select
                          id="product"
                          options={getFormatedProductOptions()}
                          defaultOption={tasting?.productReferences?.[activeProduct]?.product?.reference}
                          onChange={(id, value) => { updateProductRef(id, products[value]) }}
                        />
                      </td>
                      <td>
                        <div>
                          <b>{strings.assessmentSheet}*</b>
                        </div>
                        <Select
                          id="assessmentSheetPath"
                          options={getFormatedAssessmentOptions()}
                          defaultOption={tasting?.productReferences?.[activeProduct]?.assessmentSheetPath}
                          onChange={updateProductRef}
                        />
                      </td>
                      <td>
                        <div>{strings.recomendation}</div>
                        <Select
                          id="recommendation"
                          options={getFormatedRecommendationOptions()}
                          defaultOption={tasting?.productReferences?.[activeProduct]?.recommendation?.reference}
                          onChange={(id, value) => { updateProductRef(id, products[value]) }}
                        />
                      </td>
                    </tr>
                    {/*--------- PODCAST VIDEOS --------*/}
                    {tasting.type === "PODCAST_TAST" ? (
                      <tr>
                        <td colSpan="3">
                          <PodcastVideoForm
                            strings={strings}
                            config={config}
                            object={tasting?.productReferences?.[activeProduct]?.podcastVideos}
                            setObject={updatePodcastVideos}
                            videoTypes={videoCategories}
                            embedded={true}
                          />
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {/* --------- QUIZ DEL VINO --------*/}
                    {tasting?.type === "PODCAST_TAST" && tasting?.template === "SIMPLE_TEMPLATE" ? (
                      <></>
                    ) : (
                      <tr>
                        <td colSpan="3">
                          <QuizForm
                            strings={strings}
                            config={config}
                            productReference={tasting?.productReferences?.[activeProduct]}
                            updateProductRef={updateProductRef}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                /* ---------- NO HAY VINO SELECIONADO --------*/
                <p>{strings.noProductSelected}</p>
              )}
            </div>
          </>
        ) : (
          /* ---------------- NO HAY NINGUN VINO ----------------*/
          <div
            style={{
              display: "flex",
              padding: "1vh",
              flexDirection: "column",
              marginRight: "9%",
            }}
          >
            <Button
              action={addProductRef}
              value={strings.addProductToTasting}
              style={{ margin: "0 auto" }}
            />
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
export default ProductsForm;
