
export const copyTextToClipBoard = (text, strings, config) => {
    const el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (config && strings) {
        config.popupAlert(strings?.info, strings?.linkCopied);
    }
};