import React from "react";
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from "react-router-dom";
import EditFormEngine from "./Form/EditFormEngine";
import PlansEngine from "./Plans/PlansEngine";
import PasswordEngine from "./Password/PasswordEngine";
import Config from "../../../../../assets/Config";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const CellarRoutes = ({ strings, config, celler, setCeller }) => {
  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
  };

  let { path } = useRouteMatch();

  const isDev = Config.ENV == "DEV";
  let stripePromise;
  if (isDev) {
    stripePromise = loadStripe("pk_test_0qGuvjDH6zkO7PDdqRalPWtR00qoRXMoow");
  } else {
    stripePromise = loadStripe("pk_live_X1him3e3YDgH3jomQCcWF7Z500eYJ2Ydaj");
  }

  return (
    <Elements stripe={stripePromise}>
      <div style={Wrapper}>
        <Switch>
          <Route path={`${path}/edit`}>
            <EditFormEngine config={config} celler={celler} setCeller={setCeller} strings={strings} />
          </Route>
          <Route path={`${path}/plan`}>
            <PlansEngine strings={strings} config={config} stripeTest={isDev} />
          </Route>
          <Route path={`${path}/password`}>
            <PasswordEngine strings={strings} config={config} />
          </Route>
        </Switch>
      </div>
    </Elements>
  );
};

export default CellarRoutes;
