import React, { useState } from "react";
import { Pie, Radar, Bar } from 'react-chartjs-2';
import Colors from "../../../../../../../assets/Colors.json";
import IcGraphBar from "../../../../../../../assets/images/ic_bar_grpah.png";
import IcGraphPie from "../../../../../../../assets/images/ic_pie_grpah.png";


const GraphComponentForm = ({ strings, config, category, component, header, dataParser, type }) => {
    const [graphMode, setGrapMode] = useState(type || "pie");

    const GrapHeader = {
        width: "100%",
        textAlign: "center",
        marginBottom: "2vh",
        fontWeight: "bold",
    }

    const Graph = {
        height: "30vh"
    }

    const GraphContainer = {
        position: "relative",
        width: "30%",
        padding: "1%",
        margin: "0.5%",
        borderRadius: "6px",
        backgroundColor: "rgb(216 216 216 / 46%)",
    }

    const iconContainer = {
        height: "3vh",
        width: "3vh",
        padding: "0.6vh",
        margin: "1.2vh",
        borderRadius: "6px",
        backgroundColor: Colors.lightTheme.accentColor1,
        position: "absolute",
        top: "0",
        right: "0",
        cursor: "pointer",
    }

    const icon = {
        width: "100%",
        height: "100%"
    }

    const pieOptions = {
        maintainAspectRatio: false
    }

    const barOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        }
    };

    const radarOptions = {
        scale: {
            max: 10,
            min: 0,
            stepSize: 2.5,
            ticks: {
                display: false,
                font: {
                    size: 0
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <div style={GraphContainer}>
            {header !== null ? (
                <div style={GrapHeader}>{header}</div>
            ) : (
                <></>
            )}
            {graphMode === "pie" ? (
                <>
                    <div style={iconContainer} onClick={() => { setGrapMode("bar") }}>
                        <img style={icon} src={IcGraphBar} />
                    </div>
                    <div style={Graph}>
                        <Pie options={pieOptions} data={dataParser.getComponentGraphData(category?.reference, component?.reference, component)} />
                    </div>
                </>
            ) : graphMode === "bar" ? (
                <>
                    <div style={iconContainer} onClick={() => { setGrapMode("pie") }}>
                        <img style={icon} src={IcGraphPie} />
                    </div>
                    <div style={Graph}>
                            <Bar options={barOptions} data={dataParser.getComponentGraphData(category?.reference, component?.reference, component)} />
                    </div>
                </>
            ) : graphMode === "radar" ? (
                <Radar data={dataParser.getCategoryRadarGraphData(category)} options={radarOptions} />
            ) : (
                <></>
            )}
        </div>
    )
};
export default GraphComponentForm;