import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import ExpandImg from "../../../../../../../assets/images/ic_expand.png";
import ContractImg from "../../../../../../../assets/images/ic_contract.png";
import Colors from "../../../../../../../assets/Colors.json";
import SurveyOptionsForm from "./SurveyOptionsForm";

import Select from "../../../../../../shared/Select";
import BackendAccessor from "../../../../../../../assets/BackendAccessor";
import TextInput from "../../../../../../shared/TextInput";
import Button from "../../../../../../shared/Button";
import CrossIconWhite from "../../../../../../../assets/images/cross-icon-white.svg";
import CrossIconGrey from "../../../../../../../assets/images/cross-icon-grey.svg";

const LANG = "ES";

const SurveyForm = ({ strings, config, tasting, setTasting }) => {
  let ba = new BackendAccessor();

  const [activeQuestion, setActiveQuestion] = useState(0);
  const [expanded, setExpanded] = useState(true);

  const addQuestion = () => {
    let surveyQuestions = tasting?.surveyQuestions || [];
    surveyQuestions.push({ type: "SIMPLE_QUESTION", });

    setTasting((prevState) => ({
      ...prevState,
      ["surveyQuestions"]: surveyQuestions,
    }));
    setActiveQuestion(surveyQuestions.length - 1);
  };

  const updateSurveyQuestion = (key, value) => {
    let surveyQuestions = tasting?.surveyQuestions || [];
    let surveyQuestion = surveyQuestions[activeQuestion] || {};
    surveyQuestion[key] = value;

    setTasting((prevState) => ({
      ...prevState,
      ["surveyQuestions"]: surveyQuestions,
    }));
  };

  const addOption = () => {
    let surveyQuestions = tasting?.surveyQuestions || [];
    let surveyQuestion = surveyQuestions?.[activeQuestion] || {};
    let options = surveyQuestion?.options || {};
    options[ba.createUid()] = {
      "ES": "",
      "CA": "",
      "EN": "",
    }

    updateSurveyQuestion("options", options);
  };



  const updateOptions = (id, value) => {
    let surveyQuestions = tasting?.surveyQuestions || [];
    let surveyQuestion = surveyQuestions?.[activeQuestion] || {};
    let options = surveyQuestion?.options || {};
    options[id] = value;
    updateSurveyQuestion("options", options);
  };

  const deleteOption = (id) => {
    let surveyQuestions = tasting?.surveyQuestions || [];
    let surveyQuestion = surveyQuestions?.[activeQuestion] || {};
    let options = surveyQuestion?.options || {};
    delete options[id];
    updateSurveyQuestion("options", options);
  };

  const deleteQuestion = (index) => {
    config.popupAlert(strings.deleteQuestion, strings.deleteQuestionPop, null, true, () => {

      let surveyQuestions = tasting?.surveyQuestions || [];
      surveyQuestions.splice(index, 1);

      setTasting((prevState) => ({
        ...prevState,
        ["surveyQuestions"]: surveyQuestions,
      }));

      setActiveQuestion(0);
    });
  };

  const questionOptions = {
    SIMPLE_QUESTION: strings.initialUnique,
    MULTIPLE_OPTION_QUESTION: strings.initialMultiple,
  };

  const Wrapper = {
    backgroundColor: "white",
    borderRadius: "0px 6px 6px 6px",
    padding: "2vh",
  };

  const Header = {
    width: "91%",
    margin: "2vh",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
  };

  const IndexesWrapper = {
    display: "flex",
  };

  const IndexItem = {
    padding: "1vh 2vh",
    backgroundColor: "#d8d8d8",
    cursor: "pointer",
    color: "#6d7278",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const AddIndexItem = {
    ...IndexItem,
    backgroundColor: Colors.lightTheme.accentColor1,
    color: "white",
    border: "1.5px solid " + Colors.lightTheme.accentColor1,
  };

  const SelectedIndexItem = {
    padding: "1vh 2vh",
    cursor: "pointer",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const DeleteImg = styled.img`
    padding: 0.5vh;
    height: 1.5vh;
    margin-left: 0.5vw;
    border-radius: 50%;
    &:hover {
      background-color: LightGray;
    }
  `;

  const ExpandButton = styled.div`
    text-align: center;
    width: 9%;
    margin: 1vh;
    padding: 1vh;
    background-color: #d8d8d8;
    cursor: pointer;
    color: #6d7278;
    border-radius: 6px;
  `;

  const loadQuestionIndexes = () => {
    let views = [];
    let index = 1;
    for (let key in tasting.surveyQuestions) {
      views.push(
        <div
          style={key == activeQuestion ? SelectedIndexItem : IndexItem}
          onClick={() => {
            setActiveQuestion(key);
          }}
        >
          <div>{strings.question + " " + index++}</div>
          <DeleteImg src={CrossIconGrey} onClick={() => deleteQuestion(key)}></DeleteImg>
        </div>
      );
    }

    views.push(
      <div
        style={AddIndexItem}
        onClick={() => {
          addQuestion();
        }}
      >
        {strings.addQuestion}
      </div>
    );
    return views;
  };

  const tastingQuestions = tasting?.surveyQuestions?.[activeQuestion];

  return (
    <div style={{ paddingBottom: "2vh" }}>
      <div style={{ display: "flex" }}>
        <h3 style={Header}>{strings.initialQuestions}</h3>

        <ExpandButton
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <img src={expanded ? ContractImg : ExpandImg} style={{ height: "2vh", marginRight: "1vh" }} />
          {expanded ? strings.hide : strings.expand}
        </ExpandButton>
      </div>
      {expanded ? (
        <>
          {Object.entries(tasting?.surveyQuestions || {}).length > 0 ? (
            <>
              <div style={IndexesWrapper}>{loadQuestionIndexes()}</div>
              <div style={Wrapper}>
                {activeQuestion == undefined ? (
                  <p>{strings.noQuestionSelected}</p>
                ) : (
                  <React.Fragment>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "70%" }}>
                            <div>
                              <b>{strings.question}*</b>
                            </div>
                            <TextInput
                              styles={{ width: "100%" }}
                              defaultValue={tastingQuestions?.question?.[LANG] || ""}
                              onChange={(e) => {
                                updateSurveyQuestion("question", {
                                  CA: e.target.value,
                                  ES: e.target.value,
                                  EN: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <div>{strings.typeQuetions}</div>
                            <Select id={"type"} options={questionOptions} defaultOption={tastingQuestions?.type} onChange={updateSurveyQuestion} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <div style={{ marginTop: "1vh" }}>
                              <SurveyOptionsForm
                                strings={strings}
                                tasting={tasting}
                                activeQuestion={activeQuestion}
                                updateOptions={updateOptions}
                                deleteOption={deleteOption}
                                addOption={addOption} />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </div>
            </>
          ) : (
            <div style={{ display: "flex", padding: "1vh", flexDirection: "column", marginRight: "9%" }}>
              <Button
                action={() => {
                  addQuestion();
                }}
                value={strings.addQuestion}
                style={{ margin: "0 auto" }}
              />
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SurveyForm;