import React, { useState, useEffect } from "react";
import BackendAccessor from "../../../../../assets/BackendAccessor";
import IntegrationForm from "./IntegrationForm";
import { isNullString } from "../../../../../assets/utils/FormsUtils";
import { copyTextToClipBoard } from "../../../../../assets/utils/DeviceUtils";

const IntegrationEngine = ({ strings, config }) => {
    let ba = new BackendAccessor();

    const [apiKeys, setApiKeys] = useState([]);

    useEffect(() => {
        getApiKeys();
    }, []);

    const getApiKeys = () => {
        ba.getApiKeys((apiKeys) => {
            setApiKeys(apiKeys);
        }, (error) => {
            console.error("Error getting api keys: ", error);
        })
    }

    const generateApiKey = () => {
        config.showInputPopup(strings.createApiKey, strings.insertApiKeyName, (text) => {
            if (isNullString(text)) {
                config.popupAlert(strings.error, strings.errorName);
            } else {
                config.closeInputPopup();
                createApiKey(text);
            }
        });
    }

    const createApiKey = (name) => {
        config.showDownloadingPopup(strings.generatingApiKey);

        let rawApiKey = {
            name: name
        }

        ba.createApiKey(rawApiKey, (apiKey) => {
            config.showDownloadingPopup(null);
            getApiKeys();
        }, onError);
    }

    const onError = (error) => {
        config.showDownloadingPopup(null);
        config.popupAlert(strings.error, strings.errorGeneratingApiKey);
        console.error("Error generating api key", error);
    }

    const downloadDocumentation = () => {
        let docUrl = "http://guias.upwine.es/API Pública UpTasting.pdf"
        window.open(docUrl, '_blank');
    }

    const copyKey = (id) => {
        let key = apiKeys.find(key => key.id === id);

        if (key && key?.apiKey) {
            copyTextToClipBoard(key?.apiKey);
            config.popupAlert(strings.copied, strings.apiKeyCopied);
        }
    }

    const deleteKey = (id) => {
        config.popupAlert(strings.delete, strings.deleteConfirm, null, true, () => {
            ba.deleteApiKey(id, (response) => {
                getApiKeys();
            }, (error) => {
                console.error("Error deleting api key", error);
            });
        });
    }

    return (
        <IntegrationForm
            strings={strings}
            apiKeys={apiKeys}
            generateApiKey={generateApiKey}
            downloadDocumentation={downloadDocumentation}
            copyKey={copyKey}
            deleteKey={deleteKey} />
    );
};
export default IntegrationEngine;
