import React from "react";
import styled from "@emotion/styled";
import Steps from "./Steps";
import Background from "../../../../../assets/images/background-home.png";
import PlanBanner from "./PlanBanner";
import FAQBanner from "./FAQBanner";

const HomeComponent = ({ strings, config, changePlan, loadVideo }) => {
  const Container = styled.div`
    display: flex;
    width: 100%;
    background: url("${Background}");
    padding: 10vh 7vh 10vh 5vh;
  `;

  const ContainerLeft = styled.div`
    height: 100%;
    width: 30%;
  `;

  const ContainerRight = styled.div`
    height: 100%;
    display: flex;
    width: 70%;
    justify-content: flex-end;
  `;

  return (
    <Container>
      <ContainerLeft>
        <Steps strings={strings} />
      </ContainerLeft>
      <ContainerRight>
        <PlanBanner strings={strings} select={changePlan} config={config} />
        {/*<FAQBanner strings={strings} loadVideo={loadVideo} />*/}
      </ContainerRight>
    </Container>
  );
};

export default HomeComponent;
