import React, { useState, useEffect } from "react";
import FixedParamsForm from "./Forms/FixedParamsForm";
import DynamicParamsForm from "./Forms/DynamicParamsForm";
import StoreParamsForm from "./Forms/StoreParamsForm";
import Button from "../../../../../shared/Button";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import styled from "@emotion/styled";

const FormComponent = ({ strings, config, product, productTypes, updateProduct, confirmAction, cancelAction }) => {
  const ButtonWrapper = styled.div`
    display: flex;
    place-items: center;
    margin-top: 0.5rem;
  `;

  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const ColumnWrapper = {
    display: "flex",
    borderBottom: "1px solid grey",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",
  }

  const LeftColumn = {
    width: "67%",
    paddingRight: "0.5rem"
  }

  const LeftTop = {
    marginBottom: "2%"
  }

  const LeftBottom = {
    borderTop: "1px solid grey",
    paddingTop: "2%"
  }

  const RightColumn = {
    width: "32%",
    borderLeft: "1px solid grey",
    paddingLeft: "1.5%"
  }

  return (
    <div style={{ width: "100%" }}>
      <Title>{product?.reference ? strings.editProduct : strings.createProduct}</Title>
      <div style={{ padding: "2vh 3vh" }}>
        <div style={ColumnWrapper}>
          <div style={LeftColumn}>
            <div style={LeftTop}>
              <FixedParamsForm strings={strings} config={config} product={product} productTypes={productTypes} updateProduct={updateProduct} />
            </div>
            <div style={LeftBottom}>
              <DynamicParamsForm strings={strings} config={config} product={product} productTypes={productTypes} updateProduct={updateProduct} />
            </div>
          </div>
          <div style={RightColumn}>
            <StoreParamsForm strings={strings} config={config} product={product} updateProduct={updateProduct} />
          </div>
        </div>
        <ButtonWrapper>
          <SecondaryButton value={strings.cancel} action={cancelAction} style="margin: 0 1vh 0 auto" />
          <Button value={strings.confirm} action={confirmAction} style="margin: 0 auto 0 0" />
        </ButtonWrapper>
      </div>
    </div >
  );
};
export default FormComponent;
