import React, { useState } from "react";
import FormComponent from "./FormComponent";

import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";

const EditFormEngine = ({ celler, setCeller, strings, config }) => {
  let f = new Firebase();
  let history = useHistory();

  const onImageUpload = (e) => {
    let file = e.files[0];
    let url = URL.createObjectURL(file);
    let size = file.size / 1024; //Kb

    if (size > 250) {
      onLogoError();
      return;
    }

    let image = new Image();
    image.src = url;
    image.onload = () => {
      let height = image.height;
      let width = image.width;

      console.log("Uploaded image: " + size + "kb (" + height + "x" + width + " px)");

      /*if (height > 700 || width > 700) {
        onLogoError();
        return;
      }*/

      setCeller({
        ...celler,
        logoStorageRef: "new",
        logo: url,
      });
      config.update("modificated", true);
    };
  };

  const onLogoError = () => {
    config.popupAlert(strings.error, strings.errorLogo);
  };

  const cellerUpdate = async () => {
    let newCeller = {};
    let errorMsg = null;
    let image = null;

    if (celler.name != null && celler.name.length !== 0) {
      newCeller.name = celler.name;
    } else {
      errorMsg = strings.errorName;
    }

    newCeller.webPage = celler.webPage ? celler.webPage : null;
    newCeller.social = celler.social ? celler.social : null;
    newCeller.satisfactionActive = celler.satisfactionActive !== null ? celler.satisfactionActive : true;
    newCeller.storeActive = celler.storeActive !== null ? celler.storeActive : true;
    newCeller.currency = celler.currency ? (celler.currency == "EUR" ? "€" : celler.currency) : "€";
    newCeller.tooltipsEnabled = "tooltipsEnabled" in celler ? celler.tooltipsEnabled : true;

    if (errorMsg == null) {
      try {
        image = await fetch(celler.logo).then((r) => r.blob());

        f.uploadImage(image, (ref) => {
          newCeller.logo = ref;
          f.updateCeller(newCeller, () => { successUpdate(newCeller) });
        });
      } catch (e) {
        f.updateCeller(newCeller, () => { successUpdate(newCeller) });
      }
    } else {
      config.popupAlert(strings.error, errorMsg);
    }
  };

  const successUpdate = (newCeller) => {
    config.update("cellar", {
      ...config.celler,
      ...newCeller,
    });
    config.update("modificated", false);
    config.popupAlert(strings.success, strings.successMsg);
  };

  const cancelAction = () => {
    config.isModificated(() => {
      history.replace("/dashboard/home");
    });
  };

  const goToPlans = () => {
    config.isModificated(() => {
      history.push("/dashboard/cellar/plan");
    });
  };

  const changePw = () => {
    config.isModificated(() => {
      history.push("/dashboard/cellar/password");
    });
  };

  const cancelSubscrption = () => {
    config.popupAlert(strings.cancelSubscription, strings.cancelSubscriptionMsg);
  };

  const updateImage = () => {
    if (config.plan.customLogo) {
      document.getElementById("imageInput").click();
    } else {
      config.popupAlert(strings.error, strings.errorPlanLogo);
    }
  };

  const enableTooltips = () => {
    let enabled = !config.tooltipsEnabled;
    config.update("tooltipsEnabled", enabled);
    setCeller({
      ...celler,
      tooltipsEnabled: enabled,
    });
    config.update("modificated", true);
  };

  return (
    <>
      <FormComponent
        celler={celler}
        setCeller={setCeller}
        strings={strings}
        confirmAction={cellerUpdate}
        cancelAction={cancelAction}
        onImageUpload={onImageUpload}
        config={config}
        goToPlans={goToPlans}
        cancelSubscrption={cancelSubscrption}
        updateImage={updateImage}
        changePw={changePw}
        enableTooltips={enableTooltips}
      />
    </>
  );
};
export default EditFormEngine;
