import React, { useState, useEffect } from "react";
import CategoryForm from "./CategoryForm";

const CategoriesWrapperForm = ({ strings, config, productData, dataParser }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    if (activeCategory == null) {
      if (productData?.assessmentSheet?.categories != null) {
        let numCategories = productData?.assessmentSheet?.categories.length;
        if (numCategories > 0) {
          setActiveCategory(0);
        }
      }
    }
  }, [productData?.assessmentSheet, activeCategory]);

  const Wrapper = {
    backgroundColor: "white",
    borderRadius: "0px 6px 6px 6px",
    padding: "2vh",
  };

  const Header = {
    margin: "2vh 0",
    width: "100%",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
  };

  const IndexesWrapper = {
    display: "flex",
  };

  const IndexItem = {
    padding: "1vh 2vh",
    backgroundColor: "#d8d8d8",
    cursor: "pointer",
    color: "#6d7278",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const SelectedIndexItem = {
    padding: "1vh 2vh",
    cursor: "pointer",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const loadCategoriesTabs = () => {
    let views = [];
    let index = 1;
    for (let key in productData?.assessmentSheet?.categories) {
      views.push(
        <div
          style={key == activeCategory ? SelectedIndexItem : IndexItem}
          onClick={() => {
            setActiveCategory(key);
          }}
        >
          <div>{productData?.assessmentSheet?.categories?.[key]?.name?.[config.lang] || "-"}</div>
        </div>
      );
    }
    return views;
  };

  return (
    <div style={{ paddingBottom: "2vh" }}>
      {Object.entries(productData?.assessmentSheet?.categories || {}).length > 0 ? (
        <>
          <div style={IndexesWrapper}>{loadCategoriesTabs()}</div>
          <div style={Wrapper}>
            {activeCategory == undefined ? (
              <p>{strings.noCategorySelected}</p>
            ) : (
              <CategoryForm
                strings={strings}
                config={config}
                category={productData?.assessmentSheet?.categories[activeCategory]}
                dataParser={dataParser}
              />
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CategoriesWrapperForm;
