import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import LocalStorage from "../../../assets/LocalStorage";
import DownloadingPopup from "../../shared/DownloadingPopup";
import AssistantRoutes from "./AssistantRoutes";
import LanguagePopup from "./LanguagePopup";

const AssisantEngine = ({ strings, lang, setLang, theme, popupAlert, closePopupAlert }) => {
  let history = useHistory();
  const location = useLocation();

  const [langPopupVisible, setLanPopupVisible] = useState(false);

  const selectLang = () => {
    setLanPopupVisible(true);
  };

  const [config, setConfig] = useState({
    userRestored: false,
    email: null,
    userEmail: null,
    logged: false,
    lang: lang,
    downloadingText: null,
    inputPoupData: null,
    strings: strings,

    // Functions
    update: function (parameter, value) {
      setConfig((prevState) => ({
        ...prevState,
        [parameter]: value,
      }));
    },

    updateAll: function (data) {
      setConfig((prevState) => ({
        ...prevState,
        ...data,
      }));
    },

    showDownloadingPopup: function (text) {
      this.update("downloadingText", text);
    },

    hideDownloadingPopup: function () {
      this.update("downloadingText", null);
    },

    login: function (email, userEmail) {
      let data = { logged: true, email: email, userEmail: userEmail };
      this.updateAll(data);
      LocalStorage.set("LOGGED_ASSISTANT", data, 24 * 60 * 60 * 1000);
    },

    logout: function (e) {
      let data = { logged: false, email: null };
      console.log("Hola", data, this);
      this.updateAll(data);
      LocalStorage.set("LOGGED_ASSISTANT", data, 24 * 60 * 60 * 1000);
    },

    popupAlert: popupAlert,
    closePopupAlert: closePopupAlert,
  });

  // Restore logged assistant
  useEffect(() => {
    let loggedAssistant = LocalStorage.get("LOGGED_ASSISTANT");
    if (typeof loggedAssistant === 'object' && loggedAssistant !== null) {
      config.updateAll(loggedAssistant);
    }
    config.update("userRestored", true);
  }, []);

  // Check if assistant is logged
  useEffect(() => {
    if (config?.userRestored) {
      let isInLoginPage = location?.pathname === "/assistant/login";

      if (config?.logged && config?.email && isInLoginPage) {
        history.replace("/assistant/sessions");
      } else if (!config?.logged && !isInLoginPage) {
        history.replace("/assistant/login");
      }
    }
  }, [config?.userRestored, config?.logged, config?.email, location?.pathname]);

  return (
    <>
      <LanguagePopup strings={strings} visible={langPopupVisible} setVisible={setLanPopupVisible} setLanguage={setLang} />
      <AssistantRoutes strings={strings} config={config} lang={lang} setLang={setLang} selectLang={selectLang} />

      {config?.downloadingText !== null ? (
        <DownloadingPopup headerText={config?.downloadingText} />
      ) : (
        <></>
      )}
    </>
  );

};
export default AssisantEngine;
