import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useRouteMatch, useHistory } from "react-router-dom";
import ListComponent from "./ListComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import BackendAccessor from "../../../../../../assets/BackendAccessor";

const ListEngine = ({ strings, config }) => {
  let history = useHistory();
  let ba = new BackendAccessor();

  const [reports, setReports] = useState(null);

  const formatReports = (reports) => {
    let formatedReports = {};
    if (formatedReports) {
      console.log(reports);
      reports.forEach(report => {
        let id = ba.getIdFromPath(report.reference);
        formatedReports[id] = {
          name: report?.name || "-",
          code: report?.publicCode || "-",
          date: report?.creationDate ? new Date(report?.creationDate) : null,
        }
      });
    }
    return formatedReports;
  }

  const loadReports = () => {
    ba.getReportList(
      (reports) => {
        console.log("Reports", reports);
        setReports(formatReports(reports));
      },
      (error) => {
        console.error("Error loading reports", error);
        config.popupAlert(strings.error, strings.errorLoadingReports);
      }
    )
  };

  const viewReport = (id) => {
    history.replace(`/dashboard/reports/saved/${id}`);
  };

  const createReport = () => {
    history.replace(`/dashboard/reports/create`);
  };

  const deleteReport = (id) => {
    config.popupAlert(strings.delete + " - " + reports[id].name, strings.deleteConfirm, null, true, () => {
      ba.deleteReport(id,
        (response) => {
          console.log("Report with ID " + id + " has been deleted", response);
          setReports((prevState) => {
            const state = { ...prevState };
            delete state[id];
            return state;
          });
        },
        (error) => {
          console.error("Error deleting report with ID " + id, error);
          config.popupAlert("Error", "Error deleting report");
        });
    });
  };

  useEffect(() => {
    if (reports == null) {
      loadReports();
    }
  }, [strings]);

  return (
    reports !== null ? (
      <ListComponent strings={strings} reports={reports} viewReport={viewReport} createReport={createReport} deleteReport={deleteReport} ready={reports !== null} />
    ) : (
      <LoadingScreen />
    )
  );
};

export default ListEngine;
