import React, { useState } from "react";
import Colors from "../../../../../../../assets/Colors.json";

export default function GeneratedVideoTextArea({ strings, value, disabled, onInput }) {
  const [modeSource, setModeSource] = useState(false);

  const tagsEquivalences = {
    ",": ',<break strength="strong"/>',
    ". ": '.<break strength="x-strong"/> ',
    ";": ';<break strength="x-strong"/>',
    ".\n": '.<break time="100ms"/>\n',
    ". \n": '.<break time="100ms"/> \n',
  };

  const Input = {
    borderRadius: "0 0 0.5vh 0.5vh",
    border: "solid 0.3vh #d8dde1",
    color: "black",
    backgroundColor: "white",
    padding: "1vh",
    resize: "vertical",
    height: "12vh",
    width: "95%",
  };

  const DisabledInput = {
    ...Input,
    color: "-internal-light-dark(graytext, rgb(170, 170, 170))",
    opacity: "0.7",
  };

  const toolbar = {
    ...Input,
    padding: "0.5vh 1vh",
    height: "none",
    borderRadius: "0.5vh 0.5vh 0 0",
    backgroundColor: "#d8dde1",

  };

  const toolbarButton = {
    width: "fit-content",
    padding: "0.4vh",
    fontSize: "1.5vh",
    borderRadius: "0.25vh",
    backgroundColor: "rgb(203, 203, 203)",
    cursor: "pointer",
    margin: "auto",
    marginRight: "0",
  };

  const toolbarActiveButton = {
    ...toolbarButton,
    backgroundColor: Colors.lightTheme.accentColor1,
    color: "white",
    fontWeight: "bold",
  };

  const isDisabled = () => {
    return disabled || modeSource;
  }

  const onValueChange = (e) => {
    let value = e.target.value;

    let reg = /^[a-zA-Z0-9 ]+$/;
    if (!reg.test(value)) {
      console.debug("Invalid character");
      return;
    }

    if (!value.startsWith("<speak>")) {
      value = "<speak>" + value;
    }
    if (!value.endsWith("</speak>")) {
      value = value + "</speak>";
    }

    for (const [tag, equivalence] of Object.entries(tagsEquivalences)) {
      value = value.replaceAll(tag, equivalence);
    }

    onInput(value);
  };

  const getRawValue = () => {
    let parsedValue = value;
    return parsedValue;
  };

  const getValue = () => {
    let parsedValue = value;
    if (parsedValue != null) {
      parsedValue = parsedValue.replace("<speak>", "");
      parsedValue = parsedValue.replace("</speak>", "");

      for (const [tag, equivalence] of Object.entries(tagsEquivalences)) {
        parsedValue = parsedValue.replaceAll(equivalence, tag);
      }
    }
    return parsedValue;
  };

  return (
    <>
      <div style={toolbar}>
        <div style={modeSource ? toolbarActiveButton : toolbarButton} onClick={() => { setModeSource(!modeSource) }}>{"</>"}</div>
      </div>
      <textarea
        id="text"
        style={isDisabled() ? DisabledInput : Input}
        disabled={isDisabled()}
        value={modeSource ? getRawValue() : getValue()}
        placeholder={strings.textPlaceholder}
        onChange={onValueChange}
      />
    </>
  );
}
