import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import ListEngine from "./List/ListEngine";
import FormEngine from "./Form/FormEngine";

const AssessmentSheetsRoutes = ({ strings, config }) => {
  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
  };

  let { path } = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    if (!config?.plan?.customAssessmentSheets) {
      let confirmed = false;
      config.popupAlert(
        strings.featureNotAllowed,
        strings.featureNotAllowedText,
        () => {
          if (!confirmed) {
            history.replace("/dashboard/home");
          }
        },
        true,
        () => {
          confirmed = true;
          history.replace("/dashboard/cellar/plan");
        },
        strings.close,
        strings.changePlan
      );
    }
  }, [config?.plan]);

  return (
    <div style={Wrapper}>
      <Switch>
        <Route path={`${path}/list`}>
          <ListEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/new`}>
          <FormEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <FormEngine strings={strings} config={config} />
        </Route>
      </Switch>
    </div>
  );
};

export default AssessmentSheetsRoutes;
