import { isNullString, isNullSelecction, isNullMap, isInvalidBoolean } from "../../../../../../assets/utils/FormsUtils";
import BackendAccessor from "../../../../../../assets/BackendAccessor";

export const validateEvent = (strings, publicCode, event, onSuccess, onFail) => {
    let now = new Date();

    if (isNullSelecction(event.instructorPath)) {
        onFail(strings.errorInstructor);
        return;
    }

    if (isNullSelecction(event.tastingPath)) {
        onFail(strings.errorTasting);
        return;
    }

    let fromDate = new Date(event?.sessionCode?.fromDate);
    if (isNaN(fromDate)) {
        onFail(strings.errorStartDate);
        return;
    } else if (fromDate < now) {
        /*onFail("La fecha de inicio no puede ser anterior a la actual");
        return;*/
    }

    let toDate = new Date(event?.sessionCode?.toDate);
    if (isNaN(toDate)) {
        onFail(strings.errorFinishDate);
        return;
    } else if (toDate < now) {
        /*onFail("La fecha de inicio no puede ser anterior a la actual");
        return;*/
    }

    if (fromDate > toDate) {
        onFail(strings.errorDates);
        return;
    }

    if (event?.sessionCode?.generateReport) {
        if (isNullSelecction(event?.sessionCode?.generationReportType)) {
            onFail(strings.errorGenerationReportType);
            return;
        } else if (event?.sessionCode?.generationReportType === "PERIODIC") {
            if (isNullSelecction(event?.sessionCode?.generationPeriodicInterval)) {
                onFail(strings.errorGenerationPeriodicInterval);
                return;
            }
        }
    }

    if (publicCode !== event?.sessionCode?.publicCode) {
        validateCode(strings, publicCode, onSuccess, onFail, onFail);
    } else {
        onSuccess();
    }
}


export const validateCode = (strings, code, onFree, onTaken, onError) => {
    let ba = new BackendAccessor();
    let patt = new RegExp(/^[A-Z0-9]+$/g);

    if (code.match(patt)) {
        ba.checkSessioCodeFree(
            code,
            (result) => {
                if (!result.exists) {
                    onFree();
                } else {
                    onTaken(strings.errorExistingCode);
                }
            },
            (error) => {
                console.error("Error checking code: ", error)
                onError(strings.errorCheckingCode);
            });
    } else {
        onError(strings.errorCharsCode);
    }
}