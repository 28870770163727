import React, { useState, useEffect } from "react";

import AssessmentSheetsRoutes from "./AssessmentSheetsRoutes";

const AssessmentSheetsEngine = ({ strings, config }) => {

  return <AssessmentSheetsRoutes strings={strings} config={config} />;
};

export default AssessmentSheetsEngine;
