import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../shared/Button";
import TextInput from "../../../../shared/TextInput";
import HtmlParser from "react-html-parser";

const Centered = styled.div`
  margin: auto;
  width: 20%;
  min-height: 40%;
  padding: 3vh;
  background: lightgray; 
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
`;

const VerificationCodeForm = ({ strings, data, setData, verificateCode }) => {
    return (
        <Centered>
            <h1>{"strings.insertVerificationCode"}</h1>
            <p>{HtmlParser("Inserte el código de verificación que has recibido en la dirección de email <b>{email}</b>".replace("{email}", data?.email))}</p>

            <TextInput
                width="100%"
                value={data?.verificationCode}
                placeholder={"string.insertCodeHere"}
                onChange={(e) => {
                    let val = e.target.value;
                    setData((prev) => ({
                        ...prev,
                        verificationCode: val
                    }));
                }} />

            <Button value={strings.accept} action={verificateCode} />
        </Centered>
    );
};
export default VerificationCodeForm;