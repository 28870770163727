import React from "react";
import DayLeftCircleImage from "../../../../../assets/images/days-left-circle.png";
import FinishedCircleImage from "../../../../../assets/images/finished-circle.png";
import Colors from "../../../../../assets/Colors.json";

const PlanBanner = ({ strings, config, select }) => {
  const Wrapper = {
    backgroundColor: "white",
    borderRadius: "1vh",
    flexDirection: "column",
    fontSize: "3vh",
    padding: "4vh 3vh",
    width: "35vh",
    marginLeft: "5vh",
    height: "90%",
  };

  const H1 = {
    fontSize: "3vh",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: Colors.lightTheme.accentColor1,
  };

  const H2 = {
    fontSize: "2vh",
    textTransform: "uppercase",
    color: "black",
    marginTop: "1vh",
  };

  const Separator = {
    height: "0.2vh",
    width: "7vh",
    backgroundColor: Colors.lightTheme.accentColor1,
    margin: "2vh 0",
  };

  const PropietiesDiv = {
    margin: "auto",
    padding: "0.5vh 0",
  };

  const PropietyDiv = {
    margin: "1vh 0",
    fontSize: "2vh",
  };

  const CircleContainer = {
    height: "60%",
    position: "relative",
  };

  const Circle = {
    width: "15vw",
    height: "15vw",
    backgroundImage: `url(${DayLeftCircleImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    margin: "auto",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  };

  const FinishedCircle = {
    ...Circle,
    backgroundImage: `url(${FinishedCircleImage})`,
  };

  const TopCircle = {
    //backgroundColor: "rgba(5,255,255,0.2)",
    height: "40%",
    borderTopLeftRadius: "60vw",
    borderTopRightRadius: "60vw",
  };

  const TopCircleTag = {
    wordWrap: "breakWord",
    padding: "3vh 3vw",
    fontSize: "3vh",
    fontWeight: "bold",
    color: Colors.lightTheme.accentColor1,
    textAlign: "center",
  };

  const BottomCircle = {
    //backgroundColor: "rgba(255,255,5,0.2)",
    height: "60%",
    borderBottomLeftRadius: "60vw",
    borderBottomRightRadius: "60vw",
  };

  const BottomCircleTag = {
    wordWrap: "breakWord",
    padding: "1vh 3vw",
    fontSize: "8vh",
    fontWeight: "bolder",
    color: "white",
    textAlign: "center",
  };

  const DaysTag = {
    fontSize: "3vh",
  };

  const ButtonContainer = {
    position: "absolute",
    width: "100%",
    bottom: "0",
    left: "0",
  };

  const Button = {
    width: "100%",
    color: "white",
    margin: "0 auto",
    textAlign: "center",
    borderRadius: "1vh",
    fontWeight: "bold",
    fontSize: "2.5vh",
    cursor: "pointer",
    padding: "2vh 0",
    backgroundColor: Colors.lightTheme.accentColor1
  };

  return (
    <div style={Wrapper}>
      {config.plan ? (
        <div style={{ height: "100%", position: "relative" }}>
          {/* --- HEADER --- */}
          <div style={H1}>{config.plan.name}</div>
          <div style={H2}>{strings.actualPlan}</div>
          <div style={Separator}></div>

          {/* --- PLANS PROPIETIES --- */}
          <div style={PropietiesDiv}>
            {config.plan.visibleProperties.map((propiety, i) => {
              return (
                <div style={PropietyDiv}>
                  <b>·</b> {propiety[config.lang]}
                </div>
              );
            })}
          </div>

          {/* --- DAYS LEFT --- */}

          {config.plan && !config.plan.default ? (
            /* --- PLAN DE PAGO --- */
            <></>
          ) : config.remainingFreeDays > 0 ? (
            /* --- DIAS RESTANTES PLAN POR DEFECTO ---*/
            <div style={CircleContainer}>
              <div style={Circle}>
                <div style={TopCircle}>
                  <div style={TopCircleTag}>{strings.planFinishIn}</div>
                </div>
                <div style={BottomCircle}>
                  <div style={BottomCircleTag}>
                    {config.remainingFreeDays}
                    <div style={DaysTag}>dias</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            /* --- PLAN POR DEFECTO ACABADO ---*/
            <div style={CircleContainer}>
              <div style={FinishedCircle} onClick={select}>
                <div style={TopCircle}>
                  <div style={{ ...TopCircleTag, padding: "4vh 3vw" }}>{strings.finishedPlan}</div>
                </div>
              </div>
            </div>
          )}

          {/* --- CHANGE PLAN --- */}
          <div style={ButtonContainer}>
            <div style={Button} onClick={select}>
              {config.remainingFreeDays <= 0 ? strings.renewPlan : strings.changlePlan}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PlanBanner;
