import React, { useState, useEffect } from "react";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import ListComponent from "./ListComponent";
import { BrowserRouter as Router, useRouteMatch, useHistory, useParams } from "react-router-dom";


const ListEngine = ({ strings, config }) => {
  let { type } = useParams();
  let history = useHistory();
  let ba = new BackendAccessor();

  const [previousType, setPreviousType] = useState(null);
  const [events, setEvents] = useState(null);

  const types = {
    "live": "CONVENTIONAL_TAST",
    "podcast": "PODCAST_TAST"
  }

  const formatEvents = (events) => {
    let formatedEvents = {};
    if (events) {
      events.forEach(event => {
        let id = ba.getIdFromPath(event.reference);
        formatedEvents[id] = {
          name: event?.tasting?.name || "-",
          code: event?.sessionCode?.publicCode || "-",
          fromDate: event?.sessionCode?.fromDate ? new Date(event?.sessionCode?.fromDate) : null,
          toDate: event?.sessionCode?.toDate ? new Date(event?.sessionCode?.toDate) : null
        }
      });
    }
    return formatedEvents;
  }

  const loadEvents = () => {
    ba.getAllEventsOfType(
      types[type],
      (events) => {
        setEvents(formatEvents(events));
      },
      (error) => {
        console.error("Error loading events: ", error);
        config.popupAlert("Error", "Error loading events")
      }
    );
  }

  useEffect(() => {
    if (previousType != type || events == null) {
      setEvents(null);
      setPreviousType(type);
      loadEvents();
    }
  }, [strings, type]);

  const newEvent = () => {
    if (config.isBlocked()) return;

    checkEventsLeft(
      () => {
        history.replace(`/dashboard/events/${type}/new`);
      },
      () => {
        config.popupAlert(strings.warning, type === "live" ? strings.errorDefaultPlanSession : strings.errorDefaultPlanCampaign);
      },
      () => {
        config.popupAlert(strings.warning, type === "live" ? strings.errorMaxEvents : strings.errorMaxCampaigns, null, true, () => {
          history.replace(`/dashboard/events/${type}/new`);
        });
      },
    );
  };

  const editEvent = (id) => {
    if (config.isBlocked()) return;
    history.replace(`/dashboard/events/${type}/edit/${id}`);
  };



  const deleteEvent = (id) => {
    if (config.isBlocked()) return;
    config.popupAlert(strings.delete + " - " + events[id].name, strings.deleteConfirm, null, true, () => {
      ba.deleteEventById(
        id,
        (response) => {
          console.info("Event with id " + id + " deleted succefully", response);
          setEvents((prevState) => {
            const state = { ...prevState };
            delete state[id];
            return state;
          });
        },
        (error) => {
          console.error("Error deleting event: ", error);
        }
      );
    });
  };

  const showReport = (id) => {
    if (config.isBlocked()) return;
    let publicCode = events?.[id]?.code;
    if (publicCode) {
      history.replace(`/dashboard/reports/generated/${publicCode}`);
    }
  }

  const formatPrice = (price) => {
    return (
      price.toLocaleString("es-ES", {
        minimumFractionDigits: 2,
      }) + " €/mes"
    );
  };

  const loadPaymentData = () => {
    let paymentData = {};

    let total = Object.entries(events || {}).length;
    if (total == 0) return null;

    let planLimit;
    if (type === "live") {
      if (config?.cellar?.billing?.maxLiveEvents) {
        planLimit = config?.cellar?.billing?.maxLiveEvents
      } else {
        planLimit = config?.plan?.maxMonthlyEvents;
      }
    } else {
      if (config?.cellar?.billing?.maxPodcastEvents) {
        planLimit = config?.cellar?.billing?.maxPodcastEvents
      } else {
        planLimit = config?.plan?.maxMonthlyCampaigns;
      }
    }

    if (planLimit - total >= 0) {
      paymentData.includedActive = total;
      paymentData.includedLeft = planLimit - total;
      paymentData.additional = 0;
    } else {
      paymentData.includedActive = planLimit;
      paymentData.includedLeft = 0;
      paymentData.additional = total - planLimit;
    }

    paymentData.planLimit = planLimit;
    paymentData.additionalPayment = formatPrice(paymentData.additional * 9);
    return paymentData;
  };

  const checkEventsLeft = (success, noPlan, payment) => {
    let planLimit;
    if (type === "live") {
      if (config?.cellar?.billing?.maxLiveEvents) {
        planLimit = config?.cellar?.billing?.maxLiveEvents
      } else {
        planLimit = config?.plan?.maxMonthlyEvents;
      }
    } else {
      if (config?.cellar?.billing?.maxPodcastEvents) {
        planLimit = config?.cellar?.billing?.maxPodcastEvents
      } else {
        planLimit = config?.plan?.maxMonthlyCampaigns;
      }
    }

    let total = Object.entries(events || {}).length;
    if (total < planLimit) {
      success();
    } else if (config.plan.default || !config.cellar.stripeCustomerId) {
      noPlan();
    } else {
      payment();
    }
  };

  return (
    <ListComponent
      strings={strings}
      type={types[type]}
      config={config}
      events={events}
      paymentData={loadPaymentData()}
      ready={events != null}
      newEvent={newEvent}
      editEvent={editEvent}
      deleteEvent={deleteEvent}
      showReport={showReport}
    />
  );
};
export default ListEngine;
