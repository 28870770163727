import React from "react";
import styled from "@emotion/styled";
import Colors from "../../assets/Colors.json";
const TerciaryButton = ({ id = "actionButton", value, action=()=>{}, style }) => {
  let theme = "lightTheme";
  let colors = Colors[theme];
  const Wrapper = styled["div"]`
    display: inline-block;
    flex: 0 1 auto; /* 1 */
    overflow: hidden;
    border: none;
    background-color: #757a80;
    color: white;
    padding: 1vh 3vh;
    cursor: pointer;
    text-align: center;
    transition: 0.1s;
    text-transform: uppercase;
    border-radius: 0.5vh;
    ${style};
    &:hover {
      background-color: #6d7278;
    }
  `;
  return (
    <Wrapper id={id} onClick={(e) => action(e)}>
      {value}
    </Wrapper>
  );
};

export default TerciaryButton;
