import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import FormEngine from "./Form/FormEngine";
import CustomPageEngine from "./CustomPageForm/CustomPageEngine";
import LoadingScreen from "../../../../shared/LoadingScreen";

const ThemeRoutes = ({ strings, config }) => {
  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
  };

  let { path } = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    if (config?.plan !== null && config?.plan !== undefined) {
      if (!config?.plan?.customThemes) {
        let confirmed = false;
        config.popupAlert(
          strings.featureNotAllowed,
          strings.featureNotAllowedText,
          () => {
            if (!confirmed) {
              history.replace("/dashboard/home");
            }
          },
          true,
          () => {
            confirmed = true;
            history.replace("/dashboard/cellar/plan");
          },
          strings.close,
          strings.changePlan
        );
      } else {
        config.closePopupAlert();
      }
    }
  }, [config?.plan]);

  return (
    <div style={Wrapper}>
      <Switch>
        <Route path={`${path}/edit`}>
          <FormEngine strings={strings} config={config} />
        </Route>
        <Route path={`${path}/editCustomPage/:id`}>
          <CustomPageEngine strings={strings} config={config} />
        </Route>
      </Switch>
    </div>
  );
};

export default ThemeRoutes;
