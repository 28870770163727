import React, { useState, useEffect } from "react";
import FormComponent from "./FormComponent";
import Firebase from "../../../../../../assets/Firebase";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";

const EditFormEngine = ({ strings, config, refresh }) => {
  let { id } = useParams();

  let f = new Firebase();
  let history = useHistory();

  const [instructor, setInstructor] = useState({});
  const [ready, setReady] = useState(false);

  const [blocked, setBlocked] = useState(false);

  const loadInstructor = (doc) => {
    setInstructor({
      id: doc.ref,
      name: doc.data().name,
    });
    setReady(true);
  };
  useEffect(() => {
    f.loadInstructor(id, loadInstructor);
  }, [strings]);

  const confirmAction = () => {
    setBlocked(true);
    let errorMsg = null;
    let obj = {};
    "name" in instructor ? (obj.name = instructor.name) : (errorMsg = '"Nombre"');
    obj.imgProfile = "gs://smartwinetast-91aca.appspot.com/CommonFiles/default_instructor.webp";
    obj.group = Firebase.user.uid;
    obj.deleted = false;
    if (!errorMsg) {
      f.updateInstructor(id, obj, success);
    } else {
      setBlocked(false);
      config.popupAlert(strings.error, errorMsg + " " + strings.cantBeNull);
    }
  };

  const success = () => {
    setBlocked(false);
    config.update("modificated", false);
    refresh();
    config.popupAlert(strings.success, strings.successMsg, () => {
      history.replace(`/dashboard/instructors/list`);
    });
  };

  const cancelAction = () => {
    config.isModificated(() => {
      history.replace(`/dashboard/instructors/list`);
    });
  };

  const setInstructorFiltered = (input) => {
    if (!config.modificated) {
      config.update("modificated", true);
    }
    setInstructor(input);
  };

  return ready ? (
    <FormComponent
      strings={strings}
      config={config}
      instructor={instructor}
      setInstructor={setInstructorFiltered}
      cancelAction={cancelAction}
      confirmAction={confirmAction}
      blocked={blocked}
    />
  ) : (
    <LoadingScreen ready={ready} />
  );
};

export default EditFormEngine;
