import React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import Colors from "../../../assets/Colors.json";
import FormEngine from "./Form/FormEngine.js";
import ActionsEngine from "./Actions/ActionsEngine.js";

const SignupRoutes = ({ strings, popupAlert }) => {
  const colors = Colors.lightTheme;
  let { path } = useRouteMatch();

  const Wrapper = {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: colors.grey,
  };

  return (
    <div style={Wrapper}>
      <Switch>
        <Route path={`${path}/action`}>
          <ActionsEngine strings={strings} popupAlert={popupAlert} />
        </Route>
        <Route path={`${path}`}>
          <FormEngine strings={strings} />
        </Route>
      </Switch>
    </div>
  );
};

export default SignupRoutes;
