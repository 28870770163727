import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Colors from "../../assets/Colors.json";
import IcOrderByAscending from "../../assets/images/ic_contract.png";
import IcOrderByDescending from "../../assets/images/ic_expand.png";
import LoadingScreen from "./LoadingScreen";
import Button from "./Button";
import writeXlsxFile from 'write-excel-file';
import RateStars from "./RateStars";

const TableRanking = ({ strings, header, data, actions, ready, noElementsText, sortByField = null, sortOrder = "ascending", styles, enableExport = false, onEventsBind }) => {
  let theme = "lightTheme";
  let colors = Colors[theme];

  let [sortedField, setSortedField] = useState(sortByField);
  let [sortType, setSortType] = useState(sortOrder);

  const sortIcons = {
    ascending: IcOrderByAscending,
    descending: IcOrderByDescending,
  };

  const Table = styled.table`
    border-collapse: collapse;
    th {
      text-align: left;
      padding: 2vh 5vh;
      font-size: 2.5vh;
    }

    td {
      text-align: left;
      padding: 2vh 3vh;
      color: #53617b;
      font-weight: bold;
    }

    th {
      padding: 2vh 3vh;
      text-align: left;
      background-color: #f9fafc;
      color: #a6adbb;
    }
    
    tr {
      background-color: white;
      border: 1px solid #f0f0f0;
    }
    ${styles};
  `;

  const LoadingWrapper = styled.td`
    width: 100%;
    background-color: white;
  `;

  const ActionsDiv = styled.td`
    display: flex;
    padding: 5vh 5vh !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 56vh;

    > div {
      /* margin: 0 3vh 0 0; */
      cursor: pointer;
    }
    img {
      width: 2.5vh;
    }
  `;

  const NoElements = styled.div`
    text-align: center;
  `;

  const SortIcon = styled.img`
    width: 1.7vh;
    height: 1.7vh;
    margin: auto auto auto 0.5vw;
  `;

  const ProductImage = styled.img`
    height: 10vh;
    width: auto;
    margin: auto;
    padding: 0.5vh 2vh;
  `;

  const DivTable = styled.div`
    overflow-y: auto;
    max-height: 50vh;
  `;

  const Td = {
    textAlign: "center"
  };

  const sortBy = (key) => {
    if (key === sortedField) {
      if (sortType === "ascending") {
        setSortType("descending");
      } else {
        setSortedField(null);
      }
    } else {
      setSortedField(key);
      setSortType("ascending");
    }
    console.log("Sort by" + key);
  }

  const loadHeader = () => {
    let Views = [];
    let i = 0;
    for (let key in header) {
      let sorteable = true;
      if (i === Object.keys(header).length - 1) {
        sorteable = false;
      }

      Views.push(
        <th key={key}
          onClick={sorteable ? () => { sortBy(key) } : null}
          style={{ cursor: sorteable ? "pointer" : "default" }}>
          {header[key]}
          {sortedField === key ? (
            <SortIcon src={sortIcons[sortType]} />
          ) : (
            <></>
          )}
        </th>);
      i++;
    }
    return Views;
  };

  const getOrderedData = () => {
    if (sortedField !== null && sortType !== null) {
      var entries = [];
      for (let key in data) {
        entries.push([key, data[key]]);
      }

      entries.sort(function (a, b) {
        let value = 0;
        if (a[1][sortedField] > b[1][sortedField]) value = 1;
        if (a[1][sortedField] < b[1][sortedField]) value = -1;
        if (sortType === "descending") value *= -1;
        return value;
      });

      var sortedData = {};
      entries.forEach(entry => sortedData[entry[0]] = entry[1]);
      return sortedData
    } else {
      return data;
    }
  }

  const formateFieldValue = (value) => {
    if (value instanceof Date) {
      return value.toLocaleString();
    } else if (value === null || value === undefined) {
      return "-";
    } else if (value.toString().includes("https://storage.googleapis.com/")) {
      return <ProductImage src={value} />;
    } else {
      return value;
    }
  }

  const loadData = () => {
    let Views = [];
    let sortedData = getOrderedData();

    for (let key in sortedData) {
      let entry = sortedData[key];
      let Cells = [];
      for (let attr in entry) {
        if (attr !== "reference") {
          if (attr === "rate") {
            Cells.push(<td style={Td} key={attr}>{
              <RateStars
                value={entry[attr]}
                clear={false}
                size={4}
                color1={"#4d4d4d"}
                color2={colors.accentColor1}
              />
            }</td>);
          } else {
            Cells.push(<td style={Td} key={attr}>{formateFieldValue(entry[attr])}</td>);
          }
        }
        else {
          if (actions != null) {
            let Actions = [];
            actions.forEach((action) => {
              Actions.push(
                <div
                  key={typeof action.title === "function" ? action.title(key) : action.title}
                  onClick={() => {
                    if (action.id === "goToLink") {
                      action.action(entry[attr].url);
                    }
                    else {
                      action.action(entry[attr].productReference, entry[attr].assistantReference);
                    }
                  }}
                  style={action.style ? action.style(key) : undefined}
                >
                  {typeof action.title === "function" ? action.title(key) : action.title}
                </div>
              );
            });
            Cells.push(<ActionsDiv >{Actions}</ActionsDiv>);
          }
        }
      }

      Views.push(<tr key={key}>{Cells}</tr>);
    }
    return Views;
  };

  const exportExcel = async () => {
    const data = [];

    let hadersRow = [];
    Object.entries(header || {}).forEach(([headerId, headerTxt]) => {
      if (headerId == "actions") return;
      hadersRow.push({
        value: headerTxt,
        fontWeight: 'bold'
      })
    });
    data.push(hadersRow);

    let rawData = getOrderedData();
    Object.entries(rawData || {}).forEach(([dataId, dataVal]) => {
      let dataRow = [];

      Object.entries(dataVal || {}).forEach(([entryId, entryVal]) => {
        if (typeof entryVal === 'string' || entryVal instanceof String) {
          dataRow.push({
            type: String,
            value: entryVal,
          });
        } else if (entryVal instanceof Date) {
          dataRow.push({
            type: Date,
            value: entryVal,
            format: 'dd/mm/yyyy'
          });
        } else if (typeof entryVal == "boolean" || entryVal instanceof Boolean) {
          dataRow.push({
            type: Boolean,
            value: entryVal,
          });
        } else if (typeof entryVal === 'number' || entryVal instanceof Number) {
          dataRow.push({
            type: Number,
            value: entryVal,
          });
        } else if (typeof entryVal === 'object' && entryVal?.props?.alt) {
          dataRow.push({
            type: String,
            value: entryVal?.props?.alt,
          });
        } else {
          dataRow.push({
            type: String,
            value: "-",
          });
        }
      });

      data.push(dataRow);
    });

    await writeXlsxFile(data, {
      fileName: 'export.xlsx'
    });
  }

  useEffect(() => {
    if (typeof onEventsBind === "function") {
      onEventsBind({
        export: exportExcel
      });
    }
  }, []);

  return (
    <>
      {enableExport ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button value={strings?.excelExport || "export"} action={exportExcel} style={"margin-bottom: 1vh; backgroun-color: red !important;"} />
        </div>
      ) : (
        <></>
      )}

      <DivTable>
        <Table id="table-to-xls">
          <thead>
            <tr>{loadHeader()}</tr>
          </thead>
          <tbody>
            {data != null && Object.entries(data).length > 0 ? (
              loadData()
            ) : (
              <tr>
                {ready ? (
                  <LoadingWrapper colSpan={Object.entries(header).length}>
                    <NoElements>{noElementsText}</NoElements>
                  </LoadingWrapper>
                ) : (
                  <LoadingWrapper colSpan={Object.entries(header).length}>
                    <LoadingScreen />
                  </LoadingWrapper>
                )}
              </tr>
            )}
          </tbody>
        </Table>
      </DivTable>
    </>
  );
};

export default TableRanking;
