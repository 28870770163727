import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import Select from "../../../../../../shared/Select";
import BackendAccessor from "../../../../../../../assets/BackendAccessor";
import TextInput from "../../../../../../shared/TextInput";
import Button from "../../../../../../shared/Button";
import CrossIconGrey from "../../../../../../../assets/images/cross-icon-grey.svg";
import Colors from "../../../../../../../assets/Colors.json";

const QuizForm = ({ strings, config, productReference, updateProductRef }) => {
  let ba = new BackendAccessor();

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);

  useEffect(() => {
    const quizQuestions = productReference?.quizQuestions || [];
    setQuizQuestions(quizQuestions);
    setActiveQuestion(0);
  }, [productReference?.quizQuestions]);

  const updateQuizQuestion = (parameter, value) => {
    let quizQuestions = productReference?.quizQuestions || [];
    let question = quizQuestions[activeQuestion] || {};
    question[parameter] = value;

    updateProductRef("quizQuestions", quizQuestions);
  };

  const updateQuizOption = (optionId, value) => {
    let quizQuestions = productReference?.quizQuestions || [];
    let question = quizQuestions[activeQuestion] || {};
    let options = question?.options || {};
    options[optionId] = value;

    updateQuizQuestion("options", options);
  };

  const addQuestion = (key = ba.createUid()) => {
    let quizQuestions = productReference?.quizQuestions || [];
    quizQuestions.push({});

    updateProductRef("quizQuestions", quizQuestions);
    setActiveQuestion(quizQuestions.length - 1);
  };

  const deleteQuestion = (index) => {
    let quizQuestions = productReference?.quizQuestions || [];
    quizQuestions.splice(index, 1);

    updateProductRef("quizQuestions", quizQuestions);
    setActiveQuestion(0);
  };

  const Tag = styled.div`
    padding: 0.5rem;
  `;

  const Wrapper = {
    backgroundColor: "#ededed",
    borderRadius: "0px 6px 6px 6px",
    padding: "2vh",
  };

  const Divider = {
    borderTop: "0.1vh solid #6d7278",
    marginTop: "3vh",
    paddingTop: "0vh",
    marginBottom: "1vh",
    paddingBottom: "1vh",
    textAlign: "center",
  };

  const Header = {
    margin: "2vh",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
  };

  const PrizeContainer = {
    textAlign: "left",
    paddingBottom: "2vh",
  };

  const IndexActive = styled.div`
    padding: 1vh 2.5vh;
    cursor: pointer;
    background-color: #ededed;
    color: black;
    border-radius: 6px 6px 0px 0px;
    display: flex;
  `;

  const Index = styled.div`
    padding: 1vh 2vh;
    background-color: #d8d8d8;
    cursor: pointer;
    color: #6d7278;
    border-radius: 6px 6px 0px 0px;
    display: flex;
  `;

  const AddIndexItem = styled.div`
    padding: 1vh 2vh;
    background-color: ${Colors.lightTheme.accentColor1};
    cursor: pointer;
    color: white;
    border-bottom: 0px;
    border-radius: 6px 6px 0px 0px;
  `;

  const IndexWrapper = styled.div`
    display: flex;
  `;

  const DeleteImg = styled.img`
    padding: 0.5vh;
    height: 1.5vh;
    margin-left: 0.5vw;
    border-radius: 50%;
    &:hover {
      background-color: Gray;
    }
  `;

  const loadIndex = () => {
    let View = [];
    let index = 1;

    for (let key in quizQuestions) {
      View.push(
        <>
          {key == activeQuestion ? (
            <IndexActive key={key}>
              <div>{strings.quizQuestion + " " + index++}</div>
              <DeleteImg src={CrossIconGrey} onClick={() => deleteQuestion(key)}></DeleteImg>
            </IndexActive>
          ) : (
            <Index
              key={key}
              onClick={() => {
                setActiveQuestion(key);
              }}
            >
              <div>{strings.quizQuestion + " " + index++}</div>
              <DeleteImg src={CrossIconGrey} onClick={() => deleteQuestion(key)}></DeleteImg>
            </Index>
          )}
        </>
      );
    }

    View.push(
      <AddIndexItem
        key="add"
        onClick={() => {
          addQuestion();
        }}
      >
        {strings.addQuizQuestion}
      </AddIndexItem>
    );
    return View;
  };

  return (
    <div style={Divider}>
      <h4 style={Header}>{strings.quiz}</h4>
      {Object.entries(quizQuestions).length == 0 ? (
        /* ------- NO HAY QUIZ -----------*/
        <Button action={addQuestion} value={strings.addQuiz} />
      ) : (
        /* -------- HAY QUIZ ------------*/
        <div>
          <div style={PrizeContainer}>
            <Tag>{strings.prize}</Tag>
            <TextInput
              width="100%"
              styles={{ width: "100%" }}
              defaultValue={productReference?.quizPrize || ""}
              id={"quizPrize"}
              onChange={(e) => {
                updateProductRef("quizPrize", e.target.value);
              }}
            />
          </div>
          <IndexWrapper>{loadIndex()}</IndexWrapper>

          <div style={Wrapper}>
            {activeQuestion == undefined ? (
              /* ----- No question selected ------*/
              <p>{strings.noneQuestionSelected}</p>
            ) : (
              /* ----- Question selected ------*/
              <table style={{ width: "100%", textAlign: "left" }}>
                <tbody>
                  <tr>
                    <td>
                      <Tag>
                        <b>{strings.question}*</b>
                      </Tag>
                      <TextInput
                        defaultValue={quizQuestions?.[activeQuestion]?.question?.[config.lang] || ""}
                        id={"question"}
                        maxLength={90}
                        onChange={(e) => {
                          updateQuizQuestion("question", {
                            CA: e.target.value,
                            ES: e.target.value,
                            EN: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Tag>
                        <b>{strings.answer} 1*</b>
                      </Tag>
                      <TextInput
                        defaultValue={quizQuestions?.[activeQuestion]?.options?.answer1?.[config.lang] || ""}
                        id={"answer1"}
                        onChange={(e) => {
                          updateQuizOption("answer1", {
                            CA: e.target.value,
                            ES: e.target.value,
                            EN: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Tag>
                        <b>{strings.answer} 2*</b>
                      </Tag>
                      <TextInput
                        id={"answer2"}
                        defaultValue={quizQuestions?.[activeQuestion]?.options?.answer2?.[config.lang] || ""}
                        onChange={(e) => {
                          updateQuizOption("answer2", {
                            CA: e.target.value,
                            ES: e.target.value,
                            EN: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Tag>
                        <b>{strings.correctAnswer}*</b>
                      </Tag>
                      <Select
                        id={"correct"}
                        options={{
                          notSelected: strings.notSelected,
                          answer1: `${strings.answer}1`,
                          answer2: `${strings.answer}2`,
                          answer3: `${strings.answer}3`,
                          answer4: `${strings.answer}4`,
                        }}
                        defaultOption={quizQuestions?.[activeQuestion]?.correct?.[0]}
                        onChange={(id, value) => updateQuizQuestion(id, [value])}
                      />
                    </td>
                    <td>
                      <Tag>
                        <b>{strings.answer} 3*</b>
                      </Tag>
                      <TextInput
                        id={"answer3"}
                        defaultValue={quizQuestions?.[activeQuestion]?.options?.answer3?.[config.lang] || ""}
                        onChange={(e) => {
                          updateQuizOption("answer3", {
                            CA: e.target.value,
                            ES: e.target.value,
                            EN: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Tag>
                        <b>{strings.answer} 4*</b>
                      </Tag>
                      <TextInput
                        id={"answer4"}
                        defaultValue={quizQuestions?.[activeQuestion]?.options?.answer4?.[config.lang] || ""}
                        onChange={(e) => {
                          updateQuizOption("answer4", {
                            CA: e.target.value,
                            ES: e.target.value,
                            EN: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default QuizForm;
