import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import TableSession from "../../../../../shared/TableSession";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
`;

const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2vh 0;

  > * {
    padding: 0.5vh;
    margin: 0 2vh;
  }
`;

const DivTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const DivTitle = styled.div`
  text-transform: uppercase;
  font-size: 4vh;
  position: absolute;
  left: 4%;
  top: 25%;
`;

const DivLeftTitle = styled.div`
  position: relative;
  height: 10vh;
  display: flex;
  justify-content: center;
`;

const BorderTable = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 2vh;
  box-shadow: 0 0 7vh 1em #c6c6c6;
`;

const ButtonLR = styled.button`
  background-color: white;
  font-size: 4vh;
  color: #f52c56;
  cursor: pointer;
  border: none;
  border-radius: 2vh;
  
  &:hover {
    font-size: 5vh;
    box-shadow: 0 0 15px 0.1em #c6c6c6;
  }
`;

const ListComponent = ({ strings, assistants, config, page, nextPage, prevPage, moreDetails, lang, selectLang, data }) => {

  let action = [
    {
      title: (
        <Button
          value={strings.moreInfo}
        />
      ),
      action: moreDetails,
    }
  ];

  return (
    <Wrapper>
      <DivTable>
        <BorderTable>
          <DivLeftTitle>
            <DivTitle>{strings.listWines}</DivTitle>
            <Nav>
              <ButtonLR onClick={prevPage}>{"<"}</ButtonLR>
              <div>{page}</div>
              <ButtonLR onClick={nextPage}>{">"}</ButtonLR>
            </Nav>
          </DivLeftTitle>
          <TableSession
            header={strings.SessionsTable}
            data={data}
            actions={action}
            ready={true}
            sortOrder={"descending"}
            noElementsText={strings.noneList} />
          <br></br>
        </BorderTable>
      </DivTable>
    </Wrapper>
  );
};
export default ListComponent;
