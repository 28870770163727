import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import TerciaryButton from "../../../../../shared/TerciaryButton";
import Table from "../../../../../shared/Table";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import CloneIcon from "../../../../../../assets/images/copy-clipboard.svg";
import Tooltip from "@material-ui/core/Tooltip";

const ListComponent = ({ strings, config, assessmentSheets, create, edit, clone, deleteAS, importAS }) => {
  const Container = styled.div`
    width: 100%;
  `;
  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;
  const Wrapper = styled.div`
    flex-grow: 1;
    padding: 2vh 5vh;
    > * {
      margin: 2vh 0;
    }
  `;

  const Icon = styled.img``;
  let actions = [
    {
      title: (
        <Tooltip title={strings.cloneAssessmentSheet} aria-label="add">
          <Icon src={CloneIcon} />
        </Tooltip>
      ),
      action: clone,
    },
    {
      title: (
        <Tooltip title={strings.modifyAssessmentSheet} aria-label="add">
          <Icon src={EditIcon} />
        </Tooltip>
      ),
      action: edit,
    },
    {
      title: (
        <Tooltip title={strings.deleteAssessmentSheet} aria-label="add">
          <Icon src={DeleteIcon} />
        </Tooltip>
      ),
      action: deleteAS,
    },
  ];

  let getFormatedAssessmentSheets = () => {
    let formatedData = {};
    Object.entries(assessmentSheets).forEach(([key, assessmentSheet]) => {
      let entry = {
        name: assessmentSheet?.name[config.lang],
        product: assessmentSheet?.productData?.name[config.lang] || "-",
        type: assessmentSheet?.subtypeData?.name[config.lang] || "-",
        lastUpdate: assessmentSheet?.lastUpdate ? new Date(assessmentSheet?.lastUpdate) : null,
      };
      formatedData[key] = entry;
    });
    return formatedData;
  };

  return (
    <Container>
      <Title>{strings.assessmentSheetsList}</Title>
      <Wrapper>
        <Table
          header={strings.AssessmentSheetsTable}
          data={getFormatedAssessmentSheets()}
          actions={actions}
          ready={true}
          noElementsText={strings.noneList}
          sortByField={"lastUpdate"}
          sortOrder={"descending"} />
        <div>
          <Button value={strings.addAssessmentSheet} action={create} />
        </div>
        <div>
          <TerciaryButton value={strings.importAssessmentSheet} action={importAS} />
        </div>
      </Wrapper>
    </Container>
  );
};

export default ListComponent;
