import React, { useState } from "react";
import Colors from "../../../../../../../assets/Colors.json";
import Button from "../../../../../../shared/Button";
import IconButton from "../../../../../../shared/IconButton";
import BackIcon from "../../../../../../../assets/images/back-arrow-icon.svg";
import PcIcon from "../../../../../../../assets/images/ic_pc.png";
import MobileIcon from "../../../../../../../assets/images/ic_mobile.jpg";
import TabletIcon from "../../../../../../../assets/images/ic_tablet.png";
import styled from "@emotion/styled";
import Table from "../../../../../../shared/Table";
import { isNullOrUndefined } from "../../../../../../../assets/utils/FormsUtils";

const AssistantListFormComponent = ({ strings, config, sessionData, goBack, goToDetails }) => {
    let [listEvents, setListEvents] = useState(null);

    const Subheader = styled.div`
        display: flex;
        justify-content: space-between;
        margin: 1.5rem 0.5rem 1rem 0rem;
        font-weight: bold;
        font-size: 2.5vh;
        color: ${Colors.lightTheme.accentColor1};
        padding-bottom: 0.5rem;
        border-bottom: 1px solid gray;
    `;

    const Icon = styled.img`
        height: 3vh;
        width: auto;
        padding: 0.5vh;
    `;

    const getPlatformIcon = (platform) => {
        switch (platform) {
            case "TABLET_WEB":
                return <Icon src={TabletIcon} alt={platform} />
            case "MOBILE_WEB":
                return <Icon src={MobileIcon} alt={platform} />
            case "PC_WEB":
                return <Icon src={PcIcon} alt={platform} />
            default:
                return platform;
        }
    }

    const formatDate = (date) => {
        let dateObj = new Date(date);
        return dateObj;
    }

    const getGeocation = (geocation) => {
        let formated = null;
        let containsCountry = false;

        if (geocation?.city) {
            formated = geocation?.city;
        } else if (geocation?.regionName) {
            formated = geocation?.regionName;
        } else if (geocation?.country) {
            formated = geocation?.country;
            containsCountry = true;
        }

        if (!containsCountry && formated != null && geocation?.country) {
            formated += " (" + geocation?.country + ")";
        }
        return formated;
    }

    const getAssistantDate = (assistant) => {
        if (!isNullOrUndefined(assistant.lastUpdate)) {
            return formatDate(assistant.lastUpdate);
        } else if (!isNullOrUndefined(assistant.creationDate)) {
            return formatDate(assistant?.creationDate);
        }
        return "-";
    }

    const getAssistants = () => {
        let assistants = {};

        Object.entries(sessionData?.assistants).map(([assistantId, assistant]) => {
            let assistantData = {
                name: assistant?.name === "Lite assistant" ? "-" : assistant?.name || "-",
                date: getAssistantDate(assistant),
                email: assistant?.email || "-",
                age: assistant?.age || "-",
                gender: assistant?.gender || "-",
                platform: assistant?.platform ? getPlatformIcon(assistant?.platform) : "-",
                /* geolocation: assistant?.geolocation ? getGeocation(assistant?.geolocation) : "-", */
            }
            assistants[assistantId] = assistantData;
        });

        return assistants;
    }

    let tableHeaders = {
        name: strings.name,
        date: strings.date,
        email: strings.email,
        age: strings.age,
        gender: strings.gender,
        platform: strings.platform,
        /* geolocation: strings.geolocation, */
        actions: strings.actions,
    }

    let actions = [
        {
            title: (
                <Button style={"margin: auto;"} value={strings.showMoreDetils} />
            ),
            action: goToDetails,
        }
    ];

    return (
        <div style={{ width: "100%" }}>
            <div style={{ padding: "1vh 3vh" }}>
                <IconButton
                    action={goBack}
                    icon={BackIcon}
                    text={strings.returnBack}
                    style={{ margin: "1vh" }}
                    iconStyle={{ height: "3vh" }} />

                <Subheader>
                    {strings.listOf} {(Object.entries(sessionData?.assistants).length || 0) + " "}{strings.assistants?.toLowerCase()}
                    <Button value={strings?.excelExport} action={listEvents?.export} />
                </Subheader>

                <Table
                    strings={strings}
                    header={tableHeaders}
                    data={getAssistants()}
                    actions={actions}
                    noElementsText={strings.noReports}
                    ready={true}
                    sortByField={"date"}
                    sortOrder={"descending"}
                    onEventsBind={setListEvents}
                    styles={`
                        width: 99%;
                        max-width: 99%;
                        th {
                            padding: 2vh 2vh;
                        }
                        td {
                            padding: 1vh 2vh;
                        }
                    `} />
            </div>
        </div >
    );
};

export default AssistantListFormComponent;
