import React, { useState, useEffect } from "react";
import Colors from "../../../../../../../assets/Colors.json";
import IconButton from "../../../../../../shared/IconButton";
import BackIcon from "../../../../../../../assets/images/back-arrow-icon.svg";
import CategoriesWrapperForm from "./CategoriesWrapperForm";
import styled from "@emotion/styled";
import { Bar, Pie, Line } from 'react-chartjs-2';

const ProductFormComponent = ({ strings, config, sessionData, productData, dataParser, goBack }) => {
    const Subheader = styled.div`
        margin: 1.5rem 0.5rem 1rem 0rem;
        font-weight: bold;
        font-size: 2.5vh;
        color: ${Colors.lightTheme.accentColor1};
        padding-bottom: 0.5rem;
        border-bottom: 1px solid gray;
    `;

    const MainTable = styled.table`
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        td {
            padding: 2vh;
        }
        th {
            padding: 2vh;
            text-align: left;
            background-color: rgba(0, 0, 0, 0.1);
        }
        tr:nth-of-type(even) {
            background-color: rgba(255, 255, 255, 0.5);
        }
        tr {
            background-color: white;
        }
    `;

    const Text = styled.div`
        margin: 0.5rem;
        font-weight: bold;
        font-size: 2vh;
        color: ${Colors.lightTheme.accentColor1};
        text-align: center;
    `;

    return (
        <div style={{ width: "100%" }}>
            <div style={{ padding: "1vh 3vh" }}>
                <IconButton
                    action={goBack}
                    icon={BackIcon}
                    text={strings.returnBack}
                    style={{ margin: "1vh" }}
                    iconStyle={{ height: "3vh" }} />

                <div style={{ display: "flex" }}>
                    <div style={{ width: "38%" }}>
                        <Subheader>{strings.dataFromProduct}</Subheader>
                        <table>
                            <tr>
                                <td rowSpan="5">
                                    <img style={{ height: "15vh", margin: "0 2vw" }} src={productData?.product?.image} />
                                </td>
                            </tr>
                            <tr>
                                <th>{strings.name}:</th>
                                <td>{productData?.product?.name || "-"}</td>
                            </tr>
                            <tr>
                                <th>{strings.type}:</th>
                                <td>{productData?.product?.type?.name?.[config.lang] || "-"}</td>
                            </tr>
                            <tr>
                                <th>{strings.subtype}:</th>
                                <td>{productData?.product?.subtype?.name?.[config.lang] || "-"}</td>
                            </tr>
                            <tr>
                                <th>{strings.price}:</th>
                                <td>{productData?.product?.price || "-"} {config?.cellar?.currency || "€"}</td>
                            </tr>
                        </table>
                        <Subheader>{strings.store}</Subheader>
                        <table>
                            <tr>
                                <th>{strings.storeLinkClicks}:</th>
                                <td>{dataParser.getStoreClicksCount()}</td>
                            </tr>
                        </table>
                    </div>
                    <div style={{ width: "60%", paddingLeft: "2%" }}>
                        <Subheader>{strings.productPuntuation}</Subheader>
                        <div style={{ display: "flex" }}>

                            {dataParser.containsAgregatedParameter("rateBox") ? (
                                <div style={{ width: "48%", margin: "1%", height: "25vh" }}>
                                    <Bar options={{ plugins: { legend: { display: false } } }} data={dataParser.getNumericalParameterGraphData("rateBox", 0, 5, 0.5)} />
                                    <Text>{strings.averageScore}: {dataParser.getParameterAvg("rateBox") || "-"}</Text>
                                </div>
                            ) : (
                                <></>
                            )}

                            {sessionData?.tasting?.template === "BLIND_TEMPLATE" ? (
                                <div style={{ width: "48%", margin: "1%" }}>
                                    <Line options={{ plugins: { legend: { display: false } } }} data={dataParser.getNumericalParameterLineGraphData("blindPrice", 10)} />
                                    <Text>{strings.averagePrice}: {dataParser.getParameterAvg("blindPrice") || "-"} {config?.cellar?.currency || "€"}</Text>
                                </div>
                            ) : (
                                    <div style={{ width: "48%", height: "25vh", margin: "1%" }}>
                                        <Pie options={{ maintainAspectRatio: false }} data={dataParser.getParameterGraphData("ratePrice", strings.priceQualityAssessment)} />
                                        <Text>{strings.totalVotes}: {dataParser.getParameterTotal("ratePrice") || "-"}</Text>
                                    </div>
                            )}
                        </div>
                    </div>
                </div>

                <Subheader>{strings.productAssessments}</Subheader>

                <CategoriesWrapperForm
                    strings={strings}
                    config={config}
                    productData={productData}
                    dataParser={dataParser}
                />

                <Subheader>{strings.quizWinners}</Subheader>
                <MainTable style={{ width: "100%" }}>
                    <tr>
                        <th>{strings.name}</th>
                        <th>{strings.email}</th>
                        <th>{strings.prize}</th>
                        <th>{strings.date}</th>
                        <th>{strings.points}</th>
                    </tr>
                    {
                        (dataParser.getQuizWinners() || []).map((winner) => {
                            return (
                                <tr>
                                    <td>{winner?.assistant?.name || "-"}</td>
                                    <td>{winner?.assistant?.email || "-"}</td>
                                    <td>{winner?.price || "-"}</td>
                                    <td>{(winner?.date?.toLocaleDateString() || "-") + " " + (winner?.date?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) || "-")}</td>
                                    <td>{winner?.points || "-"}</td>
                                </tr>
                            )
                        })
                    }
                </MainTable>
            </div>
        </div >
    );
};

export default ProductFormComponent;