import React from "react";
import styled from "@emotion/styled";
const Steps = ({ strings }) => {
  const Wrapper = {
    color: "white",
    display: "flex",
    flexDirection: "column-reverse",
  };
  const H1 = {
    fontSize: "4vh",
    fontWeight: "bold",
  };
  const Separator = {
    height: "0.2vh",
    width: "5vh",
    backgroundColor: "white",
    margin: "2vh 0",
  };

  const StepsWrapper = styled.div`
    font-size: 2.5vh;
    margin-top: 3vh;
    > div {
      margin: 1.5vh 0;
    }
  `;

  const SubStep = {
    marginLeft: "1.5vw",
  };

  return (
    <div style={Wrapper}>
      <div>
        <div style={H1}>{strings.welcome}</div>
        <div style={H1}>{strings.upwineEditor}</div>
        <div style={Separator} />
        <div>{strings.stepsToBegin}:</div>
        <StepsWrapper>
          <div>1. {strings.step1}</div>
          <div>2. {strings.step2}</div>
          <div>3. {strings.step3}</div>
          <div style={SubStep}>3.1. {strings["step3-1"]}</div>
          <div style={SubStep}>3.2. {strings["step3-2"]}</div>
          <div style={SubStep}>3.3. {strings["step3-3"]}</div>
          <div>4. {strings.step4}</div>
          <div style={SubStep}>4.1. {strings["step4-1"]}</div>
          <div style={SubStep}>4.2. {strings["step4-2"]}</div>
          <div style={SubStep}>4.3. {strings["step4-3"]}</div>
          <div>5. {strings.step5}</div>
        </StepsWrapper>
      </div>
    </div>
  );
};

export default Steps;
