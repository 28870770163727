import React from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../../shared/TextInput";
import ImagePicker from "../../../../../shared/ImagePicker";
import CrossIconGrey from "../../../../../../assets/images/cross-icon-grey.svg";
import ArrowLeftIcon from "../../../../../../assets/images/ic_contract_left.png";
import ArrowRightIcon from "../../../../../../assets/images/ic_contract_right.png";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import AddIcon from "../../../../../../assets/images/add-icon.svg";
import Firebase from "../../../../../../assets/Firebase";

const OptionForm = ({
  strings,
  config,
  componentId,
  optionIndex,
  type,
  option,
  updateOption,
  deleteOption,
  moveBack,
  moveForward,
  setFocusedId,
  focusedId,
  isSuboption,
}) => {
  const f = new Firebase();

  const Wrapper = {
    backgroundColor: "rgb(216, 216, 216)",
    borderRadius: "6px",
    padding: "1%",
    margin: "0.5%",
    position: "relative",
    width: isSuboption ? "100%" : "30%",
  };

  const Tag = styled.div`
    margin: 0.5rem;
  `;

  const IconsContainer = styled.div`
    position: absolute;
    top: 1vh;
    right: 1vh;
    width: 2.5vh;
  `;

  const Icon = styled.img`
    padding: 0.5vh;
    width: 1.5vh;
    margin: 0 0.5vh;
    border-radius: 50%;
    &:hover {
      background-color: White;
    }
  `;

  const SubitemOptionImg = styled.img`
    height: 2.5vh;
    margin: auto;
    border-radius: 40%;
    padding: 0.3vw;
    &:hover {
      background-color: White;
    }
  `;

  const Table = {
    width: "100%",
    tableLayout: "fixed",
  };

  const addSuboptions = () => {
    let opId = f.createUid();
    let suboptions = option.options ? option.options : [];
    suboptions.push({ id: opId });
    updateOption("options", suboptions);
    setFocusedId(opId);
  };

  const updateSuboptions = (index, key, value) => {
    let suboptions = option.options ? option.options : [];
    suboptions[index][key] = value;
    updateOption("options", suboptions);
  };

  const deleteSuboptions = (index) => {
    let suboptions = option.options ? option.options : [];
    suboptions.splice(index, 1);
    updateOption("options", suboptions);
  };

  return (
    <div style={Wrapper}>
      {!isSuboption ? (
        <IconsContainer>
          <Icon src={CrossIconGrey} onClick={deleteOption} />
          {moveBack ? <Icon src={ArrowLeftIcon} onClick={moveBack} /> : <></>}
          {moveForward ? <Icon src={ArrowRightIcon} onClick={moveForward} /> : <></>}
        </IconsContainer>
      ) : (
        <></>
      )}
      <table style={Table}>
        <tbody>
          <tr>
            <td>
              {!isSuboption ? (
                <Tag>
                  <b>{strings.option + " " + optionIndex}*</b>
                </Tag>
              ) : (
                <></>
              )}
              <TextInput
                placeholder={strings.option + " " + optionIndex}
                defaultValue={option.name?.[config.lang]}
                id={"name"}
                maxLength="15"
                width={type === "TREE" ? "100%" : "95%"}
                focused={option.id === focusedId}
                onChange={(e) => {
                  updateOption("name", {
                    CA: e.target.value,
                    ES: e.target.value,
                    EN: e.target.value,
                  });
                }}
              />
            </td>
            {!isSuboption && type === "TREE" ? (
              <td style={{ width: "5vh", verticalAlign: "bottom" }}>
                <SubitemOptionImg
                  src={AddIcon}
                  onClick={addSuboptions}
                  style={{ height: "4vh" }}
                />
              </td>
            ) : (
              <></>
            )}
            {type !== "OPTIONS_SLIDER" ? (
              <td style={{ width: isSuboption ? "5vh" : "13vh" }}>
                <ImagePicker
                  id={"icon-" + componentId + "-" + optionIndex}
                  src={option?.icon}
                  upload={true}
                  cloudPath={"assessmentSheets/icons"}
                  onSelect={(key, value) => {
                    updateOption("icon", value);
                  }}
                  width={isSuboption ? "5vh" : "10vh"}
                  height={isSuboption ? "5vh" : "10vh"}
                />
              </td>
            ) : (
              <></>
            )}
            {isSuboption && type === "TREE" ? (
              <td style={{ width: "11vh" }}>
                <SubitemOptionImg src={AddIcon} onClick={addSuboptions} />
                <SubitemOptionImg src={DeleteIcon} onClick={deleteOption} />
              </td>
            ) : (
              <></>
            )}
          </tr>

          {option.options && type === "TREE" ? (
            <tr>
              <td colSpan="3" style={{ paddingLeft: "1vw" }}>
                {option?.options.map((suboption, index) => (
                  <OptionForm
                    strings={strings}
                    config={config}
                    componentId={componentId + "-sub"}
                    optionIndex={optionIndex + "." + (index + 1)}
                    type={type}
                    option={suboption}
                    updateOption={(key, value) => {
                      updateSuboptions(index, key, value);
                    }}
                    deleteOption={() => {
                      deleteSuboptions(index);
                    }}
                    isSuboption={true}
                    focusedId={focusedId}
                    setFocusedId={setFocusedId}
                  />
                ))}
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default OptionForm;
