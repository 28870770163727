import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import Button from "../../../../../shared/Button";
import TerciaryButton from "../../../../../shared/TerciaryButton";
import Table from "../../../../../shared/Table";

import Colors from "../../../../../../assets/Colors.json";

const ImportSelector = ({ strings, config, importData, close, select }) => {
  const PopupBackground = styled.div`
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
  `;

  const Popup = styled.div`
    position: absolute;
    max-width: 90%;
    min-width: 50%;
    max-height: 70%;
    min-height: 50%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${Colors.lightTheme.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${Colors.lightTheme.accentColor1};
    margin: 1vh auto;
  `;

  let actions = [
    {
      title: <Button value={strings.import} action={close} />,
      action: select,
    },
  ];

  let getFormatedImportData = () => {
    let formatedData = {};

    Object.entries(importData).forEach(([key, value]) => {
      let productTypeData = value;

      console.log(productTypeData);

      Object.entries(productTypeData?.sheets).forEach(([sheetKey, sheetValue]) => {
        let entry = {
          name: sheetValue?.name?.[config.lang],
          product: productTypeData?.name?.[config.lang],
          type: sheetValue?.typeData?.name?.[config.lang],
        };
        formatedData[sheetValue.reference] = entry;
      });
    });
    return formatedData;
  };

  return (
    <PopupBackground>
      <Popup>
        <Header>{strings.importAssessmentSheet}</Header>
        <Divider />
        <div>
          <Table header={strings.Table} data={getFormatedImportData()} actions={actions} ready={true} noElementsText={strings.noneList} />
        </div>
        <div>
          <TerciaryButton value={strings.close} action={close} />
        </div>
      </Popup>
    </PopupBackground>
  );
};
export default ImportSelector;
