import React from "react";
import styled from "@emotion/styled";
import Table from "../../../../../shared/Table";
import Button from "../../../../../shared/Button";
import ResumeIconGray from "../../../../../../assets/images/resume-icon-gray.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";

const ListComponent = ({ strings, reports, viewReport, createReport, deleteReport, ready }) => {
  const Icon = styled.img`
    margin: auto;
    height: 2.5vh;
    width: auto;
  `;

  const Row = styled.div`
    display: flex;
  `;

  let actions = [
    {
      title: (
        <Tooltip title={strings.showReport} aria-label="add">
          <Icon src={ResumeIconGray} />
        </Tooltip>
      ),
      action: viewReport,
    },
    {
      title: (
        <Tooltip title={strings.deleteReport} aria-label="add">
          <Icon src={DeleteIcon} />
        </Tooltip>
      ),
      action: deleteReport,
    }
  ];

  const Wrapper = {
    padding: "2vh 5vh",
  };

  const Container = {
    width: "100%",
  };

  const Title = {
    backgroundColor: "black",
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: "2vh 5vh",
  };

  const Hint = styled.div`
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 2vh;
    max-width: 25%;
    height: fit-content;
    margin: 2vh;
  `;

  const HintHeader = styled.div`
    color: #53728f;
    font-size: 2.5vh;
    font-weight: bold;
    margin-bottom: 2vh;
  `;

  const HintBody = styled.div`
    font-size: 2vh;
    text-align: justify;
  `;

  return (
    <div style={Container}>
      <div style={Title}>{strings.resumesList}</div>
      <Row>
        <div style={Wrapper}>
          <Table
            header={strings.ReportsTable}
            data={reports}
            actions={actions}
            noElementsText={strings.noReports}
            ready={ready}
            sortByField={"date"}
            sortOrder={"descending"} />

          <Button style={{ margin: "2vh 0" }} value={strings.generateReport} action={createReport} />
        </div>
        <Hint>
          <HintHeader>{strings.resportHintHeader}</HintHeader>
          <HintBody>{strings.resportHintText}</HintBody>
        </Hint>
      </Row>
    </div>
  );
};

export default ListComponent;
