import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Button from "../../../../../../shared/Button";
import SecondaryButton from "../../../../../../shared/SecondaryButton";
import VideoUploader from "../../../../../../shared/VideoUploader";

export default function VimeoVideoForm({ strings, config, object, activeVideo, updateState }) {
  var ref = useRef(null);

  const [videoId, setVideoId] = useState(object?.[activeVideo]?.id || null);
  const [metadata, setMetadata] = useState(null);
  const [methods, setMethods] = useState(null);

  useEffect(() => {
    let newId = object?.[activeVideo]?.id;

    if (newId !== null && newId !== undefined) {
      setVideoId(newId);
    } else {
      setVideoId(null);
      setMetadata(null);
    }
  }, [object, activeVideo]);

  const updateVideoId = (id) => {
    setVideoId(id);
    updateState(activeVideo, {
      platform: "vimeo",
      id: id,
    });
  };

  const formatDuration = (duration) => {
    let mins = Math.floor(duration / 60);
    let segs = ("0" + (duration % 60)).slice(-2);
    return mins + ":" + segs;
  };

  const Tag = styled.div`
    padding: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 45vh;
  `;

  return (
    <table style={{ width: "100%", textAlign: "left" }}>
      <tbody>
        <tr>
          <td style={{ width: "30%" }}>
            <VideoUploader
              ref={ref}
              strings={strings}
              config={config}
              videoId={videoId}
              upload={true}
              width="35vh"
              height="20vh"
              onIdChange={updateVideoId}
              onMetadataChange={setMetadata}
              onMethodsLoaded={setMethods}
            />

          </td>
          <td style={{ padding: "1vw" }}>
            <Tag>
              <b>{strings.tittleVideo}</b>
              {metadata?.name || "-"}
            </Tag>
            <Tag>
              <b>{strings.durationVideo} </b>
              {metadata?.duration ? formatDuration(metadata?.duration) : "-"}
            </Tag>

            <table style={{ width: "100%" }}>
              <tr>
                <td>
                  {methods?.upload ? (
                    <Button value={strings.vimeo} action={methods.upload} />
                  ) : (
                    null
                  )}
                  {methods?.delete && videoId ? (
                    <SecondaryButton style={{ marginLeft: "1vw" }} value={strings.vimeoDelete} action={methods.delete} />
                  ) : (
                    null
                  )}
                </td>
              </tr>
              </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
