import React, { useState } from "react";
import background from "../../../../assets/images/background-login.png";
import Button from "../../../shared/Button";
import Logo from "../../../../assets/images/logo-full.png";
import Input from "../../../shared/TextInput";

const EmailVerificationComponent = ({ strings, mode, verifyEmail, resetPassword }) => {
  const [value, setValue] = useState(null);
  const [confirmValue, setConfirmValue] = useState(null);

  const root = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    flexDirection: "column",
    justifyContent: "center",
  };

  const container = {
    backgroundColor: "rgba(255, 255, 255, 0.98)",
    width: "50%",
    margin: "2vh auto",
    padding: "2%",
    borderRadius: "3vh",
  };

  const ButtonContainer = {
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
  };

  const HeaderContainer = {
    color: "#000",
    marginBottom: "2vh",
    textAlign: "center",
    padding: "1vh",
  };

  const HeaderText = {
    fontSize: "3vh",
    fontWeight: "600",
    padding: "2vh",
    margin: "1vh",
  };

  const ConfirmButton = {
    width: "50%",
    margin: "auto",
    fontSize: "2.5vh",
  };

  const LogoStyle = {
    width: "8vw",
  };

  const FormContainer = {
    width: "60%",
    margin: "auto",
    marginBottom: "4vh",
  };

  return (
    <div style={root}>
      <div style={container}>
        {/* --- Header ---*/}

        <div id="formSign">
          <div style={HeaderContainer}>
            <img src={Logo} style={LogoStyle} />
            <h1 style={HeaderText} id={"tittleSign"}>
              {mode == "verifyEmail" ? (
                "Verifica tu email para completar el registro"
              ) : mode == "recoverEmail" ? (
                "Recupera tu email"
              ) : mode == "resetPassword" ? (
                "Cambia tu contraseña"
              ) : (
                <></>
              )}
            </h1>
          </div>

          <div style={FormContainer}>
            {mode == "resetPassword" ? (
              <>
                <div>Nueva contraseña</div>
                <Input
                  width="100%"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
                <div style={{ marginTop: "2vh" }}>Confirma la nueva contraseña</div>
                <Input
                  width="100%"
                  value={confirmValue}
                  onChange={(e) => {
                    setConfirmValue(e.target.value);
                  }}
                />
              </>
            ) : mode == "recoverEmail" ? (
              <></>
            ) : (
              <></>
            )}
          </div>

          <div style={ButtonContainer}>
            {mode == "verifyEmail" ? (
              <Button value="Verificar mi email" action={verifyEmail} style={ConfirmButton} />
            ) : mode == "recoverEmail" ? (
              <Button value="Recuperar email" style={ConfirmButton} />
            ) : mode == "resetPassword" ? (
              <Button
                value="Cambiar contraseña"
                action={() => {
                  resetPassword(value, confirmValue);
                }}
                style={ConfirmButton}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationComponent;
