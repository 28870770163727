import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { isNullString } from "../../../../../../../assets/utils/FormsUtils";
import Button from "../../../../../../shared/Button";
import ButtonValue from "../../../../../../shared/ButtonValue";
import ExpandImg from "../../../../../../../assets/images/ic_expand.png";
import ContractImg from "../../../../../../../assets/images/ic_contract.png";


const ProductsDetailsWrapperForm = ({ strings, config, product, productPath, assessment, dataParser, goBack, lang, goToLink }) => {
    const [isHide, setHide] = useState({
        CATEGORY_SMELL: false,
        CATEGORY_VIEW: false,
        CATEGORY_TASTE: false,
    });

    const MainTable = styled.table`
        border-collapse: collapse;
        table-layout: fixed;
        width: 97%;
        margin: 1.5%;
        border: 2px solid rgba(236, 236, 236, 1);
        td {
            padding: 2vh;
            background-color: white;
            border: 2px solid #e2e2e2;
        }
        th {
            padding: 2vh;
            text-align: left;
            background-color: #ececec;
            border: 2px solid #e2e2e2;
            text-transform: uppercase;
            justify-content: space-around;
            cursor: pointer;
        }
    `;

    const FirstTable = styled.table`
        border-collapse: collapse;
        table-layout: fixed;
        width: 97%;
        margin: 1.5%;
        background-color: rgba(236, 236, 236, 1);
        td {
            padding: 2vh;
            border: 2px solid #e2e2e2;
        }
        th {
            padding: 2vh;
            text-align: left;
            border: 2px solid #e2e2e2;
            text-transform: uppercase;
        }
    `;

    const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 85vh;
        position: relative;
    `;

    const DivTable = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    `;

    const BorderTable = styled.div`
        border: 1px solid #f0f0f0;
        border-radius: 2vh;
        box-shadow: 0 0 7vh 1em #c6c6c6;
        overflow-y: auto;
        max-height: 80vh;
        width: 80vw;
    `;

    const DivTitle = styled.div`
        text-transform: uppercase;
        font-size: 4vh;
        margin: 3vh;
    `;

    const styleButton = {
        position: "absolute",
        top: "-9.6vh",
        left: "60vh"
    }

    return (
        <Wrapper>
            <Button value={"< " + strings.back} action={goBack} style={styleButton} />
            <DivTable>
                <BorderTable>
                    <DivTitle>{strings.fichaVino}</DivTitle>
                    {product !== null ? (
                        <div style={{ width: "100%" }}>
                            <FirstTable>
                                <tr>
                                    <td rowSpan="4" style={{ width: "40vh", textAlign: "center" }}>
                                        <img style={{ height: "38vh" }} src={product?.image} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{strings.name}</th>
                                    <td>{product?.name || "-"}</td>
                                    <th>{strings.buyWine}</th>
                                    <td colSpan={2} style={{ textAlign: "center" }}><ButtonValue value={strings.buy} action={goToLink} actionValue={product?.externalStoreURL} style={{ width: "50%" }} /></td>
                                </tr>
                                <tr>
                                    {Object.entries(assessment?.parameters || {}).map(([parameterId, asParamter]) => {
                                        if (asParamter !== null && !isNullString(asParamter)) {
                                            return (
                                                <>
                                                    <th>{dataParser().getParameterName(productPath, parameterId) || parameterId}</th>
                                                    <td>{asParamter} {parameterId === "blindPrice" ? (config?.cellar?.currency || "€") : ""}</td>
                                                </>
                                            );
                                        }
                                    })}
                                </tr>
                                <tr>
                                    <th>{strings.type}</th>
                                    <td>{product?.type?.name?.[lang] || "-"}</td>
                                    <th>{strings.subtype}</th>
                                    <td>{product?.subtype?.name?.[lang] || "-"}</td>
                                </tr>
                            </FirstTable>
                        </div>
                    ) : (
                        <></>
                    )}

                    <MainTable>
                        {Object.entries(assessment?.parameters || {}).length === 0 && Object.entries(assessment?.categories || {}).length === 0 ? (
                            <tr>
                                <td colSpan="3" style={{ textAlign: "center" }}>No assessments found!</td>
                            </tr>
                        ) : (
                            <></>
                        )}

                        {Object.entries(assessment?.categories || {}).map(([categoryId, asCategory]) => {
                            let i = 0;
                            return (
                                Object.entries(asCategory || {}).map(([parameterId, asParameter]) => {
                                    let element = (
                                        <tr>
                                            {i === 0 ? (
                                                <th onClick={() => {
                                                    if (isHide[categoryId] === true) {
                                                        document.getElementsByName(categoryId).forEach(element => {
                                                            element.hidden = false;
                                                        })
                                                        let hideNew = isHide;
                                                        hideNew[categoryId] = false;
                                                        setHide(hideNew);
                                                        document.getElementById(categoryId).setAttribute("src", ExpandImg);
                                                    }
                                                    else {
                                                        document.getElementsByName(categoryId).forEach(element => {
                                                            element.hidden = true;
                                                        })
                                                        let hideNew = isHide;
                                                        hideNew[categoryId] = true;
                                                        setHide(hideNew);
                                                        console.log(document.getElementById(categoryId))
                                                        document.getElementById(categoryId).setAttribute("src", ContractImg);
                                                    }
                                                }} rowSpan={Object.entries(asCategory || {}).length}>{dataParser().getCategoryName(productPath, categoryId)}{
                                                        <img
                                                            id={categoryId}
                                                            src={ExpandImg}
                                                            style={{ height: "2vh", marginLeft: "3vh" }}
                                                        />
                                                    }</th>
                                            ) : (
                                                <></>
                                            )}
                                            <td name={categoryId}>{dataParser().getCategoryParameterName(productPath, categoryId, parameterId)}</td>
                                            <td name={categoryId}>{dataParser().getOptionsName(productPath, categoryId, parameterId, asParameter)}</td>
                                        </tr>
                                    );
                                    i++;
                                    return element;
                                })
                            )
                        })}
                    </MainTable>
                </BorderTable>
            </DivTable>
        </Wrapper>
    );
}
export default ProductsDetailsWrapperForm;