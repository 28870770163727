import React from "react";
import styled from "@emotion/styled";
import { jsx, css, keyframes } from "@emotion/core";
import Colors from "../../assets/Colors.json"
const LoadingScreen = ({}) => {
  const colors = Colors.lightTheme; 
  const Wrapper = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: '1'
  }
  const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`;
  const Animation = styled.div`
    display: inline-block;
    position: relative;
    width: 20vh;
    height: 20vh;
    margin: auto;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 15vh;
      height: 15vh;
      margin: 2vh;
      border: 2vh solid ${colors.accentColor1};
      border-radius: 50%;
      animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${colors.accentColor1} transparent transparent transparent;
    }
  `;
  //animation: 1s ${spin} linear infinite;
  return (
    <div style={Wrapper}>
      <Animation>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Animation>
    </div>
  );
};

export default LoadingScreen;
