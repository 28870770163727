import React from "react";
import styled, { css, createGlobalStyle } from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want

const DateTimeTicker = ({ id, value, style, onChange = () => { }, type = "datetime-local", disabled = false, width }) => {
  const pickerStyle = {
    borderRadius: "0.3vh",
    border: "solid 0.2vh #d8dde1",
    color: "black",
    backgroundColor: "white",
    padding: "0.5rem",
  };

  for (let key in style) {
    pickerStyle[key] = style[key];
  }

  let disabledPickerStyle = {
    ...pickerStyle,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };

  const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker {
      width: ${ width || "90%"};
      border-radius: 0.3vh;
      border: solid 0.2vh #d8dde1;
      color: black;
      background-color: white;
      padding: 0.6rem;
    }

    .date_picker.disabled {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .react-datepicker__input-container,
    .react-datepicker__input-container input{
      background-color: transparent;
      border-width: 0;
    }
`;

  const onSelection = (date) => {
    onChange(id, date);
  };

  const getDate = () => {
    if (value == null || value == undefined) {
      return new Date();
    } else if (typeof value === "string") {
      return Date.parse(value);
    } else {
      return value;
    }
  };
 
  
  return (
    <>
      <DatePicker
        wrapperClassName={disabled ? "date_picker disabled" : "date_picker"}
        dateFormat="dd/MM/yyyy HH:mm"
        locale="es"
        showTimeSelect
        disabled={disabled}
        timeIntervals={15}
        timeCaption="Hora"
        selected={getDate()}
        onChange={onSelection}
      />
      <DatePickerWrapperStyles />
    </>
  );
};
export default DateTimeTicker;
