import React from "react";
import { BrowserRouter as Router, Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import logo from "../../../../assets/images/logo-full.png";
import styled from "@emotion/styled";
import Option from "./Option";
import OptionWrapper from "./OptionWrapper";
import Colors from "../../../../assets/Colors.json";

import HomeIcon from "../../../../assets/images/home-icon.svg";
import ThemeIcon from "../../../../assets/images/theme-icon.svg";
import CellarIcon from "../../../../assets/images/cellar-icon.svg";
import InstructorIcon from "../../../../assets/images/instructor-icon.svg";
import ProductIcon from "../../../../assets/images/product-icon.svg";
import AssessmentSheetIcon from "../../../../assets/images/assessmentsheet-icon.svg";
import TastingIcon from "../../../../assets/images/tasting-icon.svg";
import EventIcon from "../../../../assets/images/event-icon.svg";
import ResumeIcon from "../../../../assets/images/resume-icon.svg";
import AboutIcon from "../../../../assets/images/about-icon.svg";
import AvatarIcon from "../../../../assets/images/avatar-icon.svg";
import IconCampaign from "../../../../assets/images/campanya-icon.svg";
import CustomizationIcon from "../../../../assets/images/customization-icon.svg";
import IntegrationIcon from "../../../../assets/images/integration-icon.svg";

const NavbarComponent = ({ strings, selected, setSelected, config }) => {
  const colors = Colors.lightTheme;

  let { url } = useRouteMatch();
  let location = useLocation().pathname.split("/")[2];
  let history = useHistory();

  const Wrapper = styled["div"]`
    display: flex;
    flex-direction: column;
    background-color:  ${colors.lightGrey};
    width: 18%;
  `;

  const LogoWrapper = styled.div`
    background-color: white;
    height: 12vh;
    width: 100%;
  `;

  const LogoBackground = styled.div`
    background-color: ${colors.veryLightGrey};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const Logo = styled.img`
    width: 75%;
    cursor: pointer;
  `;

  const LinksWrapper = styled["div"]`
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    > * {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      padding: 2vh 0 2vh 2vh;
      margin: 0.1vh;
      place-items: center;
      transition: 0.1s;
      background-color: ${colors.veryLightGrey};
      &:hover {
        background-color: ${colors.lightGrey};
      }
    }
    .child {
      background-color: rgba(0, 0, 0, 0.01);
      padding: 2vh 0 2vh 3vh;
      &:hover {
        background-color: ${colors.lightGrey};
      }
    }
  `;

  const redirect = (path) => {
    config.isModificated(() => {
      history.replace(path);
    });
  }

  return (
    <Wrapper>
      <LogoWrapper>
        <LogoBackground>
          <Logo src={logo} onClick={() => { redirect(`${url}/home`) }} />
        </LogoBackground>
      </LogoWrapper>
      <LinksWrapper>
        <Option strings={strings} to={`${url}/home`} icon={HomeIcon} id={"home"} config={config} />
        <Option text={config?.cellar?.name} strings={strings} to={`${url}/cellar/edit`} icon={CellarIcon} id={"cellar"} tooltip={strings.tooltipCellar} config={config} />

        <OptionWrapper strings={strings} icon={CustomizationIcon} id={"customizationWrapper"} selected={selected} setSelected={setSelected}>
          <Option child={true} strings={strings} to={`${url}/theme/edit`} icon={ThemeIcon} id={"theme"} tooltip={strings.tooltipTheme} config={config} />
          <Option child={true} strings={strings} to={`${url}/assessmentSheets/list`} icon={AssessmentSheetIcon} id={"assessmentSheets"} tooltip={strings.tooltipassessmentSheets} config={config} />
        </OptionWrapper>

        <Option strings={strings} to={`${url}/products/list`} icon={ProductIcon} id={"products"} tooltip={strings.tooltipProducts} config={config} />
        <Option strings={strings} to={`${url}/instructors/list`} icon={InstructorIcon} id={"instructors"} tooltip={strings.tooltipInstructors} config={config} />
        <OptionWrapper strings={strings} icon={TastingIcon} id={"podcastTastingsWrapper"} selected={selected} setSelected={setSelected}>
          <Option
            child={true}
            strings={strings}
            to={`${url}/tastings/podcast/list`}
            icon={TastingIcon}
            id={"tastings/podcast"}
            stringId={"podcastTastings"}
            tooltip={strings.tooltipPodcastTastings}
            config={config}
          />
          <Option
            child={true}
            strings={strings}
            to={`${url}/events/podcast/list`}
            icon={IconCampaign}
            id={"campaigns"}
            tooltip={strings.tooltipCampaigns}
            config={config}
          />
        </OptionWrapper>
        <OptionWrapper strings={strings} icon={TastingIcon} id={"liveTastingsWrapper"} selected={selected} setSelected={setSelected}>

          <Option
            child={true}
            strings={strings}
            to={`${url}/tastings/live/list`}
            icon={TastingIcon}
            id={"tastings/live"}
            stringId={"liveTastings"}
            tooltip={strings.tooltipLiveTastings}
            config={config}
          />
          <Option
            child={true}
            strings={strings}
            to={`${url}/events/live/list`}
            icon={EventIcon}
            id={"sessions"}
            tooltip={strings.tooltipSessions}
            config={config}
          />
        </OptionWrapper>

        <Option strings={strings} to={`${url}/reports/list`} icon={ResumeIcon} id={"resume"} tooltip={strings.tooltipResumes} config={config} />
        {/*<Option strings={strings} to={`${url}/integration`} icon={IntegrationIcon} id={"integration"} config={config} />*/}
        <Option strings={strings} to={`${url}/about`} icon={AboutIcon} id={"about"} config={config} />
      </LinksWrapper>
    </Wrapper>
  );
};

export default NavbarComponent;
