import React, { useState, useEffect } from "react";
import DateTimePicker from "../../../../../shared/DateTimeTicker";
import Button from "../../../../../shared/Button";
import Checkbox from "../../../../../shared/Checkbox";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import Select from "../../../../../shared/Select";
import CopyImg from "../../../../../../assets/images/copy-clipboard.svg";

const FormComponent = ({ strings, config, type, event, tastings, instructors, generationReportTypes, periodicIntervals, updateEvent, updateCode, editCode, editingCode, confirmAction, cancelAction, copyLinkToClipBoard, getURL }) => {
  const langList = {
    notSelected: strings.notSelected,
    ES: strings.spanish,
    CA: strings.catalan,
    EN: strings.english,
    ZH: strings.chinese,
    FR: strings.french,
    DE: strings.german,
    PT: strings.portuguese,
    IT: strings.italian,
    GL: strings.galician,
    NL: strings.dutch,
    PL: strings.polish,
    RU: strings.russian,
    UK: strings.ukrainian
  };

  const Wrapper = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    padding: "0.5rem 2rem",
  };

  const Container = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };

  const Title = {
    backgroundColor: "black",
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: "2vh 5vh",
  };

  const CodeTast = {
    padding: "0.3rem 0 0.3rem 0rem",
    width: "100%",
    boxSizing: "border-box",
    textAlign: "center",
    margin: "auto"
  };

  const Tag = {
    margin: "0.5rem",
  };

  const BoldTag = {
    ...Tag,
    fontWeight: "bold",
  };

  const LinkTag = {
    ...BoldTag,
    marginTop: "3vh",
  };

  const LinkContainer = {
    wordWrap: "break-word",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "2vh",
    margin: "auto",
    cursor: "pointer",
  };

  const Icon = {
    width: "1vw",
    height: "auto",
    margin: "auto",
    cursor: "pointer",
  };

  const ButtonWrapper = {
    display: "flex",
    placeItems: "center",
  };

  const CodeInput = {
    width: "20vw",
    backgroundColor: "white",
    margin: "1vh auto",
    borderRadius: "8px",
    padding: "1vh",
    borderColor: "transparent",
    color: "black",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "20px",
  };

  const DisabledCodeInput = {
    ...CodeInput,
    backgroundColor: "rgba(0,0,0,0.1)",
  };

  const LinkWrapper = {
    ...DisabledCodeInput,
    width: "25vw",
    display: "flex",
  };

  const Hint = {
    ...DisabledCodeInput,
    fontSize: "2vh",
    width: "100%",
    display: "flex",
    padding: "2vh",
    margin: "1vh 3vh 1vh 1vh",
    textTransform: "none",
  };

  const Group = {
    width: "98%",
    display: "flex",
    borderBottom: "1px solid grey",
    paddingBottom: "0.5%",
    marginBottom: "0.5%",
  };

  const Divider = {
    width: "100%",
    borderTop: "1px solid grey",
    marginTop: "2vh",
    marginRight: "1rem",
  };

  const FirstColum = {
    width: "60%",
    display: "flex",
    flexWrap: "wrap",
    borderRight: "1px solid grey",
    marginBottom: "1rem",
    alignContent: "flex-start"
  }

  const SecondColum = {
    ...FirstColum,
    width: "40%",
    maxHeight: "70%",
    marginLeft: "2vh",
    borderRight: "none",
  }

  const Item = {
    border: "0",
    padding: "1%",
    width: "48%",
  };

  return (
    <div style={Container}>
      <div style={Title}>{event.id ? (type === "live" ? strings.updateSession : strings.updateCampaign) : (type === "live" ? strings.addSession : strings.addCampaign)}</div>

      <div style={Wrapper}>

        <div style={Group}>

          <div style={FirstColum}>

            <div style={Item}>
              <div style={BoldTag}>{strings.tastingSelect}*</div>
              <Select id="tastingPath" options={tastings} defaultOption={event.tastingPath || "notSelected"} onChange={updateEvent} />
            </div>

            <div style={Item}>
              <div style={BoldTag}>{strings.instructorSelect}*</div>
              <Select id="instructorPath" options={instructors} defaultOption={event.instructorPath || "notSelected"} onChange={updateEvent} />
            </div>


            <div style={Item}>
              <div style={BoldTag}>{strings.dateStart}*</div>
              <DateTimePicker id="fromDate" value={event?.sessionCode?.fromDate || null} style={{ width: "90%" }} onChange={updateCode} />
            </div>

            <div style={Item}>
              <div style={BoldTag}>{strings.dateEnd}*</div>
              <DateTimePicker id="toDate" value={event?.sessionCode?.toDate || null} style={{ width: "90%" }} onChange={updateCode} disabled={type === "live"} />
            </div>

            <div style={Item}>
              <div style={Tag}>{strings.defaultLang}</div>
              <Select id="lang" options={langList} defaultOption={event.lang || "notSelected"} onChange={updateEvent} />
            </div>

            {type !== "live" ? (
              <div style={Item}>
                <div style={Tag}>{strings.additionalSettings}</div>
                <Checkbox text={strings.liteMode} checked={event.liteMode} onChange={() => { updateEvent("liteMode", !event.liteMode) }} />
              </div>
            ) : (
                <div style={Item}>
                  <div style={Tag}>{strings.additionalSettings}</div>
                  <Checkbox text={strings.enabaleNoStreamingUrl} checked={event.noStreamingUrlEnabled} onChange={() => { updateEvent("noStreamingUrlEnabled", !event.noStreamingUrlEnabled) }} />
                </div>
            )}

            {/*<div style={Divider} />

            <div style={Item}>
              <div style={BoldTag}>{strings.report}*</div>
              <Checkbox text={strings.generateAutomaticReport} checked={event?.sessionCode?.generateReport} onChange={() => { updateCode("generateReport", !event?.sessionCode?.generateReport) }} />
            </div>

            {event?.sessionCode?.generateReport ? (
              <>
                <div style={Item}>
                  <div style={BoldTag}>{strings.generationReportType}*</div>
                  <Select id="generationReportType" options={generationReportTypes} defaultOption={event?.sessionCode?.generationReportType || "notSelected"} onChange={updateCode} />
                </div>

                {event?.sessionCode?.generationReportType === "PERIODIC" ? (
                  <>
                    <div style={Item}>
                      <div style={BoldTag}>{strings.generationPeriodicInterval}*</div>
                      <Select id="generationPeriodicInterval" options={periodicIntervals} defaultOption={event?.sessionCode?.generationPeriodicInterval || "notSelected"} onChange={updateCode} />
                    </div>
                    <div style={Hint}>{strings.periodicReportHint}</div>
                  </>
                ) : event?.sessionCode?.generationReportType === "ON_FINISH_EVENT" ? (
                  <div style={Hint}>{strings.onFinishReportHint}</div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}*/}

          </div>

          <div style={SecondColum}>

            <div style={CodeTast}>
              <div style={Tag}>{strings.codeTasting}</div>
              <div>
                <input id="publicCode" defaultValue={event?.sessionCode?.publicCode || "-"} style={editingCode ? CodeInput : DisabledCodeInput} readOnly={!editingCode} />
              </div>
              <div>
                <Button value={editingCode ? strings.confirmCode : strings.modifyCode} action={editCode} style="margin: 0 auto 0 0" />
              </div>

              <div style={LinkTag}>{event?.noStreamingUrlEnabled ? strings.linkHintWithStreaming : strings.linkHint}</div>
              <div style={LinkWrapper} onClick={copyLinkToClipBoard}>
                <div style={{ width: "23vw", float: "left" }}>
                  <p style={LinkContainer} id="link">
                    {getURL()}
                  </p>
                </div>
                <img style={Icon} src={CopyImg} />
              </div>

              {event?.noStreamingUrlEnabled ? (
                <>
                  <div style={LinkTag}>{strings.linkHintNoStreaming}</div>
                  <div style={LinkWrapper} onClick={() => { copyLinkToClipBoard(true) }}>
                    <div style={{ width: "23vw", float: "left" }}>
                      <p style={LinkContainer} id="link">
                        {getURL(true)}
                      </p>
                    </div>
                    <img style={Icon} src={CopyImg} />
                  </div>
                </>
              ) : (
                <></>
              )}

            </div>
          </div>

        </div>
      </div>

      <div style={ButtonWrapper}>
        <SecondaryButton value={strings.cancel} action={cancelAction} style="margin: 0 1rem 0 auto" />
        <Button value={strings.confirm} action={confirmAction} style="margin: 0 auto 0 0" />
      </div>
    </div>
  );
};
export default FormComponent;
