import React, { useState, useEffect } from "react";
import Button from "../../../../../shared/Button";
import CrossIconGrey from "../../../../../../assets/images/cross-icon-grey.svg";
import ArrowLeftIcon from "../../../../../../assets/images/ic_contract_left.png";
import ArrowRightIcon from "../../../../../../assets/images/ic_contract_right.png";
import CategoryForm from "./CategoryForm";
import styled from "@emotion/styled";
import Firebase from "../../../../../../assets/Firebase";
import Colors from "../../../../../../assets/Colors.json";

const CategoriesWrapperForm = ({ strings, config, assessmentSheet, setAssessmentSheet }) => {
  const f = new Firebase();
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    if (activeCategory == null) {
      if (assessmentSheet.categories != null) {
        let numCategories = assessmentSheet.categories.length;
        if (numCategories > 0) {
          setActiveCategory(0);
        }
      }
    }
  }, [assessmentSheet, activeCategory]);

  const Wrapper = {
    backgroundColor: "white",
    borderRadius: "0px 6px 6px 6px",
    padding: "2vh",
  };

  const Header = {
    margin: "2vh 0",
    width: "100%",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
  };

  const IndexesWrapper = {
    display: "flex",
  };

  const IndexItem = {
    padding: "1vh 2vh",
    backgroundColor: "#d8d8d8",
    cursor: "pointer",
    color: "#6d7278",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const AddIndexItem = {
    ...IndexItem,
    backgroundColor: Colors.lightTheme.accentColor1,
    color: "white",
    border: "1.5px solid " + Colors.lightTheme.accentColor1
  };

  const SelectedIndexItem = {
    padding: "1vh 2vh",
    cursor: "pointer",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
  };

  const TabIconsContainer = {
    marginLeft: "0.5vw",
  }

  const TabIcon = styled.img`
    padding: 0.5vh;
    height: 1.5vh;
    border-radius: 50%;
    &:hover {
      background-color: LightGray;
    }
  `;

  const updateCategory = (key, value) => {
    let categories = assessmentSheet.categories ? assessmentSheet.categories : [];
    categories[activeCategory][key] = value;

    setAssessmentSheet((prevState) => ({
      ...prevState,
      ["categories"]: categories,
    }));
  };

  const addCategory = () => {
    let key = f.createUid();
    let categories = assessmentSheet.categories ? assessmentSheet.categories : [];
    categories.push({ id: key });

    setAssessmentSheet((prevState) => ({
      ...prevState,
      ["categories"]: categories,
    }));
    setActiveCategory(categories.length - 1);
  };

  const deleteCategory = (index) => {
    let categories = assessmentSheet.categories ? assessmentSheet.categories : [];
    categories.splice(index, 1);

    setAssessmentSheet((prevState) => ({
      ...prevState,
      ["categories"]: categories,
    }));
    setActiveCategory(0);
  };

  const moveBack = (index) => {
    let newIndex = index - 1;
    move(index, newIndex);
  }

  const moveForward = (index) => {
    let newIndex = index + 1;
    move(index, newIndex);
  }

  const move = (index, newIndex) => {
    let categories = assessmentSheet.categories ? assessmentSheet.categories : [];
    var category = categories[index];
    categories.splice(index, 1);
    categories.splice(newIndex, 0, category);

    setAssessmentSheet((prevState) => ({
      ...prevState,
      ["categories"]: categories,
    }));
  }


  const loadCategoriesTabs = () => {
    let views = [];
    let index = 1;
    for (let key in assessmentSheet.categories) {
      let catName = assessmentSheet?.categories?.[key]?.name?.[config.lang];
      views.push(
        <div
          style={key == activeCategory ? SelectedIndexItem : IndexItem}
          onClick={() => {
            setActiveCategory(key);
          }}
        >
          <div>{strings.category + " " + index}{catName ? " - " + catName : ""}</div>
          <div style={TabIconsContainer}>
            {index > 1 ? (
              <TabIcon src={ArrowLeftIcon} onClick={() => moveBack(key)} />
            ) : (
              <></>
            )}
            {
              index < assessmentSheet?.categories?.length ? (
                <TabIcon src={ArrowRightIcon} onClick={() => moveForward(key)} />
              ) : (
                <></>
              )
            }
            <TabIcon src={CrossIconGrey} onClick={() => deleteCategory(key)} />
          </div>
        </div>
      );
      index++;
    }

    views.push(
      <div style={AddIndexItem} onClick={addCategory}>
        {strings.addCategory}
      </div>
    );
    return views;
  };

  return (
    <div style={{ paddingBottom: "2vh" }}>
      <div>
        <h3 style={Header}>{strings.categoriesHeader}</h3>
      </div>
      {Object.entries(assessmentSheet?.categories || {}).length > 0 ? (
        <>
          <div style={IndexesWrapper}>{loadCategoriesTabs()}</div>
          <div style={Wrapper}>
            {activeCategory == undefined ? (
              <p>{strings.noCategorySelected}</p>
            ) : (
              <CategoryForm
                strings={strings}
                config={config}
                categoryId={activeCategory}
                category={assessmentSheet.categories[activeCategory]}
                updateCategory={updateCategory}
              />
            )}
          </div>
        </>
      ) : (
        <div style={{ display: "flex", padding: "1vh", flexDirection: "column" }}>
          <Button action={addCategory} value={strings.addCategory} style={{ margin: "0 auto" }} />
        </div>
      )}
    </div>
  );
};
export default CategoriesWrapperForm;
