import React, { useState } from "react";
import NavbarComponent from "./NavbarComponent";

const NavBarEngine = ({ strings, config }) => {
  const [selected, setSelected] = useState(null);

  return <NavbarComponent strings={strings} selected={selected} setSelected={setSelected} config={config} />;
};

export default NavBarEngine;
