import React, { useState, useEffect } from "react";
import Button from "../../../../../shared/Button";
import Firebase from "../../../../../../assets/Firebase";
import OptionForm from "./OptionForm";

const OptionsWrapperForm = ({ strings, config, componentId, component, updateComponent }) => {
  const f = new Firebase();
  const [focusedId, setFocusedId] = useState(null);

  const OptionWrapper = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  };

  const addOption = () => {
    let opId = f.createUid();
    let options = component.options ? component.options : [];
    options.push({ id: opId });
    updateComponent("options", options);
    setFocusedId(opId);
  };

  const deleteOption = (index) => {
    let options = component.options ? component.options : [];
    options.splice(index, 1);
    updateComponent("options", options);
  };

  const moveBack = (index) => {
    let newIndex = index - 1;
    move(index, newIndex);
  }

  const moveForward = (index) => {
    let newIndex = index + 1;
    move(index, newIndex);
  }

  const move = (index, newIndex) => {
    let options = component.options ? component.options : [];
    var option = options[index];
    options.splice(index, 1);
    options.splice(newIndex, 0, option);
    updateComponent("options", options);
  }

  const updateOption = (index, key, value) => {
    let options = component.options ? component.options : [];
    options[index][key] = value;
    updateComponent("options", options);
  };

  return (
    <div>
      {(component?.options || []).length > 0 ? (
        <div style={OptionWrapper}>
          {component.options.map((option, index, array) => (
            <OptionForm
              strings={strings}
              config={config}
              type={component?.type}
              componentId={componentId}
              optionIndex={index + 1}
              option={option}
              updateOption={(key, value) => { updateOption(index, key, value) }}
              deleteOption={() => { deleteOption(index) }}
              moveBack={index > 0 ? () => { moveBack(index) } : null}
              moveForward={index < (array.length - 1) ? () => { moveForward(index) } : null}
              setFocusedId={setFocusedId}
              focusedId={focusedId}
              isSuboption={false}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
      <div style={{ display: "flex", padding: "1vh", flexDirection: "column" }}>
        <Button
          action={addOption}
          value={strings.addOption}
          style={{ margin: "0 auto" }}
        />
      </div>
    </div>
  );
};
export default OptionsWrapperForm;
