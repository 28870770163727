import React, { useState } from "react";

const TextArea = ({ id, placeholder, value, onChange, type = "text", style, translatable }) => {
  const langs = ["ES", "CA", "EN"];

  const [selectedLang, setSelectedLang] = useState(langs[0]);

  const change = () => {
    if (onChange) {
      let newValue = document.getElementById(id).value;

      if (translatable) {
        if (typeof value !== 'object' || value === null) {
          value = { [selectedLang]: newValue };
        } else {
          value[selectedLang] = newValue;
        }
        onChange(id, value);
      } else {
        onChange(id, newValue);
      }
    }
  }

  const getValue = () => {
    if (translatable) {
      return value ? (value[selectedLang] ? value[selectedLang] : "") : ""
    } else {
      return value ? value : "";
    }
  }

  const Wrapper = {
    ...style
  }

  const LangBar = {
    display: "flex",
    width: "100%",
    height: "15%",
  }

  const SelectedLangItem = {
    padding: "1vh 2vh",
    backgroundColor: "white",
    cursor: "pointer",
    color: "black",
    borderRadius: "6px 6px 0px 0px",
    fontWeight: "bold",
  }

  const LangItem = {
    ...SelectedLangItem,
    backgroundColor: "rgb(216, 216, 216)",
    color: "rgb(109, 114, 120)",
    fontWeight: "normal",
  }

  const TextAreaStyle = {
    borderRadius: "3vg",
    border: "solid 0.3vh rgba(255, 255, 255, 0.33)",
    color: "black",
    backgroundColor: "white",
    resize: "none",
    padding: "1vh",
    width: "100%",
    height: "85%",
  }

  return (
    <div style={Wrapper}>
      {
        translatable ? (
          <div style={LangBar}>
            {langs.map((lang) => {
              return (<div style={lang === selectedLang ? SelectedLangItem : LangItem} onClick={() => { setSelectedLang(lang) }}>{lang}</div>);
            })}
          </div>
        ) : (
          <></>
        )
      }
      <textarea
        type={type}
        id={id}
        style={TextAreaStyle}
        placeholder={placeholder}
        value={getValue()}
        onChange={change}
      />
    </div>

  );
};

export default TextArea;
