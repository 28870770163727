import React, { useEffect } from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import DateTimeTicker from "../../../../../shared/DateTimeTicker";
import Select from "../../../../../shared/Select";
import TextInput from "../../../../../shared/TextInput";
import Checkbox from "../../../../../shared/Checkbox";
import { isNullOrUndefined } from "../../../../../../assets/utils/FormsUtils";

const CreateComponent = ({ strings, events, report, updateReport, confirmAction, cancelAction, }) => {
    const Wrapper = {
        padding: "2vh 5vh",
    };

    const Container = {
        width: "100%",
    };

    const Title = {
        backgroundColor: "black",
        color: "white",
        textTransform: "uppercase",
        fontWeight: "bold",
        padding: "2vh 5vh",
    };

    const Group = {
        width: "100%",
        borderBottom: "1px solid grey",
        paddingBottom: "1rem",
        marginBottom: "1rem",
    };

    const Item = {
        border: "0",
        padding: "0.5%",
        width: "50%",
        margin: "auto",
    };

    const Tag = {
        margin: "0.5rem",
    };

    const ButtonWrapper = {
        display: "flex",
        placeItems: "center",
    };

    const getTomorrowDate = () => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

    useEffect(() => {
        if (report?.dateFilterActive) {
            if (isNullOrUndefined(report?.fromDate)) {
                updateReport("fromDate", new Date());
            }
            if (isNullOrUndefined(report?.toDate)) {
                updateReport("toDate", getTomorrowDate());
            }
        }
    }, [report]);

    return (
        <div style={Container}>
            <div style={Title}>{strings.generateReport}</div>
            <div style={Wrapper}>

                <div style={Group}>
                    <div style={Item}>
                        <div style={Tag}>
                            <b>{strings.reportName}*</b>
                        </div>
                        <TextInput value={report?.name} onChange={(e) => updateReport("name", e?.target?.value)} width={"100%"} placeholder={strings.writeReportName} />
                    </div>
                    <div style={Item}>
                        <div style={Tag}>
                            <b>{strings.event + "/" + strings.campaign}*</b>
                        </div>
                        <Select id="publicCode" options={events} defaultOption={report?.publicCode} onChange={updateReport} width={"100%"} />
                    </div>

                    <div style={Item}>
                        <Checkbox id="dateFilterActive" text={strings.dateFilterActive} checked={report?.dateFilterActive}
                            onChange={() => { updateReport("dateFilterActive", !report?.dateFilterActive) }} />
                    </div>

                    {report?.dateFilterActive ? (
                        <>
                            <div style={Item}>
                                <div style={Tag}>{strings.fromDate} </div>
                                <DateTimeTicker id="fromDate" value={report?.fromDate} onChange={updateReport} width={"calc(99% - 1rem)"} />
                            </div>
                            <div style={Item}>
                                <div style={Tag}>{strings.toDate} </div>
                                <DateTimeTicker id="toDate" value={report?.toDate} onChange={updateReport} width={"calc(99% - 1rem)"} />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>


                <div style={ButtonWrapper}>
                    <SecondaryButton value={strings.cancel} action={cancelAction} style="margin: 0 1rem 0 auto" />
                    <Button value={strings.accept} action={confirmAction} style="margin: 0 auto 0 0" />
                </div>
            </div>
        </div>
    );
};

export default CreateComponent;
