import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import ReactHtmlParser from 'react-html-parser'; 
import Button from "../../../../../../shared/Button";
import { CardElement } from "@stripe/react-stripe-js";

export default function PaymentForm({ strings, stripeTest, celler, selectedPlan, interval, goBack, pay, openTermsConditions, openPrivacyPolicy }) {
  return [
    <FormComponent
      stripeTest={stripeTest}
      strings={strings}
      celler={celler}
      selectedPlan={selectedPlan}
      goBack={goBack}
      pay={pay}
      interval={interval}
      openTermsConditions={openTermsConditions}
      openPrivacyPolicy={openPrivacyPolicy}
    />,
  ];
}

const FormComponent = ({ strings, stripeTest, celler, selectedPlan, interval, goBack, pay, openTermsConditions, openPrivacyPolicy }) => {
  const Container = {
    padding: "4vh 10vw",
    width: "60%",
    "a": {
      textDecoration: "underline",
    }
  };

  const Header = styled.div`
    width: 100%;
    font-size: 3vh;
    font-weight: bold;
    margin-bottom: 1vh;
  `;

  const SubHeader = styled.div`
    width: 100%;
    font-size: 2vh;
    margin-bottom: 6vh;
  `;

  const link = {
    textDecoration: "underline",
    cursor: "pointer",
  }

  const CreditCardWrapper = {
    borderRadius: "0.3vh",
    border: "solid 0.2vh #d8dde1",
    padding: "16px",
    width: "95%",
    backgroundColor: "white",
  };

  const creditCardOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: "black",
        fontSize: "16px",

        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };


  const getHintText = () => {
    let text = strings.paymentHint1;

    text = text.replace("{planName}", selectedPlan?.name);

    if (interval === "annual") {
      text = text.replace("{interval}", strings.annual);
      text = text.replace("{price}", formatPrice(selectedPlan?.annualPayment * 12));
    } else if (interval === "monthly") {
      text = text.replace("{interval}", strings.monthly);
      text = text.replace("{price}", formatPrice(selectedPlan?.monthlyPayment));
    }

    return text;
  }

  const formatPrice = (price) => {
    if (price) {
       return price.toFixed(2).replace(".", ",");
    } else {
      return "0,00";
    }
  };

  return (
    <div style={Container}>
      <Header>{stripeTest ? strings.payment + " [TEST MODE]" : strings.payment} </Header>
      <SubHeader>{strings.paymentSubheader}</SubHeader>

      <div style={CreditCardWrapper}>
        <CardElement options={creditCardOptions} />
      </div>

      <div style={{ margin: "5vh 0" }}>
        <p style={{ textAlign: "justify", textJustify: "inter-word", width: "100%" }}>
          {ReactHtmlParser(getHintText())}
          <a onClick={openTermsConditions} style={link}>{strings.terms}</a>{" " + strings.and + " "}<a onClick={openPrivacyPolicy} style={link}>{strings.privacyPolicy}</a>{". "}
          {strings.paymentHint2}
        </p>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <Button value={strings.goBack} action={goBack} />
        </div>
        <div style={{ width: "50%", textAlign: "end" }}>
          <Button value={strings.pay} action={pay} />
        </div>
      </div>
    </div>
  );
};
