import React from "react";
import styled from "@emotion/styled";
import Select from "../../../../../shared/Select";
import TextInput from "../../../../../shared/TextInput";

const AssessmentSheetForm = ({ strings, config, availableProducts, assessmentSheet, setAssessmentSheet }) => {

  const getProductOptions = () => {
    let prodcutOptions = {
      notSelected: strings.notSelected
    }

    availableProducts.forEach(prod => {
      prodcutOptions[prod.id] = prod?.name[config.lang];
    });

    return prodcutOptions;
  }

  const getTypeOptions = () => {
    let typesOptions = {
      notSelected: strings.notSelected
    }

    let availableTypes = availableProducts.find(prod => prod?.id === assessmentSheet?.product);
    if (availableTypes && availableTypes?.subtypes) {
      availableTypes.subtypes.forEach(subtype => {
        typesOptions[subtype?.id] = subtype?.name[config.lang];
      });
    }

    return typesOptions;
  }

  const updateAssessmentSheet = (param, value) => {
    setAssessmentSheet((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  };

  const Tag = styled.div`
    margin: 0.5rem;
  `;

  const Table = {
    borderBottom: "0.1vh solid grey",
    width: "100%",
    paddingBottom: "1rem",
    tableLayout: "fixed",
    ["td"]: {
      width: "25%",
    },
  };

  return (
    <div>
      <table style={Table}>
        <tbody>
          <tr>
            <td>
              <Tag>
                <b>{strings.name}*</b>
              </Tag>
              <TextInput
                defaultValue={assessmentSheet.name?.[config.lang]}
                id={"name"}
                onChange={(e) => {
                  updateAssessmentSheet("name", {
                    CA: e.target.value,
                    ES: e.target.value,
                    EN: e.target.value,
                  });
                }}
              />
            </td>
            <td>
              <Tag>
                <b>{strings.product}*</b>
              </Tag>
              <Select id="product" options={getProductOptions()} defaultOption={assessmentSheet?.product} onChange={updateAssessmentSheet} />
            </td>
            <td>
              <Tag>
                <b>{strings.type}*</b>
              </Tag>
              <Select id="type" options={getTypeOptions()} defaultOption={assessmentSheet?.type} onChange={updateAssessmentSheet} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AssessmentSheetForm;
