import React from "react";

import ThemeRoutes from "./ThemeRoutes";

const ThemeEngine = ({ strings, config }) => {

  return <ThemeRoutes strings={strings} config={config} />;
};

export default ThemeEngine;
