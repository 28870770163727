import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import styled from "@emotion/styled";
import GeneratedVideoTextArea from "./GeneratedVideoTextArea";
import Select from "../../../../../../shared/Select";
import Button from "../../../../../../shared/Button";
import Firebase from "../../../../../../../assets/Firebase";
import { getVideoName } from "../../../../../../../assets/utils/VimeoUtils";
import AvatarImg from "../../../../../../../assets/images/avatar-icon.svg";

export default function GeneratedVideoForm({ strings, config, object, activeVideo, updateState }) {
  let f = new Firebase();

  const [duration, setDuration] = useState(0);
  const [link, setLink] = useState(null);
  let [avatars, setAvatars] = useState({});

  let disabled = object[activeVideo].taskRef != null || object[activeVideo].locked;
  let videoId = object[activeVideo] ? object[activeVideo].id : null;

  useEffect(() => {
    let id = object[activeVideo].id;
    if (id) {
      getVideoName(
        id,
        (name, status, duration, link) => {
          setDuration(duration);
          setLink(link);
        },
        onError
      );
    } else {
      setDuration(0);
      setLink(null);
    }
  }, [object, activeVideo]);

  useEffect(() => {
    let readedAvatars = { };

    f.loadDefaultAvatars((defaultSnap) => {
      defaultSnap.forEach((defaultDoc) => {
        readedAvatars[defaultDoc.ref.path] = defaultDoc.data();
      });
      f.loadAllAvatars((snap) => {
        snap.forEach((avatarDoc) => {
          readedAvatars[avatarDoc.ref.path] = avatarDoc.data();
        });
        setAvatars(readedAvatars);
      });
    });
  }, [strings]);

  const updateVideo = (param, value) => {
    object[activeVideo].platform = "generated";
    object[activeVideo].generated = true;
    object[activeVideo].modified = true;
    object[activeVideo][param] = value;
    updateState(activeVideo, object[activeVideo]);
  };

  const languages = {
    "es-ES": {
      name: strings.spanishEuropean,
      genders: ["male", "female"],
    },
    "es-MX": {
      name: strings.spanishMexican,
      genders: ["male", "female"],
    },
    "es-US": {
      name: strings.spanishUS,      
      genders: ["male", "female"],
    },
    "en-GB": {
      name: strings.englishBritish,
      genders: ["male", "female"],
    },
    "en-US": {
      name: strings.englishUS,
      genders: ["male", "female"],
    },
    "en-AU": {
      name: strings.englishAustralian,
      genders: ["male", "female"],
    },
    "fr-FR": {
      name: strings.french,
      genders: ["male", "female"],
    },
    "it-IT": {
      name: strings.italian,
      genders: ["male", "female"],
    },
    "ru-RU": {
      name: strings.russian,
      genders: ["male", "female"],
    },
    "de-DE": {
      name: strings.german,
      genders: ["male", "female"],
    },
    "cmn-CN": {
      name: strings.chinese,
      genders: ["male", "female"],
    },
    arb: {
      name: strings.arabic,
      genders: ["female"],
    },
    "da-DK": {
      name: strings.danish,
      genders: ["male", "female"],
    },
    "nl-NL": {
      name: strings.dutch,
      genders: ["male", "female"],
    },
    "hi-IN": {
      name: strings.hindi,
      genders: ["female"],
    },
    "is-IS": {
      name: strings.icelandic,
      genders: ["male", "female"],
    },
    "ja-JP": {
      name: strings.japanese,
      genders: ["male", "female"],
    },
    "ko-KR": {
      name: strings.korean,
      genders: ["female"],
    },
    "nb-NO": {
      name: strings.norwegian,
      genders: ["female"],
    },
    "pl-PL": {
      name: strings.polish,
      genders: ["male", "female"],
    },
    "pt-PT": {
      name: strings.portuguese,
      genders: ["male", "female"],
    },
    "sv-SE": {
      name: strings.swedish,
      genders: ["female"],
    },
    "tr-TR": {
      name: strings.turkish,
      genders: ["female"],
    },
    "ro-RO": {
      name: strings.romanian,
      genders: ["female"],
    },
  };

  const onError = (error) => {
    console.error(error);
  };

  const getLanguages = () => {
    let selectedLanguages = { notSelected: strings.notSelected };
    for (var [key, language] of Object.entries(languages)) {
        selectedLanguages[key] = language.name;
    }
    return selectedLanguages;
  }

  const getAvatars = () => {
    let selectedAvatars = { notSelected: strings.notSelected };
    let lang = object[activeVideo].language;

    if (lang != null && lang != undefined) {
      let genders = languages[lang].genders;

      for (var [key, avatar] of Object.entries(avatars)) {
        if(genders.includes(avatar.gender)) {
          selectedAvatars[key] = avatar.name;
        }
      }
    }
    
    return selectedAvatars;
  }

  const confirm = () => {
    if (object[activeVideo].locked) {
      updateVideo("locked", false);
    } else {
      if (object[activeVideo].avatarPath == null || object[activeVideo].avatarPath == "notSelected") {
        config.popupAlert("Error", strings.errrorAvatarSelect);
        return;
      }

      if (!object[activeVideo].text == null || 0 === object[activeVideo].text.length) {
        config.popupAlert("Error", strings.errorAvatarText);
        return;
      }

      updateVideo("error", null);
      updateVideo("locked", true);
    }
  };

  const getFormateDate = (firebaseDate) => {
    let date = new Date(new f.firebase.firestore.Timestamp(firebaseDate.seconds, 0).toDate());
    let formated =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + twoDigits(date.getHours()) + ":" + twoDigits(date.getMinutes());
    return formated;
  };

  const formatDuration = (duration) => {
    let mins = Math.floor(duration / 60);
    let segs = ("0" + (duration % 60)).slice(-2);
    return mins + ":" + segs;
  };

  const twoDigits = (n) => {
    return n > 9 ? "" + n : "0" + n;
  };

  const getErrorMsg = (errorCode) => {
    let error = strings["generationError" + object[activeVideo].error];

    if (error != undefined && error != null) {
      return error;
    } else {
      return errorCode;
    }
  };

  const Tag = styled.div`
    padding: 0.5rem 0rem;
    overflow: hidden;
    display: block;
  `;

  const GrayHint = {
    fontSize: "2vh",
    borderRadius: "0.3vh",
    color: "black",
    backgroundColor: "rgb(216, 221, 225)",
    textAlign: "center",
    marginTop: "0.5rem",
    padding: "0.5rem",
    width: "15vw",
  };

  const GreenHint = {
    ...GrayHint,
    backgroundColor: "rgb(49, 168, 51)",
    color: "white",
  };

  const ErrorHint = {
    ...GrayHint,
    backgroundColor: "red",
    color: "white",
  };

  const YellowHint = {
    ...GrayHint,
    backgroundColor: "rgb(227, 166, 0)",
    color: "white",
  };

  const Root = {
    width: "100%",
    textAlign: "left",
  };

  const Row = {
    display: "flex",
  };

  const VideoColumn = {
    width: "16vw",
    margin: "auto",
  };

  const FormCloumn = {
    width: "65%",
    marginLeft: "auto",
  };

  const ButtonsContainer = {
    textAlign: "center",
    marginTop: "1.5vh",
  };

  const VideoContainer = {
    height: "17vh",
    width: "16vw",
    backgroundColor: "black",
    textAlign: "center",
    margin: "1vh 0",
  };

  const VideoContainerUpload = {
    ...VideoContainer,
    backgroundColor: "#cbcbcb",
    position: "relative",
  };

  const ImageVideoUpload = {
    maxHeight: "40%",
    maxWidth: "40%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
  };

  return (
    <div style={Root}>
      <div style={Row}>
        <div style={VideoColumn}>
          {/* --- TOP INFROMATION PANELS ---- */}

          {object[activeVideo].error != null ? (
            <div style={ErrorHint}>{getErrorMsg(object[activeVideo].error)}</div>
          ) : object[activeVideo].taskRef != null ? (
            <div style={GreenHint}>
              {strings.infoGenerate}
              {object[activeVideo].generationDate ? " (" + getFormateDate(object[activeVideo].generationDate) + ")" : ""}
            </div>
          ) : object[activeVideo].locked ? (
            <div style={YellowHint}>{strings.generateVideo}</div>
          ) : (
            <div style={GrayHint}>{strings.infoAvatar}</div>
          )}

          {/* --- VIDEO PREVIEW FRAME --- */}
          {link ? (
            <div style={VideoContainer}>
              <ReactPlayer height="17vh" width="auto" url={link} controls={true} />
            </div>
          ) : (
            <div style={VideoContainerUpload}>
              <img src={AvatarImg} style={ImageVideoUpload} />
            </div>
          )}
          <Tag>
            <b>{strings.durationVideo} </b>
            {duration ? formatDuration(duration) : "-"}
          </Tag>
        </div>

        <div style={FormCloumn}>
          <div style={Row}>
          <div style={{ width: "50%", paddingRight: "0.5vw" }}>
              <Tag>
                <b>{strings.language}:</b>
              </Tag>
              <Select
                id="language"
                disabled={disabled}
                options={getLanguages()}
                defaultOption={object[activeVideo].language || "notSelected"}
                onChange={updateVideo}
                width="100%"
              />
            </div>
            
            <div style={{ width: "50%", paddingLeft: "0.5vw" }}>
              <Tag>
                <b>{strings.avatar}:</b>
              </Tag>
              <Select
                id="avatarPath"
                disabled={disabled}
                options={getAvatars()}
                defaultOption={object[activeVideo].avatarPath || "notSelected"}
                onChange={updateVideo}
                width="100%"
              />
            </div>
          </div>

          <div>
            <Tag>
              <b>{strings.text}</b>
            </Tag>
            <GeneratedVideoTextArea
              strings={strings}
              disabled={disabled}
              value={object[activeVideo].text}
              onInput={(e) => {
                if (!disabled) {
                  updateVideo("text", e);
                }
              }}
            />
          </div>
        </div>
      </div>

      <div style={ButtonsContainer}>
        {object[activeVideo].taskRef == null ? (
          <Button value={object[activeVideo].locked ? strings.modify : strings.confirm2} action={confirm} style={{ marginLeft: "1vw" }} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
