import React from "react";
import SignupComponent from "./FormComponent";
import Firebase from "../../../../assets/Firebase";
import { useHistory, useLocation } from "react-router-dom";

const SignupEngine = ({ strings, theme }) => {
  let f = new Firebase();
  let history = useHistory();
  let paises = new Array(
    "Afganistán",
    "Akrotiri",
    "Albania",
    "Alemania",
    "Andorra",
    "Angola",
    "Anguila",
    "Antártida",
    "Antigua y Barbuda",
    "Antillas Neerlandesas",
    "Arabia Saudí",
    "Arctic Ocean",
    "Argelia",
    "Argentina",
    "Armenia",
    "Aruba",
    "Ashmore andCartier Islands",
    "Atlantic Ocean",
    "Australia",
    "Austria",
    "Azerbaiyán",
    "Bahamas",
    "Bahráin",
    "Bangladesh",
    "Barbados",
    "Bélgica",
    "Belice",
    "Benín",
    "Bermudas",
    "Bielorrusia",
    "Birmania Myanmar",
    "Bolivia",
    "Bosnia y Hercegovina",
    "Botsuana",
    "Brasil",
    "Brunéi",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Bután",
    "Cabo Verde",
    "Camboya",
    "Camerún",
    "Canadá",
    "Chad",
    "Chile",
    "China",
    "Chipre",
    "Clipperton Island",
    "Colombia",
    "Comoras",
    "Congo",
    "Coral Sea Islands",
    "Corea del Norte",
    "Corea del Sur",
    "Costa de Marfil",
    "Costa Rica",
    "Croacia",
    "Cuba",
    "Dhekelia",
    "Dinamarca",
    "Dominica",
    "Ecuador",
    "Egipto",
    "El Salvador",
    "El Vaticano",
    "Emiratos Árabes Unidos",
    "Eritrea",
    "Eslovaquia",
    "Eslovenia",
    "España",
    "Estados Unidos",
    "Estonia",
    "Etiopía",
    "Filipinas",
    "Finlandia",
    "Fiyi",
    "Francia",
    "Gabón",
    "Gambia",
    "Gaza Strip",
    "Georgia",
    "Ghana",
    "Gibraltar",
    "Granada",
    "Grecia",
    "Groenlandia",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Ecuatorial",
    "Guinea-Bissau",
    "Guyana",
    "Haití",
    "Honduras",
    "Hong Kong",
    "Hungría",
    "India",
    "Indian Ocean",
    "Indonesia",
    "Irán",
    "Iraq",
    "Irlanda",
    "Isla Bouvet",
    "Isla Christmas",
    "Isla Norfolk",
    "Islandia",
    "Islas Caimán",
    "Islas Cocos",
    "Islas Cook",
    "Islas Feroe",
    "Islas Georgia del Sur y Sandwich del Sur",
    "Islas Heard y McDonald",
    "Islas Malvinas",
    "Islas Marianas del Norte",
    "IslasMarshall",
    "Islas Pitcairn",
    "Islas Salomón",
    "Islas Turcas y Caicos",
    "Islas Vírgenes Americanas",
    "Islas Vírgenes Británicas",
    "Israel",
    "Italia",
    "Jamaica",
    "Jan Mayen",
    "Japón",
    "Jersey",
    "Jordania",
    "Kazajistán",
    "Kenia",
    "Kirguizistán",
    "Kiribati",
    "Kuwait",
    "Laos",
    "Lesoto",
    "Letonia",
    "Líbano",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Luxemburgo",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malasia",
    "Malaui",
    "Maldivas",
    "Malí",
    "Malta",
    "Man, Isle of",
    "Marruecos",
    "Mauricio",
    "Mauritania",
    "Mayotte",
    "México",
    "Micronesia",
    "Moldavia",
    "Mónaco",
    "Mongolia",
    "Montserrat",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Navassa Island",
    "Nepal",
    "Nicaragua",
    "Níger",
    "Nigeria",
    "Niue",
    "Noruega",
    "Nueva Caledonia",
    "Nueva Zelanda",
    "Omán",
    "Pacific Ocean",
    "Países Bajos",
    "Pakistán",
    "Palaos",
    "Panamá",
    "Papúa-Nueva Guinea",
    "Paracel Islands",
    "Paraguay",
    "Perú",
    "Polinesia Francesa",
    "Polonia",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reino Unido",
    "República Centroafricana",
    "República Checa",
    "República Democrática del Congo",
    "República Dominicana",
    "Ruanda",
    "Rumania",
    "Rusia",
    "Sáhara Occidental",
    "Samoa",
    "Samoa Americana",
    "San Cristóbal y Nieves",
    "San Marino",
    "San Pedro y Miquelón",
    "San Vicente y las Granadinas",
    "Santa Helena",
    "Santa Lucía",
    "Santo Tomé y Príncipe",
    "Senegal",
    "Seychelles",
    "Sierra Leona",
    "Singapur",
    "Siria",
    "Somalia",
    "Southern Ocean",
    "Spratly Islands",
    "Sri Lanka",
    "Suazilandia",
    "Sudáfrica",
    "Sudán",
    "Suecia",
    "Suiza",
    "Surinam",
    "Svalbard y Jan Mayen",
    "Tailandia",
    "Taiwán",
    "Tanzania",
    "Tayikistán",
    "Territorio Británico del Océano Indico",
    "Territorios Australes Franceses",
    "Timor Oriental",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad y Tobago",
    "Túnez",
    "Turkmenistán",
    "Turquía",
    "Tuvalu",
    "Ucrania",
    "Uganda",
    "Uruguay",
    "Uzbekistán",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wake Island",
    "Wallis y Futuna",
    "West Bank",
    "World",
    "Yemen",
    "Yibuti",
    "Zambia",
    "Zimbabue"
  );

  const queryParams = new URLSearchParams(useLocation().search);
  const mentorCode = queryParams.get("mentorCode");

  const signUpEvent = () => {
    let email = document.getElementById("email").value;
    let username = document.getElementById("username").value;
    let phone = document.getElementById("phone").value.replace(/[^\d]/g, "");
    let country = paises[document.getElementById("country").value];
    let password = document.getElementById("password").value;
    let repPassword = document.getElementById("repPassword").value;
    let mentorCode = document.getElementById("mentorCode").value;
    let check = document.getElementById("checkTC").checked;

    let controlPhone = true;
    let controlUser = false;
    if (username === "") {
      controlUser = true;
    } else {
      controlUser = true;
    }
    if (phone.length >= 1) {
      controlPhone = false;
      if (phone.length > 6 && phone.length < 11) {
        controlPhone = true;
      }
    }
    console.log(controlUser);
    let usernameError = document.getElementById("usernameError");
    let emailError = document.getElementById("emailError");
    let passwordError = document.getElementById("passwordError");
    let repPasswordError = document.getElementById("repPasswordError");
    let repCheckError = document.getElementById("repCheckError");
    let mentorCodeError = document.getElementById("mentorCodeError");
    mentorCodeError.style.visibility = "hidden";
    passwordError.style.visibility = "hidden";
    emailError.innerText = "Este correo esta repetido";
    passwordError.innerText = "Mínimo 6 caracteres minimo";
    repCheckError.style.visibility = "hidden";
    emailError.style.visibility = "hidden";
    usernameError.style.visibility = "hidden";
    /*username = username.includes("@gmail.com")? username : username + "@gmail.com";*/
    if (document.getElementById("email").value == "") {
      emailError.style.visibility = "visible";
      emailError.innerText = "Escribe un correo electronico";
    }
    let isCorrect = true;
    if (!controlUser) {
      usernameError.style.visibility = "visible";
    }
    if (password.length < 6) {
      passwordError.style.visibility = "visible";
    }
    if (password != repPassword) {
      repPasswordError.style.visibility = "visible";
      isCorrect = true;
    } else if (password == "") {
      passwordError.style.visibility = "visible";
      passwordError.innerText = "Escribe una contraseña";
    } else {
      repPasswordError.style.visibility = "hidden";
    }
    if (!check) {
      repCheckError.style.visibility = "visible";
    }

    if (isCorrect && check && password.length >= 6 && controlUser) {
      f.tryCreateUser(email, password, username, phone, country, mentorCode, success, error);
    }
  };

  const success = (email, username, phone, country) => {
    document.getElementById("formSign").style.display = "none";
    document.getElementById("formOK").style.display = "initial";

    f.sendEmailVerification(email);
  };

  const error = (err) => {
    let emailError = document.getElementById("emailError");
    let passwordError = document.getElementById("passwordError");
    let mentorCodeError = document.getElementById("mentorCodeError");
    mentorCodeError.style.visibility = "hidden";
    emailError.style.visibility = "hidden";
    passwordError.style.visibility = "hidden";
    emailError.innerText = "Este correo ya existe.";

    console.log(err);
    switch (err) {
      case "MENTOR_CODE_NOT_FOUND":
        mentorCodeError.style.visibility = "visible";
        break;
      case "auth/weak-password":
        passwordError.style.visibility = "visible";
        break;
      case "auth/email-already-in-use":
        emailError.style.visibility = "visible";
        break;
      case "auth/invalid-email":
        emailError.style.visibility = "visible";
        if (document.getElementById("email").value == "") {
          emailError.innerText = "Escribe un correo electronico";
        } else {
          emailError.innerText = "Escribe correctamente el correo electronico";
        }
        break;
      default:
        alert("Ha ocurrido un error inesperado, inténtelo más tarde");
        console.error("Register error: " + err);
        break;
    }
  };
  const backLogin = () => {
    history.replace("/login");
  };

  return <SignupComponent strings={strings} signUpEvent={signUpEvent} backLogin={backLogin} paises={paises} mentorCode={mentorCode} />;
};

export default SignupEngine;
