import React, { useState, useEffect } from "react";
import ListComponent from "./ListComponent";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import { BrowserRouter as Router, useRouteMatch, useHistory } from "react-router-dom";

const ListEngine = ({ strings, config }) => {
  let history = useHistory();
  let ba = new BackendAccessor();

  const [products, setProducts] = useState(null);
  const [loadingText, setLoadingText] = useState(null);

  const formatProducts = (products) => {
    let formatedProducts = {};
    console.log(products);
    if (products) {
      products.forEach(product => {
        let id = ba.getIdFromPath(product?.reference);
        formatedProducts[id] = {
          name: product?.name,
          type: product?.type?.name?.[config.lang] || "-",
          lastUpdate: product?.lastUpdate ? new Date(product?.lastUpdate) : null,
        }
      });
    }
    console.log(formatedProducts);
    return formatedProducts;
  }

  const loadProducts = () => {
    ba.getProductsList(
      (products) => {
        setProducts(formatProducts(products));
      },
      (error) => {
        console.error("Error loading products: ", error);
        config.popupAlert("Error", "Error loading products")
      }
    );
  }

  useEffect(() => {
    if (products == null) {
      loadProducts();
    }
  }, [strings]);

  const createProduct = () => {
    if (Object.entries(products).length < config?.plan?.maxWines) {
      history.replace(`/dashboard/products/new`);
    } else {
      config.popupAlert(strings.error, strings.maxProductsReached);
    }
  };

  const editProduct = (id) => {
    history.replace(`/dashboard/products/edit/${id}`);
  };

  const deleteProduct = (key) => {
    config.popupAlert(strings.deleteProduct + " - " + products[key].name, strings.deleteConfirm, null, true, () => {
      ba.deleteProductById(key,
        (response) => {
          console.log("Product with ID " + key + " has been deleted", response);
          setProducts((prevState) => {
            const state = { ...prevState };
            delete state[key];
            return state;
          });
        },
        (error) => {
          console.error("Error deleting Product with ID " + key, error);
          config.popupAlert("Error", "Error deleting product");
        });
    });
  };

  const cloneProduct = (productId) => {
    setLoadingText(strings.cloning);
    ba.cloneProduct(
      productId,
      (response) => {
        console.log("Clone response", response);
        setTimeout(function () {
          loadProducts();
          setLoadingText(null);
        }, 2000);
      },
      (error) => {
        console.error("Error cloning product: ", error);
        setLoadingText(null);
      }
    );
  }

  const cloneProductStructure = (productId) => {
    setLoadingText(strings.cloningStructure);
    ba.cloneProductStructure(
      productId,
      (response) => {
        setTimeout(function () {
          loadProducts();
          setLoadingText(null);
        }, 2000);
      },
      (error) => {
        console.error("Error cloning product structure: ", error);
        setLoadingText(null);
      }
    );
  }

  return (<>
    {
      products != null ? (
        <ListComponent
          strings={strings}
          config={config}
          products={products}
          createProduct={createProduct}
          cloneProductStructure={cloneProductStructure}
          editProduct={editProduct}
          cloneProduct={cloneProduct}
          deleteProduct={deleteProduct}
          ready={products != null}
        />
      ) : (
        <LoadingScreen />
      )
    }
    {loadingText != null ? <DownloadingPopup strings={strings} headerText={loadingText} /> : <></>}
  </>
  );
};

export default ListEngine;
