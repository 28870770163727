import React from "react";
import MaterialSwitch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Colors from "../../assets/Colors.json";


const Switch = ({ strings, id, value, action }) => {
  const Wrapper = {
    display: "flex",
    width: "100%",
  };

  const Tag = {
    margin: "auto 0.5vw",
    fontWeight: "bold",
  };

  const SwitchStyle = {
    margin: "0.2rem",
  };

  const StoreSwitch = withStyles({
    switchBase: {
      color: Colors.lightTheme.accentColor1,
      "&$checked": {
        color: Colors.lightTheme.accentColor1,
      },
      "&$checked + $track": {
        backgroundColor: Colors.lightTheme.accentColor2,
      },
    },
    checked: {},
    track: {},
  })(MaterialSwitch);

  return (
    <div style={Wrapper}>
      <FormControlLabel
        style={SwitchStyle}
        control={<StoreSwitch id={id} checked={value} onChange={action} />}
        label={strings[id]}
        labelPlacement="start"
      />
      <div style={Tag}>{value ? strings.switchOn : strings.switchOff}</div>
    </div>
  );
};

export default Switch;
