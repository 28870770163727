import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import Button from "../../../../../shared/Button";
import Switch from "../../../../../shared/Switch";
import Colors from "../../../../../../assets/Colors.json";

const SoundInput = ({ config, strings, id, header, audioData, upload, cloudPath, updateAudio, maxSize = "50" }) => {

    let ba = new BackendAccessor();
    const [loadingText, setLoadingText] = useState(null);

    const onChange = (input) => {
        if (input.target.files && input.target.files[0]) {
            let file = input.target.files[0];
            let url = URL.createObjectURL(file);
            let size = file.size / (1024 * 1024); //MB

            if (maxSize && size > maxSize) {
                config.popupAlert(strings?.error, strings?.errorMaxSize?.replace("{size}", maxSize));
            } else if (upload) {
                uploadSound(file);
            } else {
                readSound(file);
            }
        }
    }

    const readSound = (file) => {

    }

    const uploadSound = (file) => {
        console.log("File", file);
        setLoadingText("Uploading image");
        ba.uploadFile(
            cloudPath,
            file,
            (result) => {
                console.log("Result", result);
                updateAudio("name", file.name);
                updateAudio("url", result.url);
                setLoadingText(null);
            },
            (error) => {
                console.error("Error uplading icon: ", error);
                setLoadingText(null);
            }
        );
    };

    const openFilePicker = () => {
        document.getElementById("SoundInput-" + id).click()
    }

    const Wrapper = {
        width: "90%",
        backgroundColor: "#cbcbcb",
        borderRadius: "1vh",
        padding: "1vh 3vh",
        marginBottom: "1vh"
    };

    const SoundInput = styled.input`
        display: none;
    `;

    const Header = styled.div`
        font-size: 2vh;
        font-weight: bold;
        color: ${Colors.lightTheme.accentColor1};
    `;

    const Row = styled.div`
        display: flex;
        flex-direction: row;
        padding: 0.5vh 0;
    `;

    const AudioPlayer = styled.audio`
        width: 90%;
    `;

    const Cell = styled.div`
        display: flex;
        width: ${props => props.width || "33%"};
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
    `;

    return (
        <div style={Wrapper}>
            <Header>{header}</Header>

            <Row>
                <Cell width={audioData?.active ? "25%" : "50%"}>
                    <Switch
                        strings={strings}
                        value={audioData?.active}
                        action={() => {
                            updateAudio("active", !audioData?.active);
                        }} />
                </Cell>
                {audioData?.active ? (
                    <>
                        <Cell width="45%">
                            <AudioPlayer controls>
                                <source src={audioData?.url} />
                            </AudioPlayer>
                        </Cell>
                        <Cell width="30%">
                            <Button value={strings.uploadSound} action={openFilePicker} style={{ padding: "1vh 2vh", fontSize: "1.75vh" }} />
                        </Cell>
                    </>
                ) : (
                    <></>
                )}
            </Row>

            <SoundInput type="file" id={"SoundInput-" + id} accept="audio/*" onChange={onChange} />
            {loadingText != null ? <DownloadingPopup headerText={loadingText} /> : <></>}
        </div >
    );
};
export default SoundInput;
