import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Firebase from "../../../../../../assets/Firebase";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import StepTopBar from "./Forms/StepTopBar";
import PlanForm from "./Forms/PlanForm";
import PersonalForm from "./Forms/PersonalForm";
import PaymentForm from "./Forms/PaymentForm";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { processSubscriptionPayment } from "../../../../../../assets/utils/StripeUtils";
import { isValidateDNI, isValidCif, isValidPhone } from "../../../../../../assets/utils/FormsUtils";
import TermsPopup from "./Forms/TermsPopup";

const PlansEngine = ({ strings, stripeTest, config }) => {
  let f = new Firebase();
  let ba = new BackendAccessor();
  let history = useHistory();

  const elements = useElements();
  const stripe = useStripe();

  const [step, setStep] = useState();
  const [processing, setProcessing] = useState(false);
  const [celler, setCeller] = useState({});
  const [mentor, setMentor] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [interval, setInterval] = useState(null);
  const [plans, setPlans] = useState({});
  const [termsVisibles, setTermsVisibles] = useState(false);

  const loadPlans = () => {
    f.loadAllPlans((snap) => {
      let plans = {};
      snap.forEach((doc) => {
        let plan = doc.data();
        plan.ref = doc.ref.path;
        plans[doc.ref.path] = plan;

        if (celler.plan != undefined && plan.ref == celler.plan.path) {
          setCurrentPlan(plan);
        }
      });
      setPlans(plans);
      goToPlanForm();
    });
  };

  const loadCellar = () => {
    f.loadCeller((doc) => {
      setCeller((prevState) => ({
        ...prevState,
        ...doc.data(),
      }));
      loadPlans();
    });
  };

  const loadMentor = () => {
    ba.getAssociatedMentor(
      (response) => {
        setMentor(response?.mentor);
      },
      (error) => {
        console.log("Mentor not found", error);
        setMentor(null);
      }
    );
  };

  useEffect(() => {
    loadCellar();
    loadMentor();
  }, [strings]);

  useEffect(() => {
    loadPlans();
  }, [celler.plan]);

  const goToPlanForm = () => {
    setStep(1);
  };

  const goToPersonalForm = () => {
    setStep(2);
  };

  const goToPaymentForm = () => {
    let errorMsg = null;

    let checkBox = document.getElementById("terminosYCondiciones");

    if (!checkBox.checked) {
      config.popupAlert(strings.error, strings.errorTerms);
      return;
    }

    if (!celler.name || celler.name.length === 0) {
      errorMsg = '"' + strings.companyName + '"';
    }

    if (celler.country == "España") {
      if (!celler.nif || celler.nif.length === 0) {
        errorMsg = '"' + strings.nif + '"';
      } else if (!isValidCif(celler.nif) && !isValidateDNI(celler.nif)) {
        config.popupAlert(strings.error, strings.errorNif);
        return;
      }
    }

    if (!celler.personalName || celler.personalName.length === 0) {
      errorMsg = '"' + strings.name + '"';
    }

    if (!celler.personalSurnames || celler.personalSurnames.length === 0) {
      errorMsg = '"' + strings.personalSurnames + '"';
    }

    if (!celler.address || celler.address.length === 0) {
      errorMsg = '"' + strings.address + '"';
    }

    if (!celler.cp || celler.cp.length === 0) {
      errorMsg = '"' + strings.cp + '"';
    }

    if (!celler.population || celler.population.length === 0) {
      errorMsg = '"' + strings.population + '"';
    }

    if (!celler.country || celler.country.length === 0) {
      errorMsg = '"' + strings.country + '"';
    }

    if (!celler.phone || celler.phone.length === 0) {
      errorMsg = '"' + strings.phone + '"';
    } else if (!isValidPhone(celler.phone)) {
      config.popupAlert(strings.error, strings.errorTelf);
      return;
    }

    if (!errorMsg) {
      f.updateCeller(celler, () => {});
      setStep(3);
    } else {
      config.popupAlert(strings.error, errorMsg + " " + strings.cantBeNull);
    }
  };

  const pay = () => {
    setProcessing(true);

    if (!stripe || !elements) {
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const planId = selectedPlan.ref.split("/")[1];

    processSubscriptionPayment(stripe, cardElement, celler, planId, interval, onSubscriptionComplete, onPaymentError);
  };

  function onSubscriptionComplete() {
    console.log("[Stripe]: Process completed successfully");
    setProcessing(false);
    config.popupAlert(strings.succesPayment, strings.succesText, () => {
      history.push("/");
    });
  }

  const onPaymentError = (error) => {
    console.error("[Stripe]: Error: ", error);
    setProcessing(false);

    if (typeof error === "string" || error instanceof String) {
      config.popupAlert(strings.error, error);
    } else {
      config.popupAlert(strings.error, "Ha ocurrido un error inesperado");
    }
  };

  const Container = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };

  const openTermsConditions = () => {
    window.open("http://terms.upwine.es/plans/bodega/condiciones-de-uso.html", "_blank");
  };

  const openPrivacyPolicy = () => {
    window.open("http://terms.upwine.es/plans/politica-privacidad.html", "_blank");
  };

  return (
    <div style={Container}>
      <StepTopBar strings={strings} step={step} />
      {step == 1 ? (
        <PlanForm
          strings={strings}
          config={config}
          celler={celler}
          plans={plans}
          selectedPlan={currentPlan}
          setSelectedPlan={setSelectedPlan}
          goNext={goToPersonalForm}
          setInterval={setInterval}
        />
      ) : step == 2 ? (
        <PersonalForm
          strings={strings}
          selectedPlan={selectedPlan}
          celler={celler}
          setCeller={setCeller}
          mentor={mentor}
          goBack={goToPlanForm}
          goNext={goToPaymentForm}
          openTermsConditions={openTermsConditions}
          openPrivacyPolicy={openPrivacyPolicy}
        />
      ) : step == 3 ? (
        <PaymentForm
          stripeTest={stripeTest}
          strings={strings}
          selectedPlan={selectedPlan}
          interval={interval}
          celler={celler}
          goBack={goToPersonalForm}
          pay={pay}
          openTermsConditions={openTermsConditions}
          openPrivacyPolicy={openPrivacyPolicy}
        />
      ) : (
        <LoadingScreen />
      )}
      {processing ? <DownloadingPopup strings={strings} /> : <></>}
      <TermsPopup strings={strings} termsVisibles={termsVisibles} setTermsVisibles={setTermsVisibles} />
    </div>
  );
};
export default PlansEngine;
