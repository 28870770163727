import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { isNullString } from "../../../../../../../assets/utils/FormsUtils";


const ProductsDetailsWrapperForm = ({ strings, config, product, productPath, assessment, dataParser }) => {

    const MainTable = styled.table`
        border-collapse: collapse;
        table-layout: fixed;
        width: 97%;
        margin: 1.5%;
        border: 2px solid rgba(100, 100, 100, 0.5);
        td {
            padding: 2vh;
            background-color: white;
            border: 2px solid rgba(100, 100, 100, 0.5);
        }
        th {
            padding: 2vh;
            text-align: left;
            background-color: rgb(225, 225, 225);
            border: 2px solid rgba(100, 100, 100, 0.5);
        }
    `;

    return (
        <>
            {product !== null ? (
                <div style={{ width: "100%" }}>
                    <table>
                        <tr>
                            <td rowSpan="4">
                                <img style={{ height: "15vh", margin: "0 2vw" }} src={product?.image} />
                            </td>
                        </tr>
                        <tr>
                            <th>{strings.name}:</th>
                            <td>{product?.name || "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.type}:</th>
                            <td>{product?.type?.name?.[config.lang] || "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.subtype}:</th>
                            <td>{product?.subtype?.name?.[config.lang] || "-"}</td>
                        </tr>
                    </table>
                </div>
            ) : (
                <></>
            )}

            <MainTable>
                {Object.entries(assessment?.parameters || {}).length === 0 && Object.entries(assessment?.categories || {}).length === 0 ? (
                    <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>No assessments found!</td>
                    </tr>
                ) : (
                    <></>
                )}

                {Object.entries(assessment?.parameters || {}).map(([parameterId, asParamter]) => {
                    if (asParamter !== null && !isNullString(asParamter)) {
                        return (
                            <tr>
                                <th>{dataParser.getParameterName(productPath, parameterId) || parameterId}</th>
                                <td colSpan="2">{asParamter} {parameterId === "blindPrice" ? (config?.cellar?.currency || "€") : ""}</td>
                            </tr>
                        );
                    }
                })}

                {Object.entries(assessment?.categories || {}).map(([categoryId, asCategory]) => {
                    let i = 0;
                    return (
                        Object.entries(asCategory || {}).map(([parameterId, asParameter]) => {
                            let element = (
                                <tr>
                                    {i === 0 ? (
                                        <th rowSpan={Object.entries(asCategory || {}).length}>{dataParser.getCategoryName(productPath, categoryId)}</th>
                                    ) : (
                                        <></>
                                    )}
                                    <td>{dataParser.getCategoryParameterName(productPath, categoryId, parameterId)}</td>
                                    <td>{dataParser.getOptionsName(productPath, categoryId, parameterId, asParameter)}</td>
                                </tr>
                            );
                            i++;
                            return element;
                        })
                    )
                })}
            </MainTable>
        </>
    );
}
export default ProductsDetailsWrapperForm;
