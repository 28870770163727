import React, { useState, useEffect } from "react";
import BackendAccessor from "../../../../../assets/BackendAccessor";
import LoadingScreen from "../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../shared/DownloadingPopup";

import AboutForm from "./AboutForm";
import Package from "../../../../../../package.json";
import Firebase from "../../../../../assets/Firebase";

const AboutEngine = ({ strings, config }) => {
  let f = new Firebase();
  let ba = new BackendAccessor();

  const [ready, setReady] = useState(false);
  const [version, setVersion] = useState(false);
  const [mentor, setMentor] = useState(null);
  const [loadingText, setLoadingText] = useState(null);

  useEffect(() => {
    ba.getAssociatedMentor(
      (response) => {
        setMentor(response?.mentor);
      },
      (error) => {
        console.log("Mentor not found", error);
      }
    );

    setReady(true);
  }, [strings]);

  useEffect(() => {
    setVersion(Package.version);
  }, [Package]);

  return <>
    {loadingText != null ?
      <DownloadingPopup headerText={loadingText} />
      :
      <></>
    }
    {
      ready ?
        <AboutForm strings={strings} version={version} mentor={mentor} />
        :
        <LoadingScreen />
    }
  </>;
};
export default AboutEngine;
