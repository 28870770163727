import React, { useState, useEffect } from "react";
import Button from "../../../../../shared/Button";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import ComponentForm from "./ComponentForm";

const ComponentsWrapperForm = ({ strings, config, object, parameter, updateObject, groupedByCategories = true }) => {
  const ba = new BackendAccessor();

  const Wrapper = {
  };

  const CategoryWrapper = {
    backgroundColor: "white",
    borderRadius: "0px 6px 6px 6px",
    padding: "2vh",
  };

  const Header = {
    margin: "1.5vh 0",
    width: "100%",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
  };

  const addComponent = () => {
    let compId = ba.createUid();
    let components = object?.[parameter] || [];
    components.push({ id: compId });
    updateObject(parameter, components);
  };

  const updateComponent = (index, key, value) => {
    let components = object?.[parameter] || [];
    components[index][key] = value;
    updateObject(parameter, components);
  };

  const deleteComponent = (index) => {
    let components = object?.[parameter] || [];
    components.splice(index, 1);
    updateObject(parameter, components);
  };

  const moveBack = (index) => {
    let newIndex = index - 1;
    move(index, newIndex);
  }

  const moveForward = (index) => {
    let newIndex = index + 1;
    move(index, newIndex);
  }

  const move = (index, newIndex) => {
    let components = object?.[parameter] || [];
    var component = components[index];
    components.splice(index, 1);
    components.splice(newIndex, 0, component);

    updateObject(parameter, components);
  }

  return (
    <div>
      {groupedByCategories ?
        <div>
          <h3 style={Header}>{strings.components}</h3>
        </div>
        :
        <></>
      }
      {(object?.[parameter] || []).length > 0 ? (
        <>
          <div style={groupedByCategories ? CategoryWrapper : Wrapper}>
            {object?.[parameter].map((component, index, array) => (
              <ComponentForm
                strings={strings}
                config={config}
                componentId={component?.id}
                component={component}
                groupedByCategories={groupedByCategories}
                updateComponent={(key, value) => { updateComponent(index, key, value) }}
                deleteComponent={() => { deleteComponent(index) }}
                moveBack={index > 0 ? () => { moveBack(index) } : null}
                moveForward={index < (array.length - 1) ? () => { moveForward(index) } : null}
              />
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      <div style={{ display: "flex", padding: "1vh", flexDirection: "column" }}>
        <Button action={addComponent} value={groupedByCategories ? strings.addComponent : strings.addParameter} style={{ margin: "0 auto" }} />
      </div>
    </div>
  );
};
export default ComponentsWrapperForm;
