import React, { useState, useEffect } from "react";
import SessionsRoutes from "./SessionsRoutes";

const SessionsEngine = ({ strings, config, lang, selectLang }) => {
  return (
    <SessionsRoutes
      strings={strings}
      config={config}
      lang={lang}
      selectLang={selectLang} />
  );
};
export default SessionsEngine;
