import React, { useState, useEffect, useRef } from "react";

import SurveyFormComponent from "./SurveyFormComponent";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";

const SurveyFormEngine = ({ strings, reportType, config, utils, reportData, sessionData }) => {
    let history = useHistory();

    const goBack = () => {
        console.log(reportType);
        history.replace("/dashboard/reports/" + reportType + "/" + reportData.id);
    }

    const getSurveyTotal = (questionRef, optionId) => {
        let totalOfValue = 0;

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let selected = assistant?.answers?.some(ans => ans.questionPath === questionRef && ans?.answers?.includes(optionId));
            if (selected) totalOfValue++;
        });

        return totalOfValue;
    }

    const getSurveyPercent = (questionRef, optionId) => {
        let totalOfValue = 0;
        let total = 0;

        Object.entries(sessionData?.assistants || {}).forEach(([id, assistant]) => {
            let anySelection = assistant?.answers?.some(ans => ans.questionPath === questionRef);
            if (anySelection) {
                let optionSelected = assistant?.answers?.some(ans => ans.questionPath === questionRef && ans?.answers?.includes(optionId));
                total++;

                if (optionSelected) {
                    totalOfValue++;
                }
            }
        });

        let percent = ((totalOfValue / total) * 100);
        return percent ? utils.roundDecimal(percent) : 0;
    }

    const getCharData = (question) => {
        let labels = [];
        let data = [];

        Object.entries(question?.options).map(([optionId, option]) => {
            labels.push(option?.[config.lang]);
            data.push(getSurveyTotal(question?.reference, optionId));
        });

        return utils.generateGraphData(labels, data);
    }

    return (
        <SurveyFormComponent
            strings={strings}
            config={config}
            sessionData={sessionData}
            getSurveyTotal={getSurveyTotal}
            getSurveyPercent={getSurveyPercent}
            getCharData={getCharData}
            goBack={goBack}
        />
    );
};

export default SurveyFormEngine;