import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import ListComponent from "./ListComponent";
import { isNullOrUndefined } from "../../../../../../assets/utils/FormsUtils";

const ListEngine = ({ strings, config, setAssistantsRanking, lang, selectLang }) => {
  const [page, setPage] = useState(1);
  const [pageOld, setPageOld] = useState(page);
  const [direction, setDirection] = useState(true);
  const [exitsNewPage, setExistsNewPage] = useState(true);
  const [assistants, setAssistants] = useState([]);
  const [data, setData] = useState(null);

  const [firstDoc, setFirstDoc] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [noneArray, setNoneArray] = useState(false);

  const ba = new BackendAccessor();
  let history = useHistory();

  useEffect(() => {
    if (config?.email && page !== pageOld || config?.email && page !== pageOld && assistants.length == 0) {
      config.showDownloadingPopup(strings.loadSessions);

      let dataPost = {
        email: config?.email,
        firstDoc: firstDoc,
        lastDoc: lastDoc,
        direction: direction,
        noneArray: noneArray,
      }

      ba.getAssistantSessionsList(dataPost, (sessions) => {
        console.log("Hola", sessions);
        config.hideDownloadingPopup();
        if (sessions.length != 0) {
          setFirstDoc(sessions[0].people)
          setLastDoc(sessions[sessions.length - 1].people)
          setNoneArray(false)
          setExistsNewPage(true)
        }
        else {
          setExistsNewPage(false)
          setNoneArray(true)
        }
        setAssistants(sessions);
        setData(null)
      }, (error) => {
        config.hideDownloadingPopup();
        setAssistants([]);
        setData(null);
        config.popupAlert("Error", "Error getting sessions");
        console.error("Error getting sessions: ", error);
      })
    }
    else if (config?.email && config?.userEmail && assistants.length == 0) {
      config.showDownloadingPopup(strings.loadSessions);
      ba.getAssistantSessionsListByEmailCreator(config?.email, config?.userEmail, page, (sessions) => {
        console.log("Hola", sessions);
        config.hideDownloadingPopup();
        if (sessions.length != 0) {
          setFirstDoc(sessions[0].people)
          setLastDoc(sessions[sessions.length - 1].people)
          setNoneArray(false)
          setExistsNewPage(true)
        }
        else {
          setExistsNewPage(false)
          setNoneArray(true)
        }
        setAssistants(sessions);
      }, (error) => {
        config.hideDownloadingPopup();
        setAssistants([]);
        config.popupAlert("Error", "Error getting sessions");
        console.error("Error getting sessions: ", error);
      })
    }
    else if (config?.email && assistants.length == 0) {
      config.showDownloadingPopup(strings.loadSessions);

      ba.getAssistantSessionsListByEmail(config?.email, page, (sessions) => {
        console.log("Hola", sessions);
        config.hideDownloadingPopup();
        if (sessions.length != 0) {
          setFirstDoc(sessions[0].people)
          setLastDoc(sessions[sessions.length - 1].people)
          setNoneArray(false)
          setExistsNewPage(true)
        }
        else {
          setExistsNewPage(false)
          setNoneArray(true)
        }
        setAssistants(sessions);
      }, (error) => {
        config.hideDownloadingPopup();
        setAssistants([]);
        config.popupAlert("Error", "Error getting sessions");
        console.error("Error getting sessions: ", error);
      })
    }

  }, [config?.email, config?.userEmail, page]);

  useEffect(() => {
    if (assistants.length !== 0) {
      loadDataTable();
    }
    if (data !== null) {
      loadDataTable();
    }
  }, data)

  const prevPage = () => {
    if ((page - 1) > 0) {
      setDirection(false)
      setPageOld(page);
      setPage(page - 1);
    }
  }

  const nextPage = () => {
    if (exitsNewPage) {
      setDirection(true);
      setPageOld(page);
      setPage(page + 1);
    }
  }

  const moreDetails = (assistantReference) => {
    let pathParts = assistantReference?.split("/");
    let userId = pathParts?.[1];
    let assistantId = pathParts?.[3];

    if (userId && assistantId) {
      history.replace(`/assistant/sessions/${userId}/${assistantId}`);
    }
  }

  const formatDate = (date) => {
    let dateObj = new Date(date);
    return dateObj.toISOString().split("T")[0] + ", " + dateObj.toTimeString().split(" ")[0];
  }

  const getDate = (assistant) => {
    if (!isNullOrUndefined(assistant.lastUpdate)) {
      return formatDate(assistant.lastUpdate);
    } else if (!isNullOrUndefined(assistant.creationDate)) {
      return formatDate(assistant?.creationDate);
    }
    return "-";
  }

  const loadDataTable = () => {
    let formatedData = {};
    Object.entries(assistants).forEach(([key2, assistant]) => {
      let entry = {
        name: assistant.name,
        date: getDate(assistant),
        creator: assistant?.session?.userEmail,
        session: assistant?.session?.sessionCode?.publicCode || "-",
        reference: assistant?.reference,
      };
      formatedData["0-" + key2] = entry;
    })
    setData(formatedData)
  }

  return (
    <ListComponent
      strings={strings}
      config={config}
      assistants={assistants}
      page={page}
      nextPage={nextPage}
      prevPage={prevPage}
      moreDetails={moreDetails}
      lang={lang}
      selectLang={selectLang}
      data={data} />
  );
};
export default ListEngine;
