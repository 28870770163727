import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import SecondaryButton from "../../../../../shared/SecondaryButton";

import NameForm from "./NameForm";
const FormComponent = ({ strings, config, blocked, instructor, setInstructor, cancelAction, confirmAction }) => {
  const [nameForm] = NameForm({ strings, instructor, setInstructor });

  const ButtonWrapper = styled.div`
    display: flex;
    place-items: center;
    margin-top: 0.5rem;
  `;

  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  return (
    <div style={{ width: "100%" }}>
      <Title>{instructor.id ? strings.editInstructor : strings.createInstructor}</Title>
      <div style={{ padding: "2vh 3vh" }}>
        {nameForm}
        <ButtonWrapper>
          <SecondaryButton value={strings.cancel} action={cancelAction} style="margin: 0 1vh 0 auto" />
          <Button value={strings.confirm} action={!blocked ? confirmAction : () => {}} style="margin: 0 auto 0 0" />
        </ButtonWrapper>
      </div>
    </div>
  );
};

export default FormComponent;
