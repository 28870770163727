import React, { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import styled from "@emotion/styled";

const CustomPageComponent = ({ strings, lang, page }) => {

    const Wrapper = styled.div`
        width: 90%;
        height: 90%;
        padding: 5%;
        background-color: rgba(0,0,0,0.1);

        h1 {
            font-size: 5vh;
        }

        h2 {
            font-size: 4vh;
        }

        h3 {
            font-size: 3.5vh;
        }
    `

    return (
        <Wrapper>
            {ReactHtmlParser(page?.content?.[lang || "ES"])}
        </Wrapper>
    );
}

export default CustomPageComponent;