import React, { useState, useEffect } from "react";
import Firebase from "../../../../../assets/Firebase";
import LoadingScreen from "../../../../shared/LoadingScreen";
import defaultImg from "../../../../../assets/images/upwine-logo.svg";
import CellarRoutes from "./CellarRoutes";

const CellerEngine = ({ strings, config }) => {
  let f = new Firebase();
  const [celler, setCeller] = useState({});
  const [ready, setReady] = useState(false);

  const success = (doc) => {
    setCeller((prevState) => ({
      ...prevState,
      ...doc.data(),
      logoStorageRef: doc.data().logo,
      currency: doc.data().currency ? (doc.data().currency == "EUR" ? "€" : doc.data().currency) : "€",
      storeActive: doc.data().storeActive !== null && doc.data().storeActive !== undefined ? doc.data().storeActive : true,
      satisfactionActive: doc.data().satisfactionActive !== null && doc.data().satisfactionActive !== undefined ? doc.data().satisfactionActive : true
    }));
    doc.data()?.logo ? f.loadImage(doc.data().logo, successLoadImage) : errorLoadImage();
  };

  const successLoadImage = (url) => {
    setCeller((prevState) => ({
      ...prevState,
      logo: url,
    }));
    setReady(true);
  };

  const errorLoadImage = () => {
    setCeller((prevState) => ({
      ...prevState,
      logo: defaultImg,
    }));
    setReady(true);
  };

  useEffect(() => {
    f.loadCeller(success);
  }, [strings]);

  return <>{ready ? <CellarRoutes celler={celler} setCeller={setCeller} strings={strings} config={config} /> : <LoadingScreen />}</>;
};
export default CellerEngine;
