import React from "react";
import styled from "@emotion/styled";
import Button from "./Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Colors from "../../assets/Colors.json";

const DownloadingPopup = ({ strings, headerText, bodyText, onCancel }) => {
  let theme = "lightTheme";
  let colors = Colors[theme];

  const PopupBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  `;

  const Popup = styled.div`
    position: absolute;
    max-width: 40%;
    min-width: 20%;
    max-height: 40%;
    min-height: 10%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${colors.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${colors.accentColor1};
    margin: 1vh auto;
  `;

  return (
    <React.Fragment>
      <PopupBackground>
        <Popup>
          <Header>{headerText ? headerText : strings?.loading}</Header>
          <Divider />
          <p>{bodyText ? bodyText : strings?.thisCanTakeSomeMinutes}</p>
          <CircularProgress style={{ color: colors.accentColor1 }} />

          { onCancel ? 
            <div><Button value={strings?.cancel} action={onCancel} style={{marginTop: "2vh"}}></Button></div>
            :
            <></>
          }
        </Popup>
      </PopupBackground>
    </React.Fragment>
  );
};

export default DownloadingPopup;
