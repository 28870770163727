import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import ExpandImg from "../../../../../../../assets/images/ic_expand.png";
import ContractImg from "../../../../../../../assets/images/ic_contract.png";
import Colors from "../../../../../../../assets/Colors.json";
import YoutubeVideoForm from "./YoutubeVideoForm";
import VimeoVideoForm from "./VimeoVideoForm";
import GeneratedVideoForm from "./GeneratedVideoForm";

const PodcastVideoForm = ({ strings, config, videoTypes, object, setObject, embedded = false }) => {
  const [activeVideo, setActiveVideo] = useState(null);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (object && !object?.[activeVideo]) {
      setObject(activeVideo, {
        platform: "vimeo",
        id: null,
        generated: false
      });
    }
  }, [object, activeVideo, object?.[activeVideo]]);

  useEffect(() => {
    if (videoTypes !== null && videoTypes !== undefined && activeVideo === null) {
      setActiveVideo(Object.keys(videoTypes)[0]);
    }
  }, [videoTypes]);

  const videoPlataforms = {
    //youtube: "Enlace de youtube",
    vimeo: strings.vimeo,
    //generated: strings.generate,
  };

  const backgroundColor = embedded ? "rgb(237, 237, 237)" : "white";
  const secondaryBackgroundColor = embedded ? "rgb(224, 224, 224)" : "rgb(237, 237, 237)";

  const Root = {
    borderTop: "0.1vh solid grey",
    borderBottom: "0.1vh solid grey",
    paddingBottom: "2vh",
  };

  const EmbeddedRoot = {
    marginTop: "2vh",
    borderTop: "0.1vh solid grey",
  };

  const Wrapper = {
    backgroundColor: backgroundColor,
    borderRadius: "0px 6px 6px 6px",
    padding: "2vh",
  };

  const Header = {
    margin: "2vh",
    width: "91%",
    fontSize: "2vh",
    textAlign: "center",
    color: "#6d7278",
    fontWeight: "bold",
    float: "left",
  };

  const PlataformsWrapper = {
    width: "25%",
  };

  const PlataformItem = {
    padding: "1vh 2.5vh",
    backgroundColor: "#d8d8d8",
    cursor: "pointer",
    color: "#6d7278",
    borderBottom: "0px",
    borderRadius: "6px 0px 0px 6px",
    marginBottom: "0.5vh",
    padding: "1.8vh 1vw",
    display: "flex",
  };

  const SelectedPlataformItem = {
    ...PlataformItem,
    backgroundColor: secondaryBackgroundColor,
    color: "black",
  };

  const Content = {
    width: "75%",
    backgroundColor: secondaryBackgroundColor,
    padding: "2vh 2vw",
    borderRadius: "0px 6px 6px 6px",
  };

  const RadioButton = {
    borderRadius: "50%",
    width: "0.7vw",
    height: "0.7vw",
    backgroundColor: "white",
    padding: "0.2vw",
    marginRight: "0.75vw",
  };

  const InsideRadioButton = {
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    backgroundColor: Colors.lightTheme.accentColor1,
  };

  const ExpandButton = styled.div`
    text-align: center;
    width: 9%;
    margin: 1vh;
    padding: 1vh;
    background-color: #d8d8d8;
    cursor: pointer;
    color: #6d7278;
    border-radius: 6px;
  `;

  const IndexActive = styled.div`
    padding: 1vh 2.5vh;
    cursor: pointer;
    background-color: ${backgroundColor};
    color: black;
    border-radius: 6px 6px 0px 0px;
  `;
  const Index = styled.div`
    padding: 1vh 2.5vh;
    background-color: #d8d8d8;
    cursor: pointer;
    color: #6d7278;
    border-bottom: 0px;
    border-radius: 6px 6px 0px 0px;
  `;

  const IndexWrapper = styled.div`
    display: flex;
  `;

  const loadIndex = () => {
    let View = [];

    for (let key in videoTypes) {
      View.push(
        <>
          {key == activeVideo ? (
            <IndexActive key={key}>{videoTypes[key]}</IndexActive>
          ) : (
            <Index
              key={key}
              onClick={() => {
                setActiveVideo(key);
              }}
            >
              {videoTypes[key]}
            </Index>
          )}
        </>
      );
    }
    return View;
  };

  const updatePlataform = (key, value) => {
    let obj = object[activeVideo];
    obj.platform = value;
    obj.id = null;
    obj.generated = value == "generated";
    setObject(key, obj);
  };

  const getPlatform = () => {
    if (object[activeVideo].platform == "vimeo" && object[activeVideo].generated) {
      return "generated";
    } else if (object[activeVideo].platform == null) {
      return "vimeo";
    } else {
      return object[activeVideo].platform;
    }
  };

  return (
    <>
      {Object.keys(videoTypes || {}).length > 0 ? (
        <div style={embedded ? EmbeddedRoot : Root}>
          <div style={{ display: "flex" }}>
            <h4 style={Header}>{strings.podcastVideos}</h4>

            <ExpandButton
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <img src={expanded ? ContractImg : ExpandImg} style={{ height: "2vh", marginRight: "1vh" }} />
              {expanded ? strings.hide : strings.expand}
            </ExpandButton>
          </div>

          {expanded ? (
            <div>
              <IndexWrapper>{loadIndex()}</IndexWrapper>
              <div style={Wrapper}>
                {activeVideo == undefined || object?.[activeVideo] == undefined ? (
                  /* ----- No question selected ------*/
                  <p>No hay ningun video seleccionado</p>
                ) : (
                  /* ----- Question selected ------*/

                  <div style={{ display: "flex" }}>
                    <div style={PlataformsWrapper}>
                      {Object.keys(videoPlataforms).map((key) => {
                        return (
                          <div
                            style={getPlatform() == key ? SelectedPlataformItem : PlataformItem}
                            onClick={() => {
                              updatePlataform(activeVideo, key);
                            }}
                          >
                            <div style={RadioButton}>{getPlatform() == key ? <div style={InsideRadioButton} /> : <></>}</div>
                            <div style={{ margin: "auto 0" }}>{videoPlataforms[key]}</div>
                          </div>
                        );
                      })}
                    </div>

                    <div style={Content}>
                      {getPlatform() == "youtube" ? (
                        <YoutubeVideoForm strings={strings} object={object} config={config} updateState={setObject} />
                      ) : getPlatform() == "generated" || object[activeVideo].generated ? (
                        <GeneratedVideoForm strings={strings} config={config} object={object} activeVideo={activeVideo} updateState={setObject} />
                      ) : (
                        <VimeoVideoForm strings={strings} config={config} object={object} activeVideo={activeVideo} updateState={setObject} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div >
      ) : (
        <></>
      )}
    </>
  );
};
export default PodcastVideoForm;
