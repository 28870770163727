import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../shared/Button";
import TextInput from "../../../../shared/TextInput";
import Tooltip from "@material-ui/core/Tooltip";
import flagCA from "../../../../../assets/images/flagCA.png";
import flagES from "../../../../../assets/images/flagES.png";
import flagEN from "../../../../../assets/images/flagEN.png";
import flagDE from "../../../../../assets/images/flagDE.png";
import flagFR from "../../../../../assets/images/flagFR.png";
import flagIT from "../../../../../assets/images/flagIT.png";
import flagPL from "../../../../../assets/images/flagPL.png";
import flagPT from "../../../../../assets/images/flagPT.png";
import Colors from "../../../../../assets/Colors.json";

const Centered = styled.div`
  margin: auto;
  width: 30%;
  height: 40%;
  padding: 3vh;
  background: lightgray; 
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  height: 100%;
`;

const DivCol = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  margin: 3vh;
`;


const Title = styled.h1`
  font-weight: bolder;
  font-size: 4vh;
`;

const SubTitle = styled.h1`

`;

const Icon = styled.img`
    height: 5vh;
    width: 5vh;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.1s;
    padding: 1vh;
    margin: 2vh;
    &:hover {
      background-color: ${Colors.lightTheme.lightGrey};
    }
`;

const Tag = styled.div`
  padding: 0.5vh;
  font-size: 1.7vh;
`;

const EmailForm = ({ config, strings, data, setData, actions, selectLang, lang }) => {

    const Icons = {
        ES: flagES,
        EN: flagEN,
        CA: flagCA,/* 
        DE: flagDE,
        FR: flagFR,
        IT: flagIT,
        PL: flagPL,
        PT: flagPT, */
    }

    return (
        <Centered>
            <Title>{strings.loginAssistant}</Title>
            <DivRow>
                <DivCol>
                    <SubTitle>{strings.insertEmail}</SubTitle>
                    <TextInput
                        id="nameInput"
                        width="100%"
                        value={data?.email}
                        placeholder={strings.user}
                        onChange={(e) => {
                            let val = e.target.value;
                            setData((prev) => ({
                                ...prev,
                                email: val
                            }));
                        }} />
                    <TextInput
                        id="passwordInput"
                        width="100%"
                        value={data?.password}
                        placeholder={strings.password}
                        onChange={(e) => {
                            let val = e.target.value;
                            setData((prev) => ({
                                ...prev,
                                password: val
                            }));
                        }} />
                    <Button value={strings.accept} action={actions.login} />
                </DivCol>
                <div style={{ border: "1px solid gray", height: "100%" }}></div>
                <DivCol>
                    <SubTitle>{strings.insertEmail2}</SubTitle>
                    <TextInput
                        id="nameInput"
                        width="100%"
                        value={data?.email}
                        placeholder={strings.mailUser}
                        onChange={(e) => {
                            let val = e.target.value;
                            setData((prev) => ({
                                ...prev,
                                email: val
                            }));
                        }} />
                    <TextInput
                        id="nameInput2"
                        width="100%"
                        value={data?.userEmail}
                        placeholder={strings.mailCreator}
                        onChange={(e) => {
                            let val = e.target.value;
                            setData((prev) => ({
                                ...prev,
                                userEmail: val
                            }));
                        }} />
                    <Button value={strings.accept} action={actions.loginMail} />
                </DivCol>
            </DivRow>
            <Tooltip title={<Tag>{strings.tooltipLang}</Tag>} aria-label="add" arrow>
                <Icon src={Icons[lang]} onClick={selectLang} />
            </Tooltip>
        </Centered >
    );
};
export default EmailForm;