import React, { useState, useEffect } from "react";
import FormComponent from "./FormComponent";
import { BrowserRouter as Router, useHistory, useParams } from "react-router-dom";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import { validateProduct } from "./ProductValidator";

const FormEngine = ({ strings, config }) => {
    let { id } = useParams();
    let history = useHistory();
    let ba = new BackendAccessor();

    const [product, setProduct] = useState(null);
    const [productTypes, setProductTypes] = useState(null);
    const [loadingText, setLoadingText] = useState(null);

    const loadProduct = () => {
        ba.getProductById(
            id,
            (product) => {
                setProduct(product);
                console.log("Product", product);
            },
            (error) => {
                console.error("Error loading product: ", error);
                config.popupAlert("Error", "Error loading product");
            }
        )
    }

    const createProduct = () => {
        console.log("Local product created");
        setProduct({});
    }

    const loadProductTypes = () => {
        ba.getPublicProductsTypes(
            (productTypes) => {
                setProductTypes(productTypes);
                console.log("Products types", productTypes);
            },
            (error) => {
                console.error("Error loading products: ", error);
                config.popupAlert("Error", "Error loading products");
            }
        )
    }

    useEffect(() => {
        if (product == null) {
            if (id == null) {
                createProduct();
            } else {
                loadProduct();
            }
        }
        if (productTypes == null) {
            loadProductTypes();
        }
    }, [strings, id]);

    const confirmAction = () => {
        console.info("Trying to update product", product);

        setLoadingText("Guardando producto...");
        let error = validateProduct(strings, product);

        if (error != null) {
            setLoadingText(null);
            config.popupAlert(strings.error, error);
        } else {
            if (id == null) {
                ba.createProduct(product, onSuccess, onError);
            } else {
                ba.updateProductById(id, product, onSuccess, onError);
            }
        }
    }

    const onSuccess = (data) => {
        setLoadingText(null);
        console.info("Product updated/created successfully: ", data);
        config.update("modificated", false);
        config.popupAlert(strings.success, strings.successMsg, () => {
            history.replace(`/dashboard/products/list`);
        });
    };

    const onError = (error) => {
        setLoadingText(null);
        console.error("Error updating/creating product", error);
        config.popupAlert(strings.error, "Ha ocurrido un error");
    }

    const cancelAction = () => {
        config.isModificated(() => {
            history.replace(`/dashboard/products/list`);
        });
    };

    const setProductFiltered = (input) => {
        if (!config.modificated) {
            config.update("modificated", true);
        }
        setProduct(input);
    };

    const updateProduct = (key, value) => {
        setProductFiltered((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    return (
        <>
            {product != null && productTypes != null ? (
                <FormComponent
                    strings={strings}
                    config={config}
                    product={product}
                    productTypes={productTypes}
                    updateProduct={updateProduct}
                    confirmAction={confirmAction}
                    cancelAction={cancelAction}
                />
            ) : (
                <LoadingScreen />
            )}
            {loadingText != null ? <DownloadingPopup strings={strings} headerText={loadingText} /> : <></>}
        </>
    );
};

export default FormEngine;
