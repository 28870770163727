import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import TerciaryButton from "../../../../../shared/TerciaryButton";
import TastingForm from "./TastingForm";
import ProductsForm from "./ProductsForm/ProductsForm";
import SurveyForm from "./SurveyForm/SurveyForm";
import PodcastVideoForm from "./PodcastVideosForm/PodcastVideoForm";

const FormComponent = ({ strings, config, tasting, setTasting, type, offlineAllowed, products, assessmentSheets, getPublicLink, confirmAction, cancelAction }) => {
  const videoTypes = {
    podcastPresentationVideo: strings.presentation,
    podcastGoodbyeVideo: strings.goodbye,
  };

  const setVideoParam = (key, value) => {
    setTasting((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getTitle = () => {
    if (tasting?.reference) {
      if (type === "podcast") return strings.editPodcastTast;
      else if (type == "live") return strings.editConventionalTast;
      else return strings.editTast;
    } else {
      if (type === "podcast") return strings.createPodcastTast;
      else if (type == "live") return strings.createConventionalTast;
      else return strings.createTast;
    }
  };

  const Title = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const ButtonWrapper = styled.div`
    text-align: center;
    margin-top: 1rem;
  `;

  return (
    <div style={{ width: "100%" }}>
      <Title>{getTitle()}</Title>

      <div style={{ padding: "0.5rem 2rem" }}>

        <TastingForm strings={strings} config={config} tasting={tasting} setTasting={setTasting} type={type} offlineAllowed={offlineAllowed} getPublicLink={getPublicLink} />

        <SurveyForm strings={strings} config={config} tasting={tasting} setTasting={setTasting} />

        {type === "podcast" ? (
          <PodcastVideoForm strings={strings} config={config} videoTypes={videoTypes} object={tasting} setObject={setVideoParam} />
        ) : (
          <></>
        )}

        <ProductsForm strings={strings} config={config} type={type} tasting={tasting} setTasting={setTasting} products={products} assessmentSheets={assessmentSheets} />

        <ButtonWrapper>
          <TerciaryButton style={{ marginRight: "2vh" }} action={cancelAction} value={strings.cancel} />
          <Button action={confirmAction} value={strings.confirm} />
        </ButtonWrapper>
      </div>
    </div>
  );
};
export default FormComponent;
