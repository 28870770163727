import React from "react";
import styled from "@emotion/styled";
import Button from "../../../shared/Button";
import SecondaryButton from "../../../shared/SecondaryButton"
import Colors from "../../../../assets/Colors.json"

const DemoDataPopupForm = ({ strings, visible, accept, cancel }) => {
  const PopupBackground = styled.div`
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
  `;

  const Popup = styled.div`
    position: absolute;
    max-width: 40%;
    min-width: 20%;
    max-height: 40%;
    min-height: 10%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${Colors.lightTheme.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${Colors.lightTheme.accentColor1};
    margin: 1vh auto;
  `;

  return (
    <React.Fragment>
      {visible ? (
        <PopupBackground>
          <Popup>
            <Header>{strings.header}</Header>
            <Divider />
            <p>{strings.demoDataText}</p>
            <div style={{ marginBottom: "1rem" }}>
              <input type="checkbox" id="demoDataPopupnotShowAgain" />
              <label for="demoDataPopupnotShowAgain">{strings.notShowAgain}</label>
            </div>
            <div>
              <SecondaryButton value={strings.cancel} action={cancel} style={{ marginRight: "0.5vw" }} />
              <Button value={strings.accept} action={accept} style={{ marginLeft: "0.5vw" }} />
            </div>
          </Popup>
        </PopupBackground>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default DemoDataPopupForm;
