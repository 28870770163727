import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "../../../../shared/Button";
import Table from "../../../../shared/Table";
import Colors from "../../../../../assets/Colors.json";
import CopyIcon from "../../../../../assets/images/copy-clipboard.svg";
import DeleteIcon from "../../../../../assets/images/delete-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";

const Header = styled.div`
    font-weight: bolder;
    font-size: 4vh;
    padding-bottom: 0.5rem;
    text-align: center;
    color: ${Colors.lightTheme.accentColor1};
`;

const Subheader = styled.div`
    margin: 1.5rem 0.5rem 1rem 0rem;
    font-weight: bold;
    font-size: 2.5vh;
    color: ${Colors.lightTheme.accentColor1};
    padding-bottom: 0.5rem;
    border-bottom: 1px solid gray;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  display: table;
`;

const Title = styled.div`
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2vh 5vh;
`;

const Line = styled.div`
  color: black;
  margin: 1.5vh 0vh;
  text-align: center;
`;

const Icon = styled.img``;

const IntegrationForm = ({ strings, apiKeys, copyKey, deleteKey, generateApiKey, downloadDocumentation }) => {
    const headers = {
        name: strings.name,
        creationDate: strings.creationDate,
        apiKey: strings.apiKey,
        actions: strings.actions,
    }

    const getFormatedKeys = () => {
        let formated = {};
        apiKeys.map((key) => {
            formated[key.id] = {
                name: key.name,
                creationDate: new Date(key.creationDate),
                apiKey: key.apiKey,
            };
        });
        return formated;
    }

    const TableStyles = `
        width: 100%;
        max-width: 100%; 
        td {
            overflow: auto;
            padding: 2vh 2vh;
            max-width: 20vw;
            white-space: nowrap;
        }
    `;

    let actions = [
        {
            title: (
                <Tooltip title={strings.copy} aria-label="add">
                    <Icon src={CopyIcon} />
                </Tooltip>
            ),
            action: copyKey,
        },
        {
            title: (
                <Tooltip title={strings.delete} aria-label="add">
                    <Icon src={DeleteIcon} />
                </Tooltip>
            ),
            action: deleteKey,
        }
    ];

    return (
        <Container>
            <Title>{strings.integration}</Title>

            <Wrapper>
                <Header>{strings.apiAccess}</Header>
                <Line>{strings.apiAccessHint}</Line>
                <Button
                    value={strings.documentation}
                    style={{ display: "flex", margin: "0 auto", width: "fit-content" }}
                    action={downloadDocumentation} />

                <Subheader>{strings.yourApiKeys}</Subheader>

                <Table
                    header={headers}
                    sortOrder={"descending"}
                    sortByField={"creationDate"}
                    noElementsText={strings.noneList}
                    data={getFormatedKeys()}
                    ready={true}
                    styles={TableStyles}
                    actions={actions} />

                <Button value={strings.generateApiKey} action={generateApiKey} style={{ display: "flex", margin: "2vh auto auto", width: "fit-content" }} />
            </Wrapper>
        </Container>
    );
};

export default IntegrationForm;
