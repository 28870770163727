import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../shared/Button";
import ReactPlayer from "react-player";
import Colors from "../../../../../assets/Colors.json";

const MessagePopup = ({ strings, popupData, setPopupData }) => {
  const PopupBackground = styled.div`
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.8);
  `;

  const Popup = styled.div`
    position: absolute;
    width: 50%;
    height: 60%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.9);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${Colors.lightTheme.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${Colors.lightTheme.accentColor1};
    margin: 1vh auto;
  `;

  const Player = {
    width: "80%",
    height: "70%",
    margin: "auto",
    padding: "1.5vw",
  };

  const close = () => {
    setPopupData({ visible: false });
  };

  return (
    <>
      {popupData.visible ? (
        <PopupBackground>
          <Popup>
            <Header>{popupData.header}</Header>
            <Divider />
            <div style={Player}>
              <ReactPlayer url={"https://www.youtube.com/watch?v=" + popupData.videoID} width="100%" height="100%" />
            </div>
            <div>
              <Button value={strings.close} action={close} />
            </div>
          </Popup>
        </PopupBackground>
      ) : (
        <></>
      )}
    </>
  );
};

export default MessagePopup;
