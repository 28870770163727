import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";
import BackendAccessor from "../../../assets/BackendAccessor";
import LoadingScreen from "../../shared/LoadingScreen";
import CustomPageComponent from "./CustomPageComponent";

const CustomPageEngine = ({ strings, lang }) => {
    let ba = new BackendAccessor();

    let { uid, pageId } = useParams();
    const [page, setPage] = useState(null);

    const loadPage = () => {
        ba.getCustomPageOfUser(uid, pageId, (page) => {
            console.info("Page loaded: ", page);
            setPage(page);
        }, (error) => {
            console.error("Error loading page: ", error);
            setPage({ content: "Page not found" });
        })
    };

    useEffect(() => {
        if (page == null) {
            loadPage();
        }
    }, [strings]);

    if (page?.header?.[lang || "ES"]) {
        document.title = page?.header?.[lang || "ES"];
    }

    return (<div>
        {page ? (
            <CustomPageComponent strings={strings} lang={lang} page={page} />
        ) : (
            <LoadingScreen />
        )}
    </div>);
}

export default CustomPageEngine;