import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";

import Button from "../../../../../shared/Button";
import TextInput from "../../../../../shared/TextInput";
import styled from "@emotion/styled";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";

const LinkInputComponent = ({ strings, config, id, text, theme, updateTheme, updateConfig, confirmAction }) => {
    let ba = new BackendAccessor();
    let history = useHistory();

    const getConfig = () => {
        return theme?.otherConfig?.[id] || {};
    }

    const createPage = () => {
        let newUrl = origin + "/page/" + ba.getUid() + "/" + id;
        updateTheme(id, newUrl);
        updateConfig(id, "customPage", true);
    }

    const editPage = () => {
        confirmAction(() => {
            config.hideDownloadingPopup();
            config.update("modificated", false);
            history.replace("/dashboard/theme/editCustomPage/" + id);
        });
    }

    const deletePage = () => {
        updateTheme(id, null);
        updateConfig(id, "customPage", false);
    }

    const Tag = styled.div`
        margin: 0.5rem 1rem 0.5rem 0rem;
    `;

    const ButtonStyle = {
        marginLeft: "2%",
        fontSize: "1rem",
        padding: "1.5vh"
    }

    const IconBtn = {
        margin: "auto 1vh",
        height: "2.5vh",
    };

    return (
        <div style={{ marginBottom: "1vh" }}>
            <Tag>{text}</Tag>

            <div style={{ display: "flex" }}>
                <TextInput
                    id={id}
                    width={getConfig()?.customPage ? "85%" : "75%"}
                    defaultValue={theme?.[id]}
                    disabled={getConfig()?.customPage}
                    onChange={(e) => {
                        updateTheme(id, e.target.value);
                    }} />

                {getConfig()?.customPage ? (
                    <>
                        <Tooltip title={strings.deletePage} aria-label="add">
                            <img src={DeleteIcon} style={IconBtn} onClick={deletePage} />
                        </Tooltip>
                        <Tooltip title={strings.editPage} aria-label="add">
                            <img src={EditIcon} style={IconBtn} onClick={editPage} />
                        </Tooltip>
                    </>
                ) : (
                    <Button value={strings.createPage} width={"15%"} style={ButtonStyle} action={createPage} />
                )}

            </div>
        </div>
    );
};
export default LinkInputComponent;