import React from "react";
import styled from "@emotion/styled";
import DynamicParamField from "./DynamicParamField";
import TipIcon from "../../../../../../../assets/images/tip-icon.svg";
import Button from "../../../../../../shared/Button";
import BackendAccessor from "../../../../../../../assets/BackendAccessor";


const DynamicParamsForm = ({ strings, config, product, productTypes, updateProduct }) => {
  let ba = new BackendAccessor();

  let addParameter = () => {
    let properties = product?.properties || {};
    let newId = ba.createUid();
    properties[newId] = {};
    updateProduct("properties", properties);
  }

  const ElementWrapper = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  };

  const Element = {
    width: "49%",
    margin: "0.5%"
  }

  const Tip = {
    color: "rgb(129 127 127)",
    margin: "auto",
    padding: "3vh",
    display: "flex"
  }

  const TipIconStyle = {
    height: "3vh",
    padding: "0.5vh",
    margin: "auto",
  }

  const ButtonWrapper = {
    width: "100%",
    textAlign: "center",
    padding: "0.5vw 1vw"
  };

  const BoldTag = styled.div`
    margin: 1vh;
    font-size: 2.3vh;
    font-weight: bold;
  `;

  return (
    <div>
      <BoldTag>{strings.productAttributes}</BoldTag>
      <div style={ElementWrapper}>
        {
          Object.keys(product?.properties || {}).length === 0 && product.type == null && product.subtype == null ? (
            <div style={Tip}>
              <img src={TipIcon} style={TipIconStyle} />
              <div style={{ margin: "auto" }}>{strings.productAttributesTip}</div>
            </div>
          ) : (
            <></>
          )
        }
        {
          Object.keys(product?.properties || {}).map(key => {
            return (
              <div style={Element}>
                <DynamicParamField strings={strings} config={config} product={product} paramId={key} productTypes={productTypes} updateProduct={updateProduct} />
              </div>
            );
          })
        }
      </div>
      <div style={ButtonWrapper}>
        <Button value={strings.addAttribute} action={addParameter} />
      </div>
    </div>
  );
};
export default DynamicParamsForm;
