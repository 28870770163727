import React, { useState } from "react";
import DemoDataPopupForm from "./DemoDataPopupForm";
import Firebase from "../../../../assets/Firebase";
import BackendAccessor from "../../../../assets/BackendAccessor";
import DownloadingPopup from "../../../shared/DownloadingPopup";

const DemoDataPopupEngine = ({ strings, config, visible }) => {
  let f = new Firebase();
  let ba = new BackendAccessor();

  const [procesing, setProcesing] = useState(false);

  const accept = () => {
    setProcesing(true);
    ba.generateDemoData(onSuccess, onError);
  };

  const cancel = () => {
    let notShowAgain = document.getElementById("demoDataPopupnotShowAgain").checked;

    if (notShowAgain) {
      f.updateCeller({ demoDataLoaded: true }, () => {});
    }
    config.update("demoDataLoaded", true);
  };

  const onSuccess = () => {
    setProcesing(false);
    config.update("demoDataLoaded", true);
    //f.updateCeller({ demoDataLoaded: true }, () => {});
    config.popupAlert(strings.successHeader, strings.successMsg);
  };

  const onError = (error) => {
    setProcesing(false);
    config.update("demoDataLoaded", true);
    config.popupAlert(strings.error, strings.errorHeader);
    console.error("Error", error);
  };

  return <>
    {procesing ?
      <DownloadingPopup strings={strings} headerText={strings.generating} />
      :
      <DemoDataPopupForm strings={strings} visible={visible} accept={accept} cancel={cancel}></DemoDataPopupForm>
    }
  </>;
};

export default DemoDataPopupEngine;
