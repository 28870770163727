import React, { useState, useEffect } from "react";
import Colors from "../../../../../../../assets/Colors.json";
import IconButton from "../../../../../../shared/IconButton";
import MapMarker from "../../../../../../shared/MapMarker";
import BackIcon from "../../../../../../../assets/images/back-arrow-icon.svg";
import Face1 from "../../../../../../../assets/images/face_1.png";
import Face2 from "../../../../../../../assets/images/face_2.png";
import Face3 from "../../../../../../../assets/images/face_3.png";
import Face4 from "../../../../../../../assets/images/face_4.png";
import styled from "@emotion/styled";
import ProductsDetailsWrapperForm from "./ProductsDetailsWrapperForm";

const AssistantDetailsFormComponent = ({ strings, config, sessionData, assistantData, dataParser, goBack }) => {
    const Subheader = styled.div`
        margin: 1.5rem 0.5rem 1rem 0rem;
        font-weight: bold;
        font-size: 2.5vh;
        color: ${Colors.lightTheme.accentColor1};
        padding-bottom: 0.5rem;
        border-bottom: 1px solid gray;
    `;

    const MainTable = styled.table`
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        td {
            padding: 2vh;
        }
        th {
            padding: 2vh;
            text-align: left;
            background-color: rgba(0, 0, 0, 0.1);
        }
        tr:nth-of-type(even) {
            background-color: white;
        }
        tr {
            background-color: rgba(255, 255, 255, 0.5);
        }
    `;

    const DetailsWrapper = styled.table`
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    `;

    const DetailsTable = styled.table`
        height: fit-content;
        flex: 1;
        margin-right: 2vh;
        width: calc(100% - 2vh);
        white-space: nowrap;

        td, th {
            width: auto;
            text-align: start;
            padding: 0.5vh 0
        }
    `;

    const FaceImg = styled.img`
        height: 5vh;
        width: auto;
        margin: auto;
    `;

    const formatDate = (date) => {
        let dateObj = new Date(date);
        return dateObj.toLocaleString("es-ES");
    }

    return (
        <div style={{ width: "100%" }}>
            <div style={{ padding: "1vh 3vh" }}>

                <IconButton
                    action={goBack}
                    icon={BackIcon}
                    text={strings.returnBack}
                    style={{ margin: "1vh" }}
                    iconStyle={{ height: "3vh" }} />

                <Subheader>{strings.dataFromAssistant}</Subheader>
                <DetailsWrapper>
                    <DetailsTable>
                        <tr>
                            <th>{strings.name}:</th>
                            <td>{assistantData?.name ? assistantData?.name : "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.email}:</th>
                            <td>{assistantData?.email ? assistantData?.email : "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.language}:</th>
                            <td>{assistantData?.language ? assistantData?.language : "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.platform}:</th>
                            <td>{assistantData?.platform ? assistantData?.platform : "-"}</td>
                        </tr>
                    </DetailsTable>

                    <DetailsTable>
                        <tr>
                            <th>{strings.age}:</th>
                            <td>{assistantData?.age ? assistantData?.age : "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.gender}:</th>
                            <td>{assistantData?.gender ? assistantData?.gender : "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.creationDate}:</th>
                            <td>{assistantData?.creationDate ? formatDate(assistantData?.creationDate) : "-"}</td>
                        </tr>
                        <tr>
                            <th>{strings.lastUpdate}:</th>
                            <td>{assistantData?.lastUpdate ? formatDate(assistantData?.lastUpdate) : "-"}</td>
                        </tr>
                    </DetailsTable>

                    {/* {assistantData?.geolocation ? (
                        <>
                            <DetailsTable>
                                <tr>
                                    <th>{strings.city}:</th>
                                    <td>{assistantData?.geolocation?.city || "-"}</td>
                                </tr>
                                <tr>
                                    <th>{strings.region}:</th>
                                    <td>{assistantData?.geolocation?.regionName + " (" + assistantData?.geolocation?.region + ")" || "-"}</td>
                                </tr>
                                <tr>
                                    <th>{strings.country}:</th>
                                    <td>{assistantData?.geolocation?.country + " (" + assistantData?.geolocation?.countryCode + ")" || "-"}</td>
                                </tr>
                            </DetailsTable>
                            <div style={{ flex: "1", background: "rgba(255,255,255,0.5)" }}>
                                <MapMarker
                                    lat={assistantData?.geolocation?.lat}
                                    lon={assistantData?.geolocation?.lon} />
                            </div>
                        </>
                    ) : (
                        <></>
                    )} */}
                </DetailsWrapper>

                <Subheader>{strings.surveyAnswers}</Subheader>
                <MainTable>
                    <tr>
                        <th>{strings.question}</th>
                        <th>{strings.answer}</th>
                    </tr>
                    {(assistantData?.answers || []).length == 0 ? (
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>Sin respuestas</td>
                        </tr>
                    ) : (
                        assistantData?.answers || []).map((answer) => {
                            return (
                                <tr>
                                    <td>{dataParser.getQuestionByRef(answer.questionPath)}</td>
                                    <td>{dataParser.getAnswersByIds(answer.questionPath, answer.answers)}</td>
                                </tr>
                            )
                        })}
                </MainTable>

                <Subheader>{strings.productAssessments}</Subheader>

                <ProductsDetailsWrapperForm
                    strings={strings}
                    config={config}
                    sessionData={sessionData}
                    assessments={assistantData?.assessments || {}}
                    dataParser={dataParser} />

                <Subheader>{strings.finalStore}</Subheader>
                <MainTable>
                    <tr>
                        <th>{strings.product}</th>
                        <th>{strings.linkClicked}</th>
                    </tr>
                    {(sessionData?.tasting?.productReferences || []).length > 0 ? (
                        (sessionData?.tasting?.productReferences || []).map(prodRef => {
                            return (
                                <tr>
                                    <td>{dataParser.getProductName(prodRef?.reference) || "-"}</td>
                                    <td>{dataParser.hasStoreClicked(prodRef?.reference) ? strings.yes : strings.no}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>No products found</td>
                        </tr>
                    )}
                </MainTable>

                <Subheader>{strings.finalSatisfaction}</Subheader>
                <MainTable>
                    <tr>
                        <th>{strings.concpet}</th>
                        <th>{strings.answer}</th>
                    </tr>
                    {(assistantData?.satisfactions || []).length == 0 ? (
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>Sin respuestas</td>
                        </tr>
                    ) : (
                        (assistantData?.satisfactions || []).map((satisfaction) => {
                            return (
                                <tr>
                                    <td>{dataParser.getSatisfactionConcept(satisfaction?.satisfactionConceptPath)}</td>
                                    <td>
                                        {satisfaction?.value ?
                                            <FaceImg src={[Face1, Face2, Face3, Face4]?.[satisfaction?.value - 1]} />
                                            :
                                            <div>{strings.notVoted}</div>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    )}
                </MainTable>
            </div >
        </div>
    );
};

export default AssistantDetailsFormComponent;
